import React from "react";
import appConfig from "../../appConfig";

const AuthFooter = () => {
  return (
    <div className="footer" style={{ background: "#000", color: "#fff" }}>
      <div className="container-fluid w-100  d-block">
        <div className="row">
          <div className="col w-100">
            <p className="my-2 text-center" style={{ fontSize: "14px" }}>
              <span>© Reneon Technologies 2024. All rights reserved</span>
              <span
                style={{
                  float: "right",
                  position: "absolute",
                  right: "30px",
                  fontSize: "13px",
                }}
              >
                <a
                  href="https://ivistech.com/term-of-use"
                  target={"_blank"}
                  className="footer_link"
                >
                  Terms of Use
                </a>{" "}
                |{" "}
                <a
                  href="https://ivistech.com/privacy-policy"
                  target={"_blank"}
                  className="footer_link"
                >
                  Privacy Policy
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
