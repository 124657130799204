import { Checkbox, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { userRole } from "../../constant";
import { setUserDetails } from "../../redux/userSlice";
import { apiNotification } from "../../utils";
import EditCustomerProfile from "./EditCustomerProfile";

export default function EditProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user?.userDetails);
  const [IsLoading, setIsLoading] = useState(false);
  const [UpdateProfileData, setUpdateProfileData] = useState({
    name: "",
    email: "",
    phone: "",
    isDisableOfflineNotification: false,
  });
  const { name, email, phone, isDisableOfflineNotification } =
    UpdateProfileData;
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (userDetails) {
      setUpdateProfileData({
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.phone,
        isDisableOfflineNotification: userDetails.isDisableOfflineNotification,
      });
    }
  }, [userDetails]);

  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const onInputChange = (e) => {
    setUpdateProfileData({
      ...UpdateProfileData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(`/user/me`, UpdateProfileData, {
        withCredentials: true,
      });
      if (selectedImage) {
        const addImageResponse = await axios.put(
          `/user/${userDetails._id}/avatarUpload`,
          selectedImage,
          { withCredentials: true }
        );
        dispatch(setUserDetails(addImageResponse.data));
      } else {
        dispatch(setUserDetails(response.data));
      }
      apiNotification({ message: "Profile Updated Successfully" });
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };
  if (userDetails?.role === "user") {
    return <EditCustomerProfile userId={userDetails?._id} />;
  }
  return (
    <Spin spinning={IsLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div>
                  <label className="az-content-label">Profile</label>
                  <span className="d-block py-2">
                    Update profile Information
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <form onSubmit={submitHandler}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={onInputChange}
                        id="name"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onInputChange}
                        id="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1">
                          +6
                        </span>
                        <input
                          type="number"
                          name="phone"
                          value={phone}
                          onChange={onInputChange}
                          className="form-control"
                          id="phone"
                          placeholder="Enter phone number"
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="avatar" className="form-label d-block">
                        Profile Photo
                      </label>

                      {imageUrl && selectedImage ? (
                        <div mt={2} textAlign="center">
                          <div>Image Preview:</div>
                          <img
                            src={imageUrl}
                            alt={selectedImage.name}
                            height="100px"
                          />
                        </div>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleFileUpload(e.target.files[0])
                            }
                          />
                          <label htmlFor="select-image">
                            {userDetails?.avatar ? (
                              <img
                                src={userDetails?.avatar}
                                alt=""
                                height="100px"
                                className="rounded-3"
                              />
                            ) : (
                              <img
                                src="assets/images/avatar.png"
                                alt=""
                                height="100px"
                                className="rounded-3"
                              />
                            )}
                          </label>
                        </>
                      )}
                    </div>
                    {(userDetails?.role === userRole.admin ||
                      userDetails?.role === userRole.superAdmin) && (
                      <div className="mb-3">
                        <Checkbox
                          checked={isDisableOfflineNotification}
                          onChange={(v) =>
                            setUpdateProfileData({
                              ...UpdateProfileData,
                              isDisableOfflineNotification: v.target.checked,
                            })
                          }
                        >
                          Disable offline meter email notifications
                        </Checkbox>
                      </div>
                    )}
                    <div className="float-end">
                      <button type="submit" className="btn btn-success me-1">
                        Update
                      </button>
                      <Link to="/profile" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
