import { notification, Select, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { api, endpoint } from "../../api";
import { apiNotification } from "../../utils";

const CreateAlarm = () => {
  const navigation = useNavigate();
  const userDetails = useSelector((state) => state?.user?.userDetails);

  const [isLoading, setIsLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [devices, setDevices] = useState([]);
  const [CreateAlarmData, setCreateAlarmData] = useState({
    name: "",
    type: "email",
    site: "",
    device: "",
    parameter: "",
    option: "",
    max: "",
    min: "",
    interval: "",
  });

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSiteLocations(userDetails);
    // eslint-disable-next-line
  }, [userDetails]);

  const { name, type, site, device, parameter, option, min, max, interval } =
    CreateAlarmData;
  const onInputChange = (e) => {
    setCreateAlarmData({ ...CreateAlarmData, [e.target.name]: e.target.value });
  };

  const getDevices = async (locationId) => {
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      if (response) {
        setDevices(response.data.results);
      }
    } catch (error) {
      setDevices([]);
    }
  };

  useEffect(() => {
    if (site) {
      getDevices(site);
    } else {
      setDevices([]);
    }
    // eslint-disable-next-line
  }, [site]);

  const getDeviceParameters = async (deviceId) => {
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (device) {
      getDeviceParameters(device);
    }
  }, [device]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!CreateAlarmData?.site) {
      apiNotification({
        message: "Please select Site.",
        warn: true,
      });
      return;
    } else if (!CreateAlarmData?.device) {
      apiNotification({
        message: "Please select Device.",
        warn: true,
      });
      return;
    } else if (!CreateAlarmData?.parameter) {
      apiNotification({
        message: "Please select Parameter.",
        warn: true,
      });
      return;
    } else if (!CreateAlarmData?.interval) {
      apiNotification({
        message: "Please select Interval.",
        warn: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await api.post(endpoint.notification, {
        ...CreateAlarmData,
        min: Number(CreateAlarmData.min || 0),
        max: Number(CreateAlarmData.max || 0),
        interval: Number(CreateAlarmData.interval || 0),
      });
      notification.success({
        message: "Notification has been created.",
        placement: "bottomLeft",
      });
      navigation("/alarm-summary");
    } catch (error) {
      notification.warning({
        message: error?.response.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    document.title = "IVIS PMS - Create New Alarm";
  }, []);

  return (
    <div className="alarm-page">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="card p-3 mb-3">
            <h6>Create New Alarm</h6>
            <Spin spinning={isLoading}>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Alarm Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={onInputChange}
                        className="form-control"
                        id="name"
                        placeholder="Enter Alarm Name"
                        required
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="type" className="form-label">
                        Alarm Type
                      </label>
                      <select
                        name="type"
                        id="type"
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option selected disabled>
                          Select Alarm Type
                        </option>
                        <option value="email">Email Notification Only</option>
                      </select>
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="site" className="form-label">
                        Site Location
                      </label>
                      <select
                        name="site"
                        id="site"
                        defaultValue={site}
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option> Select Site Location</option>
                        {siteLocations?.length &&
                          siteLocations.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="device" className="form-label">
                        Meter
                      </label>
                      <Select
                        name="device"
                        defaultValue={device || null}
                        className="w-100 "
                        size="large"
                        onChange={(device) =>
                          setCreateAlarmData({ ...CreateAlarmData, device })
                        }
                        placeholder="Select Meter"
                        showSearch
                        filterOption={(input, option) => {
                          return (
                            option?.children
                              ? option?.children.toLowerCase()
                              : ""
                          ).includes(input?.toLowerCase());
                        }}
                      >
                        {devices?.length &&
                          devices.map((item, index) => (
                            <Select.Option value={item._id} key={index}>
                              {`${item.name} ${
                                item?.customer?.name
                                  ? `(${item?.customer?.customerId})`
                                  : ""
                              }`}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="parameter" className="form-label">
                        Meter Parameter
                      </label>
                      <select
                        name="parameter"
                        id="parameter"
                        defaultValue={parameter}
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option>Select Meter Parameter</option>
                        {deviceParameters?.length &&
                          deviceParameters.map((item) => (
                            <option value={item._id} key={item?._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="option" className="form-label">
                        Alarm Notification
                      </label>
                      <select
                        name="option"
                        id="option"
                        className="form-select"
                        defaultValue={option}
                        onChange={onInputChange}
                      >
                        <option>Select Alarm Notification Option</option>
                        <option value="min">Min</option>
                        <option value="max">Max</option>
                        <option value="range">Range</option>
                      </select>
                    </div>
                    <div className="row mb-3">
                      {option === "min" && (
                        <div className="col-md-12">
                          <label htmlFor="value" className="form-label">
                            Set Value
                          </label>
                          <input
                            type="number"
                            name="min"
                            value={min}
                            className="form-control"
                            id="value"
                            placeholder="Enter minimum value to trigger alarm"
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      )}
                      {option === "max" && (
                        <div className="col-md-12">
                          <label htmlFor="value" className="form-label">
                            Set Value
                          </label>
                          <input
                            type="number"
                            name="max"
                            value={max}
                            className="form-control"
                            id="value"
                            placeholder="Enter maximum value to trigger alarm"
                            onChange={onInputChange}
                            required
                          />
                        </div>
                      )}
                      {option === "range" && (
                        <>
                          <div className="col-md-6">
                            <label htmlFor="value" className="form-label">
                              Start Value
                            </label>
                            <input
                              type="number"
                              name="min"
                              value={min}
                              className="form-control"
                              id="value"
                              placeholder="Enter minmum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="value" className="form-label">
                              End Value
                            </label>
                            <input
                              type="number"
                              name="max"
                              value={max}
                              className="form-control"
                              id="value"
                              placeholder="Enter maximum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                        </>
                      )}
                      {/* <div className="col-md-6"></div> */}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="interval" className="form-label">
                        Set Trigger Interval
                      </label>
                      <select
                        name="interval"
                        id="interval"
                        className="form-select"
                        defaultValue={interval}
                        onChange={onInputChange}
                      >
                        <option>Select Alarm Trigger Interval</option>
                        <option value="1">1 min</option>
                        <option value="15">15 min</option>
                        <option value="30">30 min</option>
                        <option value="60">1 hour</option>
                        <option value="720">12 hours</option>
                        <option value="1440">24 hours</option>
                      </select>
                    </div>
                    <div className="mb-3 float-end">
                      <button className="btn btn-success me-2" type="submit">
                        Create Alarm
                      </button>
                      <Link to="/alarm-summary" className="btn btn-secondary">
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAlarm;
