import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const ForgotPassword = () => {
  const [ErrorMessage, setErrorMessage] = useState();
  const [SuccessMessage, setSuccessMessage] = useState();
  const navigate = useNavigate();
  const [recoverData, setRecoverData] = useState({
    email: "",
  });
  const { email } = recoverData;
  const onInputChange = (e) => {
    setRecoverData({ ...recoverData, [e.target.name]: e.target.value });
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    const response = await axios
      .post(`/user/forgot-password`, recoverData, { withCredentials: true })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 401 || 500) {
            setErrorMessage("Email Not Found!");
            setTimeout(() => {
              setErrorMessage();
            }, 2000);
          }
          console.log(error.response.headers);
        }
      });
    const data = response.data;
    if (data) {
      setSuccessMessage(
        "A password reset link has been sent to this email address"
      );
      setTimeout(() => {
        setSuccessMessage();
        navigate("/");
      }, 2000);
    } else {
    }
  };

  useEffect(() => {
    document.title = "IVIS – Prepaid Monitoring System (PMS)";
  }, []);

  let background = "/img/bg.jpg";
  return (
    <div>
      <div
        className="az-content-wrapper p-0"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div>
          <div className="az-signin-wrapper">
            <div className="az-card-signin">
              <h1 className="az-logo">
                <img
                  src="/img/beta-logo.png"
                  alt="logo"
                  style={{ height: "80px" }}
                />
              </h1>
              <div className="az-signin-header">
                <h4>Forgot Password?</h4>
                {ErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {ErrorMessage}
                  </div>
                )}
                {SuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {SuccessMessage}
                  </div>
                )}
                <form onSubmit={SubmitHandler}>
                  <div className="form-group">
                    <label>Enter Email</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="mb-3 float-end">
                    <button className="btn btn-primary px-5 me-2" type="submit">
                      Submit
                    </button>
                    <Link to="/" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </div>
                </form>
              </div>
              <div className="az-signin-footer text-center mt-2">
                <p>Version 2.1.0</p>
                {/* <p>Version 2.0 | {new Date().getFullYear()}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
