import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  View,
  Font,
} from "@react-pdf/renderer";

import { UserStatisticsPdf } from "./UserStatisticsPdf";
import { DateCyclePdf } from "./DateCyclePdf";
import { FinancialStatisticsPdf } from "./FinancialStatisticsPdf ";
import { PastSixMonthStatisticsPdf } from "./PastSixMonthStatisticsPdf";
import { HeaderPdf } from "./HeaderPdf";

import regulerRobot from "./Roboto/Roboto-Regular.ttf";
import boldRoboto from "./Roboto/Roboto-Bold.ttf";

Font.register({
  family: "roboto",
  fonts: [{ src: regulerRobot }, { src: boldRoboto, fontWeight: "bold" }],
});

export const FinancePdf = ({ report }) => {
  const styles = StyleSheet.create({
    page: {
      padding: "40px 70px",
      fontFamily: "roboto",
    },
    firstSingleRowOfAdminInfo: {
      marginBottom: "15px",
      display: "flex",
      flexDirection: "row",
    },
    singleRowOfAdminInfo: {
      marginBottom: "1px",
      display: "flex",
      flexDirection: "row",
    },
    rightTextAdminInfo: {
      fontSize: "12px",
      width: "40%",
    },
    leftTextAdminInfo: {
      fontSize: "12px",
      width: "50%",
    },
    tableRow: {
      margin: "10px 0",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#DEEAF6",
      border: "1px solid black",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderLeft: "1px solid black",
    },
    sectionTitle: {
      fontSize: "12px",
      marginBottom: "10px",
      fontWeight: "bold",
    },
    sectionFooter: {
      fontSize: "12px",
      marginTop: "280px",
      textAlign: "center",
    },
  });

  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <HeaderPdf pageNumber="1/2" />

        <Text style={styles.sectionTitle}>CUSTOMER INFORMATION</Text>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Report Generation Date</Text>
          <Text style={styles.leftTextAdminInfo}>: {report.createdAt}</Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Site Name</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.siteName || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Admin Name</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.adminNames || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Total No. of Users</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.totalCustomer || "--"}
          </Text>
        </View>
        <DateCyclePdf report={report} />
        <UserStatisticsPdf report={report} />
        <FinancialStatisticsPdf report={report} />
      </Page>
      <Page size={"A4"} style={styles.page}>
        <HeaderPdf pageNumber="2/2" />
        <PastSixMonthStatisticsPdf report={report} />
        <Text style={styles.sectionFooter}>
          THIS IS AN ELECTRONICALLY GENERATED REPORT FROM IVIS.COM.MY
        </Text>
      </Page>
    </Document>
  );
};
