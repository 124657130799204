import { DatePicker, Input, notification, Spin } from "antd";
import axios from "axios";
import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Button as AntdButton } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import appConfig from "../../appConfig";
import { DataTable } from "../../components";
import { numberFormater } from "../../utils";

const InstallmentReport = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setSearchValue({ ...searchValue, monthYear: "", year: "", [name]: "" });
    } else {
      setSearchValue({
        ...searchValue,
        monthYear: "",
        year: "",
        [name]: value,
      });
    }
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocation(response.data);
    } catch (error) {}
  };

  const onChangeMonth = (_, dateString) => {
    // console.log(dateString);
    setSearchValue({ ...searchValue, monthYear: dateString });
  };
  const onChangeYear = (_, dateString) => {
    setSearchValue({ ...searchValue, year: dateString });
  };
  const getInstallationReport = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/report/get-installment-report`, {
        withCredentials: true,
        params: {
          limit,
          page,
          ...searchValue,
        },
      });
      setCustomerData(
        data.results.map((item) => {
          item.period = searchValue.monthYear || searchValue.year;
          return item;
        })
      );
      setTotal(data.count);
    } catch (error) {}
    setIsLoading(false);
  };

  const getCsvReport = async () => {
    setIsCsvLoading(true);
    try {
      const { data } = await axios.get(`/report/get-installment-report`, {
        withCredentials: true,
        params: {
          limit: 40000,
          page: 1,
          ...searchValue,
        },
      });
      let arr = [];
      data.results.map((item) => {
        item.customerId = item.customerId;
        item.name = item.name;
        // deposit
        item.depositType =
          item?.plan?.depositType === "Yes"
            ? "Monthly"
            : item?.plan?.depositType === "No"
            ? "Upfront"
            : "N/A";
        item.depositePeriod =
          item?.plan?.depositType === "Yes" &&
          item?.plan?.depositInstallmentPeriod
            ? item?.plan?.depositInstallmentPeriod
            : "";
        item.depositTotalPaid =
          item?.plan?.depositType === "Yes"
            ? numberFormater(item.allTimeDepositeFee)
            : "";
        item.depositAmountPaid =
          item?.plan?.depositType === "N/A"
            ? ""
            : numberFormater(item.inTimeDepositeFee);
        item.depositBalance =
          item?.plan?.depositType === "N/A"
            ? ""
            : numberFormater(300 - item?.allTimeDepositeFee);
        // wiring
        item.internalWiringType =
          item?.plan?.internalWiringType === "Yes"
            ? "Monthly"
            : item?.plan?.internalWiringType === "No"
            ? "Upfront"
            : "N/A";
        item.installmentPeriod =
          item?.plan?.internalWiringType === "Yes" &&
          item?.plan?.internalInstallmentPeriod
            ? item?.plan?.internalInstallmentPeriod
            : "";
        item.installmentTotalPaid =
          item?.plan?.internalWiringType === "Yes"
            ? numberFormater(item.allTimeInternalWiringFee)
            : "";
        item.installmentAmountPaid =
          item?.plan?.depositType === "N/A"
            ? ""
            : numberFormater(item.inTimeInternalWiringFee);
        item.installmentBalance =
          item?.plan?.depositType === "N/A"
            ? ""
            : numberFormater(816 - item?.allTimeInternalWiringFee);

        arr.push(item);
      });
      setCsvData(arr);
    } catch (error) {}
    setIsCsvLoading(false);
  };

  // console.log(csvData);
  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      center: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      center: true,
    },
    {
      title: "Deposit Type",
      dataIndex: "plan",
      key: "plan",
      render: (plan) =>
        plan?.depositType === "Yes"
          ? "Monthly"
          : plan?.depositType === "No"
          ? "Upfront"
          : "N/A",
      align: "center",
    },
    {
      title: "Period",
      key: "Period",
      render: (_, { plan }) => {
        return plan?.depositType === "Yes" && plan?.depositInstallmentPeriod
          ? `${plan.depositInstallmentPeriod}`
          : "-";
      },
      align: "center",
    },
    {
      title: "Total Paid (RM)",
      dataIndex: "allTimeDepositeFee",
      key: "allTimeDepositeFee",
      render: (value, { plan }) =>
        plan?.depositType === "Yes" ? numberFormater(value) : "-",
      align: "right",
    },
    {
      title: "Amount Paid (RM)",
      dataIndex: "inTimeDepositeFee",
      key: "inTimeDepositeFee",
      render: (value, { plan }) =>
        plan?.depositType === "N/A" ? "-" : numberFormater(value),
      align: "right",
    },
    {
      title: "Outstanding (RM)",
      key: "Balance",
      render: (_, { allTimeDepositeFee, plan }) =>
        plan?.depositType === "N/A"
          ? "-"
          : numberFormater(300 - allTimeDepositeFee),
      align: "right",
    },
    {
      title: "Internal Wiring Type",
      dataIndex: "plan",
      key: "plan1",
      render: (plan) =>
        plan?.internalWiringType === "Yes"
          ? "Monthly"
          : plan?.internalWiringType === "No"
          ? "Upfront"
          : "N/A",
      align: "center",
    },
    {
      title: "Period",
      key: "Period1",
      render: (_, { plan }) =>
        plan?.internalWiringType === "Yes" && plan?.internalInstallmentPeriod
          ? `${plan?.internalInstallmentPeriod}`
          : "-",
      align: "center",
    },
    {
      title: "Total Paid (RM)",
      dataIndex: "allTimeInternalWiringFee",
      key: "allTimeInternalWiringFee",
      render: (value, { plan }) =>
        plan?.internalWiringType === "Yes" ? numberFormater(value) : "-",
      align: "right",
    },
    {
      title: "Amount Paid (RM)",
      dataIndex: "inTimeInternalWiringFee",
      key: "inTimeInternalWiringFee1",
      render: (value, { plan }) =>
        plan?.depositType === "N/A" ? "-" : numberFormater(value),
      align: "right",
    },
    {
      title: "Outstanding (RM)",
      key: "Balance1",
      render: (_, { allTimeInternalWiringFee, plan }) =>
        plan?.depositType === "N/A"
          ? "-"
          : numberFormater(816 - allTimeInternalWiringFee),
      align: "right",
    },
  ];

  useEffect(() => {
    getSiteLocation();
    getInstallationReport();
    getCsvReport();
    document.title = `${appConfig.name} - Installation Reports`;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInstallationReport();
  }, [limit, page]);

  const disabledSearch =
    (!searchValue.monthYear && !searchValue.year) || !searchValue?.site;

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Installment Report</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select"
                    onChange={onChangeSearch}
                    value={searchValue.site}
                    name="site"
                  >
                    <option label="Select Location"></option>
                    {siteLocation &&
                      siteLocation.length > 0 &&
                      siteLocation.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-select"
                    onChange={onChangeSearch}
                    value={searchValue.searchType}
                    name="searchType"
                  >
                    <option label="Select Period Type"></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {searchValue.searchType === "monthly" && (
                  <div className="col-md-2">
                    <DatePicker
                      onChange={onChangeMonth}
                      picker="month"
                      format={"MMM, YYYY"}
                      value={
                        searchValue?.monthYear
                          ? dayjs(searchValue.monthYear, "YYYY-MM-DD")
                          : null
                      }
                      allowClear
                      size="large"
                      disabledDate={disabledDate}
                    />
                  </div>
                )}
                {searchValue.searchType === "yearly" && (
                  <div className="col-md-2">
                    <DatePicker
                      onChange={onChangeYear}
                      picker="year"
                      format={"YYYY"}
                      value={
                        searchValue?.year
                          ? dayjs(searchValue.year, "YYYY-MM-DD")
                          : null
                      }
                      allowClear
                      size="large"
                      disabledDate={disabledDateYear}
                    />
                  </div>
                )}
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={() => {
                      getInstallationReport();
                      getCsvReport();
                    }}
                    disabled={disabledSearch}
                  >
                    Search
                  </Button>
                </div>
                {searchValue.searchType !== "yearly" &&
                  searchValue.searchType !== "monthly" && (
                    <div className="col-md-2"></div>
                  )}
                <div className="col-md-2 text-right">
                  <AntdButton
                    // onClick={getInstallationReport}
                    disabled={disabledSearch || !csvData.length}
                    loading={isCsvLoading}
                  >
                    <CSVLink
                      headers={csvHeaders}
                      data={csvData}
                      filename={`Installment Report ${
                        searchValue.monthYear || searchValue.year
                      }.csv`}
                      role="button"
                      style={{
                        cursor:
                          disabledSearch || !csvData.length
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        // e.preventDefault();

                        if (disabledSearch || !csvData.length)
                          e.preventDefault();
                      }}
                    >
                      CSV
                    </CSVLink>
                  </AntdButton>
                </div>

                <div className="col-md-12 mt-4">
                  <DataTable
                    dataSource={customerData}
                    columns={columns}
                    rowKey={(record) => record.id}
                    pagination={{
                      defaultPageSize: limit,
                      current: page,
                      total,
                      showSizeChanger: true,
                      onShowSizeChange: (_, pageSize) => {
                        setLimit(pageSize);
                      },
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total}`,
                      pageSizeOptions: [10, 20, 50],
                      responsive: true,
                    }}
                    onChange={(pagination) => {
                      setPage(pagination.current);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default InstallmentReport;

const disabledDate = (current) => {
  let date = new Date();
  const currentDate = moment(date).format("MMM, YYYY");
  const monentDate = current.format("MMM, YYYY");
  const dayOfCrntMonth = date.getDate();
  const dayFlag = dayOfCrntMonth < 7 && currentDate === monentDate;
  const dateCurrent = Number(moment(date).format("x"));
  return (current && current.valueOf() > dateCurrent) || dayFlag;
};

const disabledDateYear = (current) => {
  let date = new Date();
  const dateCurrent = Number(moment(date).format("x"));
  return current && current.valueOf() > dateCurrent;
};

const csvHeaders = [
  // deposit
  { label: "ID", key: "customerId" },
  { label: "Name", key: "name" },
  { label: "Deposit Type", key: "depositType" },
  { label: "Period", key: "depositePeriod" },
  { label: "Total Paid (RM)", key: "depositTotalPaid" },
  { label: "Amount Paid (RM)", key: "depositAmountPaid" },
  { label: "Outstanding (RM)", key: "depositBalance" },
  // installation wiring
  { label: "Internal Wiring Type", key: "internalWiringType" },
  { label: "Period", key: "installmentPeriod" },
  { label: "Total Paid (RM)", key: "installmentTotalPaid" },
  { label: "Amount Paid (RM)", key: "installmentAmountPaid" },
  { label: "Outstanding (RM)", key: "installmentBalance" },
];
