import { View, StyleSheet, Text } from "@react-pdf/renderer";

export const DateCyclePdf = ({ report }) => {
  const styles = StyleSheet.create({
    tableRow: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#E7E6E6",
      border: "1px solid black",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderLeft: "1px solid black",
    },
  });
  return (
    <View style={styles.tableRow}>
      <Text style={styles.rightCellInRow}>Cycle</Text>
      <Text style={styles.lefttCellInRow}>{report.cycleDate || "--"}</Text>
    </View>
  );
};
