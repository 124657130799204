import React, { memo, useState } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import BarChart from "../HomeCharts/BarChart";
import LineChart from "../HomeCharts/LineChart";
import PieChart from "../HomeCharts/PieChart";
import LayoutCounter from "../LayoutCharts/LayoutCounter";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardLayoutHome = memo(({ allData, layouts, mode }) => {
  const [isLoading, setIsLoading] = useState(false)

  console.log({ allData, layouts, mode })

  return (
    <div>
      <ResponsiveReactGridLayout
        className="layout"
        id='layout'
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={140}
        layouts={layouts}
        draggableCancel='.clickbtn'
        isDraggable={false}
        isResizable={false}
      >
        {allData?.map((item, index) => (
          <div
            className="my-4 border"
            key={item._id}
            data-grid={{ w: 3, h: 3, x: 0, y: 0 }}
          >
            {item.component === "bar" ? (
              <BarChart
                mode={mode}
                data={item}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : item.component === "line" ? (
              <LineChart
                mode={mode}
                data={item}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : item.component === "pie" ? (
              <PieChart
                mode={mode}
                data={item}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : item.component === "counter" && (
              <LayoutCounter
                mode={mode}
                data={item}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        ))}
      </ResponsiveReactGridLayout>
    </div>
  );
})

export default DashboardLayoutHome;


