import React from "react";
import { Button, Table } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";

const CustomerPaymentHistory = () => {
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage</label>
                <span className="d-block py-2">Customer Payment History</span>
              </div>
            </div>
            <Table className="text-center">
              <thead>
                <tr>
                  <th className="fw-bold text-black">No</th>
                  <th className="fw-bold text-black">Customer Name</th>
                  <th className="fw-bold text-black">Status</th>
                  <th className="fw-bold text-black">Date</th>
                  <th className="fw-bold text-black">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Sams</td>
                  <td className="text-success">Paid</td>
                  <td>15-02-2023</td>
                  <td>
                    <Link>
                      <Button className="btn btn-info me-1">
                        <AiOutlineEye />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-success me-1">
                        <FiEdit />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-danger me-1">
                        <FiTrash title="Delete User" />
                      </Button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Sams</td>
                  <td className="text-danger">Due</td>
                  <td>15-02-2023</td>
                  <td>
                    <Link>
                      <Button className="btn btn-info me-1">
                        <AiOutlineEye />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-success me-1">
                        <FiEdit />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-danger me-1">
                        <FiTrash title="Delete User" />
                      </Button>
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Sams</td>
                  <td className="text-success">Paid</td>
                  <td>15-02-2023</td>
                  <td>
                    <Link>
                      <Button className="btn btn-info me-1">
                        <AiOutlineEye />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-success me-1">
                        <FiEdit />
                      </Button>
                    </Link>
                    <Link>
                      <Button className="btn btn-danger me-1">
                        <FiTrash title="Delete User" />
                      </Button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPaymentHistory;
