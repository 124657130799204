import { Modal, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OnlineCustomerReload = () => {
  const [selectedUser, setSelectedUser] = useState({});
  const userDetails = useSelector((state) => state.user?.userDetails);

  const getUser = async () => {
    try {
      const response = await axios.get(`/user/` + userDetails?._id, {
        withCredentials: true,
        params: { plan: true },
      });
      if (response) {
        console.log(response.data);
        setSelectedUser(response.data);
      }
    } catch (error) {}
  };

  const onUpdateBalance = async (e) => {
    e.preventDefault();
    Modal.info({
      title: "Feature not available.",
      content: (
        <div>
          <p>
            This feature will be available soon. Please contact admin to make an
            offline payment.
          </p>
        </div>
      ),
    });
  };

  console.log(selectedUser);
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage</label>
                <span className="d-block py-2">Manual Customer Reload</span>
              </div>
            </div>
            <div className="">
              <form onSubmit={onUpdateBalance}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="plan" className="form-label">
                      Subscription Plan
                    </label>
                    <input
                      type="text"
                      name="plan"
                      className="form-control"
                      placeholder="Plan name"
                      readOnly
                      value={selectedUser?.plan?.plan?.name || "N/A"}
                      disabled
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="user" className="form-label">
                      Transaction Type
                    </label>
                    <input
                      name="balance"
                      className="form-control"
                      readOnly
                      disabled
                      value={"Prepaid Reload"}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="balance" className="form-label">
                      Current Balance (RM)
                    </label>
                    <input
                      type="number"
                      name="balance"
                      className="form-control"
                      id="balance"
                      placeholder="00.00"
                      readOnly
                      disabled
                      value={
                        selectedUser?.currentBalance
                          ? Number(selectedUser.currentBalance).toFixed(2)
                          : "N/A"
                      }
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="amount" className="form-label">
                      Reload Amount (RM)
                    </label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      id="amount"
                      placeholder="Enter Reload Amount"
                      required
                      onChange={(e) =>
                        setSelectedUser({
                          ...selectedUser,
                          reloadBalance: e.target.value,
                        })
                      }
                      value={selectedUser?.reloadBalance || ""}
                    />
                  </div>
                </div>
                <div className="float-end">
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    disabled={!selectedUser._id || !selectedUser.reloadBalance}
                  >
                    Reload
                  </button>
                  <Link className="btn btn-secondary">Cancel</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineCustomerReload;
