import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userRole } from "../../constant";

export default function WelcomeSection() {
  const [today, setDate] = useState(new Date());
  const userDetails = useSelector((state) => state.user?.userDetails);

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="az-content-header d-block d-md-flex">
      <div>
        <h2 className="az-content-title mg-b-5 mg-b-lg-8">
          Welcome {userDetails?.name || "John Doe"}!
        </h2>
      </div>

      <div className="az-dashboard-header-right">
        <div>
          <label className="tx-13">
            {moment(today).format("hh:mm A")} |{" "}
            {moment(today).format("DD MMMM yyyy")} |{" "}
            <span className="text-capitalize">
              {userDetails?.role === userRole.businessAdmin
                ? "Business Admin"
                : userDetails?.role === "user"
                ? "Customer"
                : userDetails?.role === userRole.admin
                ? "Site admin"
                : userDetails?.role}
              {/* {userDetails?.role === "superAdmin"
                ? "Super Admin"
                : userDetails?.role === "user"
                ? "Customer"
                : userDetails?.role} */}
            </span>{" "}
            Account
          </label>
        </div>
      </div>
    </div>
  );
}
