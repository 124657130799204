import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { Spin } from "antd";
import { apiNotification } from "../../utils";

export default function AddAdmin() {
  const navigate = useNavigate();
  // const userDetails = useSelector((state) => state?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  // const [siteLocations, setSiteLocations] = useState([]);
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    password: "",
    reenterPassword: "",
    companyName: "",
    companyAddress: "",
    phone: "",
    role: "admin",
  });
  const {
    name,
    email,
    password,
    reenterPassword,
    phone,
    companyName,
    companyAddress,
  } = adminData;

  const onInputChange = (e) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
    console.log(adminData);
  };
  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  //Profile Photo
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== reenterPassword) {
      apiNotification({
        message: "Both Passwords Are Not Matching",
        warn: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`/user`, adminData, {
        withCredentials: true,
      });
      if (selectedImage) {
        const data = response.data;
        await axios.put(`/user/${data._id}/avatarUpload/`, selectedImage, {
          withCredentials: true,
        });
      }
      apiNotification({ message: "Site Admin Created Successfully" });
      setTimeout(() => {
        navigate("/site-admins");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong",
        warn: true,
      });
    }
    setIsLoading(false);
  };

  // const getSiteLocations = async () => {
  //   if (userDetails.role === "superAdmin") {
  //     const response = await axios.get(`/site-location`, {
  //       withCredentials: true,
  //     });
  //     if (response) {
  //       setSiteLocations(
  //         response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
  //       );
  //     }
  //   } else if (userDetails.role === "admin") {
  //     const response = await axios.get(
  //       `/site-location/admin-sites/` + userDetails._id,
  //       { withCredentials: true }
  //     );
  //     if (response) {
  //       setSiteLocations(
  //         response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
  //       );
  //     }
  //   }
  // };
  // useEffect(() => {
  //   getSiteLocations();
  // }, []);

  // useEffect(() => {
  //   setAdminData({ ...adminData, site: siteLocations[0]?._id });
  // }, [siteLocations]);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">MANAGE SITE ADMINS</label>
                  <span className="d-block py-2">Add New Site Admin</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Enter email address"
                      // required
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type={newPasswordShown ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onInputChange}
                        minLength="6"
                        className="form-control"
                        id="password"
                        placeholder="Enter a new password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {newPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleNewPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleNewPasswordShown()} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="reenterPassword" className="form-label">
                      Reenter Password
                    </label>
                    <div className="input-group">
                      <input
                        type={repeatPasswordShown ? "text" : "password"}
                        name="reenterPassword"
                        value={reenterPassword}
                        minLength="6"
                        onChange={onInputChange}
                        className="form-control"
                        id="reenterPassword"
                        placeholder="Reenter password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {repeatPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleRepeatPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleRepeatPasswordShown()} />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="cname" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={onInputChange}
                      className="form-control"
                      id="cname"
                      placeholder="Enter company name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="companyAddress" className="form-label">
                      Company Address
                    </label>
                    <input
                      type="text"
                      name="companyAddress"
                      value={companyAddress}
                      onChange={onInputChange}
                      className="form-control"
                      id="companyAddress"
                      placeholder="Enter company address"
                    />
                  </div>
                </div> */}
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        // required
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="bname" className="form-label">
                    Profile Photo
                  </label>
                  {imageUrl ? (
                    <div mt={2} textAlign="center">
                      <div>Preview:</div>
                      <img src={imageUrl} alt="avatar" height="100px" />
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control"
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                      />
                      <label htmlFor="select-image">
                        <img
                          src="/img/avatar.png"
                          alt=""
                          height="100px"
                          className="rounded-3 border p-2 ms-2"
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Create Site Admin
                  </button>
                  <Link to="/site-admins" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
