import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";


const ViewAssignPrepaidPlan = () => {
     const Params = useParams();
  const planId = Params.planId;
  const [assignPrepaidPlanDetails, setAssignPrepaidPlanDetails] = useState();
  const getAssignPrepaidPlan = async () => {
    const response = await axios.get(
      `/subscription/assign-plan` + planId,
      { withCredentials: true }
    );
    if (response) {
     console.log(response);
      setAssignPrepaidPlanDetails(response);
    }
  };

  useEffect(() => {
    document.title = "IVIS PMS - Edit Prepaid Plan";
    getAssignPrepaidPlan();
  }, []);
     return (
          <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Prepaid Plan</label>
                <span className="d-block py-2">Assign Prepaid Plan Details</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Plan Name</div>
                  <div className="col-8">: {assignPrepaidPlanDetails?.name}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Role</div>
                  <div className="col-8">
                    : {assignPrepaidPlanDetails?.role}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Coustomer</div>
                  <div className="col-8">: {assignPrepaidPlanDetails?.user}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link
                  to={`/edit-prepaid-plan/` + planId}
                  className="btn btn-success me-1"
                >
                  Edit
                </Link>
                <Link to={`/all-prepaid-plans`} className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     );
};

export default ViewAssignPrepaidPlan;