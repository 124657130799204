import axios from 'axios';
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FiTrash, FiEdit, } from "react-icons/fi"
import EditBTModal from '../../components/Modals/EditBTModal';
import Swal from "sweetalert2";

export default function BuildingTypeTable({ data, getBuildingTypes }) {
    const [isLoading, setIsLoading] = useState(false);
    const [SuccessMessage, setSuccessMessage] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [btDataToEdit, setBtDataToEdit] = useState();
    const columns = [
        {
            name: "No.",
            cell: (row, index, column, id) => <div>{index + 1}</div>,
            selector: row => (console.log(row)),
            width: "60px"
        },
        {
            name: 'Name',
            selector: row => (row.name),

        },
        {
            name: 'Action',
            cell: row => <div>
                <button className='btn btn-az-primary me-1' onClick={() => editType(row)}> <FiEdit />   </button>
                <button className='btn btn-danger' onClick={() => deleteType(row._id)}><FiTrash /></button>
            </div>,
            grow: 2,
            center: 'yes'
        },
    ];
    const editType = (data) => {
        setBtDataToEdit(data)
        setModalShow(true)
    }
    const deleteType = async (typeId) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Building Type?",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/building-type/` + typeId, { withCredentials: true })
                    .then(res => {
                        getBuildingTypes()
                        Swal.fire({
                            title: "Done!",
                            text: "Building Type Deleted Successfully",
                            icon: "success",
                            timer: 2000,
                            button: false
                        })

                    });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })
    }
    return (
        <div>
            <h4 className='mb-2'>Building Types</h4>
            <div className='d-flex justify-content-center'>
                {isLoading && <Spinner animation="border" variant="dark" />}
            </div>
            {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
            <DataTable
                columns={columns}
                data={data}
                persistTableHead={true}
                pagination
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
            />
            <EditBTModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                btDataToEdit={btDataToEdit}
                setModalShow={setModalShow}
                getBuildingTypes={getBuildingTypes}
            />
        </div>
    )
}
