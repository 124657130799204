import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TiHomeOutline,
  TiDeviceTablet,
  TiChartLine,
  TiCogOutline,
  TiUser,
  TiPower,
  TiBell,
} from "react-icons/ti";
import { TbReportMoney } from "react-icons/tb";
import { BiBuildingHouse, BiWallet } from "react-icons/bi";
import { MdElectricMeter } from "react-icons/md";
import { FaLocationDot, FaUsersGear } from "react-icons/fa6";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogged, setUserDetails } from "../../redux/userSlice";
import axios from "axios";
import { userRole } from "../../constant";

const NavBar = ({ handle }) => {
  const [showSubNv, setShowSubNv] = useState("dashboard");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user?.userDetails);
  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/user/logout`, {
            withCredentials: true,
          })
          .then((res) => {
            dispatch(setIsLogged(false));
            dispatch(setUserDetails({}));
            window.localStorage.clear();
            navigate("/");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  return (
    <div className="az-sidebar">
      <div className="az-sidebar-header my-3">
        <Link to="/" className="az-logo">
          <img src="/img/beta-logo.png" alt="" style={{ maxWidth:"100%" }} />
        </Link>
      </div>
      <div className="az-sidebar-loggedin">
        <div className="az-img-user online">
          <img src={userDetails?.avatar} alt="" />
        </div>
        <div className="media-body">
          <h6>{userDetails?.name || "John Doe"}</h6>
          <span className="text-capitalize">
            {userDetails?.role === userRole.businessAdmin
              ? "Business Admin"
              : userDetails?.role === "user"
              ? "Customer"
              : userDetails?.role === userRole.admin
              ? "Site admin"
              : userDetails?.role}
            {/* {userDetails?.role === "superAdmin"
              ? "Super Admin"
              : userDetails?.role === "user"
              ? "Customer"
              : userDetails?.role} */}
          </span>
        </div>
      </div>
      <div className="az-sidebar-body">
        <ul className="nav">
          <li className="nav-label">Main Menu</li>
          <li
            className={`${
              showSubNv === "dashboard" ? "nav-item active" : "nav-item"
            }`}
          >
            <Link to="/" className="nav-link">
              <i>
                <TiHomeOutline />
              </i>
              Home
            </Link>
          </li>

          {(() => {
            switch (userDetails.role) {
              case userRole.businessAdmin:
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "users"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "users"
                            ? setShowSubNv()
                            : setShowSubNv("users")
                        }
                      >
                        <i>
                          <FaUsersGear />
                        </i>
                        Manage Users
                      </span>
                      <nav className="nav-sub">
                        <Link to="/users" className="nav-sub-link">
                          All Users
                        </Link>
                        {/* <Link to="/business-admins" className="nav-sub-link">
                          Manage Business Admins
                        </Link> */}
                        <Link to="/site-admins" className="nav-sub-link">
                          Manage Site Admins
                        </Link>
                        <Link to="/installers" className="nav-sub-link">
                          Manage Installer
                        </Link>
                        <Link to="/customer" className="nav-sub-link">
                          Manage Customers
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <FaLocationDot style={{ fontSize: "20px" }} />
                        </i>
                        Site Locations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/site-locations" className="nav-sub-link">
                          All Site Locations
                        </Link>
                        <Link to="/add-site-location" className="nav-sub-link">
                          Add Site Location
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <MdElectricMeter />
                        </i>
                        Meters
                      </span>
                      <nav className="nav-sub">
                        <Link to="/meters" className="nav-sub-link">
                          All Meters
                        </Link>
                        <Link to="/add-meters" className="nav-sub-link">
                          Add New Meter
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Meter Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Meter Parameter Comparison
                        </Link>
                        <Link
                          to="/electricity-usage-reports"
                          className="nav-sub-link"
                        >
                          Electricity Usage Reports
                        </Link>
                        <Link to="/finance-reports" className="nav-sub-link">
                          Finance Reports
                        </Link>
                        <Link
                          to="/customer-billing-reports"
                          className="nav-sub-link"
                        >
                          Customer Billing Report
                        </Link>
                        <Link
                          to="/installment-reports"
                          className="nav-sub-link"
                        >
                          Installment Report
                        </Link>
                      </nav>
                    </li>

                    {/* <li
                      className={`${
                        showSubNv === "dashboards"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "dashboards"
                            ? setShowSubNv()
                            : setShowSubNv("dashboards")
                        }
                      >
                        <i>
                          <MdOutlineDashboardCustomize />
                        </i>
                        Dashboard
                      </span>
                      <nav className="nav-sub">
                        <Link to="/newDashboard" className="nav-sub-link">
                          Create New Dashboard
                        </Link>
                        <Link to="/dashboardList" className="nav-sub-link">
                          Dashboard List
                        </Link>
                        <Link to="/assignDashboard" className="nav-sub-link">
                          Assign Dashboard to Customer
                        </Link>
                      </nav>
                    </li> */}
                    <li
                      className={`${
                        showSubNv === "subscription"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "subscription"
                            ? setShowSubNv()
                            : setShowSubNv("subscription")
                        }
                      >
                        <i>
                          <TbReportMoney />
                        </i>
                        Subscription
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/create-new-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Create New Prepaid Plan
                        </Link>
                        <Link to="/all-prepaid-plans" className="nav-sub-link">
                          All Prepaid Plans
                        </Link>
                        <Link
                          to="/assign-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Assign Prepaid Plan to Customer
                        </Link>
                        <Link
                          to="/customer-subscription-summary"
                          className="nav-sub-link"
                        >
                          Customer Subscription Summary
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "wallet"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "wallet"
                            ? setShowSubNv()
                            : setShowSubNv("wallet")
                        }
                      >
                        <i>
                          <BiWallet></BiWallet>
                        </i>
                        E-Wallet
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/customer-payment-summary"
                          className="nav-sub-link"
                        >
                          Customer Payment Summary
                        </Link>
                        <Link
                          to="/minimum-credit-threshold"
                          className="nav-sub-link"
                        >
                          Set Minimum Credit Threshold
                        </Link>
                        <Link
                          to="/manual-customer-payment"
                          className="nav-sub-link"
                        >
                          Manual Customer Payments
                        </Link>
                      </nav>
                    </li>
                    {/* <li
                      className={`${
                        showSubNv === "finance"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "finance"
                            ? setShowSubNv()
                            : setShowSubNv("finance")
                        }
                      >
                        <i>
                          <BiMoney></BiMoney>
                        </i>
                        Finance
                      </span>
                      <nav className="nav-sub">
                        <Link to='/revenue-summary' className="nav-sub-link">
                        Revenue Summary
                        </Link>
                        <Link to='/customer-payment-history' className="nav-sub-link">
                        Customer Payment History
                        </Link>
                        <Link to='/outstanding-bill-summary' className="nav-sub-link">
                        Outstanding Bill Summary
                        </Link>
                      </nav>
                    </li> */}

                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        {/* <Link to="create-alarm-type" className="nav-sub-link">
                          Create Alarm Type
                        </Link> */}
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        <Link to="/alerts" className="nav-sub-link">
                          Alerts
                        </Link>
                        {/* <Link to="/activity-log" className="nav-sub-link">
                          Activity Log
                        </Link> */}
                        {/* <Link to="/electricity-tariff" className="nav-sub-link">
                          Electricity Tariff
                        </Link>
                        <Link to="/parameters" className="nav-sub-link">
                          Manage System Parameters
                        </Link>
                        <Link to="/formulas" className="nav-sub-link">
                          Manage Formulas
                        </Link> */}
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case "superAdmin":
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "users"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "users"
                            ? setShowSubNv()
                            : setShowSubNv("users")
                        }
                      >
                        <i>
                          <FaUsersGear />
                        </i>
                        Manage Users
                      </span>
                      <nav className="nav-sub">
                        <Link to="/users" className="nav-sub-link">
                          All Users
                        </Link>
                        <Link to="/business-admins" className="nav-sub-link">
                          Manage Business Admins
                        </Link>
                        <Link to="/site-admins" className="nav-sub-link">
                          Manage Site Admins
                        </Link>
                        <Link to="/installers" className="nav-sub-link">
                          Manage Installer
                        </Link>
                        <Link to="/customer" className="nav-sub-link">
                          Manage Customers
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <FaLocationDot style={{ fontSize: "20px" }} />
                        </i>
                        Site Locations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/site-locations" className="nav-sub-link">
                          All Site Locations
                        </Link>
                        <Link to="/add-site-location" className="nav-sub-link">
                          Add Site Location
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <MdElectricMeter />
                        </i>
                        Meters
                      </span>
                      <nav className="nav-sub">
                        <Link to="/meters" className="nav-sub-link">
                          All Meters
                        </Link>
                        <Link to="/add-meters" className="nav-sub-link">
                          Add New Meter
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Meter Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Meter Parameter Comparison
                        </Link>
                        <Link
                          to="/electricity-usage-reports"
                          className="nav-sub-link"
                        >
                          Electricity Usage Reports
                        </Link>
                        <Link to="/finance-reports" className="nav-sub-link">
                          Finance Reports
                        </Link>
                        <Link
                          to="/customer-billing-reports"
                          className="nav-sub-link"
                        >
                          Customer Billing Report
                        </Link>
                        <Link
                          to="/installment-reports"
                          className="nav-sub-link"
                        >
                          Installment Report
                        </Link>
                      </nav>
                    </li>

                    {/* <li
                      className={`${
                        showSubNv === "dashboards"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "dashboards"
                            ? setShowSubNv()
                            : setShowSubNv("dashboards")
                        }
                      >
                        <i>
                          <MdOutlineDashboardCustomize />
                        </i>
                        Dashboard
                      </span>
                      <nav className="nav-sub">
                        <Link to="/newDashboard" className="nav-sub-link">
                          Create New Dashboard
                        </Link>
                        <Link to="/dashboardList" className="nav-sub-link">
                          Dashboard List
                        </Link>
                        <Link to="/assignDashboard" className="nav-sub-link">
                          Assign Dashboard to Customer
                        </Link>
                      </nav>
                    </li> */}
                    <li
                      className={`${
                        showSubNv === "subscription"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "subscription"
                            ? setShowSubNv()
                            : setShowSubNv("subscription")
                        }
                      >
                        <i>
                          <TbReportMoney />
                        </i>
                        Subscription
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/create-new-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Create New Prepaid Plan
                        </Link>
                        <Link to="/all-prepaid-plans" className="nav-sub-link">
                          All Prepaid Plans
                        </Link>
                        <Link
                          to="/assign-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Assign Prepaid Plan to Customer
                        </Link>
                        <Link
                          to="/customer-subscription-summary"
                          className="nav-sub-link"
                        >
                          Customer Subscription Summary
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "wallet"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "wallet"
                            ? setShowSubNv()
                            : setShowSubNv("wallet")
                        }
                      >
                        <i>
                          <BiWallet></BiWallet>
                        </i>
                        E-Wallet
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/customer-payment-summary"
                          className="nav-sub-link"
                        >
                          Customer Payment Summary
                        </Link>
                        <Link
                          to="/minimum-credit-threshold"
                          className="nav-sub-link"
                        >
                          Set Minimum Credit Threshold
                        </Link>
                        <Link
                          to="/manual-customer-payment"
                          className="nav-sub-link"
                        >
                          Manual Customer Payments
                        </Link>
                      </nav>
                    </li>
                    {/* <li
                      className={`${
                        showSubNv === "finance"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "finance"
                            ? setShowSubNv()
                            : setShowSubNv("finance")
                        }
                      >
                        <i>
                          <BiMoney></BiMoney>
                        </i>
                        Finance
                      </span>
                      <nav className="nav-sub">
                        <Link to='/revenue-summary' className="nav-sub-link">
                        Revenue Summary
                        </Link>
                        <Link to='/customer-payment-history' className="nav-sub-link">
                        Customer Payment History
                        </Link>
                        <Link to='/outstanding-bill-summary' className="nav-sub-link">
                        Outstanding Bill Summary
                        </Link>
                      </nav>
                    </li> */}

                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        {/* <Link to="create-alarm-type" className="nav-sub-link">
                          Create Alarm Type
                        </Link> */}
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        <Link to="/alerts" className="nav-sub-link">
                          Alerts
                        </Link>
                        <Link to="/activity-log" className="nav-sub-link">
                          Activity Log
                        </Link>
                        {/* <Link to="/electricity-tariff" className="nav-sub-link">
                          Electricity Tariff
                        </Link>
                        <Link to="/parameters" className="nav-sub-link">
                          Manage System Parameters
                        </Link>
                        <Link to="/formulas" className="nav-sub-link">
                          Manage Formulas
                        </Link> */}
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case "installer":
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <FaLocationDot style={{ fontSize: "20px" }} />
                        </i>
                        Site Locations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/site-locations" className="nav-sub-link">
                          All Site Locations
                        </Link>
                        <Link to="/add-site-location" className="nav-sub-link">
                          Add Site Location
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <MdElectricMeter />
                        </i>
                        Meters
                      </span>
                      <nav className="nav-sub">
                        <Link to="/meters" className="nav-sub-link">
                          All Meters
                        </Link>
                        <Link to="/add-meters" className="nav-sub-link">
                          Add New Meter
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        {/* <Link to="create-alarm-type" className="nav-sub-link">
                          Create Alarm Type
                        </Link> */}
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case "admin":
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "users"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "users"
                            ? setShowSubNv()
                            : setShowSubNv("users")
                        }
                      >
                        <i>
                          <FaUsersGear />
                        </i>
                        Manage Users
                      </span>
                      <nav className="nav-sub">
                        <Link to="/users" className="nav-sub-link">
                          All Users
                        </Link>
                        <Link to="/installers" className="nav-sub-link">
                          Manage Installer
                        </Link>
                        <Link to="/customer" className="nav-sub-link">
                          Manage Customers
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <MdElectricMeter />
                        </i>
                        Meters
                      </span>
                      <nav className="nav-sub">
                        <Link to="/meters" className="nav-sub-link">
                          All Meters
                        </Link>
                        <Link to="/add-meters" className="nav-sub-link">
                          Add New Meter
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Meter Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Meter Parameter Comparison
                        </Link>
                        <Link
                          to="/electricity-usage-reports"
                          className="nav-sub-link"
                        >
                          Electricity Usage Reports
                        </Link>
                        <Link to="/finance-reports" className="nav-sub-link">
                          Finance Reports
                        </Link>
                        <Link
                          to="/customer-billing-reports"
                          className="nav-sub-link"
                        >
                          Customer Billing Report
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "subscription"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "subscription"
                            ? setShowSubNv()
                            : setShowSubNv("subscription")
                        }
                      >
                        <i>
                          <TbReportMoney />
                        </i>
                        Subscription
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/create-new-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Create New Prepaid Plan
                        </Link>
                        <Link to="/all-prepaid-plans" className="nav-sub-link">
                          All Prepaid Plans
                        </Link>
                        <Link
                          to="/assign-prepaid-plan"
                          className="nav-sub-link"
                        >
                          Assign Prepaid Plan to Customer
                        </Link>
                        <Link
                          to="/customer-subscription-summary"
                          className="nav-sub-link"
                        >
                          Customer Subscription Summary
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "wallet"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "wallet"
                            ? setShowSubNv()
                            : setShowSubNv("wallet")
                        }
                      >
                        <i>
                          <BiWallet></BiWallet>
                        </i>
                        E-Wallet
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/customer-payment-summary"
                          className="nav-sub-link"
                        >
                          Customer Payment Summary
                        </Link>
                        <Link
                          to="/minimum-credit-threshold"
                          className="nav-sub-link"
                        >
                          Set Minimum Credit Threshold
                        </Link>
                        <Link
                          to="/manual-customer-payment"
                          className="nav-sub-link"
                        >
                          Manual Customer Payments
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        <Link to="/alerts" className="nav-sub-link">
                          Alerts
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case "user":
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Meter Parameter Comparison
                        </Link>
                        {/* <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Meter Parameter Comparison
                        </Link> */}
                        <Link
                          to="/electricity-usage-reports"
                          className="nav-sub-link"
                        >
                          Electricity Usage Reports
                        </Link>
                        {/* <Link to="/finance-reports" className="nav-sub-link">
                          Finance Reports
                        </Link> */}
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "wallet"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "wallet"
                            ? setShowSubNv()
                            : setShowSubNv("wallet")
                        }
                      >
                        <i>
                          <BiWallet></BiWallet>
                        </i>
                        E-Wallet
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/customer-payment-summary"
                          className="nav-sub-link"
                        >
                          My Payment Summary
                        </Link>
                        <Link
                          to="/online-customer-reload"
                          className="nav-sub-link"
                        >
                          Reload Account Balance
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        {/* <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link> */}
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case "public":
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <FaLocationDot style={{ fontSize: "20px" }} />
                        </i>
                        Site Locations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/site-locations" className="nav-sub-link">
                          All Site Locations
                        </Link>
                        <Link to="/add-site-location" className="nav-sub-link">
                          Add Site Location
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <MdElectricMeter />
                        </i>
                        Devices
                      </span>
                      <nav className="nav-sub">
                        <Link to="/devices" className="nav-sub-link">
                          All Devices
                        </Link>
                        <Link to="/device-types" className="nav-sub-link">
                          Manage Device Types
                        </Link>
                        <Link to="/add-devices" className="nav-sub-link">
                          Add New Device
                        </Link>
                        <Link to="/add-virtual-device" className="nav-sub-link">
                          Add Virtual Device
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Device Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Device Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              default:
                return <></>;
            }
          })()}
          <li className="nav-item">
            <button
              className="nav-link border-0 bg-transparent"
              onClick={() => logOut()}
            >
              <i>
                <TiPower />
              </i>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
