import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import { FiTrash, FiEye, FiEdit, FiPaperclip } from "react-icons/fi"

const AdminSites = () => {
    const Params = useParams()
    const adminId = Params.adminId
    const [siteLocations, setSiteLocations] = useState([]);
    const [adminDetails, setAdminDetails] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const getSiteLocations = async () => {
        setIsLoading(true)
        const response = await axios.get(`/site-location/admin-sites/`+adminId, { withCredentials: true })
        if (response) {
            setSiteLocations(response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1))
            setIsLoading(false)
        }
    }

    const getAdmin = async () => {
        const response = await axios.get(`/user/` + adminId, { withCredentials: true })
        if (response) {
            console.log(response.data)
            setAdminDetails(response.data)
        }
    }

    useEffect(() => {
        document.title="SEDA - All Site Locations"
        getAdmin()
        getSiteLocations()
        // eslint-disable-next-line
    }, []);
    const columns = [
        {
            name: "No",
            cell: (row, index, column, id) => <div>{index + 1}</div>,
            selector: row => (console.log(row)),
            width: "60px"
        },
        {
            name: 'Name',
            selector: row => (row.name),
            
        },
        {
            name: 'Location Name',
            selector: row => (row.buildingName),
            
        },
        {
            name: 'Installer',
            cell: row => <>{row?.installer?.name}</>,
            selector: row => (row.installer),
        },
        {
            name: 'Data Assigned',
            
        },
        {
            name: 'Action',
            cell: row => <div>
                <Link to={`/site-location/`+ row._id} className='btn btn-success me-1'><FiEye title="View"/></Link>
                <Link to={`/edit-site-location/`+ row._id} className='btn btn-az-primary me-1'><FiEdit title="Edit"/></Link>
                <Link to={`/site-document/`+ row._id} className='btn btn-warning me-1'><FiPaperclip title="Documents"/></Link>
                <button className='btn btn-danger' onClick={()=>deleteSiteLocation(row._id)}><FiTrash title="Delete"/></button>
            </div>,
            grow:2,
            center:'yes'
        },
    ];
    const deleteSiteLocation = async(siteLocationId)=>{
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this site location?",
            //icon: "warning",
            dangerMode: true,
            showCancelButton: true,
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/site-location/`+siteLocationId, { withCredentials: true })
                        .then(res => {
                            getSiteLocations()
                            Swal.fire({
                                title: "Done!",
                                text: "Site location Successfully Deleted",
                                icon: "success",
                                timer: 2000,
                                button: false
                            })
                        });
            } else if (
              result.dismiss === Swal.DismissReason.cancel
            ) {
               
            }
          })
    }
    return (
        <div className='site-Locations'>
            <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="card p-3 mb-3">
                            <h6>Site Locations of {adminDetails?.name}</h6>
                            <div className='d-flex justify-content-center'>
                                {isLoading && <Spinner animation="border" variant="dark" />}
                            </div>
                            <DataTable
                                columns={columns}
                                data={siteLocations}
                                pagination
                                striped
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 20, 50]}
                            />
                        </div>
                    </div>
               
            </div>
        </div>
    );
}

export default AdminSites;
