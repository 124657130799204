import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect, useContext } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { api, endpoint } from "../../api";
import { Button, DatePicker, Modal, Spin, Tag } from "antd";
import { apiNotification } from "../../utils";
import { useSelector } from "react-redux";
import { meterStatus, userRole } from "../../constant";
import { DataTable } from "../../components";

const DeviceData = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [deviceDetails, setDeviceDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [readings, setReading] = useState([]);
  const [tableColumn, setTableColmn] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [modal1Open, setModal1Open] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [isKWHCreateLoading, setKWHCreateLoading] = useState(false);
  const userDetails = useSelector((state) => state.user.userDetails);

  const Params = useParams();
  const deviceId = Params.deviceId;
  const deviceType = searchParams.get("deviceType");

  const deleteDeviceData = async () => {
    // console.log(dateRange);
    setDeleteLoading(true);
    try {
      const res = await axios.delete(`/device/device-data`, {
        withCredentials: true,
        params: { ...dateRange, device: deviceId },
      });
      Modal.info({
        title: res.data.message,
        onOk() {},
      });
    } catch (error) {
      apiNotification({ message: "Something went wrong", warn: true });
    }
    getDeviceData();
    setModal1Open(false);
    setDeleteLoading(false);
  };
  const createKWHRequest = async () => {
    setKWHCreateLoading(true);
    try {
      const res = await axios.post(`/device/create-khw-request`, {
        device: deviceId,
      });
      apiNotification({ message: res.data.message });
      setTimeout(() => {
        getDeviceData();
      }, 5000);
    } catch (error) {
      apiNotification({ message: "Something went wrong", warn: true });
    }
    setKWHCreateLoading(false);
  };

  const getDevice = async () => {
    try {
      const response = await axios.get(`/device/` + deviceId, {
        withCredentials: true,
      });
      if (response) {
        setDeviceDetails(response.data?.result);
      }
    } catch (error) {}
  };

  const generateTableHeader = (deviceParameters) => {
    if (tableColumn.length === 0) {
      let clm = [
        {
          title: "No.",
          width: "80px",
          render: (_, { sl }) => sl,
          align: "center",
        },
        {
          title: "Time",
          render: (_, row) => moment(row._id).format("DD/MM/YYYY hh:mm:ss a"),
          // width: "200px",
          align: "center",
        },
      ];
      deviceParameters.map((name) => {
        // priceObjName = name+'price';
        clm.push({
          title: name,
          render: (_, row) =>
            typeof row?.[name] === "number"
              ? Number(row?.[name]).toFixed(2)
              : "--",
          align: "center",
        });
        if (deviceType !== "admin") {
          clm.push({
            title: "Price (RM)",
            render: (_, row) =>
              typeof row?.[name + "price"] === "number"
                ? Number(row?.[name + "price"]).toFixed(2)
                : "--",
            align: "center",
          });
        }
      });
      setTableColmn(clm);
    }
  };

  // getting the table data
  const getDeviceData = async () => {
    setIsLoading(true);
    try {
      let { results, count, deviceParameters } = await api.get(
        endpoint.get_devices_data,
        {
          params: { deviceId, limit, page, sortBy: "date", orderBy: "DESC" },
        }
      );
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });

      generateTableHeader(deviceParameters);

      setReading(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getDevice();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <div className="card p-3 mb-3">
      <div className="row">
        <div className="col-md-6">
          <label class="az-content-label" style={{ fontSize: "15px" }}>
            Meter Data Received
          </label>
          {/* <h3>Meter Data Received</h3> */}
          {deviceDetails?.deviceType === "admin" ? (
            ""
          ) : (
            <p
              style={{
                fontSize: "15px",
                fontWeight: "500",
                marginBottom: "2px",
              }}
            >
              Customer name: {deviceDetails?.customer?.name || "--"}
            </p>
          )}
          <p
            style={{ fontSize: "15px", fontWeight: "500", marginBottom: "2px" }}
          >
            Meter name: {deviceDetails?.name || "--"}
          </p>
          <p style={{ fontSize: "15px", fontWeight: "500" }}>
            Meter Status:{" "}
            <Tag color={getStatusColor(deviceDetails?.status)}>
              {deviceDetails?.status}
            </Tag>
          </p>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            {deviceDetails?.deviceType === "customer" ? (
              <button
                onClick={createKWHRequest}
                className="btn btn-primary me-1"
              >
                Check kWh
              </button>
            ) : (
              ""
            )}
            {userDetails?.role === userRole.superAdmin ? (
              <>
                <button
                  onClick={() => setModal1Open(true)}
                  className="btn btn-danger me-1"
                >
                  Delete Data
                </button>
              </>
            ) : (
              ""
            )}
            <button onClick={getDeviceData} className="btn btn-success me-1">
              Refresh
            </button>
            <button onClick={() => navigate(-1)} className="btn btn-secondary">
              Back
            </button>
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          dataSource={readings}
          columns={tableColumn}
          rowKey={(record) => record.id}
          pagination={{
            defaultPageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setLimit(pageSize);
            },
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
          }}
        />
        {/* 
        <DataTable
          columns={tableColumn}
          data={readings}
          noDataComponent={isLoading ? "Loading" : "No record found."}
          pagination
          paginationServer
          //   striped={!isDark}
          //   theme={isDark ? "dark" : "light "}
          paginationTotalRows={total}
          paginationPerPage={limit}
          onChangePage={(data) => {
            setPage(data);
          }}
          onChangeRowsPerPage={(data) => {
            setLimit(data);
            setPage(1);
          }}
          paginationRowsPerPageOptions={[10, 20, 50]}
        /> */}

        <Modal
          title="Delete Meter Data"
          centered
          open={modal1Open}
          onCancel={() => setModal1Open(false)}
          footer={[
            <Button
              key="submit"
              type="primary"
              danger
              onClick={deleteDeviceData}
              size="large"
              loading={isDeleteLoading}
            >
              Confirm
            </Button>,
            <Button
              key="back"
              onClick={() => setModal1Open(false)}
              size="large"
            >
              Cancel
            </Button>,
          ]}
        >
          <form>
            <div>
              <label className="d-block mb-2 mt-3" htmlFor="from">
                From{" "}
              </label>
              <DatePicker
                showTime
                onChange={(_, date) =>
                  setDateRange({ ...dateRange, startDate: date })
                }
                className=" w-100"
                format="DD-MM-YYYY, h:mm:ss a"
                size="large"
              />
            </div>
            <div>
              <label className="d-block mb-2 mt-3" htmlFor="to">
                To{" "}
              </label>
              <DatePicker
                showTime
                format="DD-MM-YYYY, h:mm:ss a"
                onChange={(_, date) =>
                  setDateRange({ ...dateRange, endDate: date })
                }
                className=" w-100"
                size="large"
              />
            </div>
          </form>
        </Modal>
      </Spin>
    </div>
  );
};

export default DeviceData;

const getStatusColor = (status) => {
  if (status === meterStatus.deactivated) return "red";
  else if (status === meterStatus.offline) return "magenta";
  else if (status === meterStatus.online) return "green";
};
