import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { notification, Spin } from "antd";
import { apiNotification } from "../../utils";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/userSlice";

export default function EditCustomerProfile({ userId }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    site: "",
    address1: "",
    address2: "",
    country: "",
    locationState: "",
    postCode: "",
    customerId: "",
    unit: "",
    city: "",
  });
  const {
    name,
    email,
    phone,
    address1,
    address2,
    site,
    locationState,
    postCode,
    customerId,
    unit,
    city,
  } = userData;
  const onInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  //Profile Photo
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!userData?.email && !userData?.phone) {
      notification.warning({
        message: "Either email or phone is required.",
        placement: "bottomLeft",
      });
      return;
    }
    setIsLoading(true);
    try {
      let dta = userData;
      delete dta.site;
      const response = await axios.put(`/user/me`, dta, {
        withCredentials: true,
      });
      if (selectedImage) {
        const addImageResponse = await axios.put(
          `/user/${userId}/avatarUpload`,
          selectedImage,
          { withCredentials: true }
        );
        dispatch(setUserDetails(addImageResponse.data));
      } else {
        dispatch(setUserDetails(response.data));
      }
      apiNotification({ message: "Profile Updated Successfully" });
      setTimeout(() => {
        navigate("/profile");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.put(`/user/` + userId, userData, {
  //       withCredentials: true,
  //     });
  //     const data = response.data;
  //     if (selectedImage) {
  //       await axios.put(`/user/${data._id}/avatarUpload/`, selectedImage, {
  //         withCredentials: true,
  //       });
  //     }
  //     notification.success({
  //       message: "Customer Edited Successfully.",
  //       placement: "bottomLeft",
  //     });
  //     setTimeout(() => {
  //       navigate("/customer");
  //     }, 2000);
  //   } catch (error) {
  //     apiNotification({
  //       message: error?.response?.data?.message || "Something went wrong.",
  //       warn: true,
  //     });
  //   }
  //   setIsLoading(false);
  // };

  const getUser = async () => {
    try {
      const response = await axios.get(`/user/` + userId, {
        withCredentials: true,
      });
      if (response) {
        const data = response.data;
        setUserData({
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          address1: data?.address1,
          address2: data?.address2,
          site: data?.site[0]?.name,
          country: data?.country,
          locationState: data?.locationState,
          postCode: data?.postCode,
          customerId: data?.customerId,
          unit: data?.unit,
          city: data?.city,
        });
        setImageUrl(data?.avatar);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Customer</label>
                  <span className="d-block py-2">Update Customer</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                      disabled
                    />
                  </div>
                  {console.log(site)}
                  <div className="col-md-6 mb-3">
                    <label htmlFor="site" className="form-label">
                      Assigned Site
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      name="site"
                      value={site}
                      onChange={onInputChange}
                      disabled
                      readOnly
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Enter email address"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Customer ID
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="text"
                      name="customerId"
                      value={customerId}
                      onChange={onInputChange}
                      readOnly
                      className="form-control"
                      disabled
                      placeholder="Customer ID"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        id="phone"
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div className="col-md6 mb-3 mt-3">
                      <label htmlFor="bname" className="form-label">
                        Profile Photo
                      </label>
                      {imageUrl && selectedImage ? (
                        <div mt={2} textAlign="center">
                          <img src={imageUrl} alt="avatar" height="100px" />
                        </div>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleFileUpload(e.target.files[0])
                            }
                          />
                          <label htmlFor="select-image">
                            <img
                              src={imageUrl}
                              alt=""
                              height="100px"
                              className="rounded-3 border p-2 ms-2"
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="address1" className="form-label">
                      Home Address
                    </label>
                    <div className="input-group">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="unit"
                        value={unit}
                        onChange={onInputChange}
                        className="form-control"
                        id="unit"
                        placeholder="Unit"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address1"
                        value={address1}
                        onChange={onInputChange}
                        className="form-control"
                        id="address1"
                        placeholder="Address line 1"
                        required
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address2"
                        value={address2}
                        onChange={onInputChange}
                        className="form-control"
                        id="address2"
                        placeholder="Address line 2"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="postCode"
                        value={postCode}
                        onChange={onInputChange}
                        className="form-control"
                        id="postCode"
                        placeholder="Postcode"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="city"
                        value={city}
                        onChange={onInputChange}
                        className="form-control"
                        id="city"
                        placeholder="City"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="locationState"
                        value={locationState}
                        onChange={onInputChange}
                        className="form-control"
                        id="locationState"
                        placeholder="State"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="country"
                        value={"Malaysia"}
                        onChange={onInputChange}
                        className="form-control"
                        id="country"
                        placeholder="Country"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="float-end">
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={submitHandler}
                    // disabled={customerId}
                  >
                    Update
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
