import axios, { Axios } from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineCloudDownload, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popconfirm, Select, Spin } from "antd";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MainReceipt } from "./Pdf/MainReceipt";
import { useSelector } from "react-redux";
import { QrCodeGenerator } from "../SiteUsers/QrCodeGenerator";
import { LanguageSelectorModal } from "./LanguageSelectorModal";
import { userRole } from "../../constant";
import { FiTrash } from "react-icons/fi";
import { apiNotification } from "../../utils";
import { DataTable } from "../../components";

const CustomerPaymentSummary = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [siteLocation, setSiteLocation] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState({ site: "", user: "" });
  const [isLoading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState([]);
  const [transactionType, setTransactionType] = useState("prepaidReload");
  const userDetails = useSelector((state) => state.user?.userDetails);
  const [today, setDate] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [selectedRow, setSElectedRow] = useState({});

  const onChangeCustomer = (value) => {
    setSearchValue({ ...searchValue, user: value });
  };
  const onChangeSite = (value) => {
    setSearchValue({ site: value, user: "" });
    getUser(value);
  };

  const getUser = async (siteId) => {
    if (!siteId) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(`user/siteUser/${siteId}/user`, {
        withCredentials: true,
      });
      if (response) {
        setUsers(response?.data || []);
      }
    } catch (error) {
      setUsers([]);
    }
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  const getReloadHistory = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`/user/get-reload-history`, {
        withCredentials: true,
        params: {
          limit,
          page,
          ...searchValue,
          transactionType,
        },
      });
      let arr = data?.reloadHistory || [];
      setReloadData(
        arr.map((item, idx) => {
          item.idx = (page - 1) * limit + idx + 1;
          return item;
        })
      );
      setTotal(data.count);
    } catch (error) {}
    setLoading(false);
  };
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const getReceiptName = (report) => {
    let name = "IVIS PMS - ";
    if (report.transactionType === transactionTypes.prepaidReload) {
      name += "User Receipt Reload ";
    } else if (
      report.transactionType === transactionTypes.depositPayment &&
      report.oneTime
    ) {
      name += "User Receipt Deposit One Time Fee ";
    } else if (
      report.transactionType === transactionTypes.internalWiringPayment &&
      report.oneTime
    ) {
      name += "User Receipt Wiring One Time Fee ";
    } else if (report.transactionType === transactionTypes.depositPayment) {
      name += "User Receipt Deposit Fee ";
    } else if (
      report.transactionType === transactionTypes.internalWiringPayment
    ) {
      name += "User Receipt Wiring Fee ";
    }
    name += moment(Date.now()).format("DD.MM.YYYY");
    name += ".pdf";
    return name;
  };

  const columns = [
    {
      title: userDetails?.role === userRole.user ? "Receipt ID" : "Customer ID",
      render: (_, row) => (
        <div>
          {userDetails?.role === userRole.user
            ? row?.receiptId
            : row?.customerId}
        </div>
      ),
      width: userDetails?.role === userRole.user ? "150px" : "120px",
      align: "center",
    },
    {
      title: "Customer Name",
      render: (_, row) => row?.customerName || "--",
      // align: "center",
      omit: userDetails.role === "user",
    },
    {
      title: "E-Wallet Balance (RM)",
      render: (_, row) =>
        row?.currentBalance
          ? Number(row?.currentBalance).toLocaleString("en", options)
          : "--",
      align: "center",
      omit:
        transactionType !== transactionTypes.prepaidReload ||
        userDetails.role === "user",
      // minWidth: "190px",
    },
    {
      title: "Assigned Site",
      render: (_, row) => row?.siteName || "--",
      omit: userDetails.role === "user",
      // grow: 0.7,
    },
    {
      title:
        userDetails.role === "user"
          ? "Date"
          : transactionType !== transactionTypes.prepaidReload
          ? " Payment Date"
          : "Last Reload Date",
      render: (_, row) => (
        <div>
          {moment(row?.createdAt).format("DD/MM/YYYY")}
          <br />
          {moment(row?.createdAt).format("hh:mm:ss A")}
        </div>
      ),
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "transactionType",
      render: (data) => (data ? transactionTypeText[data] : "--"),
      align: "center",
      omit: transactionType !== "allFee" || userDetails.role !== "user",
    },
    {
      title:
        transactionType !== transactionTypes.prepaidReload
          ? " Amount Paid (RM)"
          : "Amount Reloaded (RM)",
      render: (_, row) =>
        typeof row?.reloadedBalance === "number"
          ? Number(row?.reloadedBalance).toLocaleString("en", options)
          : "--",
      align: "center",
      // minWidth: "200px",
    },
    // prepaid reload
    {
      title: "Old Balance (RM)",
      render: (_, row) =>
        row?.currentBalance
          ? Number(
              (row?.currentBalance || 0) - (row?.reloadedBalance || 0)
            ).toLocaleString("en", options)
          : "--",
      align: "center",
      omit:
        transactionType !== transactionTypes.prepaidReload ||
        userDetails.role !== "user",
    },
    {
      title: "New Balance (RM)",
      render: (_, row) =>
        row?.user?.currentBalance
          ? Number(row?.currentBalance).toLocaleString("en", options)
          : "--",
      align: "center",
      omit:
        transactionType !== transactionTypes.prepaidReload ||
        userDetails.role !== "user",
    },
    // {
    //   title: "Deposit Balance (RM)",
    //   render: (_, row) => (300 - (12 - row.depositInstallmentCount) * 25).toFixed(2),
    //   align:"center",
    //   omit:
    //     transactionType !== transactionTypes.depositPayment ||
    //     userDetails.role !== "user",
    // },
    // {
    //   title: "Internal Wiring Balance (RM)",
    //   render: (_, row) =>
    //     (816 - (48 - row.internalInstallmentCount) * 17).toFixed(2),
    //   align:"center",
    //   omit:
    //     transactionType !== transactionTypes.internalWiringPayment ||
    //     userDetails.role !== "user",
    // },
    {
      title: "Authorised By",
      render: (_, row) =>
        row?.actionTacker?.name ? row?.actionTacker?.name : "--",
      align: "center",
      omit: transactionType !== transactionTypes.prepaidReload,
    },
    {
      title: "Action",
      render: (_, row) => (
        <div>
          <QrCodeGenerator
            customer={{ _id: row?.user?._id }}
            level="L"
            hideDownloadIcon={true}
          />
          {userDetails?.role !== "user" && (
            <Link
              to={`/customer/` + row?.user?._id}
              className="btn btn-info"
              title="View Profile"
            >
              <AiOutlineEye />
            </Link>
          )}
          <span title="Download Receipt">
            <button
              className="btn btn-warning ms-2"
              onClick={() => {
                setSElectedRow(row);
                setVisible(true);
              }}
            >
              <AiOutlineCloudDownload />
            </button>

            {userDetails?.role === userRole.superAdmin && (
              <Popconfirm
                title="Are you sure?"
                okButtonProps={{ danger: true }}
                onConfirm={() => deleteTransaction(row._id)}
              >
                <button className="btn btn-danger ms-2">
                  <FiTrash title="Delete transaction" />
                </button>
              </Popconfirm>
            )}
          </span>
        </div>
      ),
      align: "center",
    },
  ].filter((item) => !item.omit);

  const deleteTransaction = async (transactionId) => {
    try {
      axios
        .delete(`/user/remove-transaction/` + transactionId, {
          withCredentials: true,
        })
        .then((res) => {
          apiNotification({ message: "Reload history has been deleted." });
          getReloadHistory();
        })
        .catch((err) => {
          apiNotification({ message: "Something went wrong.", warn: true });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getBinaryQRCode = (customerId) => {
    const canvas = document.getElementById(customerId);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      return pngUrl;
    }
  };

  useEffect(() => {
    getSiteLocation();
    getReloadHistory();
    document.title = "IVIS PMS - E-Wallet";
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getReloadHistory();
  }, [limit, page, transactionType]);
  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 5 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="az-content-body">
      <LanguageSelectorModal
        visible={visible}
        setVisible={setVisible}
        selectedRow={selectedRow}
        setSElectedRow={setSElectedRow}
        today={today}
        getBinaryQRCode={getBinaryQRCode}
        getReceiptName={getReceiptName}
      />
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage</label>
                <span className="d-block py-2">Customer Payment Summary</span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-8">
                <div className="row">
                  {userDetails.role !== "user" && (
                    <>
                      <div className="col-md-6">
                        <Select
                          size="large"
                          className="w-100"
                          onChange={onChangeSite}
                          value={searchValue.site}
                        >
                          <Select.Option value="">
                            Select Location
                          </Select.Option>
                          {siteLocation &&
                            siteLocation.length > 0 &&
                            siteLocation.map((item) => (
                              <Select.Option value={item._id} key={item._id}>
                                {item.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                      <div className="col-md-6">
                        <Select
                          size="large"
                          name="user"
                          value={searchValue.user}
                          className="w-100"
                          onChange={onChangeCustomer}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.children
                              ? option?.children.toLowerCase()
                              : ""
                            ).includes(input?.toLowerCase())
                          }
                        >
                          <Select.Option value="">{`Select Customer`}</Select.Option>
                          {users?.length &&
                            users.map((item, idx) => (
                              <Select.Option
                                value={item._id}
                                key={idx}
                                label={item.name}
                              >
                                {`${item.name} ${
                                  item?.customerId ? `(${item.customerId})` : ""
                                }`}
                              </Select.Option>
                            ))}
                        </Select>
                      </div>
                    </>
                  )}
                  <div
                    className={`col-md-6 mt-${
                      userDetails.role !== "user" ? 4 : 0
                    }`}
                  >
                    <label
                      htmlFor="user"
                      className="form-label font-weight-bold"
                    >
                      Select Transaction Type
                    </label>
                    <Select
                      size="large"
                      className="w-100"
                      value={transactionType}
                      onChange={(value) => {
                        setTransactionType(value);
                      }}
                    >
                      <Select.Option value="prepaidReload">
                        Prepaid Reload
                      </Select.Option>
                      <Select.Option value="depositPayment">
                        Deposit Payment
                      </Select.Option>
                      <Select.Option value="internalWiringPayment">
                        Internal Wiring Payment
                      </Select.Option>
                      {/* <Select.Option value="monthlyFee">
                        Maintenance Fees
                      </Select.Option> */}
                      {userDetails?.role === userRole.user ? (
                        <Select.Option value="allFee">
                          Others Fees
                        </Select.Option>
                      ) : (
                        <>
                          <Select.Option value="lateRegistrationCompound">
                            Late Registration
                          </Select.Option>
                          <Select.Option value="reconnectionFeeCharges">
                            Reconnection Fee
                          </Select.Option>
                          <Select.Option value="adminFees">
                            Admin Fees
                          </Select.Option>
                          <Select.Option value="repairServices">
                            Repair Services
                          </Select.Option>
                        </>
                      )}

                      {/* <Select.Option value="customerDepositRefund">
                        Customer Deposit Refund
                      </Select.Option> */}
                    </Select>
                  </div>
                </div>
              </div>
              {userDetails.role !== "user" && (
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={() => {
                      // setPage(1);
                      getReloadHistory();
                    }}
                  >
                    Search
                  </Button>
                </div>
              )}
              <div className="col-md-2"></div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={reloadData}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />

              {/* <DataTable
                columns={columns}
                data={reloadData}
                persistTableHead={true}
                pagination
                paginationServer
                noDataComponent={
                  <p className="my-5">
                    {isLoading ? "Loading" : "No record found."}
                  </p>
                }
                className="mt-3"
                paginationTotalRows={total}
                paginationPerPage={limit}
                onChangePage={(data) => {
                  setPage(data);
                }}
                onChangeRowsPerPage={(data) => {
                  setLimit(data);
                  // setPage(1);
                }}
                paginationRowsPerPageOptions={[10, 20, 50]}
              /> */}
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPaymentSummary;

export const transactionTypes = {
  prepaidReload: "prepaidReload",
  depositPayment: "depositPayment",
  internalWiringPayment: "internalWiringPayment",
  lateRegistrationCompound: "lateRegistrationCompound",
  reconnectionFeeCharges: "reconnectionFeeCharges",
  adminFees: "adminFees",
  repairServices: "repairServices",
  customerDepositRefund: "customerDepositRefund",
};

const transactionTypeText = {
  prepaidReload: "Prepaid Reload",
  depositPayment: "Deposit Payment",
  internalWiringPayment: "Internal Wiring Payment",
  lateRegistrationCompound: "Late Registration",
  reconnectionFeeCharges: "Reconnection Fee",
  adminFees: "Admin Fees",
  repairServices: "Repair Services",
  monthlyFee: "Maintenance Fees",
};
