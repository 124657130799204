import { Select, Spin } from "antd";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { apiNotification } from "../../utils";

export default function AddSiteLocation() {
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState([]);
  const navigate = useNavigate();
  const [Admins, setAdmins] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [Installers, setInstallers] = useState([]);
  const [siteLocationData, setSiteLocationData] = useState({
    name: "",
    admin: [],
    installer: "",
    buildingName: "",
    buildingType: "",
    buildingAddress1: "",
    buildingAddress2: "",
    addLatitude: "",
    addLongitude: "",
    buildingPostalCode: "",
    locationState: "",
    locationCity: "",
    buildingOwnerName: "",
    buildingOwnerEmail: "",
    contactPersonName: "",
    contactPersonPhone: "",
    localAuthority: "",
    netFloorArea: "",
    tariffElectricity: "",
    remark: "",
    buildingBackground: "",
  });
  const {
    name,
    admin,
    buildingAddress1,
    buildingAddress2,
    buildingPostalCode,
    locationState,
    locationCity,
  } = siteLocationData;
  const onInputChange = (e) => {
    setSiteLocationData({
      ...siteLocationData,
      [e.target.name]: e.target.value,
    });
    console.log(siteLocationData);
  };
  const getAdmins = async () => {
    const response = await axios.get(`/user/role/admin`, {
      withCredentials: true,
    });
    if (response) {
      setAdmins(response.data || []);
    }
  };
  const getInstaller = async () => {
    const response = await axios.get(`/user/role/installer`, {
      withCredentials: true,
    });
    if (response) {
      setInstallers(response.data);
    }
  };
  useEffect(() => {
    document.title = "IVIS PMS - Site Location";
    getInstaller();
    getAdmins();
  }, []);

  useEffect(() => {
    if (userDetails.role === "installer") {
      setSiteLocationData({
        ...siteLocationData,
        installer: userDetails?.installer,
      });
    }
  }, [userDetails]);

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (!item?.length) {
    //   apiNotification({ message: "Please select an Installer.", warn: true });
    //   return;
    // } else
    if (!siteLocationData.admin) {
      apiNotification({ message: "Please select an Admin.", warn: true });
      return;
    }
    setIsLoading(true);
    siteLocationData.installer = item;
    try {
      await axios.post(`/site-location`, siteLocationData, {
        withCredentials: true,
      });
      apiNotification({ message: "Site Location Created Successfully" });
      setTimeout(() => {
        navigate("/site-locations");
      }, 2000);
    } catch (error) {
      apiNotification({ message: "Something went wrong.", warn: true });
    }
    setIsLoading(false);
  };

  const handleSelected = (selectedList) => {
    setItem(selectedList);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">
                    Manage Site Location
                  </label>
                  <span className="d-block py-2">Add New Site Location</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Site Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="installer" className="form-label">
                      Assign Installer
                    </label>
                    <Multiselect
                      options={Installers} // Options to display in the dropdown
                      selectedValues={item} // Preselected value to persist in dropdown
                      onSelect={handleSelected} // Function will trigger on select event
                      onRemove={handleSelected} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      placeholder="Select Multiple  Installers"
                    />
                  </div>
                  {userDetails.role === "admin" ? (
                    ""
                  ) : (
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Assign Admin
                      </label>

                      <Select
                        mode="multiple"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) =>
                          setSiteLocationData({ ...siteLocationData, admin: e })
                        }
                        value={admin}
                        tokenSeparators={[","]}
                        placeholder="Select Admin"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.children
                            ? option?.children.toLowerCase()
                            : ""
                          ).includes(input?.toLowerCase())
                        }
                      >
                        {Admins.map((item) => (
                          <Select.Option value={item._id} key={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div className="col-md-6">
                    <label htmlFor="buildingAddress1" className="form-label">
                      Location Address Line 1
                    </label>
                    <input
                      type="text"
                      name="buildingAddress1"
                      value={buildingAddress1}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingAddress1"
                      placeholder="Enter location address line 1"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="buildingAddress2" className="form-label">
                      Location Address Line 2
                    </label>
                    <input
                      type="text"
                      name="buildingAddress2"
                      value={buildingAddress2}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingAddress2"
                      placeholder="Enter location address line 2"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="buildingPostalCode" className="form-label">
                      Location Postal Code
                    </label>
                    <input
                      type="number"
                      name="buildingPostalCode"
                      value={buildingPostalCode}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingPostalCode"
                      placeholder="Enter location postal code"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="locationCity" className="form-label">
                      Location City
                    </label>
                    <input
                      type="text"
                      name="locationCity"
                      value={locationCity}
                      onChange={onInputChange}
                      className="form-control"
                      id="locationCity"
                      placeholder="Enter Location City"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="locationState" className="form-label">
                      Location State
                    </label>
                    <input
                      type="text"
                      name="locationState"
                      value={locationState}
                      onChange={onInputChange}
                      className="form-control"
                      id="locationState"
                      placeholder="Enter location state"
                    />
                  </div>
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Create Site Location
                  </button>
                  <Link to="/site-locations" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
