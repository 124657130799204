import {
  Document,
  Page,
  StyleSheet,
  Text,
  Image,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import { HeaderMalayPdf } from "../../AnalysisReporting/pdf/HeaderMalayPdf";
import { CustomerInfo } from "./CustomrInfo";
import { TableFormatterPdf } from "./TableFormatterPdf";

import regulerRobot from "../Roboto/Roboto-Regular.ttf";
import boldRoboto from "../Roboto/Roboto-Bold.ttf";

Font.register({
  family: "roboto",
  fonts: [{ src: regulerRobot }, { src: boldRoboto, fontWeight: "bold" }],
});

export const MainReceiptMalay = ({ report, today, base64Image }) => {
  const styles = StyleSheet.create({
    page: {
      padding: "40px 70px",
      position: "relative",
      fontFamily: "roboto",
    },
    footerText1: {
      fontSize: "12px",
      marginTop: "10px",
      textAlign: "left",
    },
    footerText2: {
      fontSize: "12px",
      marginTop: "15px",
      textAlign: "center",
      fontWeight: "bold",
    },
    qrCode: {
      position: "absolute",
      width: "60px",
      height: "60px",
      right: "70px",
      top: "200px",
      marginTop: "13px",
    },
  });
  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <Image
          source={{ uri: base64Image(report?.user?._id) }}
          style={styles.qrCode}
        />
        <HeaderMalayPdf pageNumber={"1/1"} headerText="Resit Rasmi" />
        <CustomerInfo {...report} today={today} />
        <TableFormatterPdf report={report} />

        <Text style={styles.footerText1}>
          &gt;&gt; Sila pastikan akaun anda mempunyai jumlah kredit yang
          secukupnya sebelum hari pertama setiap bulan untuk mengelakkan
          sebarang gangguan servis elektrik pra-bayar. Kami amat menghargai
          kerjasama anda bagi memastikan kelancaran servis elektrik.
        </Text>
        <Text style={styles.footerText2}>
          INI ADALAH RESIT ELECTRONIK YANG DIHASILKAN MENERUSI LAMAN WEB
          VSSSB.COM
        </Text>
      </Page>
    </Document>
  );
};
