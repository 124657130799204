import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { numberFormater } from "../../../utils";

export const FinancialStatisticsPdf = ({ report }) => {
  const styles = StyleSheet.create({
    table: {
      border: "1px solid black",
    },
    sectionTitle: {
      fontSize: "12px",
      marginTop: "10px",
      fontWeight: "bold",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableTitle: {
      fontSize: "12px",
      width: "100%",
      textAlign: "center",
      padding: "7px",
      backgroundColor: "#FBE4D5",
      fontWeight: "bold",
    },
    rightCellInRowMiddle: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
      paddingTop: "22px",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "55%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "45%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
    rightCellInRowBig: {
      fontSize: "12px",
      width: "77.5%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    lefttCellInRowBig: {
      fontSize: "12px",
      width: "22.5%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
    rightCellInRowBigLast: {
      fontSize: "12px",
      width: "77.5%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
      fontWeight: "bold",
    },
    lefttCellInRowBigLast: {
      fontSize: "12px",
      width: "22.5%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      fontWeight: "bold",
    },
    rightView: {
      width: "50%",
    },
  });
  return (
    <>
      <Text style={styles.sectionTitle}>PAYMENT COLLECTION INFORMATION</Text>
      <View style={styles.table}>
        <Text style={styles.tableTitle}>Breakdown of Payment Collected</Text>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowMiddle}>Customer Top-Up (RM)</Text>
          <View style={styles.rightView}>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Cash (RM)</Text>
              <Text style={styles.lefttCellInRow}>
                {numberFormater(report.totalReload)}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Bank Transfer (RM)</Text>
              <Text style={styles.lefttCellInRow}>{"0.00"}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowMiddle}>Customer Deposit (RM)</Text>
          <View style={styles.rightView}>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Cash (RM)</Text>
              <Text style={styles.lefttCellInRow}>
                {numberFormater(report.depositFee)}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Bank Transfer (RM)</Text>
              <Text style={styles.lefttCellInRow}>{"0.00"}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowMiddle}>
            Customer Internal Wiring Fees (RM)
          </Text>
          <View style={styles.rightView}>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Cash (RM)</Text>
              <Text style={styles.lefttCellInRow}>
                {numberFormater(report.internalWiringFee)}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={styles.rightCellInRow}>Bank Transfer (RM)</Text>
              <Text style={styles.lefttCellInRow}>{"0.00"}</Text>
            </View>
          </View>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowBig}>
            Customer KWTBB Surcharge Fees (RM)
          </Text>
          <Text style={styles.lefttCellInRowBig}>
            {numberFormater(report.kwtbbFee)}
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowBig}>Maintenance Fees (RM)</Text>
          <Text style={styles.lefttCellInRowBig}>
            {numberFormater(report.maintenanceFee)}
          </Text>
        </View>
        {otherCharges.map(({ label, feildName }, idx) => (
          <View style={styles.tableRow}>
            <Text style={styles.rightCellInRowBig}>{label}</Text>
            <Text style={styles.lefttCellInRowBig}>
              {idx === otherCharges.length - 1 && Number(report[feildName])
                ? "- "
                : ""}
              {report[feildName]}
            </Text>
          </View>
        ))}
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowBig}>Tax (0%) </Text>
          <Text style={styles.lefttCellInRowBig}>{"0.00"}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.rightCellInRowBigLast}>
            Total Payment Collected (RM)
          </Text>
          <Text style={styles.lefttCellInRowBigLast}>
            {numberFormater(report.totalCollection)}
          </Text>
        </View>
      </View>
    </>
  );
};

const otherCharges = [
  {
    label: "Late Registration Compound (RM)",
    feildName: "lateRegistrationCompound",
  },
  {
    label: "Reconnection Fee Charges (RM)",
    feildName: "reconnectionFeeCharges",
  },
  { label: "Admin Fees (RM)", feildName: "adminFees" },
  { label: "Repair Fees (RM)", feildName: "repairServices" },
  { label: "Deposit Refund (RM)", feildName: "customerDepositRefund" },
];
