import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiNotification } from "../../utils";
import ChangePasswordModal from "../SiteUsers/ChangePasswordModal";
import { useSelector } from "react-redux";

const EditInstaller = () => {
  const userDetails = useSelector((state) => state?.user?.userDetails);

  const [modalShow, setModalShow] = useState(false);
  const Params = useParams();
  const installerId = Params.installerId;
  const navigate = useNavigate();

  const [InstallerData, setInstallerData] = useState({
    name: "",
    email: "",
    phone: "",
    fax: "",
    companyName: "",
    companyAddress: "",
    site: "",
  });
  const { name, email, phone, fax, companyName, companyAddress, site } =
    InstallerData;
  const onInputChange = (e) => {
    setInstallerData({ ...InstallerData, [e.target.name]: e.target.value });
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [siteLocations, setSiteLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("logo", file);
    setSelectedImage(form);
  };

  const getSiteLocations = async () => {
    if (userDetails?.role === "superAdmin") {
      try {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    } else if (userDetails?.role === "admin") {
      try {
        const response = await axios.get(
          `/site-location/admin-sites/` + userDetails?._id,
          { withCredentials: true }
        );
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getSiteLocations();
  }, []);

  //   const submitHandler = async (e) => {
  //     e.preventDefault();
  //     setIsLoading(true);
  //     try {
  //       const response = await axios.put(`/user/` + installerId, InstallerData, {
  //         withCredentials: true,
  //       });
  //       if (selectedImage) {
  //         await axios.put(`/user/${data._id}/logoUpload/`, selectedImage, {
  //           withCredentials: true,
  //         });
  //       }
  //       setSuccessMessage("Installer Edited Successfully");
  //       setTimeout(() => {
  //         setSuccessMessage();
  //         navigate("/installers");
  //       }, 2000);
  //     } catch (error) {}
  //     setIsLoading(false);

  //     const response = await axios.put(`/user/` + installerId, InstallerData, {
  //       withCredentials: true,
  //     });

  //     const data = response.data;
  //     if (data) {
  //       if (selectedImage === null) {
  //       } else {
  //         const addImageResponse = await axios.put(
  //           `/user/${data._id}/logoUpload/`,
  //           selectedImage,
  //           { withCredentials: true }
  //         );
  //         if (addImageResponse) {
  //           setIsLoading(false);
  //           setSuccessMessage("Installer Edited Successfully");
  //           setTimeout(() => {
  //             setSuccessMessage();
  //             navigate("/installers");
  //           }, 2000);
  //         }
  //       }
  //     }
  //   };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(`/user/` + installerId, InstallerData, {
        withCredentials: true,
      });
      if (selectedImage) {
        await axios.put(`/user/${installerId}/logoUpload/`, selectedImage, {
          withCredentials: true,
        });
      }
      apiNotification({
        message: "Installer Edited Successfully",
      });
      setTimeout(() => {
        navigate("/installers");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };
  const getInstaller = async () => {
    try {
      const response = await axios.get(`/user/` + installerId, {
        withCredentials: true,
      });
      if (response) {
        const data = response.data;
        setInstallerData({
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          // site: data?.site?.[0]?._id,
          // fax: data?.fax,
          companyName: data?.companyName,
          companyAddress: data?.companyAddress,
        });
        setImageUrl(data?.logo);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getInstaller();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Installer</label>
                  <span className="d-block py-2">
                    Update Installer Information
                  </span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="col-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>

                  {/* <div className="col-md-6 mb-3">
                    <label htmlFor="site" className="form-label">
                      Assigned Site
                    </label>
                    <select
                      name="site"
                      value={site || ""}
                      className="form-select"
                      onChange={onInputChange}
                    >
                      <option value={""}>Select Single Site</option>
                      {siteLocations?.length &&
                        siteLocations.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div> */}

                  <div className="col-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Enter email address"
                      // required
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        // required
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="cname" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={onInputChange}
                      className="form-control"
                      id="cname"
                      placeholder="Enter company name"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="companyAddress" className="form-label">
                      Company Address
                    </label>
                    <input
                      type="text"
                      name="companyAddress"
                      value={companyAddress}
                      onChange={onInputChange}
                      className="form-control"
                      id="companyAddress"
                      placeholder="Enter company address"
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="bname" className="form-label">
                      Logo
                    </label>
                    {imageUrl && selectedImage ? (
                      <div mt={2} textAlign="center">
                        <div>Preview:</div>
                        <img
                          src={imageUrl}
                          alt={selectedImage.name}
                          height="100px"
                        />
                      </div>
                    ) : (
                      <>
                        <input
                          className="form-control"
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e.target.files[0])}
                        />
                        <label htmlFor="select-image">
                          <img
                            src={imageUrl || "/img/upload.png"}
                            alt=""
                            height="100px"
                            className="rounded-3 border p-2 ms-2"
                          />
                        </label>
                      </>
                    )}
                  </div>
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => setModalShow(true)}
                  >
                    Change Password
                  </button>
                  <Link to="/installers" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        userId={installerId}
      />
    </Spin>
  );
};

export default EditInstaller;
