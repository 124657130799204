import { Spin, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { ThemeContext } from "../../App";

const ReportTable = ({ data, title, page, limit, parameters, setPage, setLimit, total, isLoading = false, isAdminMeter, showPrice }) => {
  const generatedTableColums = useMemo(() => {
    let columps = [
      {
        title: "No.",
        width: "60px",
        render: (_, __, index) => (page - 1) * limit + index + 1,
      },
      {
        title: "Time",
        render: (row) => moment(row._id).format("DD/MM/YYYY hh:mm:ss a"),
        width: "200px",
        center: true,
      },
    ];
    if (parameters.length > 0) {
      parameters.map(({ label }) => {
        columps.push({
          title: label,
          render: (row) => (typeof (row?.[label]) === 'number' ? Number(row?.[label]).toFixed(2) : "--"),
        }
        )
        if (!isAdminMeter && showPrice) {
          columps.push({
            title: 'Price (RM)',
            render: (row) => (typeof (row?.price) === 'number' ? Number(row?.price).toFixed(2) : "--"),
            center: true,
          })
        }
      })
    }
    return columps;
  }, [parameters, page, limit, isAdminMeter]);

  return (
    <div className="p-3">
      <Spin spinning={isLoading}>
        <Table
          columns={generatedTableColums}
          dataSource={data}
          pagination={{
            onChange: (_p, size) => {
              setPage(_p);
              setLimit(size);
            },
          }}
        />
      </Spin>
    </div>
  );
};

export default ReportTable;
