import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import BarChart from "../../components/Charts/BarChart";
import { api } from "../../api";
import { useSelector } from "react-redux";
import { userRole } from "../../constant";
import moment from "moment";

export const HomePageBarChart = ({ params }) => {
  const { user, site, deviceId } = params;

  const [chartData, setChartData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const getChartData = async () => {
    setLoading(true);
    try {
      const { results } = await api.get(
        `/dashboard/chart/kwh-consumption-weekly`,
        {
          params: { userId: user, site, deviceId },
        }
      );
      setChartData(results);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getChartData();
  }, [params]);

  return (
    <div className="col-lg-6">
      <div className="card card-dashboard-six graphShadow">
        <h6 className="fw-bold text-uppercase mb-4">
          Past seven days energy usage (kwh)
        </h6>
        <div className="row">
          <Spin spinning={isLoading}>
            <BarChart
              name="kWh"
              data={chartData}
              // title="Energy Consumption (kWh)"
              xAxisName="Time(Days)"
              yAxisName="Energy (kWh)"
              viewType={"weekly"}
              dateRange={[]}
              xTitleClassName="mt-0"
            />
          </Spin>
        </div>
      </div>
    </div>
  );
};
