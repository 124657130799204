import React, { memo, useState } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";
import BarChart from "../LayoutCharts/BarChart";
import LayoutCounter from "../LayoutCharts/LayoutCounter";
import LineChart from "../LayoutCharts/LineChart";
import PieChart from "../LayoutCharts/PieChart";
import AddItemModal from '../LiveDashboard/AddItemModal';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardLayout = memo(({ allData, setAllData, setLayouts, layouts, mode }) => {


  const [editId, setEditId] = useState('')
  const [show, setShow] = useState(false);


  const onLayoutChange = (layout, layouts) => {
    setLayouts(layouts);
  }

  const handlerRemove = id => {
    const newData = allData.filter(data => data._id !== id)
    console.log(newData, id)
    setAllData(newData)
  }

  const handlerUpdate = id => {
    setEditId(id)
    setShow(true)
  }

  return (
    <div>
      <ResponsiveReactGridLayout
        className="layout"
        id='layout'
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={80}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        draggableCancel='.clickbtn'
        isDraggable={mode === 'view' ? false : true}
        isResizable={mode === 'view' ? false : true}
      >
        {allData?.map((item, index) => (
          <div
            className="my-4 border"
            key={item._id}
            data-grid={{ w: 3, h: 3, x: 0, y: 0 }}
          >
            {item.component === "bar" ? (
              <BarChart
                mode={mode}
                data={item}
                setAllData={setAllData}
                handlerRemove={handlerRemove}
                handlerUpdate={handlerUpdate}
              />
            ) : item.component === "line" ? (
              <LineChart
                mode={mode}
                data={item}
                setAllData={setAllData}
                handlerRemove={handlerRemove}
                handlerUpdate={handlerUpdate}
              />
            ) : item.component === "pie" ? (
              <PieChart
                mode={mode}
                data={item}
                setAllData={setAllData}
                handlerRemove={handlerRemove}
                handlerUpdate={handlerUpdate}
              />
            ) : item.component === "counter" && (
              <LayoutCounter
                mode={mode}
                data={item}
                setAllData={setAllData}
                handlerRemove={handlerRemove}
                handlerUpdate={handlerUpdate}
              />
            )}
          </div>
        ))}
      </ResponsiveReactGridLayout>

      {
        show && <AddItemModal
          show={show}
          setShow={setShow}
          allData={allData}
          setAllData={setAllData}
          mode='edit'
          id={editId}
        />
      }
    </div>
  );
})

export default DashboardLayout;


