import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FiTrash, FiEye, FiEdit, FiUserX, FiUserCheck } from "react-icons/fi";
import { useSelector } from "react-redux";
import { BsFillEyeFill } from "react-icons/bs";
import { Spin } from "antd";
import { QrCodeGenerator } from "./QrCodeGenerator";
import { DataTable } from "../../components";
import { userRole } from "../../constant";

export default function SiteUsers() {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByPlan, setSearchByPlan] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBySatus, setSearchBySatus] = useState("active");

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user`, {
        withCredentials: true,
        params: {
          limit,
          page,
          site: searchByLocation,
          searchText: searchTerm,
          role: userRole.user,
          plan: searchByPlan,
          device: true,
          sortBy: "customerId",
          orderBy: "ASC",
          status: searchBySatus,
        },
      });
      let deviceResult = response.data.devices;
      let userResult = response.data.results.map((item) => {
        item.device = deviceResult.find(
          (deviceItem) => deviceItem?.customer === item?._id
        );
        return item;
      });

      setTotal(response.data.count);
      setUsers(userResult);
    } catch (error) {}
    setIsLoading(false);
  };

  console.log(users);

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  // const getSiteLocation = async () => {
  //   const response = await axios.get(`/site-location`, {
  //     withCredentials: true,
  //   });
  //   if (response) {
  //     setSiteLocation(response.data);
  //   }
  // };

  const handlePlan = (e) => {
    setSearchByPlan(e.target.value);
  };
  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };
  const getSubscriptionPlan = async () => {
    try {
      const response = await axios.get(`/subscription`, {
        withCredentials: true,
      });
      if (response) {
        setSubscriptionPlan(response.data);
      }
    } catch (error) {}
  };

  const handleSearchByPlanOrLocation = () => {
    getUsers();
  };

  const activeDeactiveUser = async (userId, isActive) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: `Do you wish to ${
        !isActive ? "activate" : "deactivate"
      } this customer?`,
      // text: "Do you wish to deactivate this customer?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/user/` + userId, data, { withCredentials: true })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const columns = [
    {
      title: "Customer ID",
      key: "customerId",
      dataIndex: "customerId",
      // minWidth: "120px",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    // {
    //   title: "Email",
    //   key: "Email",
    //   dataIndex: "email",
    //   render: (email) => (email ? email : "--"),
    // },
    {
      title: "Phone",
      key: "Phone",
      dataIndex: "phone",
      render: (phone) => (phone ? "+6" + phone : "--"),
    },
    {
      title: "Plan",
      key: "plan",
      dataIndex: "plan",
      render: (plan) => <div>{plan?.plan?.name || "--"}</div>,
    },
    {
      title: "Meter",
      key: "device",
      dataIndex: "device",
      render: (device) => <div>{!!device ? "Yes" : "No"}</div>,
    },
    {
      title: "Date Created",
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive) => (
        <div>
          {isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      width: "100px",
      render: (_, row) => (
        <div>
          <Link
            to={`/customer/` + row.customerId}
            className="btn btn-success me-1"
            title="View Profile"
          >
            <FiEye />
          </Link>
          <Link
            to={`/edit-customer/` + row.customerId}
            className="btn btn-info me-1"
            title="Edit Profile"
          >
            <FiEdit />
          </Link>
          <Link
            to={
              row?.plan?.plan?._id
                ? `/view-prepaid-plan/` + row?.plan?.plan?._id
                : "#"
            }
            className="btn btn-primary me-1"
            title="View Plan"
          >
            <BsFillEyeFill />
          </Link>
          <QrCodeGenerator customer={row} />
          {(() => {
            switch (row.isActive) {
              case true:
                return (
                  <button
                    className="btn btn-danger me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <FiUserX title="Inactive Account" />
                  </button>
                );
              case false:
                return (
                  <button
                    className="btn btn-secondary me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <FiUserCheck title="Active Account" />
                  </button>
                );
              default:
                return;
            }
          })()}
          {/* <button
            className="btn btn-danger"
            title="Delete"
            onClick={() => deleteUser(row._id)}
          >
            <FiTrash />
          </button> */}
        </div>
      ),
    },
  ];
  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this site user?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/` + userId, {
            withCredentials: true,
          })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "Customer Successfully Deleted",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  useEffect(() => {
    document.title = "IVIS PMS - All Customers";
    getUsers();
    getSiteLocation();
    getSubscriptionPlan();
  }, []);

  useEffect(() => {
    getUsers();
  }, [searchTerm, limit, page]);

  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Customers</label>
                <span className="d-block py-2">All Customers</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0">
                <Link to="/add-customer" className="btn btn-az-primary btn-md">
                  Add Customer
                </Link>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3">
                <select className="form-select" onChange={handleLocation}>
                  <option label="All Locations"></option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <select className="form-select" onChange={handlePlan}>
                  <option label="All Subscription Plans"></option>
                  {subscriptionPlan?.length &&
                    subscriptionPlan.map((item, index) => (
                      <option value={item._id} key={item._id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  onChange={(e) => setSearchBySatus(e.target.value)}
                  value={searchBySatus}
                >
                  <option label="All Status" value={""}></option>
                  <option label="Active" value={"active"}></option>
                  <option label="Inactive" value={"inactive"}></option>
                </select>
              </div>
              <div className="col-md-2">
                <Button
                  onClick={handleSearchByPlanOrLocation}
                  variant="success"
                >
                  Search
                </Button>
              </div>
              <div className="col-md-2">
                <Form className="d-flex">
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => {
                      setPage(1);
                      setSearchTerm(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                    // className="me-2"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={users}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
          {/* </div>F */}
        </div>
      </div>
    </div>
  );
}
