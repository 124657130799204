import React from "react";
import Chart from "react-apexcharts";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";

const BarChart = ({ data, mode, handlerRemove, handlerUpdate }) => {
  const { userDetails } = useSelector((state) => state.user);
  const options = {
    series: [
      {
        name: "Asset 1A",
        data: [95000, 75000, 50000, 75000, 50000, 80000, 75000],
      },
      {
        name: "Asset 1B",
        data: [70000, 55000, 40000, 65000, 40000, 90000, 65000],
      },
    ],
    options: {
      chart: {
        type: "bar",
        width: "100%",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },

      title: {
        text: "Bar Chart",
        align: "left",
      },

      tooltip: {
        shared: true,
        intersect: false,
      },

      xaxis: {
        categories: [
          "Oct 01",
          "Oct 05",
          "Oct 10",
          "Oct 15",
          "Oct 20",
          "Oct 25",
          "Oct 30",
        ],
      },
    },
  };


  return (
    <div className="p-2">
      {userDetails.role === "superAdmin" && <div className="d-flex justify-content-end align-items-center">
        {
          mode === 'view' ? '' : (<>
            <div className="clickbtn" onClick={() => handlerUpdate(data._id)}>
              <FiEdit className="text-secondary me-1" />
            </div>
            <div className="clickbtn" onClick={() => handlerRemove(data._id)}>
              <MdDeleteForever className="fs-5 text-secondary" />
            </div>
          </>)
        }
      </div>}
      <div>
        <Chart
          options={options.options}
          series={options.series}
          type="bar"
        />
      </div>
    </div>
  );
};

export default BarChart;
