import { DatePicker, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { api, endpoint } from "../../api";
import appConfig from "../../appConfig";
import SplineChart from "../../components/Charts/SplineChart";
import ReportTable from "../../components/Reports/ReportTable";
import { DeviceAndParameter } from "./DeviceAndParameter";
import { exportCSV } from "../../utils/exportCsv";

const DeviceComparison = () => {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [graphView, setGraphView] = useState(true);
  const [sites, setSites] = useState([]);
  // graph data
  const [deviceData, setDeviceData] = useState([]);
  const [deviceTableData, setDeviceTableData] = useState([]);

  const [graphFrom, setGraphFrom] = useState();
  const [graphTo, setGraphTo] = useState();
  // query params
  const [addedDevices, setAddedDevices] = useState([
    { device: "", parameters: [] },
    { device: "", parameters: [] },
  ]);
  const [queryData, setQueryData] = useState({
    interval: "",
    from: "",
    to: "",
    operator: "",
  });

  const [deviceParameters, setDeviceParameters] = useState([]);

  const { interval, from, to } = queryData;

  const handleDownloadReport = () => {
    exportCSV({
      data: deviceTableData.map(({ _id, date, ...e }) => {
        return {
          // Date: date,
          Date: `${moment(date).format("DD/MM/YYYY hh:mm:ss a")}`,
          ...e,
        };
      }),
      filename: `report-multiple-device-comparison`,
    });
  };

  const getSiteLocationList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSites(response.data);
    } catch (error) {}
  };

  const onRemoveDevice = (idx) => {
    const newArray = addedDevices.filter((_, index) => idx !== index + 1);
    setAddedDevices(newArray);
  };
  const onChangeDevice = ({ idx, name, value }) => {
    addedDevices[idx][name] = value;
    setAddedDevices([...addedDevices]);
  };

  // console.log(addedDevices);
  const onInputChange = (e) => {
    setQueryData({ ...queryData, [e.target.name]: e.target.value });
  };

  /**
   * @param { String } type
   * @type graph | table
   */
  const getReport = async (type) => {
    if (type === "graph") {
      setIsLoadingGraph(true);
      setGraphFrom(from);
      setGraphTo(to);
    } else {
      setIsLoadingTable(true);
    }

    const filteredDevices = addedDevices.filter(
      (d) => d.device && d.parameters.length > 0
    );

    try {
      if (!queryData.interval) queryData.interval = 1;
      const response = await api.post(
        endpoint.device_comparison,
        { ...queryData, devices: filteredDevices },
        { params: { limit, page, type } }
      );
      if (type === "graph") {
        setDeviceData(response);
      } else {
        setDeviceTableData(response?.results);
        if (response?.deviceParameters) {
          setDeviceParameters(response?.deviceParameters);
        }
        setTotal(response?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
    if (type === "graph") {
      setIsLoadingGraph(false);
    } else {
      setIsLoadingTable(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    getReport("graph");
    getReport("table");
  };

  useEffect(() => {
    document.title = `${appConfig.name} - Meter Comparison`;
    getSiteLocationList();
  }, []);

  return (
    <div className="analysis">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">
              Manage Analysis and Reporting
            </label>
            <span className="d-block py-2">Multiple Meter Comparison</span>
          </div>
          <div className="col-md-4 d-flex justify-content-end p-0">
            {deviceTableData.length > 0 && (
              <button
                className="btn btn-danger me-2"
                onClick={handleDownloadReport}
              >
                Download Report
              </button>
            )}
            <Link className="btn btn-secondary" to="/">
              Back
            </Link>
          </div>
        </div>
        <hr className="mt-0" />

        {/* <div className="row">
            <div className="col-md-6">
              <h3>Multiple Device Comparison</h3>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <Link to="/" className="btn btn-secondary">
                Back
              </Link>
            </div>
          </div> */}
        <div className="row my-3">
          <div className="col-md-12">
            <button
              className={`btn me-2 ${graphView ? " btn-info" : "btn-success"}`}
              onClick={() => setGraphView(true)}
            >
              Graph
            </button>
            <button
              className={`btn ${!graphView ? " btn-info" : "btn-success"}`}
              onClick={() => setGraphView(false)}
            >
              Table
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <select
                className="form-select"
                name="interval"
                defaultValue={interval}
                onChange={onInputChange}
              >
                <option value="">Select Period</option>
                <option value="1">1 Min</option>
                <option value="30">30 Min</option>
                <option value="60">1 Hour</option>
                <option value="1440">24 Hours</option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                className="form-select"
                name="operator"
                onChange={onInputChange}
              >
                <option>Real Time</option>
                <option value="max">Maximum</option>
                <option value="min">Minimum</option>
                <option value="avg">Average</option>
                <option value="sum">Sum</option>
              </select>
            </div>
            <div className="col-md-3">
              {/* <input
                type="text"
                name="from"
                value={from}
                className="form-control mb-2"
                placeholder="Select Start Date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={onInputChange}
              /> */}
              <DatePicker
                className="form-control"
                placeholder="Select Start Date"
                size="large"
                disabledDate={(current) => current > moment()}
                onChange={(e, date) => {
                  setQueryData({ ...queryData, from: date });
                }}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                className="form-control"
                placeholder="Select End Date"
                size="large"
                onChange={(e, date) => {
                  setQueryData({ ...queryData, to: date });
                }}
                disabledDate={(current) => current > moment()}
              />
              {/* <input
                type="text"
                name="to"
                value={to}
                min={from}
                className="form-control"
                placeholder="Select End Date"
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                onChange={onInputChange}
              /> */}
            </div>
          </div>
          <hr className="mt-3 mb-3" />

          <div className="row">
            <div className="col-md-12 ">
              {addedDevices.map((item, idx) => (
                <DeviceAndParameter
                  device={item.device}
                  parameters={item.parameters}
                  key={idx}
                  sites={sites}
                  addedDevices={addedDevices}
                  deviceNumber={idx + 1}
                  onRemoveDevice={onRemoveDevice}
                  onChangeDevice={onChangeDevice}
                />
              ))}
            </div>

            <div className="row mt-4">
              <div className="col-md-12 d-flex gap-3 align-items-center justify-content-end">
                <div
                  className="add_device_btn d-inline-block"
                  onClick={() =>
                    setAddedDevices([
                      ...addedDevices,
                      { device: "", parameter: "" },
                    ])
                  }
                >
                  <Button variant="success" size="sm">
                    <BiPlus />
                  </Button>
                  <span className="ms-1">Add Meter</span>
                </div>
                <button className="btn btn-success mw-2" type="submit">
                  Generate
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* <div className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" variant="dark" />}
              </div> */}
        <div className="row" style={{ minHeight: "350px" }}>
          <div className="col-md-12">
            <div className="mt-3">
              {graphView ? (
                <Spin spinning={isLoadingGraph}>
                  <SplineChart
                    title="Meter Comparison"
                    data={deviceData}
                    from={graphFrom}
                    to={graphTo}
                    isLoading={isLoadingGraph}
                    type="area"
                  />
                </Spin>
              ) : (
                <Spin spinning={isLoadingTable}>
                  <ReportTable
                    title="Meter Comparison"
                    data={deviceTableData}
                    parameters={deviceParameters.map((p) => ({
                      label: `${p.device?.name} - ${p.name}`,
                    }))}
                    total={total}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                    isLoading={isLoadingTable}
                  />
                </Spin>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceComparison;
