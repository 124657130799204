import React, { useEffect, useState } from "react";
import { notification, Spin } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const CreateNewPrepaidPlan = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [prepaidPlanData, setprepaidPlanData] = useState({
    name: "",
    monthlyMaintenanceFee: "",
    baseRate: "",
    kwtbbRate: "",
    startingBalance: "",
  });

  const { name, monthlyMaintenanceFee, baseRate, kwtbbRate, startingBalance } =
    prepaidPlanData;

  const onInputChange = (e) => {
    setprepaidPlanData({ ...prepaidPlanData, [e.target.name]: e.target.value });
  };
  console.log(prepaidPlanData);
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await axios
      .post(`/subscription`, prepaidPlanData, { withCredentials: true })
      .catch(function (error) {
        notification.warning({
          message: error.response?.data?.message || "Something went wrong.",
          placement: "bottomLeft",
        });
      });
    const data = response?.data;
    if (data) {
      setIsLoading(false);
      notification.success({
        message: "Prepaid Plan Created Successfully",
        placement: "bottomLeft",
      });
      setTimeout(() => {
        navigate("/all-prepaid-plans");
      }, 2000);
    }
  };

  useEffect(() => {
    document.title = "IVIS PMS - Subscription";
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Create New Prepaid Plan</span>
                </div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="d-flex">
                    <div className="col-md-6">
                      <label className="az-content-label">
                        General Information
                      </label>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Plan Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter plan name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Monthly Maintenance Fee (RM)
                        </label>
                        <input
                          type="number"
                          name="monthlyMaintenanceFee"
                          value={monthlyMaintenanceFee}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Account Starting Balance (RM)
                        </label>
                        <input
                          type="number"
                          name="startingBalance"
                          value={startingBalance}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="az-content-label">
                          electricity charges
                        </label>
                        <label htmlFor="baseRate" className="form-label">
                          Base kWh Rate (RM)
                        </label>
                        <input
                          type="number"
                          name="baseRate"
                          value={baseRate}
                          onChange={onInputChange}
                          className="form-control"
                          id="baseRate"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">KWTBB Rate (%)</label>
                        <input
                          type="number"
                          name="kwtbbRate"
                          value={kwtbbRate}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 float-end">
                  <button className="btn btn-success me-2" type="submit">
                    Create Prepaid Plan
                  </button>
                  <Link to="/all-prepaid-plans" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CreateNewPrepaidPlan;
