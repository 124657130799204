import React from "react";
import Chart from "react-apexcharts";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";

const LineChart = ({ data, mode, handlerRemove, handlerUpdate }) => {
  const { userDetails } = useSelector((state) => state.user);
  const options = {
    series: [
      {
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
      {
        name: "Desk",
        data: [100, 41, 35, 59, 49, 62, 89, 91, 148],
      },
    ],
    options: {
      chart: {
        type: "line",
        width: "100%",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Line Chart",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  };


  return (
    <div className="p-2">
      {userDetails.role === "superAdmin" && (
        <div className="d-flex justify-content-end align-items-center">
          {
            mode === 'view' ? '' : (<>
              <div className="clickbtn" onClick={() => handlerUpdate(data._id)}>
                <FiEdit className="text-secondary me-1" />
              </div>
              <div className="clickbtn" onClick={() => handlerRemove(data._id)}>
                <MdDeleteForever className="fs-5 text-secondary" />
              </div>
            </>)
          }
        </div>
      )}
      <div className="p-2">
        <Chart
          options={options.options}
          series={options.series}
          type="line"
        />
      </div>
    </div>
  );
};

export default LineChart;
