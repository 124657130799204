import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { FiUserCheck, FiUserX, FiTrash } from "react-icons/fi";
import { Spin } from "antd";
import { DataTable } from "../../components";
import { showAlert } from "../../utils";
import { userRole } from "../../constant";

export default function Users() {
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");
  const [searchBySatus, setSearchBySatus] = useState("active");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user`, {
        withCredentials: true,
        params: {
          limit,
          page,
          site: searchByLocation,
          status: searchBySatus,
          searchText: searchTerm,
        },
      });
      setTotal(response.data.count);
      setUsers(response.data.results);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "IVIS PMS - Users";
    getUsers();
    getSiteLocation();
  }, []);

  useEffect(() => {
    getUsers();
  }, [searchTerm, limit, page]);

  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };

  const columns = [
    {
      title: "No.",
      key: "no",
      render: (_, __, index) => index + 1 + limit * (page - 1),
      width: "60px",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User Type",
      dataIndex: "role",
      key: "user_type",
      render: (role) => (
        <div className="text-capitalize">
          {role === userRole.businessAdmin
            ? "Business Admin"
            : role === "user"
            ? "Customer"
            : role === userRole.admin
            ? "Site admin"
            : role}
        </div>
      ),
    },
    {
      title: "Date Created",
      key: "Date_Created",
      dataIndex: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
      // width: "130px",
    },
    {
      title: "Last Login",
      key: "Last_Login",
      dataIndex: "lastLogin",
      render: (lastLogin) => (
        <div>
          {lastLogin ? (
            <div>
              {moment(lastLogin).format("DD/MM/YYYY")}
              <br />
              {moment(lastLogin).format("hh:mm:ss A")}
            </div>
          ) : (
            "--"
          )}
        </div>
      ),
      minWidth: "120px",
    },
    {
      title: "Status",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive) => (
        <div>
          {isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "Action",
      align: "center",
      render: (_, row) => (
        <div>
          {(() => {
            switch (row.role) {
              case "installer":
                return (
                  <Link
                    to={`/installer/` + row._id}
                    className="btn btn-info me-1"
                  >
                    <AiOutlineEye title="View Profile" />
                  </Link>
                );
              case "admin":
                return (
                  <Link to={`/admin/` + row._id} className="btn btn-info me-1">
                    <AiOutlineEye title="View Profile" />
                  </Link>
                );
              case "businessAdmin":
                return (
                  <Link
                    to={`/business-admin/` + row._id}
                    className="btn btn-info me-1"
                  >
                    <AiOutlineEye title="View Profile" />
                  </Link>
                );
              case "user":
                return (
                  <Link
                    to={`/customer/` + row._id}
                    className="btn btn-info me-1"
                  >
                    <AiOutlineEye title="View Profile" />
                  </Link>
                );
              case "public":
                return (
                  <Link
                    to={`/public-user/` + row._id}
                    className="btn btn-info me-1"
                  >
                    <AiOutlineEye title="View Profile" />
                  </Link>
                );
              default:
                return;
            }
          })()}
          {(() => {
            switch (row.isActive) {
              case true:
                return (
                  <button
                    className="btn btn-danger me-1"
                    onClick={() =>
                      activeDeactiveUser(row._id, row.isActive, row.role)
                    }
                  >
                    <FiUserX title="Inactive Account" />
                  </button>
                );
              case false:
                return (
                  <button
                    className="btn btn-secondary  me-1"
                    onClick={() =>
                      activeDeactiveUser(row._id, row.isActive, row.role)
                    }
                  >
                    <FiUserCheck title="Active Account" />
                  </button>
                );
              default:
                return;
            }
          })()}

          {/* <button
            className="btn btn-danger"
            onClick={() => deleteUser(row._id)}
          >
            <FiTrash title="Delete User" />
          </button> */}
        </div>
      ),
      align: "center",
    },
  ];
  const activeDeactiveUser = async (userId, isActive, role) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: `Do you wish to ${!isActive ? "activate" : "deactivate"} this ${
        role === userRole.user ? "customer" : role
      }?`,
      // text: "Do you wish to deactivate this customer?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/user/` + userId, data, { withCredentials: true })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/` + userId, { withCredentials: true })
          .then((res) => {
            getUsers();
            showAlert(res.data);
          })
          .catch((err) => {
            showAlert(err.response?.data);
          });
      }
    });
  };
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div>
                <label className="az-content-label">Manage Users</label>
                <span className="d-block py-2">All Users</span>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3">
                <select
                  className="form-select"
                  onChange={handleLocation}
                  value={searchByLocation || ""}
                >
                  <option label="All Locations" value={""}></option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  onChange={(e) => setSearchBySatus(e.target.value)}
                  value={searchBySatus}
                >
                  <option label="All Status" value={""}></option>
                  <option label="Active" value={"active"}></option>
                  <option label="Inactive" value={"inactive"}></option>
                </select>
              </div>
              <div className="col-md-2">
                <Button onClick={getUsers} variant="success">
                  Search
                </Button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <Form className="d-flex">
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => {
                      setPage(1);
                      setSearchTerm(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={users}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}
