import { DatePicker, Spin } from "antd";
import axios from "axios";
import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Button as AntdButton } from "antd";
import dayjs from "dayjs";
import appConfig from "../../appConfig";
import { DataTable } from "../../components";
import moment from "moment";
import { numberFormater } from "../../utils";

const CustomerReport = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [othersInfo, setOthersInfo] = useState({});

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    if (!value) {
      setSearchValue({ ...searchValue, monthYear: "", year: "", [name]: "" });
    } else {
      setSearchValue({
        ...searchValue,
        monthYear: "",
        year: "",
        [name]: value,
      });
    }
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocation(response.data);
    } catch (error) {}
  };
  // const onChangeMonth = (_, dateString) => {
  //   console.log(dateString);
  //   setSearchValue({ ...searchValue, monthYear: dateString });
  // };
  // const onChangeYear = (_, dateString) => {
  //   setSearchValue({ ...searchValue, year: dateString });
  // };
  const getCustomerReport = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`/report/get-customer-report`, {
        withCredentials: true,
        params: {
          limit,
          page,
          ...searchValue,
        },
      });
      setCustomerData(
        data.results.map((item) => {
          item.period = `${dayjs(searchValue.from).format(
            "DD MMM, YYYY"
          )} - ${dayjs(searchValue.to).format("DD MMM, YYYY")}`;
          return item;
        })
      );
      setTotal(data.count);
      setOthersInfo(data);
    } catch (error) {}
    setIsLoading(false);
  };

  const getCsvReport = async () => {
    setIsCsvLoading(true);
    try {
      const { data } = await axios.get(`/report/get-customer-report`, {
        withCredentials: true,
        params: {
          limit: 40000,
          page: 1,
          ...searchValue,
        },
      });
      let arr = [];
      data.results.map((item) => {
        item.period = `${dayjs(searchValue.from).format(
          "DD MMM, YYYY"
        )} - ${dayjs(searchValue.to).format("DD MMM, YYYY")}`;
        item.totalBill = numberFormater(
          item.energyCost +
            item.internalWiringFee +
            item.depositeFee +
            item.maintenanceFee +
            item.kwtbbFee +
            item.lateRegistrationCompound +
            item.reconnectionFeeCharges +
            item.adminFees +
            item.repairServices
        );
        item.energyCost = numberFormater(item.energyCost);
        item.depositeFee = numberFormater(item.depositeFee);
        item.internalWiringFee = numberFormater(item.internalWiringFee);
        item.maintenanceFee = numberFormater(item.maintenanceFee);
        item.kwtbbFee = numberFormater(item.kwtbbFee);
        item.totalReload = numberFormater(item.totalReload);
        item.energyUsage = numberFormater(item.energyUsage);
        item.currentBalance = numberFormater(item.currentBalance);
        item.lateRegistrationCompound = numberFormater(
          item.lateRegistrationCompound
        );
        item.reconnectionFeeCharges = numberFormater(
          item.reconnectionFeeCharges
        );
        item.adminFees = numberFormater(item.adminFees);
        item.repairServices = numberFormater(item.repairServices);
        item.customerDepositRefund = numberFormater(item.customerDepositRefund);
        arr.push(item);
      });
      setCsvData(arr);
    } catch (error) {}
    setIsCsvLoading(false);
  };

  // console.log(csvData);

  useEffect(() => {
    getSiteLocation();
    getCustomerReport();
    getCsvReport();
    document.title = `${appConfig.name} - Customer Billing Report`;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCustomerReport();
  }, [limit, page]);

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      center: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Energy Usage (kWh)",
      dataIndex: "energyUsage",
      key: "energyUsage",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Energy Cost (RM)",
      dataIndex: "energyCost",
      key: "energyCost",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Maintenance (RM)",
      dataIndex: "maintenanceFee",
      key: "maintenanceFee",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Deposit (RM)",
      dataIndex: "depositeFee",
      key: "depositeFee",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Internal Wiring Fees  (RM)",
      dataIndex: "internalWiringFee",
      key: "internalWiringFee",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "KWTBB (RM)",
      dataIndex: "kwtbbFee",
      key: "kwtbbFee",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Late Registration (RM)",
      dataIndex: "lateRegistrationCompound",
      key: "lateRegistrationCompound",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Reconnection Fees (RM)",
      dataIndex: "reconnectionFeeCharges",
      key: "reconnectionFeeCharges",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Admin Fees (RM)",
      dataIndex: "adminFees",
      key: "adminFees",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Repair Services (RM)",
      dataIndex: "repairServices",
      key: "repairServices",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Deposit Refund (RM)",
      dataIndex: "customerDepositRefund",
      key: "customerDepositRefund",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Current Balance (RM)",
      dataIndex: "currentBalance",
      key: "currentBalance",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Total Top-Up (RM)",
      dataIndex: "totalReload",
      key: "totalReload",
      render: (data) => numberFormater(data),
      align: "center",
    },
    {
      title: "Total Bill (RM)",
      key: "TotalBill",
      render: ({
        energyCost,
        kwtbbFee,
        internalWiringFee,
        depositeFee,
        maintenanceFee,
      }) =>
        numberFormater(
          energyCost +
            kwtbbFee +
            internalWiringFee +
            depositeFee +
            maintenanceFee
        ),
      align: "center",
    },
    {
      title: "Period",
      key: "Period",
      dataIndex: "period",
      align: "center",
    },
  ];
  // const disabledSearch =    (!searchValue.monthYear && !searchValue.year) || !searchValue?.site;
  const disabledSearch =
    (!searchValue.from && !searchValue.to) || !searchValue?.site;

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Customer Billing Report</span>
                </div>
              </div>
              <div className="row gy-2">
                <div className="col-md-3">
                  <select
                    className="form-select"
                    onChange={onChangeSearch}
                    value={searchValue.site}
                    name="site"
                  >
                    <option label="Select Location"></option>
                    {siteLocation &&
                      siteLocation.length > 0 &&
                      siteLocation.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <DatePicker
                    className="form-control"
                    placeholder="Select Start Date"
                    size="large"
                    disabledDate={disabledDate}
                    onChange={(e, date) => {
                      setSearchValue({ ...searchValue, from: date });
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    className="form-control"
                    placeholder="Select End Date"
                    size="large"
                    disabledDate={disabledDate}
                    onChange={(e, date) => {
                      setSearchValue({ ...searchValue, to: date });
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={() => {
                      getCustomerReport();
                      getCsvReport();
                    }}
                    disabled={disabledSearch}
                  >
                    Search
                  </Button>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-2 text-right">
                  <AntdButton
                    // onClick={getCustomerReport}
                    disabled={disabledSearch || !csvData.length}
                    loading={isCsvLoading}
                  >
                    <CSVLink
                      headers={csvHeaders}
                      data={csvData}
                      filename={`Customer Report.csv`}
                      role="button"
                      style={{
                        cursor:
                          disabledSearch || !csvData.length
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={(e) => {
                        // e.preventDefault();

                        if (disabledSearch || !csvData.length)
                          e.preventDefault();
                      }}
                    >
                      CSV
                    </CSVLink>
                  </AntdButton>
                </div>
              </div>
              <div className="row mt-2">
                {pageHeaderItems.map(({ label, fieldName }) => (
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 mt-2">{label}</div>
                      <div className="col-md-1"></div>
                      <div className="col-md-5 mt-2">
                        :{" "}
                        {fieldName === "period"
                          ? othersInfo?.[fieldName] || "--"
                          : fieldName === "count"
                          ? othersInfo?.[fieldName]
                          : othersInfo?.[fieldName]
                          ? numberFormater(othersInfo?.[fieldName])
                          : "0.00"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-md-12 mt-4">
                  <DataTable
                    dataSource={customerData}
                    columns={columns}
                    rowKey={(record) => record.id}
                    pagination={{
                      defaultPageSize: limit,
                      current: page,
                      total,
                      showSizeChanger: true,
                      onShowSizeChange: (_, pageSize) => {
                        setLimit(pageSize);
                      },
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total}`,
                      pageSizeOptions: [10, 20, 50],
                      responsive: true,
                    }}
                    onChange={(pagination) => {
                      setPage(pagination.current);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CustomerReport;

const disabledDate = (current) => {
  let date = new Date();
  const currentDate = moment(date).format("MMM, YYYY");
  const monentDate = current.format("MMM, YYYY");
  const dayOfCrntMonth = date.getDate();
  const dayFlag = dayOfCrntMonth < 7 && currentDate === monentDate;
  const dateCurrent = Number(moment(date).format("x"));
  return current && current.valueOf() > dateCurrent;
};

const csvHeaders = [
  { label: "ID", key: "customerId" },
  { label: "Customer", key: "name" },
  { label: "Energy Usage (kWh)", key: "energyUsage" },
  { label: "Energy Cost (RM)", key: "energyCost" },
  { label: "Maintenance (RM)", key: "maintenanceFee" },
  { label: "Deposit (RM)", key: "depositeFee" },
  { label: "Internal Wiring Fees (RM)", key: "internalWiringFee" },
  { label: "KWTBB (RM)", key: "kwtbbFee" },
  {
    label: "Late Registration Compound (RM)",
    key: "lateRegistrationCompound",
  },
  {
    label: "Reconnection Fee Charges (RM)",
    key: "reconnectionFeeCharges",
  },
  { label: "Admin Fees (RM)", key: "adminFees" },
  { label: "Repair Fees (RM)", key: "repairServices" },
  { label: "Deposit Refund (RM)", key: "customerDepositRefund" },

  { label: "Current Balance (RM)", key: "currentBalance" },
  { label: "Total Top-Up (RM)", key: "totalReload" },
  { label: "Total Bill (RM)", key: "totalBill" },
  { label: "Period", key: "period" },
];

const pageHeaderItems = [
  { label: "Period Selected", fieldName: "period" },
  {
    label: "Total Maintenance Fees Collected (RM)",
    fieldName: "totalMonthlyMaintenanceFee",
  },
  { label: "Total Customers", fieldName: "count" },
  { label: "Total Deposit Fees Collected (RM)", fieldName: "totalDeposite" },
  { label: "Total Energy Usage (kWh)", fieldName: "totalKwh" },
  {
    label: "Total Internal Wiring Fees Collected (RM)",
    fieldName: "totalWiring",
  },
  { label: "Total Energy Cost (RM)", fieldName: "totalPrice" },
  { label: "Total KWTBB Fees Collected (RM)", fieldName: "totalOthersFee" },
  {
    label: "Total Customer Deposit Refund (RM)",
    fieldName: "customerDepositRefund",
  },
  { label: "Total Other Fees (RM)", fieldName: "totalOtherFees" },
];
