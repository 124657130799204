import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FiEye, FiEdit, FiTrash, FiPause, FiPlay } from "react-icons/fi";
import { AiOutlineFundView } from "react-icons/ai";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Select, Spin, Tag } from "antd";
import { meterStatus } from "../../constant";
import { socket } from "../../api/socket";
import { DataTable } from "../../components";

const Devices = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(Number(searchParams.get("limit")) || 10);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  // const [reloadData, setReloadData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [siteLocation, setSiteLocation] = useState([]);
  const [portList, setPortList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("s") || "");
  const [searchByLocation, setSearchByLocation] = useState(
    searchParams.get("site") || ""
  );
  const [searchByStatus, setSearchByStatus] = useState(
    searchParams.get("status") || ""
  );
  const [searchByPort, setSearchByPort] = useState(
    searchParams.get("port") || ""
  );
  const [searchByDeviceType, setSearchByDeviceType] = useState(
    searchParams.get("deviceType") || "customer"
  );
  const [query, setQeury] = useState({
    site: searchParams.get("site") || "",
    status: searchParams.get("status") || "",
    port: searchParams.get("port") || "",
    deviceType: searchParams.get("deviceType") || "customer",
  });

  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.user?.userDetails);

  const getDevices = async (shouldNotLoad) => {
    if (!shouldNotLoad) setIsLoading(true);
    try {
      let response = await axios.get(`/device`, {
        withCredentials: true,
        params: {
          searchText: searchTerm,
          limit,
          page,
          ...query,
          sortBy: "createdAt",
          orderBy: "DESC",
        },
      });

      let { results, count } = response.data;

      results = results.map((item, idx) => {
        item.idx = (page - 1) * limit + idx + 1;
        return item;
      });
      setDevices(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  const getPortList = async (site) => {
    try {
      let ports = await axios.get(`/device/get-tcp-ports`, {
        withCredentials: true,
        params: { site },
      });
      setPortList(ports.data?.results || []);
    } catch (error) {
      setPortList([]);
    }
  };

  const handleLocation = async (value) => {
    setSearchByLocation(value);
    getPortList(value);
    setSearchByPort("");
  };

  const deleteDevice = async (deviceId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this meter?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/device/` + deviceId, {
            withCredentials: true,
          })
          .then((res) => {
            getDevices();
            Swal.fire({
              title: "Done!",
              text: "Device Deleted successfully",
              icon: "info",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const getStatusColor = (status) => {
    if (status === meterStatus.deactivated) return "red";
    else if (status === meterStatus.offline) return "magenta";
    else if (status === meterStatus.online) return "green";
  };

  const activeDeactiveMeter = async (deviceId, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change meter status?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.put(
            `/device/status/` + deviceId,
            { status },
            {
              withCredentials: true,
            }
          );
          Swal.fire({
            title: "Done!",
            text: "Meter Status Successfully Changed",
            icon: "success",
            timer: 2000,
            button: false,
          });
          getDevices();
        } catch (error) {}
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "idx",
      key: "idx",
    },
    {
      title: "Meter Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Customer",
      key: "customer",
      dataIndex: "customer",
      render: (customer) => customer?.name || "N/A",
    },
    {
      title: "Credit Balance (RM)",
      key: "customer1",
      dataIndex: "customer",
      render: (customer) =>
        typeof customer?.currentBalance === "number"
          ? Number(customer?.currentBalance || 0).toFixed(2)
          : "N/A",
      align: "right",
    },
    {
      title: "Site Location",
      dataIndex: "site",
      key: "site",
      render: (site) => <div className="text-wrap">{site?.name}</div>,
    },
    {
      title: "Status",
      render: ({ status }) => (
        <div className="text-wrap">
          <Tag color={getStatusColor(status)}>{status}</Tag>
        </div>
      ),
      align: "center",
    },
    {
      title: "Date Installed",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
      key: "createdAt",
      dataIndex: "createdAt",
      align: "center",
    },
    {
      title: "Action",
      render: (row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link
                      to={`/meters/` + row._id}
                      className="btn btn-az-primary me-1"
                      title="View Meter"
                    >
                      <FiEye />
                    </Link>
                    {row?.customer?._id ? (
                      <Link
                        to={
                          (row?.deviceType === "admin"
                            ? "/admin/"
                            : `/customer/`) + row.customer._id
                        }
                        className="btn btn-info me-1"
                        title={
                          row?.deviceType === "admin"
                            ? "View Admin"
                            : "ViewCustomer"
                        }
                      >
                        <FiEye />
                      </Link>
                    ) : (
                      ""
                    )}
                    <Link
                      to={`/edit-meter/` + row._id}
                      title="Edit Meter"
                      className="btn btn-success me-1"
                    >
                      <FiEdit />
                    </Link>
                    <Link
                      to={
                        `/meters-data/` +
                        row._id +
                        "?deviceType=" +
                        row.deviceType
                      }
                      title="View Data"
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                    {row.deviceType !== "admin" && (
                      <>
                        {row.status !== meterStatus.deactivated ? (
                          <button
                            className="btn btn-secondary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(
                                row._id,
                                meterStatus.deactivated
                              )
                            }
                          >
                            <FiPause title="Deactivate Meter" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(row._id, meterStatus.offline)
                            }
                          >
                            <FiPlay title="Active Meter" />
                          </button>
                        )}
                      </>
                    )}

                    <button
                      className="btn btn-danger"
                      title="Delete Meter"
                      onClick={() => deleteDevice(row._id)}
                    >
                      <FiTrash />
                    </button>
                  </div>
                );
              case "installer":
                return (
                  <div className="actions">
                    <Link
                      to={`/meters/` + row._id}
                      className="btn btn-info me-1"
                      title="View Meter"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={
                        `/meters-data/` +
                        row._id +
                        "?deviceType=" +
                        row.deviceType
                      }
                      className="btn btn-warning me-1"
                      title="View Data"
                    >
                      <AiOutlineFundView />
                    </Link>
                    {row.deviceType !== "admin" && (
                      <>
                        {row.status !== meterStatus.deactivated ? (
                          <button
                            className="btn btn-secondary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(
                                row._id,
                                meterStatus.deactivated
                              )
                            }
                          >
                            <FiPause title="Deactivate Meter" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(row._id, meterStatus.offline)
                            }
                          >
                            <FiPlay title="Active Meter" />
                          </button>
                        )}
                      </>
                    )}
                    <Link
                      to={`/edit-meter/` + row._id}
                      className="btn btn-success me-1"
                      title="Edit Meter"
                    >
                      <FiEdit />
                    </Link>
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link
                      to={`/meters/` + row._id}
                      className="btn btn-info me-1"
                      title="View Meter"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/edit-meter/` + row._id}
                      className="btn btn-success me-1"
                      title="Edit Meter"
                    >
                      <FiEdit />
                    </Link>
                    <Link
                      to={
                        `/meters-data/` +
                        row._id +
                        "?deviceType=" +
                        row.deviceType
                      }
                      className="btn btn-warning me-1"
                      title="View Data"
                    >
                      <AiOutlineFundView />
                    </Link>
                    {row.deviceType !== "admin" && (
                      <>
                        {row.status !== meterStatus.deactivated ? (
                          <button
                            className="btn btn-secondary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(
                                row._id,
                                meterStatus.deactivated
                              )
                            }
                          >
                            <FiPause title="Deactivate Meter" />
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary  me-1"
                            onClick={() =>
                              activeDeactiveMeter(row._id, meterStatus.offline)
                            }
                          >
                            <FiPlay title="Active Meter" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                );
              case "user":
                return (
                  <div className="actions">
                    <Link
                      to={`/meters/` + row._id}
                      className="btn btn-info me-1"
                      title="View Meter"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={
                        `/meters-data/` +
                        row._id +
                        "?deviceType=" +
                        row.deviceType
                      }
                      className="btn btn-warning me-1"
                      title="View Data"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              case "public":
                return (
                  <div className="actions">
                    <Link
                      to={`/meters/` + row._id}
                      className="btn btn-info me-1"
                      title="View Meter"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={
                        `/meters-data/` +
                        row._id +
                        "?deviceType=" +
                        row.deviceType
                      }
                      className="btn btn-warning me-1"
                      title="View Data"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              default:
                return;
            }
          })()}
        </div>
      ),
      align: "center",
    },
  ];
  // console.log({limit, page});

  useEffect(() => {
    document.title = "IVIS PMS - Meters";
    getDevices();
    getSiteLocation();
    getPortList();

    let queryLimit = Number(searchParams.get("limit")) || 10;
    let queryPage = Number(searchParams.get("page")) || 1;
    let querytext = searchParams.get("s") || "";
    let port = searchParams.get("port") || "";
    let site = searchParams.get("site") || "";
    let status = searchParams.get("status") || "";
    let deviceType = searchParams.get("deviceType") || "customer";

    setSearchByLocation(site);
    setSearchTerm(querytext);
    setSearchByPort(port);
    setSearchByDeviceType(deviceType);
    setSearchByStatus(status);
    setPage(queryPage);
    setLimit(queryLimit);
    setQeury({
      site,
      port,
      status,
      deviceType,
    });
  }, [searchParams]);

  useEffect(() => {
    // socket.on("messageResponse", (data) => {
    //   getDevices(true);
    // });
    getDevices();
    const interval = setInterval(() => {
      getDevices(true);
    }, 5000);
    return () => clearInterval(interval);
  }, [limit, page, searchTerm, query]);

  // useEffect(() => {
  //   socket.on("messageResponse", (data) => {
  //     getDevices(true);
  //   });
  // }, [socket]);

  const navigateWithQuryParams = (payload) => {
    let queryParams = {};
    searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    queryParams = new URLSearchParams({ ...queryParams, ...payload });
    navigate({ search: queryParams.toString() });
  };

  const onSubmitSearch = () => {
    const obj = {};
    setLimit(10);
    setPage(1);
    obj.site = searchByLocation;
    obj.status = searchByStatus;
    obj.port = searchByPort;
    obj.deviceType = searchByDeviceType;
    obj.limit = 10;
    obj.page = 1;
    navigateWithQuryParams(obj);
    // setQeury(obj);
  };

  return (
    <div className="devices az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-md-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div>
                <label className="az-content-label">Manage Meters</label>
                <span className="d-block py-2">All Meters</span>
              </div>
              <div>
                <Link to="/add-meters" className="btn btn-az-primary btn-md">
                  Add New Meter
                </Link>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3">
                <Select
                  onChange={handleLocation}
                  value={searchByLocation || ""}
                  size="large"
                  className="w-100"
                >
                  <option value="">All Locations</option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </div>
              {/* status */}
              <div className="col-md-2">
                <Select
                  onChange={(value) => setSearchByStatus(value)}
                  value={searchByStatus || ""}
                  size="large"
                  className="w-100"
                >
                  <option value="">All Status</option>
                  {meterStatusList.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              {/* port */}
              <div className="col-md-2">
                <Select
                  onChange={(value) => setSearchByPort(value)}
                  value={searchByPort || ""}
                  size="large"
                  className="w-100"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children
                      ? option?.children.toLowerCase()
                      : ""
                    ).includes(input?.toLowerCase())
                  }
                >
                  <option value="">All Ports</option>
                  {portList.map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              {/* device type */}
              <div className="col-md-2">
                <Select
                  onChange={(value) => setSearchByDeviceType(value)}
                  value={searchByDeviceType}
                  size="large"
                  className="w-100"
                >
                  <option value="customer">Customer Meter</option>
                  <option value="admin">Admin Meter</option>
                </Select>
              </div>
              <div className="col-md-1">
                <Button onClick={onSubmitSearch} variant="success">
                  Search
                </Button>
              </div>
              <div className="col-md-2">
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    value={searchTerm}
                    onChange={(e) => {
                      navigateWithQuryParams({ s: e.target.value });
                      setSearchTerm(e.target.value);
                    }}
                    placeholder="Search"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                className="devices-table"
                dataSource={devices}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  pageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                // onChange={(pagination) => {
                //   navigate(
                //     `/meters?page=${pagination.current}&limit=${limit}&s=${searchTerm}`
                //   );
                // }}
                onChange={(pagination) => {
                  navigateWithQuryParams({
                    page: pagination.current,
                    limit: pagination.pageSize,
                  });
                  setLimit(pagination.pageSize);
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;

export const meterStatusList = ["Online", "Offline", "Deactivated"];
