import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiNotification } from "../../utils";
import { Spin } from "antd";
import ChangePasswordModal from "../SiteUsers/ChangePasswordModal";

export default function EditBusinessAdmin() {
  const [modalShow, setModalShow] = useState(false);
  const Params = useParams();
  const adminId = Params.adminId;
  const navigate = useNavigate();
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddress: "",
  });
  const { name, email, phone, companyName, companyAddress } = adminData;

  const onInputChange = (e) => {
    setAdminData({ ...adminData, [e.target.name]: e.target.value });
  };

  //Profile Photo
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(`/user/` + adminId, adminData, {
        withCredentials: true,
      });
      if (selectedImage) {
        await axios.put(`/user/${adminId}/avatarUpload`, selectedImage, {
          withCredentials: true,
        });
      }
      apiNotification({ message: "Business Admin Edited Successfully" });
      setTimeout(() => {
        setSuccessMessage();
        navigate("/business-admins");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong",
        warn: true,
      });
    }
    setIsLoading(false);
  };

  const getAdmin = async (adminId) => {
    const response = await axios.get(`/user/` + adminId, {
      withCredentials: true,
    });
    if (response) {
      const data = response.data;
      setAdminData({
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        // fax: data?.fax,
        companyName: data?.companyName,
        companyAddress: data?.companyAddress,
      });
      setImageUrl(data?.avatar);
    }
  };
  useEffect(() => {
    getAdmin(adminId);
  }, [adminId]);
  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Business Admins</label>
                  <span className="d-block py-2">Update Business Admin Information</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Enter email address"
                      // required
                    />
                  </div>
                </div>

                {/* <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="cname" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={onInputChange}
                      className="form-control"
                      id="cname"
                      placeholder="Enter company name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="companyAddress" className="form-label">
                      Company Address
                    </label>
                    <input
                      type="text"
                      name="companyAddress"
                      value={companyAddress}
                      onChange={onInputChange}
                      className="form-control"
                      id="companyAddress"
                      placeholder="Enter company address"
                    />
                  </div>
                </div> */}

                <div className="row mb-3">
                  {/* <div className="col-md-6">
                  <label htmlFor="fax" className="form-label">
                    Fax Number
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      +6
                    </span>
                    <input
                      type="number"
                      name="fax"
                      value={fax}
                      onChange={onInputChange}
                      className="form-control"
                      id="fax"
                      placeholder="Enter fax number"
                    />
                  </div>
                </div> */}
                  <div className="col-md-6">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        // required
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="bname" className="form-label">
                    Profile Photo
                  </label>
                  {imageUrl && selectedImage ? (
                    <div mt={2} textAlign="center">
                      <img src={imageUrl} alt="avatar" height="100px" />
                    </div>
                  ) : (
                    <>
                      <input
                        className="form-control"
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                      />
                      <label htmlFor="select-image">
                        <img
                          src={imageUrl}
                          alt=""
                          height="100px"
                          className="rounded-3 border p-2 ms-2"
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => setModalShow(true)}
                  >
                    Change Password
                  </button>
                  <Link to="/business-admins" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        userId={adminId}
      />
    </Spin>
  );
}
