import { Modal, notification, Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { VsssbSelect } from "../../components/Common/VsssbSelect";

const RecipientList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [users, setUsers] = useState([]);
  const [siteLocation, setSiteLocation] = useState([]);
  const [cancelDeposit, setCancelDeoposit] = useState(false);
  const [cancelInternal, setCancelInternal] = useState(false);
  const [assignPlanInput, setAssignPlanInput] = useState({
    site: "",
    user: "",
    plan: "",
    depositType: "",
    internalWiringType: "",
  });

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  const getPlan = async () => {
    try {
      const response = await axios.get(`/subscription`, {
        withCredentials: true,
      });
      if (response) {
        setPlans(response.data);
      }
    } catch (error) {}
  };
  const getUser = async (siteId) => {
    try {
      const response = await axios.get(`user/siteUser/${siteId}/user`, {
        withCredentials: true,
        params: { onlyActive: true },
      });
      if (response) {
        // console.log(response)
        setUsers(response?.data || []);
      }
    } catch (error) {}
  };

  const onInputChange = (e) => {
    console.log(e.target.name);
    setAssignPlanInput({
      ...assignPlanInput,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "user") {
      setCancelDeoposit(false);
      setCancelInternal(false);
    }
  };

  const handleLocationChange = (e) => {
    const siteId = e?.target?.value;
    setAssignPlanInput({
      user: "",
      plan: "",
      depositType: "",
      internalWiringType: "",
      site: siteId,
    });
    if (siteId) getUser(siteId);
    else {
      setUsers([]);
    }
    setCancelDeoposit(false);
    setCancelInternal(false);
  };
  let isDisable =
    !assignPlanInput?.site || !assignPlanInput?.user || !assignPlanInput.plan;

  console.log({
    site: assignPlanInput?.site,
    user: assignPlanInput?.user,
    plan: assignPlanInput.plan,
  });

  const hasPlan = users?.find((us) => us._id === assignPlanInput?.user)?.plan;

  const onUpdateCustomerPlan = async () => {
    // e.preventDefault();
    // setIsLoading(true);
    let data = { ...assignPlanInput };
    // deposite
    if (assignPlanInput.depositType === "Yes") {
      if (cancelDeposit || !hasPlan?.depositInstallmentPeriod) {
        data.depositInstallmentPeriod = getInstallmentPeriod(12);
        console.log("new deposite");
      } else {
        data.depositInstallmentPeriod = hasPlan?.depositInstallmentPeriod;
        console.log("old deposite");
      }
    } else {
      data.depositInstallmentPeriod = "";
    }
    // internal
    if (assignPlanInput.internalWiringType === "Yes") {
      if (cancelInternal || !hasPlan?.internalInstallmentPeriod) {
        data.internalInstallmentPeriod = getInstallmentPeriod(48);
        console.log("new internal");
      } else {
        data.internalInstallmentPeriod = hasPlan?.internalInstallmentPeriod;
        console.log("old internal");
      }
    } else {
      data.internalInstallmentPeriod = "";
    }
    // console.log(data);
    // return;
    try {
      const res = await axios.post(`/subscription/assign-plan`, data, {
        withCredentials: true,
      });
      notification.success({
        message: res?.data?.message,
        placement: "bottomLeft",
      });
      setAssignPlanInput({
        user: "",
        site: "",
        plan: "",
        depositType: "",
        internalWiringType: "",
      });
      setCancelDeoposit(false);
      setCancelInternal(false);
      setUsers([]);
    } catch (error) {
      notification.warning({
        message: error.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  const handleSubmitEx = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "This action will change the subscription plan information for this customer.",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        onUpdateCustomerPlan();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  useEffect(() => {
    if (hasPlan) {
      setAssignPlanInput({
        ...assignPlanInput,
        internalWiringType: hasPlan.internalWiringType,
        depositType: hasPlan.depositType,
        plan: hasPlan.plan?._id,
      });
    } else {
      setAssignPlanInput({
        ...assignPlanInput,
        internalWiringType: "",
        depositType: "",
        plan: "",
      });
    }
  }, [hasPlan]);

  useEffect(() => {
    document.title = "IVIS PMS - Recipient List";
    getSiteLocation();
    getPlan();
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="settings">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <h6>Prepaid Plan Customer List</h6>
                </div>
                <div className="col-md-6 ">
                  <Link
                    to="/customer-subscription-summary"
                    className="btn btn-secondary float-end"
                  >
                    Back
                  </Link>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-12">
                  <h5 className="pb-2">Assign Prepaid Plan To Customer</h5>
                  <form onSubmit={handleSubmitEx}>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="site" className="form-label">
                          Site Locations
                        </label>
                        <select
                          name="site"
                          value={assignPlanInput.site}
                          className="form-select"
                          onChange={handleLocationChange}
                        >
                          <option label="All Site Locations"></option>
                          {siteLocation?.length &&
                            siteLocation.map((item, index) => (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <VsssbSelect
                          label="Customer"
                          placeholder="Select Customer"
                          value={assignPlanInput.user}
                          onChange={(value) => {
                            onInputChange({
                              target: { name: "user", value },
                            });
                          }}
                          options={users}
                        />
                        <p
                          style={{
                            color: "red",
                            marginBottom: 0,
                            marginTop: "2px",
                            visibility: hasPlan?._id ? "visible" : "hidden",
                          }}
                        >
                          Warning: This customer is already assigned to an
                          existing prepaid plan.
                        </p>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <h6>Select Prepaid Plan</h6>

                      <div className="col-md-6">
                        <label htmlFor="planName" className="form-label">
                          Plan Name
                        </label>
                        <select
                          name="plan"
                          value={assignPlanInput.plan}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option> Select Plan</option>
                          {plans.length &&
                            plans.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <h6>Select Additional Fees</h6>

                      <div className="col-md-6">
                        <label htmlFor="planName" className="form-label">
                          Deposit Type
                        </label>
                        <select
                          name="depositType"
                          value={assignPlanInput.depositType || ""}
                          className="form-select"
                          onChange={onInputChange}
                          placeholder="Select Deposit Type"
                          disabled={
                            !cancelDeposit && hasPlan?.depositType === "Yes"
                          }
                        >
                          <option value="">Select Deposit Type</option>
                          <option value="Yes">
                            Monthly (RM 25 x 12 Months)
                            {!cancelDeposit &&
                              hasPlan?.depositType === "Yes" &&
                              ` : ${hasPlan?.depositInstallmentPeriod}`}
                          </option>
                          <option value="No">Upfront</option>
                          <option value="N/A">Not Applicable</option>
                        </select>
                        {hasPlan?.depositType === "Yes" && (
                          <button
                            className="btn btn-danger mt-1"
                            onClick={() => {
                              setCancelDeoposit(true);
                              setAssignPlanInput({
                                ...assignPlanInput,
                                depositType: "No",
                              });
                            }}
                            disabled={cancelDeposit}
                          >
                            Cancel Deposit Instalment Plan
                          </button>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="planName" className="form-label">
                          Internal Wiring Type
                        </label>
                        <select
                          name="internalWiringType"
                          value={assignPlanInput.internalWiringType || ""}
                          className="form-select"
                          onChange={onInputChange}
                          placeholder="Select Internal Wiring Type"
                          disabled={
                            !cancelInternal &&
                            hasPlan?.internalWiringType === "Yes"
                          }
                        >
                          <option value="">Select Internal Wiring Type</option>
                          <option value="Yes">
                            Monthly (RM17 x 48 Months)
                            {!cancelInternal &&
                              hasPlan?.internalWiringType === "Yes" &&
                              ` : ${hasPlan?.internalInstallmentPeriod}`}
                          </option>
                          <option value="No">Upfront</option>
                          <option value="N/A">Not Applicable</option>
                        </select>
                        {hasPlan?.internalWiringType === "Yes" && (
                          <button
                            className="btn btn-danger mt-1"
                            onClick={() => {
                              setCancelInternal(true);
                              setAssignPlanInput({
                                ...assignPlanInput,
                                internalWiringType: "No",
                              });
                            }}
                            disabled={cancelInternal}
                          >
                            Cancel Internal Wiring Instalment Plan
                          </button>
                        )}
                      </div>
                      <div className="col-md-12">
                        <button
                          type="submit"
                          className="btn btn-success mt-4 float-end"
                          disabled={isDisable}
                        >
                          {!hasPlan?._id ? " Assign Plan" : "Save"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <hr /> */}

              {/* <PrepaidPlanListTable
              data={assignedPlanEx}
              getAssignedPlan={getAssignedPlan}
            /> */}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default RecipientList;

// if (res?.data?.havePrevPlan) {
//   Modal.info({
//     title: "Plan successfully reassigned to customer.",
//     content: (
//       <div>
//         <p>
//           The change will only take effect in the new month (1st day).
//         </p>
//       </div>
//     ),
//   });
// } else {
//   notification.success({
//     message: res?.data?.message,
//     placement: "bottomLeft",
//   });
// }

const getInstallmentPeriod = (month) => {
  let depositeFirstDay = new Date();
  let depositeLastDay = new Date();
  let depositInstallmentCount = 0;

  if (depositeFirstDay.getDate() >= 7) {
    depositInstallmentCount = -1;
  }
  depositeFirstDay = new Date(
    depositeFirstDay.getFullYear(),
    depositeFirstDay.getMonth() - depositInstallmentCount,
    1
  );
  depositeLastDay = new Date(
    depositeLastDay.getFullYear(),
    depositeLastDay.getMonth() + (month - depositInstallmentCount),
    0
  );
  depositeFirstDay = moment(depositeFirstDay).format("MM/YYYY");
  depositeLastDay = moment(depositeLastDay).format("MM/YYYY");
  return depositeFirstDay + " - " + depositeLastDay;
};
