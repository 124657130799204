import { Spin } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const ViewPrepaidPlan = () => {
  const [isLoading, setLoading] = useState(false);
  const Params = useParams();
  const planId = Params.planId;
  const [prepaidPlanDetails, setprepaidPlanDetails] = useState();
  const navigate = useNavigate();
  const getPrepaidPlan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/subscription/` + planId, {
        withCredentials: true,
      });
      if (response) {
        setprepaidPlanDetails(response.data);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    document.title = "IVIS PMS - Edit Prepaid Plan";
    getPrepaidPlan();
  }, []);
  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">
                    Manage Prepaid Plan
                  </label>
                  <span className="d-block py-2">Prepaid Plan Details</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="row mb-2">
                    <div className="col-5">Plan Name</div>
                    <div className="col-7">: {prepaidPlanDetails?.name}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">Monthly Maintenance Fee (RM)</div>
                    <div className="col-7">
                      : { Number(prepaidPlanDetails?.monthlyMaintenanceFee || 0).toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">Account Starting Balance (RM)</div>
                    <div className="col-7">
                      : { Number(prepaidPlanDetails?.startingBalance || 0).toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">Base kWh Rate (RM)</div>
                    <div className="col-7">
                      : { Number(prepaidPlanDetails?.baseRate || 0).toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-5">KWTBB Rate (%)</div>
                    <div className="col-7">
                      : { Number(prepaidPlanDetails?.kwtbbRate || 0).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                  <Link
                    to={`/edit-prepaid-plan/` + planId}
                    className="btn btn-success me-1"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ViewPrepaidPlan;
