import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import { Select, Spin } from "antd";
import { apiNotification } from "../../utils";

export default function EditSiteLocation() {
  const Params = useParams();
  const siteLocationId = Params.siteLocationId;
  const userDetails = useSelector((state) => state.user.userDetails);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [buildingTypes, setBuildingTypes] = useState([]);
  const [Admins, setAdmins] = useState([]);
  const [item, setItem] = useState([]);
  const [Installers, setInstallers] = useState([]);
  const [ElectricityTariff, setElectricityTariff] = useState([]);
  const [siteLocationData, setSiteLocationData] = useState({
    name: "",
    admin: "",
    installer: "",
    buildingName: "",
    buildingType: "",
    buildingAddress1: "",
    buildingAddress2: "",
    addLatitude: "",
    addLongitude: "",
    buildingPostalCode: "",
    buildingOwnerName: "",
    buildingOwnerEmail: "",
    contactPersonName: "",
    contactPersonPhone: "",
    localAuthority: "",
    netFloorArea: "",
    tariffElectricity: "",
    remark: "",
    buildingBackground: "",
    locationCity: "",
    locationState: "",
  });
  const {
    name,
    admin,
    installer,
    buildingName,
    buildingType,
    buildingAddress1,
    buildingAddress2,
    buildingPostalCode,
    locationState,
    locationCity,
  } = siteLocationData;

  const onInputChange = (e) => {
    setSiteLocationData({
      ...siteLocationData,
      [e.target.name]: e.target.value,
    });
  };

  const getBuildingTypes = async () => {
    const response = await axios.get(`/building-type`, {
      withCredentials: true,
    });
    if (response) {
      setBuildingTypes(response.data);
    }
  };
  const getAdmins = async () => {
    const response = await axios.get(`/user/role/admin`, {
      withCredentials: true,
    });
    if (response) {
      setAdmins(response.data);
    }
  };
  const getInstaller = async () => {
    const response = await axios.get(`/user/role/installer`, {
      withCredentials: true,
    });
    if (response) {
      setInstallers(response.data);
    }
  };
  const getElectricityTariff = async () => {
    const response = await axios.get(`/electricity-tariff`, {
      withCredentials: true,
    });
    if (response) {
      setElectricityTariff(response.data);
    }
  };
  useEffect(() => {
    document.title = "IVIS PMS - Site Location";
    getElectricityTariff();
    getInstaller();
    getAdmins();
    getBuildingTypes();
  }, []);

  const handleSelected = (selectedList) => {
    setItem(selectedList);
  };

  const getSiteLocation = async () => {
    const response = await axios.get(`/site-location/` + siteLocationId, {
      withCredentials: true,
    });
    if (response) {
      const data = response.data;
      console.log({ data });
      setSiteLocationData({
        name: data?.name,
        admin: data?.admin.map((a) => a._id),
        installer: data?.installer[0]?._id || Installers[0]?._id,
        buildingName: data?.buildingName,
        buildingType: data?.buildingType,
        buildingAddress1: data?.buildingAddress1,
        buildingAddress2: data?.buildingAddress2,
        addLatitude: data?.addLatitude,
        addLongitude: data?.addLongitude,
        buildingPostalCode: data?.buildingPostalCode,
        buildingOwnerName: data?.buildingOwnerName,
        buildingOwnerEmail: data?.buildingOwnerEmail,
        contactPersonName: data?.contactPersonName,
        contactPersonPhone: data?.contactPersonPhone,
        localAuthority: data?.localAuthority,
        netFloorArea: data?.netFloorArea,
        locationState: data?.locationState,
        locationCity: data?.locationCity,
        tariffElectricity:
          data?.tariffElectricity?._id || ElectricityTariff[0]?._id,
        remark: data?.remark,
        buildingBackground: data?.buildingBackground?._id,
      });
      setItem(data?.installer || []);
    }
  };
  useEffect(() => {
    getSiteLocation();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedDta = {
      ...siteLocationData,
      installer: item.map(({ _id }) => _id),
    };
    // if (!item?.length) {
    //   apiNotification({ message: "Please select an Installer.", warn: true });
    //   return;
    // } else
    if (!updatedDta.admin) {
      apiNotification({ message: "Please select an Admin.", warn: true });
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(`/site-location/` + siteLocationId, updatedDta, {
        withCredentials: true,
      });
      apiNotification({ message: "Site Location Edited Successfully" });
      setTimeout(() => {
        navigate("/site-locations");
      }, 2000);
    } catch (error) {
      apiNotification({ message: "Something went wrong.", warn: true });
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">
                    Manage Site Location
                  </label>
                  <span className="d-block py-2">Update Site Location</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Site Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="installer" className="form-label">
                      Assign Installer
                    </label>
                    <Multiselect
                      options={Installers} // Options to display in the dropdown
                      selectedValues={item} // Preselected value to persist in dropdown
                      onSelect={handleSelected} // Function will trigger on select event
                      onRemove={handleSelected} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      placeholder="Select Multiple  Installers"
                      required
                    />
                  </div>
                  {userDetails.role === "admin" ? (
                    ""
                  ) : (
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Assign Admin
                      </label>
                      {/* <select
                        className="form-select"
                        name="admin"
                        onChange={onInputChange}
                        value={admin}
                        required
                      >
                        <option>Select admin</option>
                        {Admins.map(({ _id, name }) => (
                          <option value={_id} key={_id}>
                            {name}
                          </option>
                        ))}
                      </select> */}

                      <Select
                        mode="multiple"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) =>
                          setSiteLocationData({ ...siteLocationData, admin: e })
                        }
                        value={admin}
                        tokenSeparators={[","]}
                        placeholder="Select Admin"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.children
                            ? option?.children.toLowerCase()
                            : ""
                          ).includes(input?.toLowerCase())
                        }
                      >
                        {Admins.map((item) => (
                          <Select.Option value={item._id} key={item._id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div className="col-md-6">
                    <label htmlFor="buildingAddress1" className="form-label">
                      Location Address Line 1
                    </label>
                    <input
                      type="text"
                      name="buildingAddress1"
                      value={buildingAddress1}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingAddress1"
                      placeholder="Enter building address line 1"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="buildingAddress2" className="form-label">
                      Location Address Line 2
                    </label>
                    <input
                      type="text"
                      name="buildingAddress2"
                      value={buildingAddress2}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingAddress2"
                      placeholder="Enter building address line 2"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="buildingPostalCode" className="form-label">
                      Location Postal Code
                    </label>
                    <input
                      type="number"
                      name="buildingPostalCode"
                      value={buildingPostalCode}
                      onChange={onInputChange}
                      className="form-control"
                      id="buildingPostalCode"
                      placeholder="Enter building postal code"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="locationCity" className="form-label">
                      Location City
                    </label>
                    <input
                      type="text"
                      name="locationCity"
                      value={locationCity}
                      onChange={onInputChange}
                      className="form-control"
                      id="locationCity"
                      placeholder="Enter Location City"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="locationState" className="form-label">
                      Location State
                    </label>
                    <input
                      type="text"
                      name="locationState"
                      value={locationState}
                      onChange={onInputChange}
                      className="form-control"
                      id="locationState"
                      placeholder="Enter location state"
                    />
                  </div>
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                  <Link to="/site-locations" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
