import { Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InstallationDetails } from "../Profile/InstallationDetails";
import { QrCodeGenerator } from "./QrCodeGenerator";

export default function SiteUserView() {
  const Params = useParams();
  const navigate = useNavigate();
  const userId = Params.userId;
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user/` + userId, {
        withCredentials: true,
        params: { plan: true },
      });
      if (response) {
        console.log(response.data);
        setUser(response.data);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Customers</label>
                  <span className="d-block py-2">Customers Details</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="row mb-2">
                    <div className="col-4">Full Name</div>
                    <div className="col-8">: {user?.name}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Email Address</div>
                    <div className="col-8">: {user?.email}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Customer ID</div>
                    <div className="col-8">: {user?.customerId}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Phone Number</div>
                    {/* <div className="col-8">: +6{user?.phone}</div> */}
                    <div className="col-8">
                      : {user?.phone ? `+6${user?.phone}` : "--"}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Assigned Site </div>
                    <div className="col-8">: {user?.site?.[0]?.name}</div>
                  </div>
                  <div className="mb-2">
                    <div>
                      Home Address
                      <div className="row">
                        <div className="col-1"></div>
                        <div className="col-3">Unit </div>
                        <div className="col-8">: {user?.unit || "--"}</div>
                        <div className="col-1"></div>
                        <div className="col-3">Address line 1 </div>
                        <div className="col-8">: {user?.address1 || "--"}</div>
                        <div className="col-1"></div>
                        <div className="col-3">Address line 2 </div>
                        <div className="col-8">: {user?.address2 || "--"}</div>
                        <div className="col-1"></div>
                        <div className="col-3">Post code </div>
                        <div className="col-8">: {user?.postCode || "--"}</div>
                        <div className="col-1"></div>
                        <div className="col-3">City </div>
                        <div className="col-8">: {user?.city || "--"}</div>
                        <div className="col-1"></div>
                        <div className="col-3">State </div>
                        <div className="col-8">
                          : {user?.locationState || "--"}
                        </div>
                        <div className="col-1"></div>
                        <div className="col-3">Country </div>
                        <div className="col-8">: Malaysia</div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-4">Subscription Plan</div>
                    <div className="col-8">
                      : {user?.plan?.plan?.name || "--"}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Account Member Since</div>
                    <div className="col-8">
                      :{" "}
                      {user?.createdAt
                        ? moment(user.createdAt).format("MMM, YYYY")
                        : "--"}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Offline Email Status</div>
                    <div className="col-8">
                      : {user?.isDisableOfflineNotification ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row mb-5">
                    <div className="col-4">Profile Photo</div>
                    <div className="col-8">
                      {user?.avatar && (
                        <img
                          src={user?.avatar}
                          alt="logo"
                          className="rounded"
                          style={{ height: "150px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">QR Code</div>
                    <div className="col-8">
                      <QrCodeGenerator
                        customer={user}
                        showQrCode
                        hideDownloadIcon={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <InstallationDetails
                  installmentType={user?.plan?.depositType}
                  hasInstallment={user?.plan?.depositType === "Yes"}
                  installmentPeriod={user?.plan?.depositInstallmentPeriod || ""}
                  installmentCount={user?.plan?.depositInstallmentCount}
                  totalInstallment={12}
                  installmentAmount={25}
                  upfrontDetails={user?.depositUpfront}
                />
                <InstallationDetails
                  installmentType={user?.plan?.internalWiringType}
                  hasInstallment={user?.plan?.internalWiringType === "Yes"}
                  installmentPeriod={
                    user?.plan?.internalInstallmentPeriod || ""
                  }
                  installmentCount={user?.plan?.internalInstallmentCount}
                  totalInstallment={48}
                  installmentAmount={17}
                  upfrontDetails={user?.internalWiringUpfront}
                />
              </div>

              <div className="row">
                <div className="col-md-12 d-flex justify-content-end">
                  <Link
                    to={`/edit-customer/` + userId}
                    className="btn btn-success me-1"
                  >
                    Edit
                  </Link>
                  {/* <Link to={`/customer`} className="btn btn-secondary">Cancel</Link> */}
                  <button
                    className="btn btn-secondary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
