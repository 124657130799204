import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { numberFormater } from "../../../utils";

export const OtherChargesPdfForElectricityReport = ({ report }) => {
  const styles = StyleSheet.create({
    table: {
      border: "1px solid black",
      marginTop: "10px",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableTitle: {
      fontSize: "12px",
      width: "100%",
      textAlign: "center",
      padding: "7px",
      backgroundColor: "#FBE4D5",
      fontWeight: "bold",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
  });
  return (
    <View style={styles.table}>
      <Text style={styles.tableTitle}>Other Charges</Text>
      {otherCharges.map(({ label, feildName }, idx) => (
        <View style={styles.tableRow} key={idx}>
          <Text style={styles.rightCellInRow}>{label}</Text>
          <Text style={styles.lefttCellInRow}>
            {idx === otherCharges.length - 1 && Number(report[feildName])
              ? "- "
              : ""}
            {numberFormater(report[feildName])}
          </Text>
        </View>
      ))}
      {/* <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>Deposit Installment (RM)</Text>
        <Text style={styles.lefttCellInRow}>
          {report.depositInstallmentFee}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>
          Internal Wiring Installment (RM)
        </Text>
        <Text style={styles.lefttCellInRow}>
          {report.internalWiringInstallment}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>Monthly Maintenance Fees (RM)</Text>
        <Text style={styles.lefttCellInRow}>
          {report.monthlyMaintenanceFee}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>KWTBB (RM)</Text>
        <Text style={styles.lefttCellInRow}>{report.kwtbbRate}</Text>
      </View> */}
    </View>
  );
};

const otherCharges = [
  { label: "Deposit Installment (RM)", feildName: "depositInstallmentFee" },
  {
    label: "Internal Wiring Installment (RM)",
    feildName: "internalWiringInstallment",
  },
  {
    label: "Monthly Maintenance Fees (RM)",
    feildName: "monthlyMaintenanceFee",
  },
  { label: "KWTBB (RM)", feildName: "kwtbbRate" },
  {
    label: "Late Registration Compound (RM)",
    feildName: "lateRegistrationCompound",
  },
  {
    label: "Reconnection Fee Charges (RM)",
    feildName: "reconnectionFeeCharges",
  },
  { label: "Admin Fees (RM)", feildName: "adminFees" },
  { label: "Repair Fees (RM)", feildName: "repairServices" },
  { label: "Deposit Refund (RM)", feildName: "customerDepositRefund" },
];
