import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FiTrash, FiEye, FiEdit, FiHome } from "react-icons/fi";
import { Spin } from "antd";
import { DataTable } from "../../components";
import { userRole } from "../../constant";
import { Button, Form } from "react-bootstrap";
import { showAlert } from "../../utils";

export const ActivityLog = () => {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");

  const getAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user/activity-log`, {
        withCredentials: true,
        params: {
          limit,
          page,
          // site: searchByLocation,
          // searchText: searchTerm,
          // role: userRole.admin,
        },
      });
      setTotal(response.data.count);
      setAdmins(response.data.results);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "VSSSB - Activity Log";
    getAdmins();
    getSiteLocation();
  }, []);

  useEffect(() => {
    getAdmins();
  }, [searchTerm, limit, page]);

  const columns = [
    {
      title: "No.",
      key: "no",
      render: (_, __, index) => index + 1 + limit * (page - 1),
      width: "60px",
    },
    {
      title: "Authorised By",
      dataIndex: "activityOwner",
      key: "activityOwner",
      render: (activityOwner) => {
        console.log(activityOwner);
        return `${activityOwner.name}${
          activityOwner.role === "user" ? ` (${activityOwner.customerId})` : ""
        }`;
      },
    },
    {
      title: "User Type",
      dataIndex: "activityOwner",
      key: "activityOwner1",
      render: (activityOwner) => (
        <p className="text-capitalize mb-0">
          {activityOwner.role === userRole.user
            ? "Customer"
            : activityOwner.role === userRole.superAdmin
            ? "Super admin"
            : activityOwner.role === userRole.businessAdmin
            ? "Business admin"
            : activityOwner.role === userRole.admin
            ? "Site admin"
            : activityOwner.role}
        </p>
      ),
    },
    {
      title: "Activity Message",
      dataIndex: "activityMsg",
      key: "activityMsg",
    },
    {
      title: "Time Stamp",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD-MM-YYYY, h:mm:ss a"),
      center: true,
    },
  ];

  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Activity log</label>
                <span className="d-block py-2">All Activity Log</span>
              </div>
            </div>
            {/* <div className="row my-3">
              <div className="col-md-3">
                <select className="form-select" onChange={handleLocation}>
                  <option label="All Locations"></option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-2">
                <Button onClick={getAdmins} variant="success">
                  Search
                </Button>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-3">
                <Form className="d-flex">
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => {
                      setPage(1);
                      setSearchTerm(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div> */}
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={admins}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};
