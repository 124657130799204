export const userRole = {
  superAdmin: "superAdmin",
  installer: "installer",
  businessAdmin: "businessAdmin",
  admin: "admin",
  user: "user",
  customer: "customer",
};

export const meterStatus = {
  online: "Online",
  offline: "Offline",
  deactivated: "Deactivated",
  // activated: "Activated",
};
