import { Checkbox, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { VsssbSelect } from "../../components/Common/VsssbSelect";
import { apiNotification } from "../../utils";

const EditDevice = () => {
  const Params = useParams();
  const deviceId = Params.deviceId;
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [haveSamePortAndID, setSamePortAndID] = useState(false);
  const [deviceData, setDeviceData] = useState({
    name: "",
    deviceType: "",
    serial: "",
    apiKey: "",
    site: "",
    customer: "",
    tcpPort: "",
    onHexCode: "",
    offHexCode: "",
    meterId: "",
    kwhHexCode: "",
    checkHexCode: "",
    pullInterval: "",
    deviceType: "",
  });
  const {
    name,
    customer,
    apiKey,
    site,
    tcpPort,
    onHexCode,
    offHexCode,
    kwhHexCode,
    meterId,
    checkHexCode,
    pullInterval,
    deviceType,
    isDisableOfflineNotification,
  } = deviceData;
  const userDetails = useSelector((state) => state.user.userDetails);

  const resetMeterData = async (e) => {
    setIsResetLoading(true);
    try {
      const res = await axios.post(`/device/reset-device-data`, {
        device: deviceId,
      });
      apiNotification({ message: res.data.message });
    } catch (error) {
      apiNotification({ message: "Something went wrong", warn: true });
    }
    setIsResetLoading(false);
  };

  const onInputChange = (e) => {
    if (e.target.name === "site") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setDeviceData({ ...deviceData, site: selectedOptions, customer: "" });
    } else if (e.target.name === "deviceType") {
      setDeviceData({
        ...deviceData,
        [e.target.name]: e.target.value,
        customer: "",
        tcpPort: "",
        meterId: "",
        apiKey: "",
        onHexCode: "",
        offHexCode: "",
        checkHexCode: "",
        kwhHexCode: "",
      });
    } else {
      setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
    }
  };
  const submitHandler = async (e) => {
    if (!deviceData?.site) {
      apiNotification({
        message: "Please select Site.",
        warn: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(
        `/device/` + deviceId,
        { ...deviceData, tcpPort: tcpPort.trim() },
        {
          withCredentials: true,
        }
      );
      apiNotification({
        message: "Device Updated Successfully",
      });
      setTimeout(() => {
        navigate("/meters");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };
  //htmlFor site location dropdown
  const [siteLocations, setSiteLocations] = useState([]);
  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(response.data);
      }
    } catch (error) {}
  };

  const getUser = async () => {
    if (!site) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(
        `user/siteUser/${site}/${deviceType === "admin" ? "admin" : "user"}`,
        {
          withCredentials: true,
          params: {
            dontHaveDevice: true,
            deviceId,
          },
        }
      );
      if (response) {
        // console.log(response)
        setUsers(Array.isArray(response?.data) ? response?.data : []);
      }
    } catch (error) {
      setUsers([]);
    }
  };
  //   console.log(deviceData, users);
  const getDevice = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/device/` + deviceId, {
        withCredentials: true,
      });
      if (response) {
        const data = response.data.result;
        setDeviceData({
          _id: data?._id,
          name: data?.name,
          deviceType: data?.deviceType,
          apiKey: data?.apiKey,
          tcpPort: data?.tcpPort,
          onHexCode: data?.onHexCode,
          offHexCode: data?.offHexCode,
          checkHexCode: data?.checkHexCode,
          kwhHexCode: data?.kwhHexCode,
          pullInterval: data?.pullInterval,
          meterId: data?.meterId,
          isDisableOfflineNotification: data?.isDisableOfflineNotification,
          site: data?.site?._id,
          customer: data?.customer?._id,
        });
      }
    } catch (error) {}
    setIsLoading(false);
  };

  const checkPortAndID = async () => {
    try {
      const response = await axios.get(`/device/check-port-and-meterId`, {
        params: { tcpPort: tcpPort.trim(), meterId, deviceId },
      });
      setSamePortAndID(response?.data?.isHave);
    } catch (error) {}
  };
  useEffect(() => {
    getUser();
  }, [site, deviceType]);
  useEffect(() => {
    checkPortAndID();
  }, [tcpPort, meterId]);

  useEffect(() => {
    getDevice();
    getSiteLocations();
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="devices">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mb-4">Edit Meter</h6>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="name" className="form-label">
                    Meter Name <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={onInputChange}
                    className="form-control"
                    id="name"
                    placeholder="Enter Meter Name"
                    required
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="site" className="form-label">
                    Site Location <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    className="form-select"
                    id="site"
                    name="site"
                    value={site}
                    onChange={onInputChange}
                    aria-label="Select site"
                  >
                    <option label="Select Site" disabled></option>
                    {siteLocations &&
                      siteLocations.length > 0 &&
                      siteLocations.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="deviceType" className="form-label">
                    Meter Type <sup style={{ color: "red" }}>*</sup>
                  </label>
                  <select
                    className="form-select"
                    id="deviceType"
                    name="deviceType"
                    value={deviceType}
                    onChange={onInputChange}
                  >
                    <option value={"customer"}>Customer Meter</option>
                    <option value={"admin"}>Admin Meter</option>
                  </select>
                </div>
              </div>
              {deviceType === "admin" ? (
                ""
              ) : (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <VsssbSelect
                      label={
                        <>
                          {deviceType === "admin" ? "Admin" : "Customer"}{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </>
                      }
                      placeholder="None"
                      value={customer}
                      onChange={(value) => {
                        setDeviceData({ ...deviceData, customer: value });
                      }}
                      options={users}
                      isEnablePlaceholder={false}
                    />
                  </div>
                </div>
              )}
              {deviceType === "admin" && (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="apiKey" className="form-label">
                      Meter API Key <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      type="text"
                      name="apiKey"
                      value={apiKey}
                      onChange={onInputChange}
                      className="form-control"
                      id="apiKey"
                      placeholder="Enter Device API Key"
                      required
                    />
                    <div id="emailHelp" className="form-text">
                      API Key is mandatory to get device data from the gateway
                    </div>
                  </div>
                </div>
              )}
              {userDetails?.role !== "user" && deviceType !== "admin" && (
                <>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        TCP Port <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        name="tcpPort"
                        value={tcpPort}
                        onChange={onInputChange}
                        className="form-control"
                        placeholder="Enter TCP Port"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        Meter ID <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        name="meterId"
                        value={meterId}
                        onChange={onInputChange}
                        className="form-control"
                        placeholder="Enter Meter ID"
                        required
                      />
                      <div
                        id="emailHelp"
                        className="form-text"
                        style={{
                          visibility: haveSamePortAndID ? "visible" : "hidden",
                          color: "red",
                        }}
                      >
                        Meter ID is taken.
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        HEX Code (On) <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        className="form-select"
                        name="onHexCode"
                        value={onHexCode}
                        onChange={onInputChange}
                      >
                        <option
                          label="Select HEX Code For On"
                          value=""
                        ></option>
                        <option value="05 00 00 FF 00">05 00 00 FF 00</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        HEX Code(Off) <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        className="form-select"
                        name="offHexCode"
                        value={offHexCode}
                        onChange={onInputChange}
                      >
                        <option
                          label="Select HEX Code For Off"
                          value=""
                        ></option>
                        <option value="05 00 00 00 00">05 00 00 00 00</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        HEX Code(Check) <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        className="form-select"
                        name="checkHexCode"
                        value={checkHexCode}
                        onChange={onInputChange}
                      >
                        <option
                          label="Select HEX Code For Status Check"
                          value=""
                        ></option>
                        <option value="01 00 00 00 01">01 00 00 00 01</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">
                        HEX Code(kwh) <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <select
                        className="form-select"
                        name="kwhHexCode"
                        value={kwhHexCode}
                        onChange={onInputChange}
                      >
                        <option
                          label="Select HEX Code For KWH"
                          value=""
                        ></option>
                        <option value="04 01 56 00 02">04 01 56 00 02</option>
                      </select>
                    </div>
                  </div>
                  <div className="row mb-5 mt-3">
                    <div className="col-md-6">
                      <div>
                        <div className="input-group">
                          <Checkbox
                            style={{ fontSize: "1rem", fontWeight: "400" }}
                            s
                            checked={isDisableOfflineNotification}
                            onChange={(e) =>
                              setDeviceData({
                                ...deviceData,
                                isDisableOfflineNotification: e.target.checked,
                              })
                            }
                          >
                            Disable Offline Meter Email Notifications
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex justify-content2-end align-items-center">
                        <label className="form-label">
                          Interval <sup style={{ color: "red" }}>*</sup> :
                        </label>
                        <input
                          type="number"
                          name="pullInterval"
                          value={pullInterval}
                          style={{ maxWidth: "100px", marginLeft: "10px" }}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter Interval"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {deviceData?.deviceType === "admin" ? (
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div>
                      <div className="input-group">
                        <Checkbox
                          style={{ fontSize: "1rem", fontWeight: "400" }}
                          s
                          checked={isDisableOfflineNotification}
                          onChange={(e) =>
                            setDeviceData({
                              ...deviceData,
                              isDisableOfflineNotification: e.target.checked,
                            })
                          }
                        >
                          Disable Offline Meter Email Notifications
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              ) : (
                ""
              )}
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-danger me-2"
                  onClick={resetMeterData}
                >
                  Reset Meter
                </button>
                <button
                  type="submit"
                  className="btn btn-success me-2"
                  disabled={
                    (deviceType === "admin" && !apiKey) ||
                    (deviceType === "customer" &&
                      (haveSamePortAndID ||
                        !tcpPort ||
                        !meterId ||
                        !onHexCode ||
                        !offHexCode ||
                        !checkHexCode ||
                        !kwhHexCode ||
                        // !customer ||
                        !pullInterval)) ||
                    !name ||
                    !site?.length
                  }
                  onClick={submitHandler}
                >
                  Update Meter
                </button>

                <Link
                  className="btn btn-secondary"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EditDevice;

// Check - 01 00 00 00 01
// On - 05 00 00 FF 00
// Off - 05 00 00 00 00
// KWH - 04 01 56 00 02
