import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FiTrash, FiEye, FiEdit, FiUserX, FiUserCheck } from "react-icons/fi";
import { Spin } from "antd";
import { DataTable } from "../../components";
import { userRole } from "../../constant";
import { Button, Form } from "react-bootstrap";
import { showAlert } from "../../utils";

export default function BusinessAdmins() {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");
  const [searchBySatus, setSearchBySatus] = useState("active");

  const getAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user`, {
        withCredentials: true,
        params: {
          limit,
          page,
          // site: searchByLocation,
          searchText: searchTerm,
          role: userRole.businessAdmin,
          status: searchBySatus,
        },
      });
      setTotal(response.data.count);
      setAdmins(response.data.results);
    } catch (error) {}
    setIsLoading(false);
  };

  // const getSiteLocation = async () => {
  //   try {
  //     const response = await axios.get(`/site-location/my-site`, {
  //       withCredentials: true,
  //     });
  //     if (response) {
  //       setSiteLocation(response.data);
  //     }
  //   } catch (error) {}
  // };

  useEffect(() => {
    document.title = "VSSSB - All Business Admins";
    getAdmins();
    // getSiteLocation();
  }, []);

  useEffect(() => {
    getAdmins();
  }, [searchTerm, limit, page]);

  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };

  const activeDeactiveUser = async (userId, isActive) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: `Do you wish to ${
        !isActive ? "activate" : "deactivate"
      } this business admin?`,
      // text: "Do you wish to deactivate this customer?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/user/` + userId, data, { withCredentials: true })
          .then((res) => {
            getAdmins();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const columns = [
    {
      title: "No.",
      key: "no",
      render: (_, __, index) => index + 1 + limit * (page - 1),
      width: "60px",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => <>+6{phone}</>,
      center: true,
    },
    // {
    //   title: "Location",
    //   dataIndex: "site",
    //   key: "site",
    //   render: (site) => (
    //     <div>
    //       {site.map((item) => (
    //         <div>{item?.name}</div>
    //       ))}
    //     </div>
    //   ),
    //   center: true,
    // },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      title: "Status",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive) => (
        <div>
          {isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "Action",
      render: (_, row) => (
        <div>
          <Link to={`/business-admin/` + row._id} className="btn btn-success me-1">
            <FiEye title="View Profile" />
          </Link>
          <Link
            to={`/edit-business-admin/` + row._id}
            className="btn btn-az-primary me-1"
          >
            <FiEdit title="Edit Profile" />
          </Link>
          {(() => {
            switch (row.isActive) {
              case true:
                return (
                  <button
                    className="btn btn-danger me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <FiUserX title="Inactive Account" />
                  </button>
                );
              case false:
                return (
                  <button
                    className="btn btn-secondary  me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <FiUserCheck title="Active Account" />
                  </button>
                );
              default:
                return;
            }
          })()}
          {/* <button
            className="btn btn-danger"
            onClick={() => deleteUser(row._id)}
          >
            <FiTrash title="Delete" />
          </button> */}
        </div>
      ),
      align: "center",
    },
  ];
  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this business admin?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/` + userId, { withCredentials: true })
          .then((res) => {
            getAdmins();
            // text: "Site Admin Successfully Deleted",
            showAlert(res.data);
          })
          .catch((err) => {
            showAlert(err.response?.data);
          });
      }
    });
  };
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Business Admins</label>
                <span className="d-block py-2">All Business Admins</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0">
                <Link to="/add-business-admin" className="btn btn-az-primary btn-md">
                  Add Business Admin
                </Link>
              </div>
            </div>
            <div className="row my-3">
              {/* <div className="col-md-3">
                <select className="form-select" onChange={handleLocation}>
                  <option label="All Locations"></option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div> */}
              <div className="col-md-3">
                <select
                  className="form-select"
                  onChange={(e) => setSearchBySatus(e.target.value)}
                  value={searchBySatus}
                >
                  <option label="All Status" value={""}></option>
                  <option label="Active" value={"active"}></option>
                  <option label="Inactive" value={"inactive"}></option>
                </select>
              </div>
              <div className="col-md-2">
                <Button onClick={getAdmins} variant="success">
                  Search
                </Button>
              </div>
              <div className="col-md-4"></div>
              <div className="col-md-3">
                <Form className="d-flex">
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => {
                      setPage(1);
                      setSearchTerm(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={admins}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}
