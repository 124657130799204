import React, { useEffect, useState } from "react";
import axios from "axios";
import { HomePageBarChart } from "./HomePageBarChart";
import moment from "moment";
import { FullScreen } from "react-full-screen";
import { Select, DatePicker, Spin } from "antd";
import { SiteDashboardStatistics } from "./SiteDashboardStatistics";
import { api } from "../../api";
import dayjs from "dayjs";
import { VsssbSelect } from "../../components/Common/VsssbSelect";
import AreaChart from "../../components/Charts/AreaChart";

const SiteDashboard = ({ handleFullScreen }) => {
  const [siteLocation, setSiteLocation] = useState([]);
  const [filter, setFilter] = useState({
    type: "month",
    date: new Date().getDate(),
    month: moment().format("MM"),
    year: new Date().getFullYear(),
  });
  const [params, setParams] = useState({
    site: "",
  });
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [chartData, setChartData] = useState([]);
  const [isDailyLoading, setDailyLoading] = useState(false);
  const [isCounterLoading, setCounterLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const [statisticsData, setStatisticsData] = useState({
    energyUsage: 0,
    energyCost: 0,
    totalReload: 0,
    maintenanceFee: 0,
    internalWiringFee: 0,
    depositFee: 0,
    kwtbbFee: 0,
    totalKwh: 0,
    totalPrice: 0,
    totalMeter: 0,
    totalCustomer: 0,
  });

  const getUserStatistics = async (from, to) => {
    if (!params.site || !from || !to) return;
    setCounterLoading(true);
    try {
      const { results } = await api.get(`/report/get-site-report/all`, {
        params: {
          site: params.site || "",
          from,
          to,
        },
      });
      setStatisticsData({ ...results });
    } catch (error) {}
    setCounterLoading(false);
  };

  const getDailyChartData = async () => {
    if (!params?.site || !from || !to) return;
    setDailyLoading(true);
    try {
      const response = await axios.get(
        `/dashboard/chart/kwh-consumption-daily-site-dashboard`,
        {
          params: { site: params.site, from, to },
          withCredentials: true,
        }
      );
      const results = response.data?.results || [];
      const results1 = [];
      results.map((item) => {
        results1.push({ ...item, y: Number(item?.y?.toFixed(2) || 0) });
      });
      setChartData(results1);
    } catch (error) {}
    setDailyLoading(false);
  };
  const getAllChartData = () => {
    getUserStatistics(from, to);
    getDailyChartData();
  };
  useEffect(() => {
    getAllChartData();
    const interval = setInterval(() => {
      getAllChartData();
    }, 600000);
    return () => clearInterval(interval);
  }, [from, to, params]);

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
        let site = response.data?.[0]?._id || "";
        setParams({ site });
      }
    } catch (error) {}
  };

  const setDateRange = ({ type, mObj }) => {
    if (type === "date") {
      setFrom(mObj.startOf("day").format("YYYY-MM-DD"));
      setTo(mObj.endOf("day").format("YYYY-MM-DD"));
    } else if (type === "week") {
      setFrom(mObj.startOf("week").format("YYYY-MM-DD"));
      setTo(mObj.endOf("week").format("YYYY-MM-DD"));
    } else if (type === "month") {
      setFrom(mObj.startOf("month").format("YYYY-MM-DD"));
      setTo(mObj.endOf("month").format("YYYY-MM-DD"));
    }
  };
  // console.log({ from, to });

  const handleLocationChange = (siteId) => {
    setParams({ ...params, site: siteId });
  };
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  useEffect(() => {
    getSiteLocation();
    let mObj = dayjs();
    setDateRange({ type: "month", mObj });
  }, []);

  return (
    <div>
      <Spin spinning={isDailyLoading || isCounterLoading}>
        <div className="az-content-body">
          <div className="row mb-5">
            <div className="col-md-4 mt-3">
              <VsssbSelect
                label="Site Locations"
                placeholder="Select Site Locations"
                value={params.site}
                onChange={handleLocationChange}
                options={siteLocation}
              />
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4 d-inline-flex justify-content-end align-items-end mt-3 md-mt-0">
              <button
                onClick={getAllChartData}
                to="#"
                className="btn btn-success ms-2 mb-2"
              >
                Refresh
              </button>
            </div>
          </div>
          <FullScreen handle={handleFullScreen}>
            <div>
              <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                  <div className="card card-dashboard-six graphShadow">
                    <div className="d-md-flex align-items-center justify-content-between flex-row-reverse mb-4">
                      <form name="filterForm" className="d-flex mb-3 mb-md-0">
                        <Select
                          style={{ width: 110 }}
                          value={filter?.type}
                          onChange={(e) => {
                            setFilter({ ...filter, type: e });
                            setSelectedDate(null);
                            setFrom("");
                            setTo("");
                            // setDateRange({ type: e, mObj: selectedDate });
                          }}
                          size="large"
                          options={[
                            {
                              name: "Daily",
                              label: "Daily",
                              value: "date",
                            },
                            {
                              name: "Weekly",
                              label: "Weekly",
                              value: "week",
                            },
                            {
                              name: "Monthly",
                              label: "Monthly",
                              value: "month",
                            },
                          ]}
                        />
                        <DatePicker
                          style={{ width: "150px" }}
                          className="form-control text-center ms-2"
                          picker={filter?.type}
                          value={selectedDate}
                          onChange={(m) => {
                            setSelectedDate(m);
                            setDateRange({ mObj: m, type: filter?.type });
                          }}
                          disabledDate={disabledDate}
                        />
                      </form>
                      <h6 className="fw-bold text-uppercase">
                        ENERGY USAGE OVERVIEW
                      </h6>
                    </div>
                    <div className="row g-4  mb-4">
                      <div className="col-xxl-2 col-lg-3 col-md-6">
                        <div className="text-uppercase az-content-label  fw-bold">
                          Total (kwh)
                        </div>
                        <div className="fs-3 fw-bold">
                          {/* totalKwh */}
                          {Number(
                            statisticsData.energyUsage || 0
                          ).toLocaleString("en", options)}
                        </div>
                      </div>
                      <div className="col-xxl-2 col-lg-3 col-md-6">
                        <div className="text-uppercase az-content-label  fw-bold">
                          Current Bill (RM)
                        </div>
                        <div className="fs-3 fw-bold">
                          {/* totalPrice */}
                          {Number(
                            statisticsData.energyCost || 0
                          ).toLocaleString("en", options)}
                        </div>
                      </div>
                      <div className="col-xxl-2 col-lg-6 col-md-6">
                        <div className="text-uppercase az-content-label  fw-bold">
                          total meter installed
                        </div>
                        <div className="fs-3 fw-bold">
                          {statisticsData.totalMeter || 0}
                        </div>
                      </div>
                      <div className="col-xxl-2 col-lg-6 col-md-6">
                        <div className="text-uppercase az-content-label  fw-bold">
                          total customer
                        </div>
                        <div className="fs-3 fw-bold">
                          {statisticsData.totalCustomer}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <AreaChart
                        data={chartData}
                        from={from}
                        to={to}
                        name="KWH"
                        xAxisName="Time (h)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-sm mg-b-15 mg-sm-b-20 align-items-stretch">
                <HomePageBarChart params={params} />
                <SiteDashboardStatistics
                  options={options}
                  statisticsData={statisticsData}
                />
              </div>
            </div>
          </FullScreen>
        </div>
      </Spin>
    </div>
  );
};

export default SiteDashboard;

const disabledDate = (current) => {
  let date = new Date();
  const dateCurrent = Number(moment(date).format("x"));
  return current && current.valueOf() > dateCurrent;
};
