import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { numberFormater } from "../../../utils";

export const UserStatisticsPdfForElectricityReport = ({ report }) => {
  const styles = StyleSheet.create({
    table: {
      border: "1px solid black",
      marginTop: "0px",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableTitle: {
      fontSize: "12px",
      width: "100%",
      textAlign: "center",
      padding: "7px",
      backgroundColor: "#FBE4D5",
      fontWeight: "bold",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
  });
  return (
    <View style={styles.table}>
      <Text style={styles.tableTitle}>Electricity Usage</Text>
      <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>Usage This Month (kWh)</Text>
        <Text style={styles.lefttCellInRow}>
          {numberFormater(report.monthlyKWHUsed)}
        </Text>
      </View>
      <View style={styles.tableRow}>
        <Text style={styles.rightCellInRow}>Total Usage Cost (RM)</Text>
        <Text style={styles.lefttCellInRow}>
          {numberFormater(report.totalUsageCost)}
        </Text>
      </View>
    </View>
  );
};
