import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiTrash, FiEye, FiEdit } from "react-icons/fi";
import moment from "moment";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const AlarmSummary = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/notification`, {
        withCredentials: true,
      });
      if (response) {
        setNotifications(
          response.data.results.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          )
        );
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false);
  };
  useEffect(() => {
    document.title = "IVIS PMS - System Alarm Summary";
    getNotifications();
  }, []);

  const columns = [
    {
      name: "No.",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
      center: true,
    },
    {
      name: "Alarm Name",
      cell: (row) => <div>{row?.name || '--'}</div>,
      selector: (row) => row,
    },
    {
      name: "Meter Name",
      selector: (row) => row?.device?.name || '--',
    },
    {
      name: "Site Name",
      selector: (row) => row?.site?.name || '--',
      omit: userDetails?.role === "user",
    },
    {
      name: "Date Alarm Created",
      selector: (row) => row?.device?.createdAt ? moment(row.createdAt).format("DD/MM/YYYY") : '--',
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link to={`/alarm-view/` + row._id} className="btn btn-success me-1">
            <FiEye title="View" />
          </Link>
          <Link to={`/edit-alarm/` + row._id} className="btn btn-info me-1">
            <FiEdit title="Edit" />
          </Link>
          <button
            className="btn btn-danger"
            onClick={() => deleteAlarm(row._id)}
          >
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
      center: "yes",
    },
  ];

  const deleteAlarm = async (alarmId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Alarm?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/notification/` + alarmId, { withCredentials: true })
          .then((res) => {
            getNotifications();
            Swal.fire({
              title: "Done!",
              text: "Alarm Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="card p-3 mb-3">
            <label className="az-content-label">System Alarm Summary</label>
            <span className="d-block py-2">All Alarms</span>
            <Spin spinning={isLoading}>
              <DataTable
                columns={columns}
                data={notifications}
                persistTableHead={true}
                pagination
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmSummary;
