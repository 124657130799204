import { View, StyleSheet, Text } from "@react-pdf/renderer";

export const TablePdf = ({ rows, tableTitle }) => {
  const styles = StyleSheet.create({
    table: {
      borderBottom: "1px solid black",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      marginTop: "20px",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableRowBold: {
      display: "flex",
      flexDirection: "row",
      fontWeight: "bold",
    },
    tableTitle: {
      fontSize: "12px",
      width: "100%",
      textAlign: "center",
      padding: "7px",
      borderTop: "1px solid black",
      backgroundColor: "#C5E0B3",
      fontWeight: "bold",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    lefttCellInRowCenter: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderTop: "1px solid black",
    },
    lefttCellInRowRight: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
  });
  return (
    <View style={styles.table}>
      {tableTitle && <Text style={styles.tableTitle}>{tableTitle}</Text>}
      {rows.map(({ title, value, algnRight, bold }) => (
        <View style={bold ? styles.tableRowBold : styles.tableRow}>
          <Text style={styles.rightCellInRow}>{title}</Text>
          <Text
            style={
              algnRight
                ? styles.lefttCellInRowRight
                : styles.lefttCellInRowCenter
            }
          >
            {value}
          </Text>
        </View>
      ))}
    </View>
  );
};
