import { Text, StyleSheet, View } from "@react-pdf/renderer";

export const InstallationDetails = ({ rows, title, marginTop }) => {
  const styles = StyleSheet.create({
    singleRowOfAdminInfo: {
      marginBottom: "1px",
      display: "flex",
      flexDirection: "row",
    },
    rightTextAdminInfo: {
      fontSize: "12px",
      width: "48%",
    },
    leftTextAdminInfo: {
      fontSize: "12px",
      width: "50%",
    },
    sectionTitle: {
      fontSize: "12px",
      fontWeight: "bold",
      marginTop: marginTop || 10 + "px",
      marginBottom: "10px",
    },
  });

  return (
    <>
      <Text style={styles.sectionTitle}>{title}</Text>
      {rows.map((item) => (
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>{item.title}</Text>
          <Text style={styles.leftTextAdminInfo}>: {item.value}</Text>
        </View>
      ))}
    </>
  );
};
