import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const InstallerView = () => {
  const Params = useParams();
  const installerId = Params.installerId;
  const [installerDetails, setInstallerDetails] = useState();

  const getInstaller = async () => {
    const response = await axios.get(`/user/` + installerId, {
      withCredentials: true,
    });
    if (response) {
      setInstallerDetails(response.data);
    }
  };
  useEffect(() => {
    getInstaller();
  }, []);
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Installer</label>
                <span className="d-block py-2">Installer Information</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-3">Full Name</div>
                  <div className="col-9">: {installerDetails?.name}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Email Address</div>
                  <div className="col-9">: {installerDetails?.email}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Phone Number</div>
                  <div className="col-9">: +6{installerDetails?.phone}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Company Name</div>
                  <div className="col-9">: {installerDetails?.companyName}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-3">Company Address</div>
                  <div className="col-9">
                    : {installerDetails?.companyAddress}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-3">Logo</div>
                  <div className="col-9">
                    {installerDetails?.logo && (
                      <img
                        src={installerDetails?.logo}
                        alt="logo"
                        style={{ height: "150px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link
                  to={`/edit-installer/` + installerId}
                  className="btn btn-success me-1"
                >
                  Edit
                </Link>
                <Link to={`/installers`} className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerView;
