import moment from "moment/moment";
import { numberFormater } from "../../utils";

export const InstallationDetails = ({
  installmentPeriod,
  hasInstallment,
  totalInstallment,
  installmentCount,
  installmentAmount,
  upfrontDetails,
  installmentType,
  outterCol,
}) => {
  let installmentStart = installmentPeriod
    ? "7/" + installmentPeriod.split("-")[0]?.trim()
    : "";
  let installmentEnd = installmentPeriod
    ? "7/" + installmentPeriod.split("-")[1]?.trim()
    : "";

  return (
    <>
      <div className={`col-md-${outterCol || 6}`}>
        <div className="row mb-2">
          <div className="col-4">
            {installmentAmount === 25 ? "Deposit Type" : "Internal Wiring Type"}
          </div>
          <div className="col-8">
            :{" "}
            {installmentType === "Yes"
              ? "Monthly"
              : installmentType === "No"
              ? "Upfront"
              : installmentType === "N/A"
              ? "N/A"
              : "Unassigned"}
          </div>
        </div>
        {installmentType === "No" ? (
          <>
            <div className="row mb-2">
              <div className="col-4">Amount Paid (RM)</div>
              <div className="col-8">
                : {(upfrontDetails?.reloadedBalance || 0).toFixed(2)}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Date Paid (RM) </div>
              <div className="col-8">
                :{" "}
                {upfrontDetails?.createdAt
                  ? moment(upfrontDetails?.createdAt).format("DD/MM/YYYY")
                  : "--"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Balance (RM) </div>
              <div className="col-8">
                :{" "}
                {upfrontDetails?.createdAt
                  ? (
                      (installmentAmount !== 25 ? 816.0 : 300.0) -
                        upfrontDetails?.reloadedBalance || 0
                    ).toFixed(2)
                  : installmentAmount !== 25
                  ? "816.00"
                  : "300.00"}
              </div>
            </div>
          </>
        ) : installmentType === "Yes" ? (
          <>
            <div className="row mb-2">
              <div className="col-4">Instalment Start Date</div>
              <div className="col-8">
                : {installmentStart ? installmentStart : "N/A"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Instalment End Date</div>
              <div className="col-8">
                : {installmentEnd ? installmentEnd : "N/A"}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-4">Instalment Balance (Months)</div>
              <div className="col-8">
                : {hasInstallment ? totalInstallment - installmentCount : "N/A"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Instalment Total Paid (RM)</div>
              <div className="col-8">
                :{" "}
                {hasInstallment
                  ? numberFormater(installmentCount * installmentAmount)
                  : "N/A"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Instalment Pending (RM)</div>
              <div className="col-8">
                :{" "}
                {hasInstallment
                  ? numberFormater(
                      totalInstallment * installmentAmount -
                        installmentCount * installmentAmount
                    )
                  : "N/A"}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
