import React from "react";
import Chart from "react-apexcharts";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";

const PieChart = ({ data, mode, handlerRemove, handlerUpdate }) => {
  const { userDetails } = useSelector((state) => state.user);
  const options = {
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        type: "pie",
        width: "100%",
        height: 380,
        zoom: {
          enabled: false,
        },
      },

      title: {
        text: "Pie Chart",
        align: "left",
      },

      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };


  return (
    <div className="p-2">
      {userDetails.role === "superAdmin" && (
        <div className="d-flex justify-content-end align-items-center">
          {
            mode === 'view' ? '' : (<>
              <div className="clickbtn" onClick={() => handlerUpdate(data._id)}>
                <FiEdit className="text-secondary me-1" />
              </div>
              <div className="clickbtn" onClick={() => handlerRemove(data._id)}>
                <MdDeleteForever className="fs-5 text-secondary" />
              </div>
            </>)
          }
        </div>
      )}
      <div className="p-2">
        <Chart
          options={options.options}
          series={options.series}
          type="pie"
          height={380}

        />
      </div>
    </div>
  );
};

export default PieChart;
