import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { endpoint } from "../../api";
import AddItemModal from "../LiveDashboard/AddItemModal";
import DashboardLayout from "./DashboardLayout";

const DashboardListView = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [layouts, setLayouts] = useState({})
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState([]);


  const getDashboard = async () => {
    if (!isLoading) {
      setIsLoading(true)
      const response = await axios.get(endpoint.dashboard + '/' + id, { withCredentials: true });
      if (response.status === 200) {
        setData(response.data.result)
        setLayouts(response.data.result.layouts)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    getDashboard()
  }, [id]);

  return (
    <div className="px-3 mt-3">
      <div className="border bg-white p-3">
        {/* <h5>Dashboard List</h5> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">Manage Dashboard</label>
            <span className="d-block py-2">View Dashboard: {data?.name}</span>
          </div>

        </div>
        <hr className="mt-0 mb-5" />
        <div className="pb-5 px-3">
          <div className="bg-layout pb-5">
            {data.data?.length !== 0 ? (
              <DashboardLayout
                allData={data.items}
                layouts={layouts}
                setLayouts={setLayouts}
                mode='view'
              />
            ) : (
              <div>No Data Found</div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-3 gap-2">
            <Link to={`/dashboardEdit/${id}`}>
              <Button variant='success'> Edit </Button>
            </Link>
            <Button onClick={() => navigate(-1)} variant='secondary'> Cancel </Button>
          </div>
        </div>
      </div>


      {/* item added with modal */}
      {/* <AddItemModal
        show={show}
        setShow={setShow}
        allData={allData}
        setAllData={setAllData}
        mode='create'
        // editData={} 
      />*/}



    </div>
  );
};

export default DashboardListView;
