import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import ElectricityTariffTable from './ElectricityTariffTable';

export default function ElectricityTariff() {
    const [SuccessMessage, setSuccessMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [eTariff, setETariff] = useState([]);
    const [electricityTariffData, setElectricityTariffData] = useState({
        name: "",
        description: ""
    });
    const { name, description } = electricityTariffData;

    const handleChange = (value, bodyContent) => {
        setElectricityTariffData({
            ...electricityTariffData,
            [bodyContent]: value
        });
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const response = await axios.post(`/electricity-tariff`, electricityTariffData, { withCredentials: true })
        if (response) {
            setIsLoading(false)
            setElectricityTariffData({ name: "", description: "" })
            getElectricityTariff()
            setSuccessMessage("Electricity Tariff Created Successfully")
            setTimeout(() => {
                setSuccessMessage()
            }, 2000)
        }
        e.target.reset();
    }
    const getElectricityTariff = async () => {
        const response = await axios.get(`/electricity-tariff`, { withCredentials: true })
        if (response) {
            setETariff(response.data.results)
        }
    }
    useEffect(() => {
        getElectricityTariff()
    }, []);
    return (
        <div className="az-content-body">
            <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                    <div className="card card-dashboard-six">
                        <div className="card-header">
                            <div>
                                <label className="az-content-label">Settings</label>
                                <span className="d-block py-2">Electricity Tariff</span>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <h4 className='mb-3'>Add Tariff Category</h4>
                                <div className='d-flex justify-content-center'>
                                    {isLoading && <Spinner animation="border" variant="dark" />}
                                </div>
                                {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Tariff Category</label>
                                        <input type="text" name='name' value={name} onChange={(event) => handleChange(event.target.value, 'name')} className="form-control" id="name" placeholder='Enter a tariff category' required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">Description</label>
                                        <ReactQuill theme="snow" className='mb-5' id="description" name='description' value={description} onChange={(value) => handleChange(value, 'description')} style={{ height: "200px" }} />
                                    </div>
                                    <div className='float-end mt-5'>
                                        <button type="submit" className="btn btn-success me-2">Create</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-8">
                                <ElectricityTariffTable data={eTariff} getElectricityTariff={getElectricityTariff} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
