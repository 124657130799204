import axios from "axios";
import React, { useState, useEffect } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setIsLogged, setUserDetails } from "../../redux/userSlice";

const SignIn = () => {
  const [PasswordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!PasswordShown);
  };
  const [ErrorMessage, setErrorMessage] = useState();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const { email, password } = login;
  const onInputChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SubmitHandler = async (e) => {
    e.preventDefault();
    const response = await axios
      .post(`/user/login`, login, { withCredentials: true })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401 || 500) {
            setErrorMessage("Credentials information wrong!");
            setTimeout(() => {
              setErrorMessage();
            }, 2000);
          }
        }
      });
    const data = response.data;
    if (data) {
      dispatch(setIsLogged(true));
      const userResponse = await axios.get(`/user/me`, {
        withCredentials: true,
      });
      if (userResponse) {
        console.log(userResponse.data);
        dispatch(setUserDetails(userResponse.data));
        navigate("/");
      }
    } else {
    }
  };
  useEffect(() => {
    document.title = "IVIS – Prepaid Monitoring System (PMS)";
  }, []);
  let background = "/img/bg.jpg";
  return (
    <div>
      <div
        className="az-content-wrapper p-0"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div>
          <div className="az-signin-wrapper">
            <div className="az-card-signin">
              <h1 className="az-logo">
                <img
                  src="/img/beta-logo.png"
                  alt="logo"
                  style={{ height: "80px" }}
                />
              </h1>
              <div className="az-signin-header">
                <h2>Welcome Back!</h2>
                <h4>Please sign in to continue</h4>
                {ErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {ErrorMessage}
                  </div>
                )}
                <form onSubmit={SubmitHandler}>
                  <div className="form-group">
                    <label>Email / Phone</label>
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      placeholder="Enter Your Email / Phone Number"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <input
                        type={PasswordShown ? "text" : "password"}
                        value={password}
                        onChange={(e) => onInputChange(e)}
                        name="password"
                        className="form-control"
                        placeholder="Enter your password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {PasswordShown ? (
                          <BsEyeSlash onClick={() => togglePassword()} />
                        ) : (
                          <BsEye onClick={() => togglePassword()} />
                        )}
                      </span>
                    </div>
                    <p className="float-end text-muted">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </p>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-az-primary btn-block"
                  >
                    Sign In
                  </button>
                </form>
              </div>
              <div className="az-signin-footer text-center mt-2">
                <p>Version 2.1.0</p>
                {/* Version 2.0 | {new Date().getFullYear()}  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
