import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import BuildingTypeTable from './BuildingTypeTable';

const BuildingTypes = () => {
    const [SuccessMessage, setSuccessMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [buildingTypes, setBuildingTypes] = useState([]);
    const [buildingTypeData, setBuildingTypeData] = useState({
        name: ""
    });
    const { name } = buildingTypeData;
    const onInputChange = e => {
        setBuildingTypeData({ ...buildingTypeData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const response = await axios.post(`/building-type`, buildingTypeData, { withCredentials: true })
        if (response) {
            setIsLoading(false)
            setBuildingTypeData({ name: "" })
            getBuildingTypes()
            setSuccessMessage("New Building Type created Successfully")
            setTimeout(() => {
                setSuccessMessage()
            }, 2000)
        }
    }
    const getBuildingTypes = async () => {
        const response = await axios.get(`/building-type`, { withCredentials: true })
        if (response) {
            setBuildingTypes(response.data)
        }
    }
    useEffect(() => {
        document.title = "IVIS PMS - Add New Building Type"
        getBuildingTypes()
    }, []);
    return (
        <div className="az-content-body">
            <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                    <div className="card card-dashboard-six">
                        <div className="card-header">
                            <div>
                                <label className="az-content-label">Settings</label>
                                <span className="d-block py-2">Building Background Types</span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <h4 className='mb-2'>Add New Building Type</h4>
                                <div className='d-flex justify-content-center'>
                                    {isLoading && <Spinner animation="border" variant="dark" />}
                                </div>
                                {SuccessMessage && <div className="alert alert-success" role="alert">{SuccessMessage} </div>}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input type="text" name='name' value={name} onChange={onInputChange} className="form-control" id="name" placeholder='Enter a name' required />
                                    </div>
                                    <div className='float-end'>
                                        <button type="submit" className="btn btn-success me-2">Save</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <BuildingTypeTable data={buildingTypes} getBuildingTypes={getBuildingTypes}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuildingTypes;
