import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { FiUserCheck, FiUserX } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { DatePicker, Input, Spin } from "antd";
import { Button } from "react-bootstrap";

const CustomerSubscriptionSummary = () => {
  const [subscriptionSummary, setSubscriptionSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState({});
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchText, setSerachText] = useState("");

  const onChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchValue({
      ...searchValue,
      [name]: value,
    });
  };
  // console.log(searchValue);
  const getSiteLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocation(response.data);
    } catch (error) {}
  };

  const onChangeMonth = (_, dateString) => {
    // console.log(dateString);
    setSearchValue({ ...searchValue, monthYear: dateString });
  };
  const onChangeYear = (_, dateString) => {
    setSearchValue({ ...searchValue, year: dateString });
  };

  const getSubscriptionSummary = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/subscription/assign-plan/`, {
        withCredentials: true,
        params: { ...searchValue, searchText },
      });
      let arr = response.data.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );
      arr = arr.map((item, idx) => {
        item.idx = idx + 1;
        return item;
      });

      setSubscriptionSummary(arr);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "IVIS PMS - Customer Subscription Summary";
    getSubscriptionSummary();
    getSiteLocation();
  }, []);

  useEffect(() => {
    getSubscriptionSummary();
  }, [searchText]);

  const columns = [
    {
      name: "No.",
      cell: (row) => <div>{row.idx}</div>,
      // selector: (row) => console.log(row),
      width: "60px",
      center: true,
    },
    {
      name: "Customer",
      cell: (row) => row?.user?.name || "--",
      // center: true,
    },
    {
      name: "Customer ID",
      cell: (row) => row?.user?.customerId || "--",
      center: true,
    },
    {
      name: "Plan",
      selector: (row) => row?.plan?.name || "--",
      center: true,
    },
    {
      name: "Deposit",
      selector: (row) => {
        console.log(row.depositType);
        return row.depositType
          ? row.depositType === "No"
            ? "Upfront"
            : row.depositType === "Yes"
            ? "Monthly"
            : row.depositType
          : "Unassigned";
      },
      center: true,
    },
    {
      name: "Internal Wiring",
      selector: (row) =>
        row.internalWiringType
          ? row.internalWiringType === "No"
            ? "Upfront"
            : row.internalWiringType === "Yes"
            ? "Monthly"
            : row.internalWiringType
          : "Unassigned",
      center: true,
    },
    {
      name: "Date Assigned",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
      selector: (row) => row.isActive,
      width: "100px",
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link
            to={`/customer/` + row?.user?._id}
            className="btn btn-info me-1"
            title="View Profile"
          >
            <AiOutlineEye />
          </Link>
          {(() => {
            switch (row.isActive) {
              case true:
                return (
                  <button
                    className="btn btn-warning  me-1"
                    onClick={() => activeDeactivePlan(row._id, row.isActive)}
                  >
                    <FiUserX title="Deactivate Subscription" />
                  </button>
                );
              case false:
                return (
                  <button
                    className="btn btn-success  me-1"
                    onClick={() => activeDeactivePlan(row._id, row.isActive)}
                  >
                    <FiUserCheck title="Activate Subscription" />
                  </button>
                );
              default:
                return;
            }
          })()}
          {/* <button
            className="btn btn-danger"
            title="Delete"
            onClick={() => deletePlan(row._id)}
          >
            <FiTrash />
          </button> */}
        </div>
      ),
      center: "yes",
    },
  ];

  const activeDeactivePlan = async (planId, isActive) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: isActive
        ? `This action will deactivate the customer from the current subscription plan.`
        : "This action will activate the customer's previous subscription plan.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/subscription/assign-plan/` + planId, data, {
            withCredentials: true,
          })
          .then((res) => {
            getSubscriptionSummary();
            Swal.fire({
              title: "Done!",
              text: "Assign Plan Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  // const deletePlan = async (subscriptionId) => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to delete this site user?",
  //     //icon: "warning",
  //     dangerMode: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Confirm",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .delete(`/subscription/assign-plan/` + subscriptionId, {
  //           withCredentials: true,
  //         })
  //         .then((res) => {
  //           getSubscriptionSummary();
  //           Swal.fire({
  //             title: "Done!",
  //             text: "Plan Successfully Deleted",
  //             icon: "success",
  //             timer: 2000,
  //             button: false,
  //           });
  //         });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //     }
  //   });
  // };

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div>
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">
                    Customer Subscription Summary
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <select
                    className="form-select"
                    onChange={onChangeSearch}
                    value={searchValue.site}
                    name="site"
                  >
                    <option label="All Location"></option>
                    {siteLocation &&
                      siteLocation.length > 0 &&
                      siteLocation.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-select"
                    onChange={onChangeSearch}
                    value={searchValue.searchType}
                    name="searchType"
                  >
                    <option label="Select Period Type"></option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
                {searchValue.searchType === "monthly" && (
                  <div className="col-md-2">
                    <DatePicker
                      onChange={onChangeMonth}
                      picker="month"
                      format={"MMM, YYYY"}
                      value={
                        searchValue?.monthYear
                          ? dayjs(searchValue.monthYear, "YYYY-MM-DD")
                          : null
                      }
                      allowClear
                      size="large"
                      disabledDate={disabledDate}
                    />
                  </div>
                )}
                {searchValue.searchType === "yearly" && (
                  <div className="col-md-2">
                    <DatePicker
                      onChange={onChangeYear}
                      picker="year"
                      format={"YYYY"}
                      value={
                        searchValue?.year
                          ? dayjs(searchValue.year, "YYYY-MM-DD")
                          : null
                      }
                      allowClear
                      size="large"
                      disabledDate={disabledDateYear}
                    />
                  </div>
                )}
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={() => {
                      getSubscriptionSummary();
                    }}
                    // disabled={disabledSearch}
                  >
                    Search
                  </Button>
                </div>
                {searchValue.searchType !== "yearly" &&
                  searchValue.searchType !== "monthly" && (
                    <div className="col-md-2"></div>
                  )}
                <div className="col-md-2 text-right">
                  <Input
                    placeholder="Search"
                    size="large"
                    name="searchText"
                    onChange={(e) => setSerachText(e.target.value)}
                  />
                </div>
              </div>

              <DataTable
                columns={columns}
                data={subscriptionSummary}
                persistTableHead={true}
                pagination
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CustomerSubscriptionSummary;

const disabledDate = (current) => {
  let date = new Date();
  const currentDate = moment(date).format("MMM, YYYY");
  const monentDate = current.format("MMM, YYYY");
  const dayOfCrntMonth = date.getDate();
  const dayFlag = dayOfCrntMonth < 7 && currentDate === monentDate;
  const dateCurrent = Number(moment(date).format("x"));
  return (current && current.valueOf() > dateCurrent) || dayFlag;
};

const disabledDateYear = (current) => {
  let date = new Date();
  const dateCurrent = Number(moment(date).format("x"));
  return current && current.valueOf() > dateCurrent;
};
