import { Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Checkbox, notification, Spin } from "antd";

export default function AddSiteUser() {
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [siteLocations, setSiteLocations] = useState([]);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    reenterPassword: "",
    phone: "",
    address1: "",
    address2: "",
    country: "Malaysia",
    locationState: "",
    locationC: "",
    postCode: "",
    city: "",
    site: "",
    customerId: "",
    unit: "",
    role: "user",
    isDisableOfflineNotification: false,
  });
  const {
    name,
    email,
    password,
    reenterPassword,
    phone,
    address1,
    address2,
    site,
    country,
    locationState,
    postCode,
    unit,
    city,
    isDisableOfflineNotification,
  } = userData;

  const onInputChange = (e) => {
    if (e.target.name === "site") {
      const selectedSite = siteLocations.find(
        (item) => item._id === e.target.value
      );
      console.log(selectedSite);
      const obj = {
        site: e.target.value,
        address1: selectedSite.buildingAddress1,
        address2: selectedSite.buildingAddress2,
        postCode: selectedSite.buildingPostalCode,
        locationState: selectedSite.locationState,
        city: selectedSite.locationCity,
      };
      setUserData({ ...userData, ...obj });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  console.log(userData);

  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  //Profile Photo
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userData?.site) {
      notification.warning({
        message: "Site Not Assigned!",
        placement: "bottomLeft",
      });
      return;
    }
    if (!userData?.email && !userData?.phone) {
      notification.warning({
        message: "Either email or phone is required.",
        placement: "bottomLeft",
      });
      return;
    }
    if (password === reenterPassword) {
      setIsLoading(true);
      console.log(userData);
      const response = await axios
        .post(`/user`, userData, { withCredentials: true })
        .catch(function (error) {
          console.log(error.response.data.message);
          notification.warning({
            message: error?.response?.data?.message || "Something went wrong.",
            placement: "bottomLeft",
          });
        });
      const data = response?.data;
      if (data) {
        if (selectedImage === null) {
          setIsLoading(false);
          notification.success({
            message: "Customer Created Successfully",
            placement: "bottomLeft",
          });
          setTimeout(() => {
            // navigate("/customer");
            navigate("/assign-prepaid-plan");
          }, 2000);
        } else {
          const addImageResponse = await axios.put(
            `/user/${data._id}/avatarUpload/`,
            selectedImage,
            { withCredentials: true }
          );
          if (addImageResponse) {
            setIsLoading(false);
            notification.success({
              message: "Customer Created Successfully",
              placement: "bottomLeft",
            });
            setTimeout(() => {
              // navigate("/customer");
              navigate("/assign-prepaid-plan");
            }, 2000);
          }
        }
      }
    } else {
      notification.warning({
        message: "Both Passwords Are Not Matching.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  const getSiteLocations = async () => {
    if (userDetails.role === "superAdmin") {
      try {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    } else if (userDetails.role === "admin") {
      try {
        const response = await axios.get(
          `/site-location/admin-sites/` + userDetails._id,
          { withCredentials: true }
        );
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getSiteLocations();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Customer</label>
                  <span className="d-block py-2">Add New Customer</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="site" className="form-label">
                      Assigned Site
                    </label>
                    <select
                      name="site"
                      value={site}
                      className="form-select"
                      onChange={onInputChange}
                    >
                      <option>Select Single Site</option>
                      {siteLocations?.length &&
                        siteLocations.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      // id="email"
                      placeholder="Enter email address"
                      // required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        // required
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type={newPasswordShown ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onInputChange}
                        minLength="6"
                        className="form-control"
                        id="password"
                        placeholder="Enter a new password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {newPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleNewPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleNewPasswordShown()} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="reenterPassword" className="form-label">
                      Reenter Password
                    </label>
                    <div className="input-group">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type={repeatPasswordShown ? "text" : "password"}
                        name="reenterPassword"
                        value={reenterPassword}
                        minLength="6"
                        onChange={onInputChange}
                        className="form-control"
                        id="reenterPassword"
                        placeholder="Reenter password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {repeatPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleRepeatPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleRepeatPasswordShown()} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="address1" className="form-label">
                      Home Address
                    </label>
                    <div className="input-group">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="unit"
                        value={unit}
                        onChange={onInputChange}
                        className="form-control"
                        id="unit"
                        placeholder="Unit"
                        required
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address1"
                        value={address1}
                        onChange={onInputChange}
                        className="form-control"
                        id="address1"
                        placeholder="Address line 1"
                        required
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address2"
                        value={address2}
                        onChange={onInputChange}
                        className="form-control"
                        id="address2"
                        placeholder="Address line 2"
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="postCode"
                        value={postCode}
                        onChange={onInputChange}
                        className="form-control"
                        id="postCode"
                        placeholder="Postcode"
                        required
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="city"
                        value={city}
                        onChange={onInputChange}
                        className="form-control"
                        id="city"
                        placeholder="City"
                        required
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="locationState"
                        value={locationState}
                        onChange={onInputChange}
                        className="form-control"
                        id="locationState"
                        placeholder="State"
                        required
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="country"
                        value={country}
                        onChange={onInputChange}
                        className="form-control"
                        id="country"
                        placeholder="Country"
                        disabled
                      />
                    </div>
                    <div className="input-group mt-3">
                      <Checkbox
                        style={{ fontSize: "1rem", fontWeight: "400" }}
                        s
                        checked={isDisableOfflineNotification}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            isDisableOfflineNotification: e.target.checked,
                          })
                        }
                      >
                        Disable offline meter email notifications
                      </Checkbox>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 mt-3">
                    <label htmlFor="bname" className="form-label">
                      Profile Photo
                    </label>
                    {imageUrl ? (
                      <div mt={2} textAlign="center">
                        <div>Preview:</div>
                        <img src={imageUrl} alt="avatar" height="100px" />
                      </div>
                    ) : (
                      <>
                        <input
                          className="form-control"
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileUpload(e.target.files[0])}
                        />
                        <label htmlFor="select-image">
                          <img
                            src="/img/avatar.png"
                            alt=""
                            height="100px"
                            className="rounded-3 border p-2 ms-2"
                          />
                        </label>
                      </>
                    )}
                  </div>
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Create Customer
                  </button>
                  <Link to="/customer" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
