import React, { useState, useEffect } from "react";
import { FiMaximize } from "react-icons/fi";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  setIsLogged,
  setUserDetails,
  setCurrentDashboard,
} from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import { useFullScreenHandle } from "react-full-screen";
import { userRole } from "../../constant";

const Header = ({ toggleShow, handleFullScreen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails, activeDashboard } = useSelector((state) => state.user);

  // const [dashboardList, setDashboardList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const getDashboardList = async () => {
  //   setIsLoading(true)
  //   try {
  //     const { results } = await api.get(endpoint.dashboard_list);
  //     setDashboardList(results);
  //     if (results.length <= 0) {
  //       dispatch(setCurrentDashboard(''));
  //       setDisplay('');
  //     }
  //   } catch (error) { }
  //   setIsLoading(false)
  // };

  // useEffect(() => {
  //   getDashboardList()
  // }, [])
  // useEffect(() => {
  //   if (location.pathname === '/') {
  //     getDashboardList()
  //   }
  // }, [location.pathname])

  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/user/logout`, {
            withCredentials: true,
          })
          .then((res) => {
            dispatch(setIsLogged(false));
            dispatch(setUserDetails({}));
            window.localStorage.clear();
            navigate("/");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const handleHeaderChange = (e) => {
    dispatch(setCurrentDashboard(e.target.value));
  };

  useEffect(() => {
    const logginUserRole = userDetails?.role;
    if (
      !activeDashboard &&
      (logginUserRole === userRole.superAdmin ||
        logginUserRole === userRole.businessAdmin ||
        logginUserRole === userRole.admin ||
        logginUserRole === userRole.installer)
    ) {
      dispatch(setCurrentDashboard("site_location_dashboard"));
    } else if (!activeDashboard && logginUserRole === userRole.user) {
      dispatch(setCurrentDashboard("customer_dashboard"));
    }
  }, [activeDashboard, userDetails]);

  return (
    <Spin spinning={isLoading}>
      <div className="az-header">
        <div className="container-fluid">
          <div className="az-header-left d-block d-md-none">
            <button
              id="azSidebarToggle"
              className="bg-transparent border-0 az-header-menu-icon"
              onClick={() => toggleShow()}
              style={{ minWidth: "28px" }}
            >
              <span></span>
            </button>
          </div>
          <div className="mx-1">
            {location.pathname === "/" && (
              <select
                className="form-select"
                value={activeDashboard}
                onChange={handleHeaderChange}
                style={{ maxWidth: "250px" }}
              >
                {(userDetails?.role === userRole.superAdmin ||
                  userDetails?.role === userRole.businessAdmin ||
                  userDetails?.role === userRole.admin ||
                  userDetails?.role === userRole.installer) && (
                  <>
                    <option value="site_location_dashboard">
                      Site Location Dashboard
                    </option>
                    <option value="site_meter_dashboard">
                      Site Meter Dashboard
                    </option>
                  </>
                )}
                <option value="customer_dashboard">Customer Dashboard</option>
                {/* {dashboardList.length > 0 &&
                  dashboardList.map((dashboard) => (
                    <option key={dashboard._id} value={dashboard._id}>
                      {dashboard.name}
                    </option>
                  ))} */}
              </select>
            )}
          </div>

          <div className="az-header-right">
            {location.pathname === "/" && (
              <div>
                <FiMaximize
                  className="tx-16"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleFullScreen.enter();
                  }}
                />
              </div>
            )}
            <DropdownButton
              key={"down"}
              id={`dropdown-button-drop-down`}
              className="d-flex"
              drop={"down"}
              variant="none"
              title={
                <div className="az-img-user online">
                  <img src={userDetails?.avatar} alt="user" />
                </div>
              }
            >
              <div className="az-header-profile">
                <div className="az-img-user">
                  <img src={userDetails?.avatar} alt="" />
                </div>
                <h6>{userDetails?.name}</h6>
                <span className="text-capitalize">
                  {userDetails?.role === "superAdmin"
                    ? "Super Admin"
                    : userDetails?.role === userRole.user
                    ? `Customer (${userDetails?.customerId})`
                    : userDetails?.role}
                </span>
              </div>
              <div className="nav">
                <Dropdown.Item eventKey="1">
                  <Link to="/settings" className="dropdown-item">
                    Settings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="1">
                  <Link to="/profile" className="dropdown-item">
                    Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item eventKey="1">
                  <button
                    className="dropdown-item border-0 bg-transparent"
                    onClick={() => logOut()}
                  >
                    Logout
                  </button>
                </Dropdown.Item>
              </div>
            </DropdownButton>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Header;
