import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, endpoint } from "../../api";
import { apiNotification } from "../../utils";

const EditAlarm = () => {
  const Params = useParams();
  const alarmId = Params.alarmId;
  const navigate = useNavigate();

  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [state, setState] = useState({
    name: "",
    type: "",
    site: "",
    device: "",
    parameter: "",
    option: "",
    min: "",
    max: "",
    interval: "",
  });

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(response.data);
      }
    } catch (error) {}
  };

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const getDevices = async (locationId) => {
    const response = await axios.get(`/device`, {
      withCredentials: true,
      params: { site: locationId },
    });
    if (response) {
      setDevices(response.data.results);
    }
  };

  useEffect(() => {
    if (state.site) {
      getDevices(state.site);
    }
    // eslint-disable-next-line
  }, [state.site]);

  const getDeviceParameters = async (deviceId) => {
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (state.device) {
      getDeviceParameters(state.device);
    }
    // eslint-disable-next-line
  }, [state.device]);

  const getAlarm = async () => {
    const response = await axios.get(`${endpoint.notification}/${alarmId}`, {
      withCredentials: true,
    });
    if (response) {
      const data = response.data;
      setState({
        name: data?.name,
        type: data?.type,
        site: data?.site?._id,
        device: data?.device?._id,
        parameter: data?.parameter?._id,
        option: data?.option,
        min: data?.min,
        max: data?.max,
        interval: data?.interval,
      });
    }
  };

  useEffect(() => {
    getSiteLocations();
    getAlarm();
    // eslint-disable-next-line
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!state?.site) {
      apiNotification({
        message: "Please select Site.",
        warn: true,
      });
      return;
    } else if (!state?.device) {
      apiNotification({
        message: "Please select Device.",
        warn: true,
      });
      return;
    } else if (!state?.parameter) {
      apiNotification({
        message: "Please select Parameter.",
        warn: true,
      });
      return;
    } else if (!state?.interval) {
      apiNotification({
        message: "Please select Interval.",
        warn: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/notification/` + alarmId,
        state,
        {
          withCredentials: true,
        }
      );
      notification.success({
        message: "Notification has been updated.",
        placement: "bottomLeft",
      });
      navigate("/alarm-summary");
    } catch (error) {
      notification.warning({
        message: error?.response.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="alarm-page">
      <div className="container-fluid d-block">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <h3>Edit Alarm Settings</h3>
              <div className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" variant="dark" />}
              </div>
              {SuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {SuccessMessage}
                </div>
              )}
              <Spin spinning={isLoading}>
                <form onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Alarm Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={state.name}
                          onChange={onInputChange}
                          className="form-control"
                          id="name"
                          placeholder="Enter Alarm Name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          Alarm Type
                        </label>
                        <select
                          name="type"
                          id="type"
                          value={state.type}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option disabled> Select alarm type</option>
                          {/* <option value="message">
                          System Warning Message Only
                        </option> */}
                          <option value="email">Email Notification Only</option>
                          {/* <option value="both">
                          Email Notification + System Warning Message
                        </option> */}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="site" className="form-label">
                          Site Location
                        </label>
                        <select
                          name="site"
                          id="site"
                          value={state.site}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option disabled> Select site location</option>
                          {siteLocations?.length &&
                            siteLocations.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="device" className="form-label">
                          Device
                        </label>
                        <select
                          name="device"
                          id="device"
                          value={state.device}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option disabled> Select device</option>
                          {devices?.length &&
                            devices.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="parameter" className="form-label">
                          Device Parameter
                        </label>
                        <select
                          name="parameter"
                          id="parameter"
                          value={state.parameter}
                          className="form-select"
                          onChange={onInputChange}
                        >
                          <option disabled> Select device parameter</option>
                          {deviceParameters?.length &&
                            deviceParameters.map((item, index) => (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="option" className="form-label">
                          Alarm Notification
                        </label>
                        <select
                          name="option"
                          id="option"
                          className="form-select"
                          defaultValue={state.option}
                          onChange={onInputChange}
                          value={state.option}
                        >
                          <option disabled>
                            {" "}
                            Select alarm notification option
                          </option>
                          <option value="min">Min</option>
                          <option value="max">Max</option>
                          <option value="range">Range</option>
                        </select>
                      </div>
                      <div className="row mb-3">
                        {state.option === "min" && (
                          <div className="col-md-12">
                            <label htmlFor="value" className="form-label">
                              Set Value
                            </label>
                            <input
                              type="number"
                              name="min"
                              value={state.min}
                              className="form-control"
                              id="value"
                              placeholder="Enter minimum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                        )}
                        {state.option === "max" && (
                          <div className="col-md-12">
                            <label htmlFor="value" className="form-label">
                              Set Value
                            </label>
                            <input
                              type="number"
                              name="max"
                              value={state.max}
                              className="form-control"
                              id="value"
                              placeholder="Enter maximum value to trigger alarm"
                              onChange={onInputChange}
                              required
                            />
                          </div>
                        )}
                        {state.option === "range" && (
                          <>
                            <div className="col-md-6">
                              <label htmlFor="value" className="form-label">
                                Start Value
                              </label>
                              <input
                                type="number"
                                name="min"
                                value={state.min}
                                className="form-control"
                                id="value"
                                placeholder="Enter minmum value to trigger alarm"
                                onChange={onInputChange}
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="value" className="form-label">
                                End Value
                              </label>
                              <input
                                type="number"
                                name="max"
                                value={state.max}
                                className="form-control"
                                id="value"
                                placeholder="Enter maximum value to trigger alarm"
                                onChange={onInputChange}
                                required
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="interval" className="form-label">
                          Set Trigger Interval
                        </label>
                        <select
                          name="interval"
                          id="interval"
                          className="form-select"
                          value={state.interval}
                          onChange={onInputChange}
                        >
                          <option>Select alarm trigger interval</option>
                          <option value="1">1 min</option>
                          <option value="15">15 min</option>
                          <option value="30">30 min</option>
                          <option value="60">1 hour</option>
                          <option value="720">12 hours</option>
                          <option value="1440">24 hours</option>
                        </select>
                      </div>
                      <div className="mb-3 float-end">
                        <button className="btn btn-success me-2" type="submit">
                          Update
                        </button>
                        <Link to="/alarm-summary" className="btn btn-secondary">
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAlarm;
