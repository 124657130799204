import { Select } from "antd";

export const VsssbSelect = ({
  value,
  placeholder,
  onChange,
  options,
  label,
  isEnablePlaceholder = true,
}) => {
  return (
    <>
      {label && <label className="form-label">{label}</label>}
      <Select
        size="large"
        value={value || ""}
        className="w-100"
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          (option?.children ? option?.children.toLowerCase() : "").includes(
            input?.toLowerCase()
          )
        }
      >
        <Select.Option value="" disabled={isEnablePlaceholder}>
          {placeholder}
        </Select.Option>
        {options?.length &&
          options.map((item, idx) => (
            <Select.Option value={item._id} key={idx}>
              {`${item.name} ${item?.customerId ? `(${item.customerId})` : ""}`}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};
