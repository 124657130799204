import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    userDetails: {},
    userSites: [],
    currentSite: {},
    activeDashboard: "",
  },
  reducers: {
    setIsLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.userSites = action?.payload?.site || [];
    },
    setSiteDetails: (state, action) => {
      state.userSites = action.payload;
    },
    setCurrentSite: (state, action) => {
      state.currentSite = action.payload;
    },
    setCurrentDashboard: (state, action) => {
      state.activeDashboard = action.payload;
    },
  },
});
export const {
  setIsLogged,
  setUserDetails,
  setSiteDetails,
  setCurrentSite,
  setCurrentDashboard,
} = userSlice.actions;

export default userSlice.reducer;
