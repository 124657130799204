import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { numberFormater } from "../../../utils";
import { getLast6MonthDate } from "../FinanceReports";

export const PastSixMonthStatisticsPdf = ({ report }) => {
  const styles = StyleSheet.create({
    table: {
      border: "1px solid black",
    },
    sectionTitle: {
      fontSize: "12px",
      fontWeight: "bold",
    },
    tableRow: {
      display: "flex",
      flexDirection: "row",
    },
    tableTitle: {
      fontSize: "12px",
      width: "100%",
      textAlign: "center",
      padding: "7px",
      backgroundColor: "#FBE4D5",
      fontWeight: "bold",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "25%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
    },
    boldRightCellInRow: {
      fontSize: "12px",
      width: "25%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      borderRight: "1px solid black",
      fontWeight: "bold",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
    },
    boldLefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "right",
      padding: "7px",
      borderTop: "1px solid black",
      fontWeight: "bold",
    },
  });
  return (
    <>
      <Text style={styles.sectionTitle}>COMPARISON</Text>
      <View style={styles.table}>
        <Text style={styles.tableTitle}>Comparison Statistics</Text>
        <View style={styles.tableRow}>
          <Text style={styles.boldRightCellInRow}>Period</Text>
          <Text style={styles.boldRightCellInRow}>Total kWh Used</Text>
          <Text style={styles.boldLefttCellInRow}>
            Total Payment Collected (RM)
          </Text>
        </View>
        {getLast6MonthDate(report.cycleDate).map((date, idx) => (
          <View style={styles.tableRow} key={idx}>
            <Text style={styles.rightCellInRow}>{date}</Text>
            <Text style={styles.rightCellInRow}>
              {numberFormater(report?.past6MonthStatistics?.[idx]?.kWh)}
            </Text>
            <Text style={styles.lefttCellInRow}>
              {numberFormater(
                report?.past6MonthStatistics?.[idx]?.paymentCollected
              )}
            </Text>
          </View>
        ))}
      </View>
    </>
  );
};
