import { Input, notification, Select, Spin } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const Alerts = () => {
  const [state, setState] = useState({
    smsIntervalType: null,
    smsIntervalValue: "",
    emailIntervalType: null,
    emailIntervalValue: "",
  });
  const [isLoading, setLoading] = useState(false);

  const getBalance = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/app-settings/get-sms-and-email-interval`, {
        withCredentials: true,
      });

      setState({
        smsIntervalType: res.data?.smsIntervalType || null,
        smsIntervalValue: res.data?.smsIntervalValue || "",
        emailIntervalType: res.data?.emailIntervalType || null,
        emailIntervalValue: res.data?.emailIntervalValue || "",
      });
    } catch (error) {}
    setLoading(false);
  };

  const handleAction = async () => {
    setLoading(true);
    try {
      await axios.post(`/app-settings/set-sms-and-email-interval`, state, {
        withCredentials: true,
      });
      notification.success({
        message: "Intervals has been updated.",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }

    setLoading(false);
  };
  // const handleConfirmation = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "This action will affect all customer accounts in the system.",
  //     //icon: "warning",
  //     dangerMode: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Confirm",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       handleAction();
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //     }
  //   });
  // };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-12 p-0">
                  <label className="az-content-label">SETTINGS</label>
                  <span className="d-block py-2">Alerts</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <span
                    className="d-block pb-2"
                    style={{ marginTop: "-15px", marginBottom: "20px" }}
                  >
                    The settings below will determine the interval of the next
                    notification sent to a user from the system.
                  </span>
                  <div className="row">
                    <div className="col-md-3">
                      <div>
                        <label htmlFor="" className="font-weight-bold">
                          Set SMS Interval Alert
                        </label>
                      </div>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Interval"
                        size="large"
                        value={state.smsIntervalType}
                        onChange={(smsIntervalType) =>
                          setState({ ...state, smsIntervalType })
                        }
                      >
                        <option value="daily">Daily</option>
                        <option value="hourly">Hourly</option>
                      </Select>
                    </div>
                    <div className="col-md-3">
                      <div style={{ visibility: "hidden" }}>
                        <label htmlFor="" className="font-weight-bold">
                          Set SMS Interval Alert
                        </label>
                      </div>
                      <Input
                        type="number"
                        name="amount"
                        size="large"
                        value={state.smsIntervalValue}
                        onChange={(e) =>
                          setState({
                            ...state,
                            smsIntervalValue: e.target.value,
                          })
                        }
                        placeholder="Set Value"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-3">
                      <div>
                        <label htmlFor="" className="font-weight-bold">
                          Set Email Interval Alert
                        </label>
                      </div>
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select Interval"
                        size="large"
                        value={state.emailIntervalType}
                        onChange={(emailIntervalType) =>
                          setState({ ...state, emailIntervalType })
                        }
                      >
                        <option value="daily">Daily</option>
                        <option value="hourly">Hourly</option>
                      </Select>
                    </div>
                    <div className="col-md-3">
                      <div style={{ visibility: "hidden" }}>
                        <label htmlFor="" className="font-weight-bold">
                          Set SMS Interval Alert
                        </label>
                      </div>
                      <Input
                        type="number"
                        name="amount"
                        size="large"
                        value={state.emailIntervalValue}
                        onChange={(e) =>
                          setState({
                            ...state,
                            emailIntervalValue: e.target.value,
                          })
                        }
                        placeholder="Set Value"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 mt-5 text-right">
                <button
                  className="btn btn-success me-2"
                  type="submit"
                  onClick={handleAction}
                >
                  Update
                </button>
                <Link
                  to="/customer-payment-summary"
                  className="btn btn-secondary"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Alerts;
