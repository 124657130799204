import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
  Font,
} from "@react-pdf/renderer";

import { HeaderPdf } from "./HeaderPdf";
import { UserStatisticsPdfForElectricityReport } from "./UserStatisticsPdfForElectricityReport";
import { OtherChargesPdfForElectricityReport } from "./OtherChargesPdfForElectricityReport";
import { TotalChargesPdfForElectricityReport } from "./TotalChargesPdfForElectricityReport";
import { InstallationDetails } from "./InstallationDetails";

import regulerRobot from "./Roboto/Roboto-Regular.ttf";
import boldRoboto from "./Roboto/Roboto-Bold.ttf";
import moment from "moment";
import { numberFormater } from "../../../utils";

export const ElectricityUsageReportPdf = ({ report, base64Image }) => {
  const styles = StyleSheet.create({
    page: {
      padding: "40px 70px",
      position: "relative",
      fontFamily: "roboto",
    },
    singleRowOfAdminInfo: {
      marginBottom: "2px",
      display: "flex",
      flexDirection: "row",
    },
    rightTextAdminInfo: {
      fontSize: "12px",
      width: "48%",
    },
    leftTextAdminInfo: {
      fontSize: "12px",
      width: "50%",
    },
    tableRow: {
      margin: "10px 0",
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#DEEAF6",
      border: "1px solid black",
    },
    rightCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
    },
    lefttCellInRow: {
      fontSize: "12px",
      width: "50%",
      textAlign: "center",
      padding: "7px",
      borderLeft: "1px solid black",
    },
    sectionTitle: {
      fontSize: "12px",
      marginBottom: "10px",
      fontFamily: "roboto",
      fontWeight: "bold",
    },
    tableTitle: {
      fontSize: "12px",
      fontWeight: "bold",
      marginBottom: "0px",
    },
    sectionFooter: {
      fontSize: "12px",
      marginTop: "280px",
      textAlign: "center",
    },
    addressView: {
      width: "100%",
    },
    footerText1: {
      fontSize: "12px",
      marginTop: "25px",
      textAlign: "left",
    },
    footerText2: {
      fontSize: "12px",
      marginTop: "15px",
      textAlign: "center",
    },
    addressRightRow: {
      display: "flex",
      flexDirection: "row",
      width: "50%",
    },
    addressInnerLetfRow: {
      fontSize: "12px",
      width: "4px",
      marginRight: "2px",
    },
    addressInnerRightRow: {
      fontSize: "12px",
    },
    qrCode: {
      position: "absolute",
      width: "60px",
      height: "60px",
      right: "70px",
      top: "200px",
      marginTop: "13px",
    },
  });

  Font.register({
    family: "roboto",
    fonts: [{ src: regulerRobot }, { src: boldRoboto, fontWeight: "bold" }],
  });

  return (
    <Document>
      <Page size={"A4"} style={styles.page}>
        <Image source={{ uri: base64Image() }} style={styles.qrCode} />
        <HeaderPdf pageNumber="1/2" />

        <Text style={styles.sectionTitle}>CUSTOMER INFORMATION</Text>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Report Generation Date</Text>
          <Text style={styles.leftTextAdminInfo}>: {report.createdAt}</Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Monthly Cycle Selected</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.cycleDate || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Customer ID</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.customerId || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Customer Name</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.cusotmerName || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Site Name</Text>
          <Text style={styles.leftTextAdminInfo}>
            : {report.siteName || "--"}
          </Text>
        </View>
        <View style={styles.singleRowOfAdminInfo}>
          <Text style={styles.rightTextAdminInfo}>Customer Address</Text>
          <View style={styles.addressRightRow}>
            <Text style={styles.addressInnerLetfRow}>:</Text>
            <Text style={styles.addressInnerRightRow}>
              {report.pdfCustomerAddress || "--"}
            </Text>
          </View>
        </View>
        {/* deposit */}
        {!report?.planDetails?.depositType && (
          <InstallationDetails
            rows={getUnassingedDeposit()}
            title="CUSTOMER DEPOSIT INFORMATION"
          />
        )}
        {report?.planDetails?.depositType === "Yes" && (
          <InstallationDetails
            rows={getDepositRows(report?.planDetails || {})}
            title="CUSTOMER DEPOSIT INFORMATION"
          />
        )}
        {report?.planDetails?.depositType === "No" && (
          <InstallationDetails
            rows={getUpfrontDeposit(report?.depositUpfront || {})}
            title="CUSTOMER DEPOSIT INFORMATION"
          />
        )}
        {report?.planDetails?.depositType === "N/A" && (
          <InstallationDetails
            rows={getNADeposit(report?.depositUpfront || {})}
            title="CUSTOMER DEPOSIT INFORMATION"
          />
        )}
        {/* installment */}
        {!report?.planDetails?.internalWiringType && (
          <InstallationDetails
            rows={getUnassingedInstallmentWiring()}
            title="CUSTOMER INTERNAL WIRING INFORMATION"
            marginTop={15}
          />
        )}
        {report?.planDetails?.internalWiringType === "Yes" && (
          <InstallationDetails
            rows={getInstallmentWiring(report?.planDetails || {})}
            title="CUSTOMER INTERNAL WIRING INFORMATION"
            marginTop={15}
          />
        )}
        {report?.planDetails?.internalWiringType === "No" && (
          <InstallationDetails
            rows={getUpfrontInstallmentWiring(
              report?.internalWiringUpfront || {}
            )}
            title="CUSTOMER INTERNAL WIRING INFORMATION"
            marginTop={15}
          />
        )}
        {report?.planDetails?.internalWiringType === "N/A" && (
          <InstallationDetails
            rows={getNAInstallmentWiring(report?.internalWiringUpfront || {})}
            title="CUSTOMER INTERNAL WIRING INFORMATION"
            marginTop={15}
          />
        )}
      </Page>
      <Page size={"A4"} style={styles.page}>
        <HeaderPdf pageNumber="2/2" />
        <Text style={styles.tableTitle}>ELECTRICITY USAGE INFORMATION</Text>
        <UserStatisticsPdfForElectricityReport report={report} />
        <OtherChargesPdfForElectricityReport report={report} />
        <TotalChargesPdfForElectricityReport report={report} />
        <Text style={styles.footerText1}>
          &gt;&gt; Please ensure you have sufficient credit in your account
          before the start of every month to avoid any service disruption. We
          thank you for your kind understanding.
        </Text>
        <Text style={styles.footerText2}>
          THIS IS AN ELECTRONICALLY GENERATED REPORT FROM IVIS.COM.MY
        </Text>
      </Page>
    </Document>
  );
};

const getUnassingedDeposit = () => [
  {
    title: "Type for Deposite",
    value: "Unassigned",
  },
  {
    title: "Amount Paid (RM)",
    value: "0.00",
  },
  {
    title: "Date of Payment",
    value: "N/A",
  },
];
const getUpfrontDeposit = (upfrontDetails) => [
  {
    title: "Type for Deposite",
    value: "Upfront",
  },
  {
    title: "Amount Paid (RM)",
    // value: upfrontDetails?.currentBalance ? "300.00" : "",
    value: upfrontDetails?.createdAt
      ? (upfrontDetails?.reloadedBalance || 0).toFixed(2)
      : "0.00",
  },
  {
    title: "Date of Payment",
    value: upfrontDetails?.createdAt
      ? moment(upfrontDetails.createdAt).format("DD/MM/YYYY")
      : "N/A",
  },
];
const getNADeposit = () => [
  {
    title: "Type for Deposite",
    value: "N/A",
  },
];

const getUnassingedInstallmentWiring = () => {
  return [
    {
      title: "Type for Internal Wiring",
      value: "Unassigned",
    },
    {
      title: "Amount Paid (RM)",
      value: "0.00",
    },
    {
      title: "Date of Payment",
      value: "N/A",
    },
  ];
};
const getUpfrontInstallmentWiring = (upfrontDetails) => {
  // console.log(
  //   "(816.0 - upfrontDetails?.reloadedBalance || 0).toFixed(2)",
  //   (816.0 - upfrontDetails?.reloadedBalance || 0).toFixed(2)
  // );
  return [
    {
      title: "Type for Internal Wiring",
      value: "Upfront",
    },
    {
      title: "Amount Paid (RM)",
      // value: upfrontDetails?.currentBalance ? "816.00" : "",
      value: upfrontDetails?.createdAt
        ? (upfrontDetails?.reloadedBalance || 0).toFixed(2)
        : "0.00",
    },
    {
      title: "Date of Payment",
      value: upfrontDetails?.createdAt
        ? moment(upfrontDetails.createdAt).format("DD/MM/YYYY")
        : "N/A",
    },
  ];
};
const getNAInstallmentWiring = () => [
  {
    title: "Type for Internal Wiring",
    value: "N/A",
  },
];
const getInstallmentWiring = (planDetails) => [
  {
    title: "Type for Internal Wiring",
    value: "Monthly",
  },
  {
    title: "Installment Start Date",
    value: planDetails?.internalInstallmentPeriod
      ? "7/" + planDetails?.internalInstallmentPeriod?.split("-")?.[0].trim()
      : "",
  },
  {
    title: "Installment End Date",
    value: planDetails?.internalInstallmentPeriod
      ? "7/" + planDetails?.internalInstallmentPeriod?.split("-")?.[1].trim()
      : "",
  },
  {
    title: "Installment Remaining Period (Months)",
    value:
      typeof planDetails?.internalInstallmentCount === "number"
        ? 48 - planDetails?.internalInstallmentCount
        : "",
  },
  {
    title: "Total Installment Payment Made (RM)",
    value:
      typeof planDetails?.internalInstallmentCount === "number"
        ? numberFormater(planDetails?.internalInstallmentCount * 17)
        : "",
  },
  {
    title: "Balance For Installment Payment (RM)",
    value:
      typeof planDetails?.internalInstallmentCount === "number"
        ? numberFormater((48 - planDetails?.internalInstallmentCount) * 17)
        : "",
  },
];

const getDepositRows = (planDetails) => [
  {
    title: "Type for Deposit",
    value: "Monthly",
  },
  {
    title: "Installment Start Date",
    value: planDetails?.depositInstallmentPeriod
      ? "7/" + planDetails?.depositInstallmentPeriod?.split("-")?.[0].trim()
      : "",
  },
  {
    title: "Installment End Date",
    value: planDetails?.depositInstallmentPeriod
      ? "7/" + planDetails?.depositInstallmentPeriod?.split("-")?.[1].trim()
      : "",
  },
  {
    title: "Installment Remaining Period (Months)",
    value:
      typeof planDetails?.depositInstallmentCount === "number"
        ? 12 - planDetails?.depositInstallmentCount
        : "",
  },
  {
    title: "Total Installment Payment Made (RM)",
    value:
      typeof planDetails?.depositInstallmentCount === "number"
        ? numberFormater(planDetails?.depositInstallmentCount * 25)
        : "",
  },
  {
    title: "Balance For Installment Payment (RM)",
    value:
      typeof planDetails?.depositInstallmentCount === "number"
        ? numberFormater((12 - planDetails?.depositInstallmentCount) * 25)
        : "",
  },
];
