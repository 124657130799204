import React from 'react';

const AlarmType = () => {
    return (
        <div className="az-content-body">
            <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                    <div className="card card-dashboard-six">
                        <div className="card-header">
                            <div className="col-md-8 p-0">
                                <label className="az-content-label">Manage</label>
                                <span className="d-block py-2">Create Alarm Type</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AlarmType;