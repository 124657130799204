import { PDFDownloadLink } from "@react-pdf/renderer";
import { Modal } from "antd";
import { MainReceiptMalay } from "./Pdf-Malay/MainReceiptMalay";
import { MainReceipt } from "./Pdf/MainReceipt";

export const LanguageSelectorModal = ({
  visible,
  setVisible,
  selectedRow,
  setSElectedRow,
  today,
  getBinaryQRCode,
  getReceiptName,
}) => {
  return (
    <Modal
      title="Select Language "
      open={visible}
      footer={null}
      onCancel={() => {
        setVisible(false);
        setSElectedRow({});
      }}
      style={{ textAlign: "center", maxWidth:"300px" }}
    >
      <div style={{ textAlign: "center", marginTop:"25px" }}>
        <PDFDownloadLink
          document={
            <MainReceipt
              report={selectedRow}
              today={today}
              base64Image={getBinaryQRCode}
            />
          }
          fileName={getReceiptName(selectedRow)}
          className="btn btn-warning ms-2"
          onClick={() => setVisible(false)}
        >
          English
        </PDFDownloadLink>
        <PDFDownloadLink
          document={
            <MainReceiptMalay
              report={selectedRow}
              today={today}
              base64Image={getBinaryQRCode}
            />
          }
          fileName={getReceiptName(selectedRow)}
          className="btn btn-warning ms-2"
          onClick={() => setVisible(false)}
        >
          {/* <AiOutlineCloudDownload /> */}Malay
        </PDFDownloadLink>
      </div>
    </Modal>
  );
};
