import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setIsLogged, setUserDetails } from "../../redux/userSlice";

const QrSignInSignIn = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { id } = useParams();
  console.log(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`/user/get-cusotmer/${id}`, {
        withCredentials: true,
      });
      const data = response.data;
      if (data) {
        dispatch(setIsLogged(true));
        const userResponse = await axios.get(`/user/me`, {
          withCredentials: true,
        });
        if (userResponse) {
          console.log(userResponse.data);
          dispatch(setUserDetails(userResponse.data));
          navigate("/");
        }
      }
    } catch (error) {
      setErrorMessage("We can't find you!!!!");
    }
    setLoading(false);
  };
  const confitmLogout = async () => {
    setLoading(true);
    try {
      await axios.get(`/user/logout`, {
        withCredentials: true,
      });
      dispatch(setIsLogged(false));
      dispatch(setUserDetails({}));
      window.localStorage.clear();
    } catch (error) {
      setErrorMessage("Something went wrong!");
      setLoading(false);
      return false;
    }
    return true;
  };
  const setUpUser = async () => {
    const isLoggedout = await confitmLogout();
    if (isLoggedout) handleLogin();
  };
  useEffect(() => {
    setUpUser();
    document.title = "IVIS – Prepaid Monitoring System (PMS)";
  }, []);

  let background = "/img/bg.jpg";

  return (
    <Spin spinning={loading}>
      <div>
        <div
          className="az-content-wrapper p-0"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div>
            <div className="az-signin-wrapper">
              <div className="az-card-signin">
                <h1 className="az-logo d-flex">
                  <img
                    src="/img/beta-logo.png"
                    alt="logo"
                    style={{ height: "80px", margin: "auto" }}
                    onClick={() => navigate("/")}
                  />
                </h1>
                <div className="az-signin-header">
                  <h2>Welcome Back!</h2>
                  <h4>
                    {errorMessage || "Please wait... We are finding you."}
                  </h4>
                </div>
                <div className="az-signin-footer text-center mt-2">
                  <p>Version 2.1.0</p>
                  {/* <p>Version 2.0 | {new Date().getFullYear()}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default QrSignInSignIn;
