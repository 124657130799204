import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid bg-light w-100  d-block">
        <div className="row">
          <div className="col w-100">
            <p className="py-2 mt-5 text-center">
              <span>
                &copy; {new Date().getFullYear()} IVIS – Prepaid Monitoring
                System (PMS){" "}
              </span>
              <span style={{ float: "right" }}>Version 2.1.0</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
