import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { endpoint } from "../../api";
import AddItemModal from "../LiveDashboard/AddItemModal";
import DashboardLayout from "./DashboardLayout";

const DashboardForm = ({ mode, data }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState([]);
  const [layouts, setLayouts] = useState({});
  const [name, setName] = useState('')

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (mode === 'edit') {
      setLayouts(data.layouts)
      setAllData(data.items)
      setName(data.name)
    }
  }, [data])



  // create Dashboard
  const handleSubmit = async (e) => {
    e.preventDefault()
    const dashboardType = new Date().getTime()
    const body = { name, layouts, items: allData, dashboardType }

    try {
      const { status, data } = await axios.post(endpoint.create_dashboard, body, { withCredentials: true });
      if (status === 201) {
        Swal.fire("", "Dashboard Created Successfully!", "success");
        navigate("/dashboardList");
      }

    } catch (error) {
      console.lot(error)
    }
  };

  // update dashboard
  const handleUpdate = async e => {
    e.preventDefault()
    const body = { name, layouts, items: allData }

    const response = await axios.put(endpoint.update_dashboard + '/' + data._id, body, { withCredentials: true });
    console.log(response.data)
    if (response.status === 204) {
      Swal.fire("", "Dashboard Upated Successfully!", "success");
      navigate("/dashboardList");
    }

  }



  return (
    <div className="px-3 mt-3">
      <div className="border bg-white p-3">
        {/* <h5 className="fw-bold">Add New Dashboard</h5> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">Manage Dashboard</label>
            <span className="d-block py-2">Add New Dashboard</span>
          </div>
          {/* <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/site-locations/create">Add Site Location</Link>
          </div> */}
        </div>
        <hr className="mt-0" />
        <Form onSubmit={mode === 'edit' ? handleUpdate : handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mt-3">
                <Form.Label>Dashboard Name</Form.Label>
                <Form.Control
                  required
                  className="text-secondary"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Enter Dashboard Name"
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>
              <div className="my-3">
                <small onClick={handleShow} className="btn btn-success ">
                  Add Item
                </small>
              </div>
            </Col>
          </Row>
          <div className="bg-layout pb-5">
            {allData?.length > 0 && <DashboardLayout
              allData={allData}
              setAllData={setAllData}
              layouts={layouts}
              setLayouts={setLayouts}
              mode='create'
            />}
          </div>

          <div className="d-flex justify-content-end mt-4">
            <Button
              disabled={!allData?.length}
              type="submit"
              className="me-2"
              variant="success"
            >
              {mode === 'edit' ? 'Update Dashboard' : 'Create Dashboard'}
            </Button>
            <Link to="/dashboardList">
              <Button variant="secondary">Cancel</Button>
            </Link>
          </div>
        </Form>
      </div>

      {/* item added with modal */}
      <AddItemModal
        show={show}
        setShow={setShow}
        allData={allData}
        setAllData={setAllData}
        mode={'create'}
      />
    </div>
  );
};

export default DashboardForm;
