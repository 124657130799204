import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Swal from "sweetalert2";

const ChangePasswordModal = (props) => {
  const { userId, setModalShow, phone, email } = props;
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };
  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };

  const [Data, setData] = useState({
    password: "",
    reenterPassword: "",
  });
  const { password, reenterPassword } = Data;
  const [ErrorMessage, setErrorMessage] = useState();
  const onInputChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password === reenterPassword) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to change the password?",
        //icon: "warning",
        dangerMode: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put(
              `user/` + userId,
              { ...Data, email, phone },
              { withCredentials: true }
            )
            .then((res) => {
              setModalShow(false);
              Swal.fire({
                title: "Done!",
                text: "Password has been changed successfully",
                icon: "success",
                timer: 2000,
                button: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    } else {
      setErrorMessage("Both Passwords Are Not Matching");
      setTimeout(() => {
        setErrorMessage();
      }, 2000);
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="text-center">Change Password</h4>
        {ErrorMessage && (
          <div className="alert alert-danger" role="alert">
            {ErrorMessage}
          </div>
        )}
        <form onSubmit={submitHandler}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <div className="input-group">
                <input
                  type={newPasswordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={onInputChange}
                  minLength="6"
                  className="form-control"
                  id="password"
                  placeholder="Enter new password"
                  required
                />
                <span className="input-group-text" id="basic-addon1">
                  {newPasswordShown ? (
                    <BsEyeSlash onClick={() => toggleNewPasswordShown()} />
                  ) : (
                    <BsEye onClick={() => toggleNewPasswordShown()} />
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <label htmlFor="reenterPassword" className="form-label">
                Reenter Password
              </label>
              <div className="input-group">
                <input
                  type={repeatPasswordShown ? "text" : "password"}
                  name="reenterPassword"
                  value={reenterPassword}
                  minLength="6"
                  onChange={onInputChange}
                  className="form-control"
                  id="repeatPassword"
                  placeholder="Retype the password"
                  required
                />
                <span className="input-group-text" id="basic-addon1">
                  {repeatPasswordShown ? (
                    <BsEyeSlash onClick={() => toggleRepeatPasswordShown()} />
                  ) : (
                    <BsEye onClick={() => toggleRepeatPasswordShown()} />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="float-end">
            <button type="submit" className="btn btn-success me-2">
              Change
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
