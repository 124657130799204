import { notification } from "antd";
import Swal from "sweetalert2";

export const apiNotification = ({ message, warn = false }) => {
  if (warn) {
    notification.warning({ message, placement: "bottomLeft" });
  } else {
    notification.success({ message, placement: "bottomLeft" });
  }
};

export const showAlert = ({ message, success }) => {
  Swal.fire({
    title: success ? "Done!" : "Warning!",
    text: message || "Something is wrong.",
    icon: success ? "success" : "warning",
    timer: 2000,
    button: false,
  });
};

export const numberFormater = (numb) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Number(numb || 0).toLocaleString("en", options);
};
