import { Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userRole } from "../../constant";
import { InstallationDetailsShort } from "../Profile/InstallationDetailsShort";

export const UserStatistics = ({ user, options, role }) => {
  const navigator = useNavigate();
  const [basicUser, setBasicUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    balance: 0,
    lastTopUp: 0,
    topUpInLast6Month: 0,
  });
  const getUserStatistics = async () => {
    setLoading(false);
    try {
      const response = await axios.get(`/dashboard/chart/user-statistics`, {
        params: { userId: user },
        withCredentials: true,
      });
      setUserData(response.data);
    } catch (error) {}
    setLoading(false);
  };
  const getUser = async () => {
    try {
      const response = await axios.get(`/user/` + user, {
        withCredentials: true,
        params: { plan: true },
      });
      if (response) {
        setBasicUser(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (user) {
      getUser();
    }
    getUserStatistics();
  }, [user]);
  let lastDate = new Date();

  return (
    <div className="col-lg-6">
      <Spin spinning={isLoading}>
        <div className="card card-dashboard-six graphShadow h-100">
          <div className="d-flex justify-content-between">
            <h6 className="fw-bold text-uppercase mb-4">
              ACCOUNT FINANCIAL SUMMARY
            </h6>
            {(role === userRole.superAdmin || role === userRole.admin) && (
              <div>
                <button
                  type="submit"
                  className="btn btn-success me-2"
                  disabled={!basicUser?._id}
                  onClick={() => {
                    navigator(
                      `manual-customer-reload?site=${basicUser?.site?.[0]?._id}&customer=${basicUser?._id}`
                    );
                  }}
                  // to={`manual-customer-reload?site=${basicUser?.site?.[0]?._id}&customer=${basicUser?._id}`}
                >
                  Top-Up
                </button>
              </div>
            )}
          </div>
          <div>
            <h1 className="fw-bold">
              RM{" "}
              {Number(userData?.topUpInLast6Month).toLocaleString(
                "en",
                options
              )}
            </h1>
            <div>
              Total Payment Summary (Jan -{moment(lastDate).format("MMM, YYYY")}
              )
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <h3 className="fw-bold">
                  RM {Number(userData?.balance).toLocaleString("en", options)}
                </h3>
                <div>Prepaid Credit Balance </div>
              </div>
              <div className="col-md-6">
                <h3 className="fw-bold">
                  RM {Number(userData?.lastTopUp).toLocaleString("en", options)}
                </h3>
                <div>
                  {" "}
                  Last top-up amount{" "}
                  {userData?.lastTopUpDate &&
                    moment(userData?.lastTopUpDate).format("DD/MM/YYYY")}
                </div>
              </div>
            </div>
            <div className="row">
              <InstallationDetailsShort
                installmentType={basicUser?.plan?.depositType}
                hasInstallment={basicUser?.plan?.depositType === "Yes"}
                installmentPeriod={
                  basicUser?.plan?.depositInstallmentPeriod || ""
                }
                installmentCount={basicUser?.plan?.depositInstallmentCount}
                totalInstallment={12}
                installmentAmount={25}
                upfrontDetails={basicUser?.depositUpfront}
                outterCol="12"
              />
              <InstallationDetailsShort
                installmentType={basicUser?.plan?.internalWiringType}
                hasInstallment={basicUser?.plan?.internalWiringType === "Yes"}
                installmentPeriod={
                  basicUser?.plan?.internalInstallmentPeriod || ""
                }
                installmentCount={basicUser?.plan?.internalInstallmentCount}
                totalInstallment={48}
                installmentAmount={17}
                upfrontDetails={basicUser?.internalWiringUpfront}
                outterCol="12"
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};
