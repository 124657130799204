import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FiTrash, FiEye, FiEdit, FiUserX, FiUserCheck } from "react-icons/fi";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { Button, Form, Spinner } from "react-bootstrap";
import { Spin, Table } from "antd";
import { userRole } from "../../constant";
import { DataTable } from "../../components";
import { showAlert } from "../../utils";

export default function Installers() {
  const userDetails = useSelector((state) => state.user.userDetails);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");
  const [installers, setInstallers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBySatus, setSearchBySatus] = useState("active");

  const getInstaller = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/user`, {
        withCredentials: true,
        params: {
          limit,
          page,
          site: searchByLocation,
          searchText: searchTerm,
          role: userRole.installer,
          status: searchBySatus,
        },
      });
      setTotal(response.data.count);
      setInstallers(response.data.results);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "IVIS PMS - Installers";
    getInstaller();
    getSiteLocation();
  }, []);

  useEffect(() => {
    getInstaller();
  }, [searchTerm, limit, page]);

  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };

  const activeDeactiveUser = async (userId, isActive) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: `Do you wish to ${
        !isActive ? "activate" : "deactivate"
      } this installer?`,
      // text: "Do you wish to deactivate this customer?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`/user/` + userId, data, { withCredentials: true })
          .then((res) => {
            getInstaller();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const columns = [
    {
      title: "No.",
      key: "no",
      render: (_, __, index) => index + 1 + limit * (page - 1),
      width: "60px",
    },
    {
      title: "User Name",
      key: "User_Name",
      render: (_, row) => <div>{row?.name}</div>,
    },
    {
      title: "Company Name",
      key: "Company_Name",
      render: (_, row) => row?.companyName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      render: (phone) => <>+6{phone}</>,
    },
    {
      title: "Location",
      key: "Location",
      render: (_, row) => (
        <div>
          {row?.site.map((item) => (
            <div>{item?.name}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Date Created",
      keu: "Date_Created",
      render: (_, row) => moment(row.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      key: "isActive",
      dataIndex: "isActive",
      render: (isActive) => (
        <div>
          {isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "Action",
      render: (_, row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link
                      to={`/installer/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View Profile" />
                    </Link>
                    <Link
                      to={`/edit-installer/` + row._id}
                      className="btn btn-az-primary me-1"
                    >
                      <FiEdit title="Edit Profile" />
                    </Link>
                    {(() => {
                      switch (row.isActive) {
                        case true:
                          return (
                            <button
                              className="btn btn-danger me-1"
                              onClick={() =>
                                activeDeactiveUser(row._id, row.isActive)
                              }
                            >
                              <FiUserX title="Inactive Account" />
                            </button>
                          );
                        case false:
                          return (
                            <button
                              className="btn btn-secondary  me-1"
                              onClick={() =>
                                activeDeactiveUser(row._id, row.isActive)
                              }
                            >
                              <FiUserCheck title="Active Account" />
                            </button>
                          );
                        default:
                          return;
                      }
                    })()}
                    {/* <button
                      className="btn btn-danger"
                      onClick={() => deleteUser(row._id)}
                    >
                      <FiTrash title="Delete" />
                    </button> */}
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link
                      to={`/installer/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View Profile" />
                    </Link>
                  </div>
                );
            }
          })()}
        </div>
      ),
      align: "center",
    },
  ];
  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this installer?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/user/` + userId, { withCredentials: true })
          .then((res) => {
            getInstaller();
            // text: "Installer Successfully Deleted",
            showAlert(res.data);
          })
          .catch((err) => {
            showAlert(err.response?.data);
          });
      }
    });
  };

  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Installer</label>
                <span className="d-block py-2">All Installer</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0">
                <Link to="/add-installer" className="btn btn-az-primary btn-md">
                  Add Installer
                </Link>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3">
                <select className="form-select" onChange={handleLocation}>
                  <option label="All Locations"></option>
                  {siteLocation &&
                    siteLocation.length > 0 &&
                    siteLocation.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  onChange={(e) => setSearchBySatus(e.target.value)}
                  value={searchBySatus}
                >
                  <option label="All Status" value={""}></option>
                  <option label="Active" value={"active"}></option>
                  <option label="Inactive" value={"inactive"}></option>
                </select>
              </div>
              <div className="col-md-2">
                <Button onClick={getInstaller} variant="success">
                  Search
                </Button>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-3">
                <Form className="d-flex">
                  <Form.Control
                    value={searchTerm}
                    onChange={(e) => {
                      setPage(1);
                      setSearchTerm(e.target.value);
                    }}
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                </Form>
              </div>
            </div>
            <Spin spinning={isLoading}>
              <DataTable
                dataSource={installers}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  defaultPageSize: limit,
                  current: page,
                  total,
                  showSizeChanger: true,
                  onShowSizeChange: (_, pageSize) => {
                    setLimit(pageSize);
                  },
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total}`,
                  pageSizeOptions: [10, 20, 50],
                  responsive: true,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}
