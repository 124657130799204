import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import CanvasJS from "../../assets/canvasjs.min";
import moment from "moment";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SplineChart = ({ title, data = [], isLoading = false, type, chartType }) => {
  CanvasJS.addColorSet("greenShades", ["#3f9ffb", "#3ffbe8", "#f36a08", "#3ffb42", "#fb3f6b"]);

  const [options, setOptions] = useState({})


  useEffect(() => {
    if (chartType === 'Device Chart' && data.length) {
      setOptions({
        zoomEnabled: true,
        animationEnabled: false,
        title: {
          text: title,
          textTransform: 'capitalize',
        },

        data: [
          ...data.map((d) => ({
            type,
            name: d[0]?.name,
            xValueFormatString: "MMM YYYY, hh:mm TT",
            showInLegend: true,
            indexLabel: type === 'pie' ? "{label} {y}" : "",
            dataPoints: d?.map((t) => ({ name: moment(t.date).format("MMM YYYY"), x: new Date(t.date), y: t.data })),
          })),
        ],
      })
    } else if (chartType === 'Location Chart' && data.length) {
      const dt = data.map(d => {
        return {
          type,
          name: `${d[0]?._id?.location} (${d[0]?._id?.name})`,
          showInLegend: true,
          dataPoints: d[0]?.data?.map((t) => ({ x: new Date(t.date), y: t.value })),
        }
      })

      setOptions({
        zoomEnabled: true,
        animationEnabled: false,
        title: {
          text: title,
          textTransform: 'capitalize',
        },
        toolTip: {
          shared: true
        },

        data: dt,
      })
    }
  }, [data, type])



  return <CanvasJSChart options={options} />

};

export default SplineChart;
