import { Modal, notification, Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditPrepaidPlan = () => {
  const Params = useParams();
  const planId = Params.planId;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [prepaidPlanData, setprepaidPlanData] = useState({
    name: "",
    monthlyMaintenanceFee: "",
    baseRate: "",
    kwtbbRate: "",
    startingBalance: "",
  });

  const { name, monthlyMaintenanceFee, baseRate, kwtbbRate, startingBalance } =
    prepaidPlanData;

  const onInputChange = (e) => {
    setprepaidPlanData({ ...prepaidPlanData, [e.target.name]: e.target.value });
    console.log(prepaidPlanData);
  };

  const getPrepaidPlan = async () => {
    const response = await axios.get(`/subscription/` + planId, {
      withCredentials: true,
    });
    if (response) {
      const data = response.data;
      setprepaidPlanData({
        name: data.name,
        monthlyMaintenanceFee: data.monthlyMaintenanceFee,
        baseRate: data.baseRate,
        kwtbbRate: data.kwtbbRate,
        startingBalance: data.startingBalance,
      });
    }
  };
  useEffect(() => {
    getPrepaidPlan();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(`/subscription/` + planId, prepaidPlanData, {
        withCredentials: true,
      });
      notification.success({
        message: "Prepaid Plan Edited Successfully. ",
        placement: "bottomLeft",
      });
      setTimeout(() => {
        navigate(`/view-prepaid-plan/${planId}`);
      }, 2000);
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Edit Prepaid Plan</span>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center">
                {isLoading && <Spinner animation="border" variant="dark" />}
              </div> */}
              {/* {SuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {SuccessMessage}
                </div>
              )}
              {ErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  {ErrorMessage}
                </div>
              )} */}
              <form onSubmit={submitHandler}>
                <div className="row">
                  <div className="d-flex">
                    <div className="col-md-6">
                      <label className="az-content-label">
                        General Information
                      </label>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Plan Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                          className="form-control"
                          id="name"
                          placeholder="Enter plan name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Monthly Maintenance Fee (RM)
                        </label>
                        <input
                          type="number"
                          name="monthlyMaintenanceFee"
                          value={monthlyMaintenanceFee}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Account Starting Balance (RM)
                        </label>
                        <input
                          type="number"
                          name="startingBalance"
                          value={startingBalance}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="az-content-label">
                        Electricity Charges
                      </label>
                      <div className="mb-3">
                        <label htmlFor="baseRate" className="form-label">
                          Base kWh Rate (RM)
                        </label>
                        <input
                          type="number"
                          name="baseRate"
                          value={baseRate}
                          onChange={onInputChange}
                          className="form-control"
                          id="baseRate"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">KWTBB Rate (%)</label>
                        <input
                          type="number"
                          name="kwtbbRate"
                          value={kwtbbRate}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter value"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-3 float-end">
                  <button className="btn btn-success me-2" type="submit">
                    Update
                  </button>
                  <Link
                    to={`/view-prepaid-plan/${planId}`}
                    className="btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EditPrepaidPlan;
