import { Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Checkbox, notification, Spin } from "antd";
import ChangePasswordModal from "./ChangePasswordModal";
import { apiNotification } from "../../utils";
import { userRole } from "../../constant";

export default function EditSiteUser() {
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const Params = useParams();
  const userId = Params.userId;
  const navigate = useNavigate();
  const [oldSiteID, setOldSiteID] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    site: "",
    address1: "",
    address2: "",
    country: "",
    locationState: "",
    postCode: "",
    customerId: "",
    unit: "",
    city: "",
    isDisableOfflineNotification: false,
  });
  const {
    name,
    email,
    phone,
    address1,
    address2,
    site,
    country,
    locationState,
    postCode,
    customerId,
    unit,
    city,
    isDisableOfflineNotification,
  } = userData;
  const onInputChange = (e) => {
    if (e.target.name === "site") {
      const selectedSite = siteLocations.find(
        (item) => item._id === e.target.value
      );
      console.log(selectedSite);
      const obj = {
        site: e.target.value,
        unit: "",
        address1: selectedSite.buildingAddress1,
        address2: selectedSite.buildingAddress2,
        postCode: selectedSite.buildingPostalCode,
        locationState: selectedSite.locationState,
        city: selectedSite.locationCity,
      };
      setUserData({ ...userData, ...obj });
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  // const onInputChange = (e) => {
  //   setUserData({ ...userData, [e.target.name]: e.target.value });
  // };
  //   console.log(userData);
  //Profile Photo
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const handleFileUpload = (file) => {
    setImageUrl(URL.createObjectURL(file));
    let form = new FormData();
    form.append("avatar", file);
    setSelectedImage(form);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!userData?.email && !userData?.phone) {
      notification.warning({
        message: "Either email or phone is required.",
        placement: "bottomLeft",
      });
      return;
    }
    setIsLoading(true);
    console.log(userData?._id);
    try {
      const response = await axios.put(`/user/` + userData?._id, userData, {
        withCredentials: true,
      });
      const data = response.data;
      if (selectedImage) {
        await axios.put(`/user/${data._id}/avatarUpload/`, selectedImage, {
          withCredentials: true,
        });
      }
      notification.success({
        message: "Customer Edited Successfully.",
        placement: "bottomLeft",
      });
      setTimeout(() => {
        navigate("/customer");
      }, 2000);
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };

  const [siteLocations, setSiteLocations] = useState([]);
  const getSiteLocations = async () => {
    try {
      if (userDetails.role === "superAdmin") {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } else if (userDetails.role === "admin") {
        const response = await axios.get(
          `/site-location/admin-sites/` + userDetails._id,
          { withCredentials: true }
        );
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      }
    } catch (error) {}
  };

  const getUser = async () => {
    try {
      const response = await axios.get(`/user/` + userId, {
        withCredentials: true,
      });
      if (response) {
        const data = response.data;
        setOldSiteID(data?.site[0]?._id);
        setUserData({
          name: data?.name,
          email: data?.email,
          phone: data?.phone,
          address1: data?.address1,
          address2: data?.address2,
          site: data?.site[0]?._id,
          country: data?.country,
          locationState: data?.locationState,
          postCode: data?.postCode,
          customerId: data?.customerId,
          unit: data?.unit,
          city: data?.city,
          isDisableOfflineNotification: data?.isDisableOfflineNotification,
          _id: data?._id,
        });
        setImageUrl(data?.avatar);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUser();
    getSiteLocations();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage Customer</label>
                  <span className="d-block py-2">Update Customer</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0"></div>
              </div>
              {/* <div className="d-flex justify-content-center">
              {isLoading && <Spinner animation="border" variant="dark" />}
            </div>
            {SuccessMessage && (
              <div className="alert alert-success" role="alert">
                {SuccessMessage}
              </div>
            )} */}
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="name" className="form-label">
                      Full Name
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter full name"
                      required
                      disabled={userDetails?.role !== userRole.superAdmin}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="site" className="form-label">
                      Assigned Site
                    </label>
                    <select
                      name="site"
                      value={site}
                      className="form-select"
                      onChange={onInputChange}
                      required
                    >
                      <option>Select Single Site</option>
                      {siteLocations?.length &&
                        siteLocations.map((item) => (
                          <option value={item._id} key={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="email"
                      name="email"
                      value={email}
                      onChange={onInputChange}
                      className="form-control"
                      id="email"
                      placeholder="Enter email address"
                      // required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="email" className="form-label">
                      Customer ID
                    </label>
                    <input
                      role="presentation"
                      autocomplete="off"
                      type="text"
                      name="customerId"
                      value={customerId}
                      onChange={onInputChange}
                      readOnly
                      className="form-control"
                      disabled
                      placeholder="Customer ID"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        +6
                      </span>
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={onInputChange}
                        className="form-control"
                        // required
                        placeholder="Enter phone number"
                      />
                    </div>
                    <div className="col-md6 mb-3 mt-3">
                      <label htmlFor="bname" className="form-label">
                        Profile Photo
                      </label>
                      {imageUrl && selectedImage ? (
                        <div mt={2} textAlign="center">
                          <img src={imageUrl} alt="avatar" height="100px" />
                        </div>
                      ) : (
                        <>
                          <input
                            className="form-control"
                            accept="image/*"
                            type="file"
                            id="select-image"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              handleFileUpload(e.target.files[0])
                            }
                          />
                          <label htmlFor="select-image">
                            <img
                              src={imageUrl}
                              alt=""
                              height="100px"
                              className="rounded-3 border p-2 ms-2"
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="address1" className="form-label">
                      Home Address
                    </label>
                    <div className="input-group">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="unit"
                        value={unit}
                        onChange={onInputChange}
                        className="form-control"
                        id="unit"
                        placeholder="Unit"
                        required
                        disabled={site === oldSiteID}
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address1"
                        value={address1}
                        onChange={onInputChange}
                        className="form-control"
                        id="address1"
                        placeholder="Address line 1"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="address2"
                        value={address2}
                        onChange={onInputChange}
                        className="form-control"
                        id="address2"
                        placeholder="Address line 2"
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="postCode"
                        value={postCode}
                        onChange={onInputChange}
                        className="form-control"
                        id="postCode"
                        placeholder="Postcode"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="city"
                        value={city}
                        onChange={onInputChange}
                        className="form-control"
                        id="city"
                        placeholder="City"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="locationState"
                        value={locationState}
                        onChange={onInputChange}
                        className="form-control"
                        id="locationState"
                        placeholder="State"
                        required
                        disabled
                      />
                    </div>
                    <div className="input-group mt-2">
                      <input
                        role="presentation"
                        autocomplete="off"
                        type="text"
                        name="country"
                        value={"Malaysia"}
                        onChange={onInputChange}
                        className="form-control"
                        id="country"
                        placeholder="Country"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="float-end">
                  <button type="submit" className="btn btn-success me-2">
                    Update
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark me-2"
                    onClick={() => setModalShow(true)}
                  >
                    Change Password
                  </button>
                  <Link to="/customer" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        userId={userData?._id}
        {...userData}
      />
    </Spin>
  );
}
