import React, { useMemo, useState } from "react";
import axios from "axios";
import { Select } from "antd";
import { FiTrash } from "react-icons/fi";
import ReactSelect from "react-select";
import { api, endpoint } from "../../api";

export const DeviceAndParameter = ({
  deviceNumber,
  device,
  parameters = [],
  onRemoveDevice,
  onChangeDevice,
  addedDevices,
  sites,
}) => {
  const [deviceParameters, setDeviceParameters] = useState();
  const [deviceList, setListDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [isLoadingParameters, setIsLoadingParameters] = useState(false);

  const onChangeSite = async (locationId) => {
    onChangeDevice({ idx: deviceNumber - 1, value: "", name: "device" });
    setIsLoadingDevices(true);
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      if (response) {
        setListDevices(response.data?.results);
      }
    } catch (error) {}
    setIsLoadingDevices(false);
  };

  const getDeviceParameters = async (deviceId) => {
    setIsLoadingParameters(true);
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}

    setIsLoadingParameters(false);
  };

  const onChangeDev = (deviceId) => {
    getDeviceParameters(deviceId);
    onChangeDevice({ idx: deviceNumber - 1, value: deviceId, name: "device" });
    onChangeDevice({ idx: deviceNumber - 1, value: [], name: "parameters" });
  };
  const onChangeParm = (value, name) => {
    onChangeDevice({
      idx: deviceNumber - 1,
      value: value.map((v) => v.label),
      name: "parameters",
    });
  };

  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item.name, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const parameterValues = useMemo(() => {
    return parameters.map((item) => {
      return { value: item, label: item };
    });
  }, [parameters]);

  return (
    <div className="row">
      <div className="col-md-3 mt-2">
        <select
          className="form-select"
          name="siteLocations"
          onChange={(e) => onChangeSite(e.target.value)}
          aria-label="Select an admin"
          defaultValue={""}
        >
          <option disabled label="Select Site" value={""}></option>
          {sites?.length &&
            sites.map((item, index) => (
              <option value={item._id} key={index}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      <div className="col-md-3 mt-2">
        <Select
          classNames="mb-2"
          onChange={onChangeDev}
          value={device}
          size="large"
          className="w-100"
          showSearch
          filterOption={(input, option) => {
            console.log(option?.children);
            return (
              option?.children ? option?.children.toLowerCase() : ""
            ).includes(input?.toLowerCase());
          }}
        >
          <Select.Option value="">{`Select Meter ${deviceNumber}`}</Select.Option>
          {/* {deviceList?.length &&
            deviceList.map((item) => (
              <Select.Option value={item?._id} key={item?._id}>
                {item?.name}
              </Select.Option>
            ))} */}

          {deviceList?.length &&
            deviceList.map((item) => (
              <Select.Option value={item._id} key={item._id}>
                {`${item.name} ${
                  item?.customer?.name ? `(${item?.customer?.customerId})` : ""
                }`}
              </Select.Option>
            ))}
        </Select>
      </div>
      <div className="col-md-3 mt-2">
        <ReactSelect
          isMulti
          // defaultInputValue={parameters.map((p) => ({ label: p, value: p }))}
          onChange={(value) => onChangeParm(value, "parameters")}
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          isSearchable={true}
          placeholder="Select Parameters"
          value={parameterValues}
          isLoading={isLoadingParameters}
        />
      </div>
      <div className="col-md-3 mt-2">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            onRemoveDevice(deviceNumber);
          }}
        >
          <FiTrash title="Delete Device" />
        </button>
      </div>
    </div>
  );
};
