import React from "react";
import { Routes, Route } from "react-router-dom";
import AddAdmin from "./pages/Admins/AddAdmin";
import Admins from "./pages/Admins/Admins";
import AdminSites from "./pages/Admins/AdminSites";
import AdminView from "./pages/Admins/AdminView";
import EditAdmin from "./pages/Admins/EditAdmin";
import AnalysisReporting from "./pages/AnalysisReporting/AnalysisReporting";
import DeviceComparison from "./pages/AnalysisReporting/DeviceComparison";
import ElectricityUsageReports from "./pages/AnalysisReporting/ElectricityUsageReports";
import FinanceReports from "./pages/AnalysisReporting/FinanceReports";
import AssignDashboard from "./pages/Dashboard/AssignDashboard";
import CreateDashboard from "./pages/Dashboard/CreateDashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardLayout from "./pages/Dashboard/DashboardLayout";
import DashboardList from "./pages/Dashboard/DashboardList";
import DashboardListView from "./pages/Dashboard/DashboardListView";
// import DashboardTwo from "./pages/Dashboard/DashboardTwo";
import DeviceInformation from "./pages/Dashboard/DeviceInformation";
import AddDevice from "./pages/Devices/AddDevice";
import DeviceData from "./pages/Devices/DeviceData";
import Devices from "./pages/Devices/Devices";
import DeviceView from "./pages/Devices/DeviceView";
import EditDevice from "./pages/Devices/EditDevice";
import CustomerPaymentSummary from "./pages/Ewallet/CustomerPaymentSummary";
import ManualCustomerPayment from "./pages/Ewallet/ManualCustomerPayment";
import MinimumCreditThreshold from "./pages/Ewallet/MinimumCreditThreshold";
import CustomerPaymentHistory from "./pages/Finance/CustomerPaymentHistory";
import OutstandingBillSummary from "./pages/Finance/OutstandingBillSummary";
import RevenueSummary from "./pages/Finance/RevenueSummary";
import AddInstaller from "./pages/Installers/AddInstaller";
import EditInstaller from "./pages/Installers/EditInstaller";
import Installers from "./pages/Installers/Installers";
import InstallerView from "./pages/Installers/InstallerView";
import AlarmSummary from "./pages/Notifications/AlarmSummary";
import AlarmType from "./pages/Notifications/AlarmType";
import AlarmView from "./pages/Notifications/AlarmView";
import CreateAlarm from "./pages/Notifications/CreateAlarm";
import EditAlarm from "./pages/Notifications/EditAlarm";
import RecipientList from "./pages/Notifications/RecipientList";
import EditProfile from "./pages/Profile/EditProfile";
import Profile from "./pages/Profile/Profile";
import BuildingTypes from "./pages/Settings/BuildingTypes";
import DashboardSettings from "./pages/Settings/DashboardSettings";
import ElectricityTariff from "./pages/Settings/ElectricityTariff";
// import ManageFormulas from "./pages/Settings/Formula/ManageFormulas";
import ManageFormulas from "./pages/Settings/Formula/ManageFormulas";
import Parameters from "./pages/Settings/Parameters/Parameters";
import Settings from "./pages/Settings/Settings";
import AddSiteLocation from "./pages/SiteLocations/AddSiteLocation";
import EditSiteLocation from "./pages/SiteLocations/EditSiteLocation";
import SiteDocuments from "./pages/SiteLocations/SiteDocuments";
import SiteLocations from "./pages/SiteLocations/SiteLocations";
import SiteLocationView from "./pages/SiteLocations/SiteLocationView";
import AddSiteUser from "./pages/SiteUsers/AddSiteUser";
import EditSiteUser from "./pages/SiteUsers/EditSiteUser";
import SiteUsers from "./pages/SiteUsers/SiteUsers";
import SiteUserView from "./pages/SiteUsers/SiteUserView";
import AllPrepaidPlans from "./pages/Subscription/AllPrepaidPlans";
import AssignPrepaidPlan from "./pages/Subscription/AssignPrepaidPlan";
import CreateNewPrepaidPlan from "./pages/Subscription/CreateNewPrepaidPlan";
import CustomerSubscriptionSummary from "./pages/Subscription/CustomerSubscriptionSummary";
import EditPrepaidPlan from "./pages/Subscription/EditPrepaidPlan";
import ViewAssignPrepaidPlan from "./pages/Subscription/ViewAssignPrepaidPlan";
import ViewPrepaidPlan from "./pages/Subscription/ViewPrepaidPlan";
import Users from "./pages/Users/Users";
import EditDashboard from "./pages/Dashboard/EditDashboard";
import OnlineCustomerReload from "./pages/Ewallet/OnlineCustomerReload";
import CustomerReport from "./pages/AnalysisReporting/CustomerReport";
import InstallmentReport from "./pages/AnalysisReporting/InstallmentReport";
import Alerts from "./pages/Settings/Alerts";
import { ActivityLog } from "./pages/Settings/ActivityLog";
import BusinessAdmins from "./pages/BusinessAdmin/BusinessAdmins";
import AddBusinessAdmin from "./pages/BusinessAdmin/AddBusinessAdmin";
import BusinessAdminView from "./pages/BusinessAdmin/BusinessAdminView";
import EditBusinessAdmin from "./pages/BusinessAdmin/EditBusinessAdmin";

export default function MyRoutes({ handleFullScreen }) {
  return (
    <Routes>
      {/* Dashboard Informations Route */}
      <Route path="/dashboardList/:id" element={<DashboardListView />} />
      <Route path="/dashboardEdit/:id" element={<EditDashboard />} />
      <Route path="/newDashboard" element={<CreateDashboard />} />
      <Route path="/dashboardList" element={<DashboardList />} />
      <Route path="/assignDashboard" element={<AssignDashboard />} />
      <Route path="/liveDashbroad" element={<DashboardLayout />} />
      <Route
        path="/"
        element={<Dashboard handleFullScreen={handleFullScreen} />}
      />
      {/* <Route
        path="/2"
        element={<DashboardTwo handleFullScreen={handleFullScreen} />}
      /> */}
      {/* user */}
      <Route path="/users" element={<Users />} />
      {/* Device Informations Route */}
      <Route path="/device-details" element={<DeviceInformation />} />
      {/* Installer related Routes */}
      <Route path="/installers" element={<Installers />} />
      <Route path="/add-installer" element={<AddInstaller />} />
      <Route path="/installer/:installerId" element={<InstallerView />} />
      <Route path="/edit-installer/:installerId" element={<EditInstaller />} />
      {/* Admin related Routes */}
      <Route path="/site-admins" element={<Admins />} />
      <Route path="/add-site-admin" element={<AddAdmin />} />
      <Route path="/site-admin/:adminId" element={<AdminView />} />
      <Route path="/edit-admin/:adminId" element={<EditAdmin />} />
      <Route path="/admin-sites/:adminId" element={<AdminSites />} />
      {/* Business Admin related Routes */}
      <Route path="/business-admins" element={<BusinessAdmins />} />
      <Route path="/add-business-admin" element={<AddBusinessAdmin />} />
      <Route path="/business-admin/:adminId" element={<BusinessAdminView />} />
      <Route path="/edit-business-admin/:adminId" element={<EditBusinessAdmin />} />
      <Route path="/admin-businesss/:adminId" element={<AdminSites />} />
      {/* Site Location related routes */}
      <Route path="/site-locations" element={<SiteLocations />} />
      <Route path="/add-site-location" element={<AddSiteLocation />} />
      <Route
        path="/site-location/:siteLocationId"
        element={<SiteLocationView />}
      />
      <Route
        path="/edit-site-location/:siteLocationId"
        element={<EditSiteLocation />}
      />
      <Route
        path="/site-document/:siteLocationId"
        element={<SiteDocuments />}
      />
      {/* Site users related routes */}
      <Route path="/customer" element={<SiteUsers />} />
      <Route path="/add-customer" element={<AddSiteUser />} />
      <Route path="/customer/:userId" element={<SiteUserView />} />
      <Route path="/edit-customer/:userId" element={<EditSiteUser />} />
      {/* Meter related routes */} {/* Device as Meter*/}
      <Route path="/meters" element={<Devices />} />
      <Route path="/add-meters" element={<AddDevice />} />
      <Route path="/meters/:meterId" element={<DeviceView />} />
      <Route path="/edit-meter/:deviceId" element={<EditDevice />} />
      <Route path="/meters-data/:deviceId" element={<DeviceData />} />
      {/* Subcription Related Routes */}
      <Route
        path="/create-new-prepaid-plan"
        element={<CreateNewPrepaidPlan />}
      />
      <Route path="/all-prepaid-plans" element={<AllPrepaidPlans />} />
      <Route path="/view-prepaid-plan/:planId" element={<ViewPrepaidPlan />} />
      <Route path="/edit-prepaid-plan/:planId" element={<EditPrepaidPlan />} />
      <Route path="/assign-prepaid-plan" element={<AssignPrepaidPlan />} />
      <Route
        path="/view-assign-plan/:planId"
        element={<ViewAssignPrepaidPlan />}
      />
      <Route
        path="/customer-subscription-summary"
        element={<CustomerSubscriptionSummary />}
      />
      {/* E-Wallet Related Routes*/}
      <Route
        path="/customer-payment-summary"
        element={<CustomerPaymentSummary />}
      />
      <Route
        path="/minimum-credit-threshold"
        element={<MinimumCreditThreshold />}
      />
      <Route
        path="/manual-customer-payment"
        element={<ManualCustomerPayment />}
      />
      <Route
        path="/online-customer-reload"
        element={<OnlineCustomerReload />}
      />
      {/* Finance Related Routes*/}
      <Route path="/revenue-summary" element={<RevenueSummary />} />
      <Route
        path="/customer-payment-history"
        element={<CustomerPaymentHistory />}
      />
      <Route
        path="/outstanding-bill-summary"
        element={<OutstandingBillSummary />}
      />
      {/* Analysis &Reporting related routes */}
      <Route path="/parameter-comparison" element={<AnalysisReporting />} />
      <Route path="/device-comparison" element={<DeviceComparison />} />
      <Route
        path="/electricity-usage-reports"
        element={<ElectricityUsageReports />}
      />
      <Route path="/finance-reports" element={<FinanceReports />} />
      <Route path="/customer-billing-reports" element={<CustomerReport />} />
      <Route path="/installment-reports" element={<InstallmentReport />} />
      {/* Settings related Routes */}
      <Route path="/settings" element={<Settings />} />
      <Route path="/alerts" element={<Alerts />} />
      <Route path="/activity-log" element={<ActivityLog />} />
      <Route path="/building-background-types" element={<BuildingTypes />} />
      <Route path="/electricity-tariff" element={<ElectricityTariff />} />
      <Route path="/dashboard-settings" element={<DashboardSettings />} />
      <Route path="/parameters" element={<Parameters />} />
      <Route path="/formulas" element={<ManageFormulas />} />
      {/* Notification related routes */}
      <Route path="/alarm-summary" element={<AlarmSummary />} />
      <Route path="/create-alarm-type" element={<AlarmType />} />
      <Route path="/create-alarm" element={<CreateAlarm />} />
      <Route path="/recipient-list" element={<RecipientList />} />
      <Route path="/alarm-view/:alarmId" element={<AlarmView />} />
      <Route path="/edit-alarm/:alarmId" element={<EditAlarm />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
    </Routes>
  );
}
