import { PDFDownloadLink } from "@react-pdf/renderer";
import { DatePicker, notification, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import appConfig from "../../appConfig";
import { VsssbSelect } from "../../components/Common/VsssbSelect";
import { numberFormater } from "../../utils";
import { QrCodeGenerator } from "../SiteUsers/QrCodeGenerator";
import { ElectricityUsageReportPdf } from "./pdf/ElectricityUsageReportPdf";

const ElectricityUsageReports = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const [siteLocation, setSiteLocation] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async (siteId) => {
    if (!siteId) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(`user/siteUser/${siteId}/user`, {
        withCredentials: true,
        // params: { onlyActive: true },
      });
      if (response) {
        setUsers(response?.data || []);
      }
    } catch (error) {
      setUsers([]);
    }
  };

  const onChangeSearch = ({ name, value }) => {
    if (!value) {
      setSearchValue({ [name]: "" });
    } else if (name === "site") {
      setSearchValue({ ...searchValue, site: value, customerId: "" });
      getUser(value);
    } else {
      setSearchValue({ ...searchValue, customerId: value });
    }
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocation(response.data);
    } catch (error) {}
  };

  const onChangeMonth = (date, dateString) => {
    // console.log(date, dateString);
    setSearchValue({ ...searchValue, cycleDate: dateString });
  };
  const onCreateReport = async (searchParams) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get("/report/get-electricity-usage-report", {
        params: searchParams,
      });
      setReport(data);
      console.log(data);
    } catch (error) {
      notification.warning({
        message: error?.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSiteLocation();
    document.title = `${appConfig.name} - Electricity Usage Reports`;
    if (userDetails?.role === "user") {
      let data = {
        site: userDetails?.site[0]?._id || "",
        customerId: userDetails?._id,
        cycleDate: moment().format("MMM, YYYY"),
      };
      setSearchValue(data);
      onCreateReport(data);
    }
    // eslint-disable-next-line
  }, []);

  const getBinaryQRCode = () => {
    const canvas = document.getElementById(report?._id);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      return pngUrl;
    }
  };

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">
                    Electricity Usage Reports
                  </span>
                </div>
              </div>
              <div className="row mb-1">
                {userDetails.role !== "user" && (
                  <>
                    <div className="col-md-3">
                      <VsssbSelect
                        placeholder="Select Location"
                        value={searchValue.site}
                        onChange={(value) => {
                          onChangeSearch({ name: "site", value });
                        }}
                        options={siteLocation}
                      />
                    </div>
                    <div className="col-md-3">
                      <VsssbSelect
                        placeholder="Select Customer"
                        value={searchValue.customerId}
                        onChange={(value) => {
                          onChangeSearch({ name: "customerId", value });
                        }}
                        options={users}
                      />
                    </div>
                  </>
                )}
                <div
                  className={
                    userDetails?.role === "user" ? "col-md-3" : "col-md-2"
                  }
                >
                  <DatePicker
                    onChange={onChangeMonth}
                    picker="month"
                    format={"MMM, YYYY"}
                    value={
                      searchValue?.cycleDate
                        ? dayjs(searchValue?.cycleDate, "MMM, YYYY")
                        : null
                    }
                    allowClear
                    size="large"
                    style={{ width: "100%" }}
                    disabledDate={disabledDate}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={() => onCreateReport(searchValue)}
                    disabled={
                      !searchValue?.customerId || !searchValue?.cycleDate
                    }
                  >
                    Search
                  </Button>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 mt-4">
                  <label className="az-content-label mb-3">
                    Customer Information
                  </label>
                  {customerInformation.map(({ label, feildName }, idx) => (
                    <div
                      className="d-flex justify-content-between mb-2"
                      key={idx}
                    >
                      <p style={{ fontWeight: "600", width: "50%" }}>{label}</p>
                      <p
                        className="form-control"
                        style={{
                          // fontWeight: "600",
                          width: "50%",
                          minHeight: "38px",
                          height: "auto",
                          backgroundColor: "#e9ecef",
                        }}
                      >
                        {report[feildName]}
                      </p>
                    </div>
                  ))}

                  <div className=" mt-4">
                    <label className="az-content-label mb-3">
                      Customer Deposit Information
                    </label>
                    {(report?.planDetails?.depositType === "No"
                      ? customerDepositInfoUpfront
                      : report?.planDetails?.depositType === "N/A"
                      ? customerDepositInfoNA
                      : !report?.planDetails?.depositType
                      ? customerDepositInfoUpfront
                      : customerDepositInfo
                    ).map(({ label }, idx) => (
                      <div
                        className="d-flex justify-content-between mb-2"
                        key={idx}
                      >
                        <p style={{ fontWeight: "600", width: "50%" }}>
                          {label}
                        </p>
                        <p
                          className="form-control"
                          style={{
                            // fontWeight: "600",
                            width: "50%",
                            minHeight: "38px",
                            height: "auto",
                            backgroundColor: "#e9ecef",
                          }}
                        >
                          {getDepositeDetails({
                            plan: report.planDetails,
                            label,
                            upfrontDetails: report?.depositUpfront,
                          })}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className=" mt-4">
                    <label className="az-content-label mb-3">
                      Customer Internal Wiring Information
                    </label>
                    {(report?.planDetails?.internalWiringType === "No"
                      ? customerWringInfoUpfront
                      : report?.planDetails?.internalWiringType === "N/A"
                      ? customerWringInfoNa
                      : !report?.planDetails?.internalWiringType
                      ? customerWringInfoUpfront
                      : customerWringInfo
                    ).map(({ label }, idx) => (
                      <div
                        className="d-flex justify-content-between mb-2"
                        key={idx}
                      >
                        <p style={{ fontWeight: "600", width: "50%" }}>
                          {label}
                        </p>
                        <p
                          className="form-control"
                          style={{
                            // fontWeight: "600",
                            width: "50%",
                            minHeight: "38px",
                            height: "auto",
                            backgroundColor: "#e9ecef",
                          }}
                        >
                          {getWiringDetails({
                            plan: report.planDetails,
                            label,
                            upfrontDetails: report?.internalWiringUpfront,
                          })}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <label className="az-content-label">
                    Electricity Usage Information
                  </label>
                  {/* table */}
                  <table className="reportTable">
                    <tr>
                      <th colSpan={2}>Electricity Usage</th>
                    </tr>
                    {electricityUsage.map(({ label, feildName }, idx) => (
                      <tr key={idx}>
                        <td width={"50%"}>{label}</td>
                        <td>{numberFormater(report[feildName])}</td>
                      </tr>
                    ))}
                  </table>
                  <table className="reportTable">
                    <tr>
                      <th id="border-top-0" colSpan={2}>
                        Other Charges
                      </th>
                    </tr>
                    {otherCharges.map(({ label, feildName }, idx) => (
                      <tr key={idx}>
                        <td width={"50%"}>{label}</td>
                        <td>
                          {idx === otherCharges.length - 1 &&
                          Number(report[feildName])
                            ? "- "
                            : ""}
                          {numberFormater(report[feildName])}
                        </td>
                      </tr>
                    ))}
                  </table>
                  <table className="reportTable mt-5">
                    {/* <tr className="opacity-0 border-0">
                      <th colSpan={2}></th>
                    </tr> */}
                    {othreFees.map(({ label, bold, feildName }, idx) => (
                      <tr key={idx}>
                        <td width={"50%"} className={bold ? "fw-bold" : ""}>
                          {label}
                        </td>
                        <td className={bold ? "fw-bold" : ""}>
                          {numberFormater(report[feildName])}
                        </td>
                      </tr>
                    ))}
                  </table>
                  {/* make the qr code */}
                  <QrCodeGenerator
                    customer={{ _id: report?._id }}
                    level="L"
                    hideDownloadIcon={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {/* table end */}
                  <div className="float-end mt-5">
                    <button
                      type="submit"
                      className={
                        report?.customerId
                          ? "btn btn-success me-2"
                          : "btn btn-success me-2 disable_area"
                      }
                    >
                      <PDFDownloadLink
                        document={
                          <ElectricityUsageReportPdf
                            report={report}
                            base64Image={getBinaryQRCode}
                          />
                        }
                        fileName={`${report?.customerId} - Electricity Usage Report ${report?.cycleDate}`}
                      >
                        <span style={{ color: "#fff" }}>Download PDF</span>
                      </PDFDownloadLink>
                    </button>
                    <button type="button" className="btn btn-secondary">
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ElectricityUsageReports;

const customerInformation = [
  { label: "Report Generation Date", feildName: "createdAt" },
  { label: "Monthly Cycle Selected", feildName: "cycleDate" },
  { label: "Customer Name", feildName: "cusotmerName" },
  { label: "Customer ID", feildName: "customerId" },
  { label: "Meter ID", feildName: "meterId" },
  { label: "Current Balance in Account (RM)", feildName: "currentBalance" },
  { label: "Site Name", feildName: "siteName" },
  { label: "Customer Address", feildName: "pdfCustomerAddress" },
];

const customerDepositInfo = [
  { label: "Type for Deposit" },
  { label: "Installment Start Date" },
  { label: "Installment End Date" },
  { label: "Installment Remaining Period (Months)" },
  { label: "Total Installment Payment Made (RM)" },
  { label: "Balance For Installment Payment (RM)" },
];
const customerDepositInfoUpfront = [
  { label: "Type for Deposit" },
  { label: "Amount Paid (RM)" },
  { label: "Date of Payment" },
];
const customerDepositInfoNA = [{ label: "Type for Deposit" }];

const getDepositeDetails = ({ plan, label, upfrontDetails }) => {
  switch (label) {
    case "Type for Deposit":
      return plan?.depositType
        ? plan?.depositType === "No"
          ? "Upfront"
          : plan?.depositType === "Yes"
          ? "Monthly"
          : "N/A"
        : "Unassigned";
    case "Amount Paid (RM)":
      return upfrontDetails?.createdAt
        ? (upfrontDetails?.reloadedBalance || 0).toFixed(2)
        : "0.00";
    case "Date of Payment":
      return upfrontDetails?.createdAt
        ? moment(upfrontDetails.createdAt).format("DD/MM/YYYY")
        : "N/A";
    // installments info
    case customerDepositInfo[1].label:
      // case "Installment Start Date":
      return plan?.depositInstallmentPeriod
        ? "7/" + plan?.depositInstallmentPeriod?.split("-")?.[0]?.trim()
        : "";
    case customerDepositInfo[2].label:
      // case "Installment End Date":
      return plan?.depositInstallmentPeriod
        ? "7/" + plan?.depositInstallmentPeriod?.split("-")?.[1]?.trim()
        : "";
    case customerDepositInfo[3].label:
      return typeof plan?.depositInstallmentCount === "number"
        ? 48 - plan?.depositInstallmentCount
        : "";

    case customerDepositInfo[4].label:
      return typeof plan?.depositInstallmentCount === "number"
        ? numberFormater(plan?.depositInstallmentCount * 25)
        : "";
    case customerDepositInfo[5].label:
      return typeof plan?.depositInstallmentCount === "number"
        ? numberFormater((12 - plan?.depositInstallmentCount) * 25)
        : "";
    default:
      break;
  }
};

const getWiringDetails = ({ plan, label, upfrontDetails }) => {
  switch (label) {
    case customerWringInfo[0].label:
      return plan?.internalWiringType
        ? plan?.internalWiringType === "No"
          ? "Upfront"
          : plan?.internalWiringType === "Yes"
          ? "Monthly"
          : "N/A"
        : "Unassigned";
    case "Amount Paid (RM)":
      return upfrontDetails?.createdAt
        ? (upfrontDetails?.reloadedBalance || 0).toFixed(2)
        : "0.00";
    case "Date of Payment":
      return upfrontDetails?.createdAt
        ? moment(upfrontDetails.createdAt).format("DD/MM/YYYY")
        : "N/A";
    // installments info
    case "Installment Start Date":
      return plan?.internalInstallmentPeriod
        ? "7/" + plan?.internalInstallmentPeriod?.split("-")?.[0]?.trim()
        : "";
    case "Installment End Date":
      return plan?.internalInstallmentPeriod
        ? "7/" + plan?.internalInstallmentPeriod?.split("-")?.[1]?.trim()
        : "";
    case customerWringInfo[3].label:
      return typeof plan?.internalInstallmentCount === "number"
        ? 48 - plan?.internalInstallmentCount
        : "";
    case customerWringInfo[4].label:
      return typeof plan?.internalInstallmentCount === "number"
        ? numberFormater(plan?.internalInstallmentCount * 17)
        : "";
    case customerWringInfo[5].label:
      return typeof plan?.internalInstallmentCount === "number"
        ? numberFormater((48 - plan?.internalInstallmentCount) * 17)
        : "";
    default:
      break;
  }
};

const customerWringInfo = [
  { label: "Type for Internal Wiring" },
  { label: "Installment Start Date" },
  { label: "Installment End Date" },
  { label: "Installment Remaining Period (Months)" },
  { label: "Total Installment Payment Made (RM)" },
  { label: "Balance for Installment Payment (RM)" },
];
const customerWringInfoUpfront = [
  { label: "Type for Internal Wiring" },
  { label: "Amount Paid (RM)" },
  { label: "Date of Payment" },
];
const customerWringInfoNa = [{ label: "Type for Internal Wiring" }];

const electricityUsage = [
  { label: "Usage This Month (kWh)", feildName: "monthlyKWHUsed" },
  { label: "Total Usage Cost (RM)", feildName: "totalUsageCost" },
];
const otherCharges = [
  { label: "Deposit Installment (RM)", feildName: "depositInstallmentFee" },
  {
    label: "Internal Wiring Installment (RM)",
    feildName: "internalWiringInstallment",
  },
  {
    label: "Monthly Maintenance Fees (RM)",
    feildName: "monthlyMaintenanceFee",
  },
  { label: "KWTBB (RM)", feildName: "kwtbbRate" },
  {
    label: "Late Registration Compound (RM)",
    feildName: "lateRegistrationCompound",
  },
  {
    label: "Reconnection Fee Charges (RM)",
    feildName: "reconnectionFeeCharges",
  },
  { label: "Admin Fees (RM)", feildName: "adminFees" },
  { label: "Repair Fees (RM)", feildName: "repairServices" },
  { label: "Deposit Refund (RM)", feildName: "customerDepositRefund" },
];
const othreFees = [
  { label: "Total Fee (RM)", feildName: "subTotal" },
  { label: "Service Tax", feildName: "serviceTax" },
  { label: "Total Charge (RM)", feildName: "grandTotal", bold: true },
];

// is disable or not
const disabledDate = (current) => {
  // return false
  let date = new Date();
  const currentDate = moment(date).format("MMM, YYYY");
  const monentDate = current.format("MMM, YYYY");
  const dayOfCrntMonth = date.getDate();
  const dayFlag = dayOfCrntMonth < 7 && currentDate === monentDate;
  const dateCurrent = Number(moment(date).format("x"));
  return (current && current.valueOf() > dateCurrent) || dayFlag;
};
