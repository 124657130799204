import { TimePicker, Tag, Spin } from "antd";
import axios from "axios";
import moment from "moment/moment";
import React, { memo } from "react";
import { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { api, endpoint } from "../../api";
import MultipleLocation from "./MultipleLocation";
import { BiPlus } from 'react-icons/bi';

const AddItemModal = memo(({ show, setShow, allData, setAllData, editData, mode, id }) => {
  const initialState = {
    locations: [],
    chartType: "",
    component: '',
    chartTitle: '',
    operator: '',
    xAxis: {},        //  type, unit, range  
    option: '',
    formula: '',
    countFrom: '',
    countTo: '',
  }

  const initLocation = [{
    location: '',
    device: '',
    systemParameters: '',
    yAxis: '',
  }]



  const { RangePicker } = TimePicker;

  const [deviceList, setDeviceList] = useState([]);
  const [siteLocations, setSiteLocations] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [itemData, setItemData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false)
  const [multipleLocation, setMultipleLocation] = useState(initLocation)

  const {
    chartType,
    component,
    chartTitle,
    operator,
    xAxis,
    option,
    formula,
    countFrom,
    countTo,

  } = itemData

  const Today = moment().day();

  const handleClose = () => {
    setShow(false)
    setItemData(initialState)
    setMultipleLocation(initLocation)
  };

  const handleChange = (e) => {
    if (e.target.name === 'component') {
      return setItemData({
        ...initialState,
        chartType: itemData.chartType,
        _id: itemData._id,
        [e.target.name]: e.target.value,
      });
    }
    setItemData({
      ...itemData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    setItemData({
      ...initialState,
      [e.target.name]: e.target.value,
      component: '',
    });
  };

  // ***** api ******* //
  // get device
  const getDevice = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(endpoint.get_devices, {
        withCredentials: true,
      });
      if (response) {
        setDeviceList(response.data?.results);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  };

  // get site locations
  const getSiteLocations = async (userDetails) => {
    try {
      const response = await axios.get(`/site-location`, { withCredentials: true });
      if (response) {
        setSiteLocations(response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)));
      }


    } catch (error) { }
  };

  // get formulas
  const getFormulas = async () => {
    const response = await axios.get(`/formula`, {
      withCredentials: true,
      params: {
        type: "system",
      },
    });
    if (response) {
      setFormulas(response.data);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getDevice()
      getSiteLocations()
      getFormulas()
    }
  }, []);


  useEffect(() => {
    if (mode === 'edit' && id && deviceList.length > 0) {
      const editData = allData.find(data => data._id === id);
      setItemData(editData);
    }
  }, [show, isLoading])

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const data = { ...itemData, locations: multipleLocation }
    data._id = new Date().getTime()

    setAllData([...allData, data])
    setShow(false);
    setItemData(initialState)
    setMultipleLocation(initLocation)
  };

  // have to change according new data
  const handleEdit = e => {
    e.preventDefault()
    e.stopPropagation()
    itemData.locations = multipleLocation
    const updated = allData.map(d => {
      if (d._id === itemData._id) {
        d = itemData
      }
      return d
    })
    setAllData(updated)
    setShow(false)
  }


  useEffect(() => {
    if (mode === 'edit' && itemData.locations.length > 0) {
      setMultipleLocation(itemData.locations)
    }

  }, [itemData.locations])

  return (
    <div>
      <Modal className="mt-5 mt-md-0" show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Spin spinning={isLoading}>
          <Modal.Body>
            <Form onSubmit={mode === 'edit' ? handleEdit : handleSubmit}>
              {/* type */}
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold">Type</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  name="chartType"
                  value={chartType}
                  onChange={(e) => {
                    handleTypeChange(e)
                    setMultipleLocation(initLocation)
                  }}
                >
                  <option value="">Select Type</option>
                  <option value="Location Chart">Location Chart</option>
                  <option value="Device Chart">Device Chart</option>
                </Form.Select>
              </Form.Group>

              {/* components */}
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold">Component</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  name="component"
                  value={component}
                  onChange={handleChange}
                >
                  <option value="">Select Component</option>
                  <option value="bar">Bar Graph</option>
                  <option value="line">Line Graph</option>
                  <option value="pie">Pie Chart</option>
                  {chartType === "Device Chart" && <option value="counter">Counter</option>}
                </Form.Select>
              </Form.Group>

              {/* title */}
              <Form.Group className="mb-2">
                <Form.Label className="fw-bold">Title</Form.Label>
                <Form.Control
                  required
                  className="text-secondary"
                  type="text"
                  name="chartTitle"
                  value={chartTitle}
                  onChange={handleChange}
                  placeholder="Enter Chart Title"
                />
              </Form.Group>


              {/* for device chart */}

              {chartType === "Device Chart" && (
                <>
                  {
                    multipleLocation.map((location, ind) => {
                      const props = {
                        ind,
                        location,
                        component,
                        siteLocations,
                        multipleLocation,
                        setMultipleLocation,
                      }
                      return <MultipleLocation key={ind} {...props} />
                    })
                  }

                  {/* x axis */}
                  {
                    component === 'bar' || component === 'line' ? (
                      <Row>
                        <Col xs={6}>
                          <Form.Group className="mb-2">
                            <Form.Label className="fw-bold">
                              Select X-Axis
                            </Form.Label>
                            <Form.Select
                              required
                              className="text-secondary"
                              value={xAxis?.unit}
                              name="unit"
                              onChange={e => setItemData({
                                ...itemData,
                                xAxis: {
                                  type: 'time',
                                  unit: e.target.value,
                                }
                              })}
                            >
                              <option value="">Select Period</option>
                              <option value="Daily">Daily</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Yearly">Yearly</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        {xAxis?.unit === "Daily" && (
                          <Col xs={6}>
                            <Form.Group className="mb-2">
                              <Form.Label className="fw-bold">Unit</Form.Label>
                              <RangePicker
                                presets={{ label: 'date' }}
                                className='w-100'
                                showTime={{ format: 'HH:mm' }}
                                placeholder={['Select Start Date | Time', 'Select End Date | Time']}
                                format="HH:mm"
                                value={xAxis?.value}
                                onChange={(value, dateString) => {
                                  setItemData({
                                    ...itemData,
                                    xAxis: {
                                      ...itemData?.xAxis,
                                      range: dateString[0] + '-' + dateString[1],
                                      value,
                                    }
                                  })
                                  // setItemData({ ...itemData, xDailyUnit: dateString[0] + '-' + dateString[1] });
                                }}
                              />
                            </Form.Group>
                          </Col>
                        )}
                        {xAxis?.unit === "Weekly" && (
                          <Col xs={6}>
                            <Form.Group className="mb-2">
                              <Form.Label className="fw-bold">Day</Form.Label>
                              <Form.Select
                                required
                                className="text-secondary"
                                name="xWeeklyDay"
                                value={xAxis.range}
                                onChange={e => setItemData({
                                  ...itemData,
                                  xAxis: {
                                    ...itemData?.xAxis,
                                    range: e.target.value,
                                  }
                                })}
                              >
                                <option value="">Select Day</option>
                                <option value="Saturday">
                                  Saturday {Today === 6 && "(Today)"}
                                </option>
                                <option value="Sunday">
                                  Sunday {Today === 0 && "(Today)"}
                                </option>
                                <option value="Monday">
                                  Monday {Today === 1 && "(Today)"}
                                </option>
                                <option value="Tuesday">
                                  Tuesday {Today === 2 && "(Today)"}
                                </option>
                                <option value="Wednesday">
                                  Wednesday {Today === 3 && "(Today)"}
                                </option>
                                <option value="Thursday">
                                  Thursday {Today === 4 && "(Today)"}
                                </option>
                                <option value="Friday">
                                  Friday {Today === 6 && "(Today)"}
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        )}
                        {xAxis?.unit === "Monthly" && (
                          <Col xs={6}>
                            <Form.Group className="mb-2">
                              <Form.Label className="fw-bold">Month</Form.Label>
                              <Form.Control
                                required
                                type="month"
                                className="text-secondary"
                                name="xMonth"
                                value={xAxis.range}
                                onChange={e => setItemData({
                                  ...itemData,
                                  xAxis: {
                                    ...itemData?.xAxis,
                                    range: e.target.value,
                                  }
                                })}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        )}
                        {xAxis?.unit === "Yearly" && (
                          <Col xs={6}>
                            <Form.Group className="mb-2">
                              <Form.Label className="fw-bold">Year</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="YYYY"
                                min="1900"
                                max="2024"
                                className="text-secondary"
                                name="xYear"
                                value={xAxis.range}
                                onChange={e => setItemData({
                                  ...itemData,
                                  xAxis: {
                                    ...itemData?.xAxis,
                                    range: e.target.value,
                                  }
                                })}
                              ></Form.Control>
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                    ) : ''
                  }

                </>
              )}


              {chartType === "Location Chart" && (
                <>
                  <hr />
                  {
                    multipleLocation.map((location, ind) => {
                      const props = {
                        ind,
                        location,
                        component,
                        siteLocations,
                        multipleLocation,
                        setMultipleLocation,
                      }
                      return (
                        <React.Fragment key={ind}>
                          <MultipleLocation {...props} />
                          <hr />
                        </React.Fragment>
                      )
                    })
                  }

                  <Button
                    className='mb-2 py-2 rounded-circle'
                    onClick={() => setMultipleLocation([...multipleLocation, ...initLocation])}> <BiPlus /> </Button>

                  {/* Operator */}
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold">Select Operator</Form.Label>
                    <Form.Select
                      required
                      className="text-secondary"
                      name="operator"
                      value={operator}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Select Operator</option>
                      <option value="max">Maximum</option>
                      <option value="min">Minimum</option>
                      <option value="avg">Average</option>
                      <option value="sum">Sum</option>
                    </Form.Select>
                  </Form.Group>

                  {/* x axis */}
                  {
                    component === 'bar' || component === 'line' ? (
                      <Row className='my-3'>
                        <Col xs={12}>
                          <span className="fw-bold d-inline-block me-2">X-Axis :</span>
                          {
                            siteLocations.filter((item) => multipleLocation.some((filterItem) => filterItem.location === item._id)).map((item, ind) => <Tag key={ind}>  {item.name} </Tag>)
                          }
                        </Col>
                      </Row>
                    ) : ''
                  }


                </>
              )}

              {itemData?.component === "counter" && (
                <>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold">Option</Form.Label>
                    <Form.Control
                      required
                      className="text-secondary"
                      type="number"
                      name="option"
                      value={option}
                      onChange={handleChange}
                      placeholder="Total Current"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2">
                    <Form.Label className="fw-bold">Formula</Form.Label>
                    <Form.Select
                      required
                      className="text-secondary"
                      name="formula"
                      value={formula}
                      onChange={handleChange}
                    >
                      <option value="">Select one</option>
                      {formulas &&
                        formulas.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                  <Row>
                    <Col xs={6}>
                      <Form.Group className="mb-2">
                        <Form.Label className="fw-bold">From</Form.Label>
                        <Form.Control
                          required
                          className="text-secondary"
                          type="date"
                          name="countFrom"
                          value={countFrom}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Form.Group className="mb-2">
                        <Form.Label className="fw-bold">To</Form.Label>
                        <Form.Control
                          required
                          className="text-secondary"
                          type="date"
                          name="countTo"
                          value={countTo}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}

              <div className=" mt-4" >
                <Button type="submit" className="px-4 " variant="success">
                  {mode === 'edit' ? 'Edit' : 'Create'}
                </Button>

              </div>
            </Form>
          </Modal.Body>
        </Spin>
      </Modal>
    </div >
  );
});

export default AddItemModal;
