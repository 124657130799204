import React from "react";
import QRCode from "qrcode.react";
import { AiOutlineQrcode } from "react-icons/ai";

export const QrCodeGenerator = ({
  customer,
  showQrCode,
  hideDownloadIcon,
  level,
}) => {
  const downloadQR = () => {
    const canvas = document.getElementById(customer?._id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${customer?.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const downloadBtn = (
    <a
      onClick={downloadQR}
      className="btn btn-warning me-1"
      title="Download QR code"
    >
      <AiOutlineQrcode />
    </a>
  );

  return (
    <>
      <div className="position-relative d-inline-block qrCode">
        <QRCode
          id={customer?._id}
          value={`${process.env.REACT_APP_API_URL}/qr-sign/${customer?._id}`}
          size={150}
          level={level || "M"}
          includeMargin={false}
          style={{
            display: showQrCode ? "block" : "none",
          }}
        />
        <div className="qrCode_overlay">{downloadBtn}</div>
      </div>
      {hideDownloadIcon ? "" : downloadBtn}
    </>
  );
};
