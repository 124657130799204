import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { FiTrash, FiEye, FiEdit } from "react-icons/fi";
import { Spin } from "antd";
import { showAlert } from "../../utils";

export default function SiteLocations() {
  const [siteLocations, setSiteLocations] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [isLoading, setIsLoading] = useState(false);

  const getSiteLocations = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(response.data);
      }
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = "IVIS PMS - All Site Locations";
    getSiteLocations();
  }, [userDetails]);

  const columns = [
    {
      name: "No",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "Site Name",
      selector: (row) => row.name,
    },
    {
      name: "State",
      selector: (row) => row.locationState,
    },
    // {
    //   name: 'Contact Person',
    //   selector: row => (row.contactPersonName),
    // },
    // {
    //   name: 'Phone',
    //   cell: row => <>+6{row.contactPersonPhone}</>,
    //   selector: row => (row.contactPersonPhone),
    //   width: "140px"
    // },
    {
      name: "Admin",
      cell: (row) => (
        <div>
          {row?.admin?.map(({ _id, name }) => (
            <div className="d-block" key={_id}>
              {name}
            </div>
          ))}
        </div>
      ),
      // selector: (row) => row.admin.name,
    },
    {
      name: "Installer",
      cell: (row) => (
        <div>
          {row?.installer.map((item) => (
            <div className="d-block">{item?.name}</div>
          ))}
        </div>
      ),
      // selector: (row) => row.installer.name,
    },
    {
      name: "Date Created",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "125px",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link
                      to={`/site-location/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                    <Link
                      to={`/edit-site-location/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit title="Edit" />
                    </Link>
                    {/* <Link to={`/site-document/` + row._id} className='btn btn-warning me-1'><FiPaperclip title="Documents" /></Link> */}
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteSiteLocation(row._id)}
                    >
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "installer":
                return (
                  <div className="actions">
                    <Link
                      to={`/site-location/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link
                      to={`/site-location/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                    <Link
                      to={`/edit-site-location/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit title="Edit" />
                    </Link>
                    {/* <Link to={`/site-document/` + row._id} className='btn btn-warning me-1'><FiPaperclip title="Documents" /></Link> */}
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteSiteLocation(row._id)}
                    >
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "user":
                return <div className="actions"></div>;
              case "public":
                return <div className="actions"></div>;
              default:
                return;
            }
          })()}
        </div>
      ),
      // grow: 1,
      center: "yes",
    },
  ];

  const deleteSiteLocation = async (siteLocationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this site location?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/site-location/` + siteLocationId, { withCredentials: true })
          .then((res) => {
            console.log(res);
            getSiteLocations();
            showAlert(res.data);
          })
          .catch((err) => {
            showAlert(err.response?.data);
          });
      }
    });
  };
  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">
                    Manage Site Location
                  </label>
                  <span className="d-block py-2">All Site Locations</span>
                </div>
                <div className="col-md-4 d-flex justify-content-end p-0">
                  <Link
                    to="/add-site-location"
                    className="btn btn-az-primary btn-md"
                  >
                    Add Site Location
                  </Link>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={siteLocations}
                persistTableHead={true}
                pagination
                striped
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
