import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit, FiTrash, FiUserX } from "react-icons/fi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { showAlert } from "../../utils";

const AllPrepaidPlans = () => {
  const [prepaidPlan, setPrepaidPlan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPrepaidPlan = async () => {
    const response = await axios.get(`/subscription`, {
      withCredentials: true,
    });
    if (response) {
      setIsLoading(false);
      setPrepaidPlan(
        response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    document.title = "IVIS PMS - All Prepaid Plan";
    getPrepaidPlan();
  }, []);

  const columns = [
    {
      name: "No.",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
      center: true,
    },
    {
      name: "Plan Name",
      cell: (row) => row.name || "--",
      center: true,
    },
    {
      name: "Maintenance Fees (RM)",
      cell: (row) => Number(row?.monthlyMaintenanceFee || 0).toFixed(2),
      center: true,
    },
    {
      name: "Tarrif (RM)",
      selector: (row) => Number(row?.baseRate || 0).toFixed(2),
      center: true,
    },
    {
      name: "KWTBB (%)",
      selector: (row) => Number(row?.kwtbbRate || 0).toFixed(2),
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link
            to={`/view-prepaid-plan/` + row._id}
            className="btn btn-success me-1"
            title="View Profile"
          >
            <AiOutlineEye />
          </Link>
          <Link
            to={`/edit-prepaid-plan/` + row._id}
            className="btn btn-info me-1"
            title="Edit Profile"
          >
            <FiEdit />
          </Link>
          <button
            className="btn btn-danger"
            title="Delete"
            onClick={() => deletePlan(row._id)}
          >
            <FiTrash />
          </button>
        </div>
      ),
      center: "yes",
    },
  ];

  const deletePlan = async (subscriptionId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This prepaid plan will be deleted and could affect users who are assigned to it.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/subscription/` + subscriptionId, {
            withCredentials: true,
          })
          .then((res) => {
            getPrepaidPlan();
            showAlert(res.data)
          }).catch(err => {
            showAlert(err.response?.data)
          })
      }
    });
  };

  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="">
                <label className="az-content-label">Manage Prepaid Plan</label>
                <span className="d-block py-2">All Prepaid Plan</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {isLoading && <Spinner animation="border" variant="dark" />}
            </div>
            <DataTable
              columns={columns}
              data={prepaidPlan}
              persistTableHead={true}
              pagination
              striped
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPrepaidPlans;
