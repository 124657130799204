import moment from "moment/moment";
import { numberFormater } from "../../utils";

export const InstallationDetailsShort = ({
  hasInstallment,
  totalInstallment,
  installmentCount,
  installmentAmount,
  upfrontDetails,
  outterCol,
  installmentType,
}) => {
  return (
    <>
      <div className={`col-md-${outterCol || 6}`} style={{ marginTop: "25px" }}>
        <div className="row mb-2">
          <div className="col-5">
            {installmentAmount === 25 ? "Deposit Type" : "Internal Wiring Type"}
          </div>
          <div className="col-7">
            :{" "}
            {installmentType === "Yes"
              ? "Monthly"
              : installmentType === "No"
              ? "Upfront"
              : installmentType === "N/A"
              ? "N/A"
              : "Unassigned"}
          </div>
        </div>
        {installmentType === "No" ? (
          <>
            <div className="row mb-2">
              <div className="col-5">
                {installmentAmount === 25 ? "Deposit" : "Internal Wiring"} Paid
                (RM)
              </div>
              <div className="col-7">
                : {(upfrontDetails?.reloadedBalance || 0)?.toFixed(2)} /{" "}
                {installmentAmount === 25 ? "300.00" : "816.00"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-5">
                {installmentAmount === 25 ? "Deposit" : "Internal Wiring"}{" "}
                Balance (RM){" "}
              </div>
              <div className="col-7">
                :{" "}
                {(
                  totalInstallment * installmentAmount -
                  (upfrontDetails?.reloadedBalance || 0)
                ).toFixed(2)}
              </div>
            </div>
          </>
        ) : installmentType === "Yes" ? (
          <>
            <div className="row mb-2">
              <div className="col-5">
                {installmentAmount === 25 ? "Deposit" : "Internal Wiring"} Paid
                (RM)
              </div>
              <div className="col-7">
                :{" "}
                {hasInstallment
                  ? numberFormater(installmentCount * installmentAmount)
                  : "N/A"}{" "}
                / {installmentAmount === 25 ? "300.00" : "816.00"}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-5">
                {installmentAmount === 25 ? "Deposit" : "Internal Wiring"}{" "}
                Balance (RM){" "}
              </div>
              <div className="col-7">
                :{" "}
                {hasInstallment
                  ? numberFormater(
                      totalInstallment * installmentAmount -
                        installmentCount * installmentAmount
                    )
                  : "N/A"}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
