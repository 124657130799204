import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomerProfile from "./CustomerProfile";
import axios from "axios";
import { userRole } from "../../constant";

export default function Profile() {
  const userDetails = useSelector((state) => state.user?.userDetails);
  const [siteLocations, setSiteLocations] = useState([]);

  const getSiteLocations = async () => {
    if (userDetails?.role === "superAdmin") {
      try {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    } else if (userDetails?.role === "admin") {
      try {
        const response = await axios.get(
          `/site-location/admin-sites/` + userDetails?._id,
          { withCredentials: true }
        );
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    document.title = "IVIS PMS - Profile";
    getSiteLocations();
  }, []);

  if (userDetails?.role === "user") {
    return <CustomerProfile userId={userDetails?._id} />;
  }
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div>
                <label className="az-content-label">Profile</label>
                <span className="d-block py-2">Your Profile Information</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Full Name</div>
                  <div className="col-8">: {userDetails?.name}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Email</div>
                  <div className="col-8">: {userDetails?.email}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Phone Number</div>
                  <div className="col-8">
                    : {userDetails?.phone ? `+6${userDetails?.phone}` : "--"}
                  </div>
                </div>
                {/* <div className="row mb-2">
                  <div className="col-6">Fax Number</div>
                  <div className="col-6">: +6{userDetails?.fax}</div>
                </div> */}
                {(userDetails?.role === userRole.admin ||
                  userDetails?.role === userRole.superAdmin) && (
                  <div className="row mb-2">
                    <div className="col-4">
                      Disable Offline Meter Email Notifications
                    </div>
                    <div className="col-8">
                      : {userDetails?.isDisabledOfflineEmail ? "Yes" : "No"}
                    </div>
                  </div>
                )}
                <div className="row mb-2">
                  <div className="col-4">Profile Photo</div>
                  <div className="col-8">
                    {userDetails?.avatar && (
                      <img
                        src={userDetails?.avatar}
                        alt="logo"
                        className="rounded"
                        style={{ height: "150px" }}
                      />
                    )}
                  </div>
                </div>

                <div className="float-end">
                  <Link to="/edit-profile" className="btn btn-success me-1">
                    Edit
                  </Link>
                  <Link to="/" className="btn btn-secondary">
                    Back
                  </Link>
                </div>
              </div>
              {userDetails?.role !== userRole.superAdmin &&
                userDetails?.role !== userRole.businessAdmin && (
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-4">Site Locations</div>
                      <div className="col-8">
                        {Array.isArray(siteLocations) &&
                          siteLocations.map((site) => (
                            <div key={site._id}> : {site?.name} </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
