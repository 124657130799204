import { Spin } from "antd";
import React from "react";

export const SiteDashboardStatistics = ({ options, statisticsData }) => {
  return (
    <div className="col-lg-6">
      <div className="card card-dashboard-six graphShadow h-100">
        <h6 className="fw-bold text-uppercase mb-2">
          ACCOUNT FINANCIAL SUMMARY
        </h6>
        <div>
          <div className="row mt-3">
            <div className="col-md-6">
              <div className="mb-5">
                <div> All Payments </div>
                <h2 className="fw-bold mb-0">
                  RM{" "}
                  {Number(
                    statisticsData?.energyCost +
                      statisticsData?.totalReload +
                      statisticsData?.depositFee +
                      statisticsData?.internalWiringFee +
                      statisticsData?.kwtbbFee +
                      statisticsData?.maintenanceFee +
                      statisticsData?.otherFee
                  ).toLocaleString("en", options)}
                </h2>
                <div>Total Payment Summary</div>
              </div>
              <div className="mb-5">
                <div>Electricity Usages</div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.energyCost).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
              <div className="mb-5">
                <div>Top-Up</div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.totalReload).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
              <div className="mb-5">
                <div>Monthly Maintenance</div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.maintenanceFee).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
              <div>
                <div>Deposit Refund</div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.customerDepositRefund).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-5">
                <div> Deposit Collection </div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.depositFee).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
              <div className="mb-5">
                <div> Internal Wiring Collection </div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.internalWiringFee).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>{" "}
              <div className="mb-5">
                <div>KWTBB Surcharge </div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.kwtbbFee).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
              <div>
                <div>Other Fees</div>
                <h3 className="fw-bold">
                  RM{" "}
                  {Number(statisticsData?.otherFee).toLocaleString(
                    "en",
                    options
                  )}
                </h3>
                <div>Total Payment Summary </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
