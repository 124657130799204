import { useState } from "react";
import Header from "../src/components/Layouts/Header";
import NavBar from "../src/components/Layouts/NavBar";
import Footer from "../src/components/Layouts/Footer";
import SignIn from "./pages/SignIn/SignIn";
import { useFullScreenHandle } from "react-full-screen";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import WelcomeSection from "./components/Layouts/WelcomeSection";
import MyRoutes from "./MyRoutes";
import "react-datepicker/dist/react-datepicker.css";

import "react-awesome-lightbox/build/style.css";
import "./app.css";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import QrSignInSignIn from "./pages/SignIn/QrSignInSignIn";
import { ResetPassword } from "./pages/SignIn/ResetPassword";
import AuthFooter from "./components/Layouts/AuthFooter";

function App() {
  const isLogged = useSelector((state) => state.user?.isLogged || false);
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleShow = () => setShowSidebar(!showSidebar);
  const handleFullScreen = useFullScreenHandle();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/qr-sign/:id" element={<QrSignInSignIn />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
      </Routes>
      {isLogged ? (
        <div
          className={
            showSidebar
              ? "az-body az-body-sidebar az-sidebar-show"
              : "az-body az-body-sidebar"
          }
        >
          <NavBar />
          <div className="az-content az-content-dashboard-two justify-content-between">
            <div>
              <Header
                toggleShow={toggleShow}
                handleFullScreen={handleFullScreen}
              />
              <WelcomeSection />
              <MyRoutes handleFullScreen={handleFullScreen} />
            </div>
            <Footer />
          </div>
        </div>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/qr-sign" element={<QrSignInSignIn />} />
          </Routes>
          <AuthFooter />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
