import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export default function BusinessAdminView() {
  const Params = useParams();
  const adminId = Params.adminId;
  const [adminDetails, setAdminDetails] = useState();

  const getAdmin = async (adminId) => {
    const response = await axios.get(`/user/` + adminId, {
      withCredentials: true,
    });
    if (response) {
      console.log(response.data);
      setAdminDetails(response.data);
    }
  };
  useEffect(() => {
    getAdmin(adminId);
  }, [adminId]);
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Business Admins</label>
                <span className="d-block py-2">Business Admin Information</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Full Name</div>
                  <div className="col-8">: {adminDetails?.name}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Email Address</div>
                  <div className="col-8">: {adminDetails?.email}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Phone Number</div>
                  <div className="col-8">: +6{adminDetails?.phone}</div>
                </div>
                {/* <div className="row mb-2">
                  <div className="col-4">Company Name</div>
                  <div className="col-8">: {adminDetails?.companyName}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Company Address</div>
                  <div className="col-8">: {adminDetails?.companyAddress}</div>
                </div> */}

                <div className="row mb-2">
                  <div className="col-4">Profile Photo</div>
                  <div className="col-8">
                    {adminDetails?.avatar && (
                      <img
                        src={adminDetails?.avatar}
                        alt="logo"
                        className="rounded"
                        style={{ height: "150px" }}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Full Name</div>
                  <div className="col-8">
                    {adminDetails?.site?.map(({ _id, name }) => (
                      <div key={_id}> : {name} </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link
                  to={`/edit-business-admin/` + adminId}
                  className="btn btn-success me-1"
                >
                  Edit
                </Link>
                <Link to={`/business-admins`} className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
