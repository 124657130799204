import axios from "axios";
import React, { useEffect, useState } from "react";
import { DatePicker, Spin, Select } from "antd";
import ReportTable from "../../components/Reports/ReportTable";
import ReactSelect from "react-select";
import SplineChart from "../../components/Charts/SplineChart";
import { useMemo } from "react";
import { api, endpoint } from "../../api";
import { Link } from "react-router-dom";
import appConfig from "../../appConfig";
import moment from "moment";
import { useSelector } from "react-redux";
import { exportCSV } from "../../utils/exportCsv";

const AnalysisReporting = () => {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [reportTypeGraph, setReportTypeGraph] = useState(true);
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState();
  const [sites, setSites] = useState([]);
  const [graphFrom, setGraphFrom] = useState();
  const [graphTo, setGraphTo] = useState();
  const [state, setState] = useState({
    interval: "",
    device: "",
    from: "",
    to: "",
    operator: "",
    parameters: "",
  });
  const [isAdminMeter, setIsAdminMeter] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const userDetails = useSelector((state) => state.user.userDetails);

  const { interval, device, from, to, parameters } = state;

  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item._id, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const handleDownloadReport = () => {
    const device = devices.find((d) => d._id === state.device);
    let data = deviceTableData.map(({ _id, date, ...e }) => {
      let obj = {
        // Date: date,
        Date: `${moment(date).format("DD/MM/YYYY hh:mm:ss a")}`,
        ...e,
        // price: Number(e.price || 0).toFixed(2),
      };
      if (device.deviceType === "admin") {
        delete obj.price;
      }
      return obj;
    });
    exportCSV({
      data,
      filename: `report-parameter-comparison-${device.name}`,
    });
  };

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    if (
      e.target.name === "device" &&
      devices.find(
        ({ _id, deviceType }) =>
          _id === e.target.value && deviceType === "admin"
      )?._id
    ) {
      setIsAdminMeter(true);
    } else setIsAdminMeter(false);
  };

  const handleParameterChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const getDeviceParameters = async (deviceId) => {
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (device) {
      setState({ ...state, parameters: [] });

      getDeviceParameters(device);
    }
  }, [device]);

  const onChangeSite = async (locationId) => {
    if (locationId) {
      setState({ ...state, device: "" });
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: locationId },
      });
      if (response) {
        setDevices(response.data?.results);
      }
    }
  };

  const getSiteLocationList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSites(response.data);
    } catch (error) {}
  };

  /**
   * @param { String } type
   * @type graph | table
   */
  const getReport = async (type) => {
    if (type === "graph") {
      setIsLoadingGraph(true);
      setGraphFrom(from);
      setGraphTo(to);
    } else {
      setIsLoadingTable(true);
    }
    const body = { ...state };
    if (state.parameters?.length)
      body.parameters = state.parameters.map((p) => p.label);
    if (!body.interval) body.interval = "1";
    try {
      const response = await api.post(endpoint.parameter_comparison, body, {
        params: { limit, page, type },
      });
      if (type === "graph") {
        setDeviceData(response);
      } else {
        setDeviceTableData(response?.results);
        setTotal(response?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
    if (type === "graph") {
      setIsLoadingGraph(false);
    } else {
      setIsLoadingTable(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    getReport("graph");
    getReport("table");
  };

  const getCustomerDevice = async (customer) => {
    if (customer) {
      try {
        const response = await axios.get(`/device`, {
          withCredentials: true,
          params: { customer },
        });
        if (response) {
          setDeviceName(response.data?.results[0]?.name || "");
          setState({ ...state, device: response?.data?.results[0]?._id });
          // getDeviceParameters(response.data?.results[0]?._id)
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getSiteLocationList();
    document.title = `${appConfig.name} - Parameter Comparison`;
    if (userDetails?.role === "user") getCustomerDevice(userDetails?._id);
  }, []);

  return (
    <div className="analysis">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">
              Manage Analysis and Reporting
            </label>
            <span className="d-block py-2">Meter Parameter Comparison</span>
          </div>
          <div className="col-md-4 d-flex justify-content-end p-0">
            {deviceTableData.length > 0 && (
              <button
                className="btn btn-danger me-2"
                onClick={handleDownloadReport}
              >
                Download Report
              </button>
            )}
            <Link className="btn btn-secondary" to="/">
              Back
            </Link>
          </div>
        </div>
        <hr className="mt-0" />
        <div className="row my-3">
          <div className="col-md-12">
            <button
              className={`btn me-2 ${
                reportTypeGraph ? " btn-info" : "btn-success"
              }`}
              onClick={() => setReportTypeGraph(true)}
            >
              Graph
            </button>
            <button
              className={`btn ${
                !reportTypeGraph ? " btn-info" : "btn-success"
              }`}
              onClick={() => setReportTypeGraph(false)}
            >
              Table
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="col-md-3 mt-2">
              <select
                className="form-select"
                name="interval"
                defaultValue={interval}
                onChange={onInputChange}
              >
                <option value="">Select Period</option>
                <option value="1">1 Min</option>
                <option value="30">30 Min</option>
                <option value="60">1 Hour</option>
                <option value="1440">24 Hours</option>
              </select>
            </div>
            <div className="col-md-3 mt-2">
              {userDetails?.role !== "user" ? (
                <select
                  className="form-select"
                  name="siteLocations"
                  onChange={(e) => onChangeSite(e.target.value)}
                  aria-label="Select an admin"
                  defaultValue={""}
                >
                  <option disabled label="Select Site" value={""}></option>
                  {sites?.length &&
                    sites.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              ) : (
                <input
                  className="form-select"
                  value={userDetails?.site[0]?.name}
                  readOnly={true}
                  disabled
                  style={{ background: "none" }}
                />
              )}
            </div>
            <div className="col-md-3 mt-2">
              {userDetails?.role !== "user" ? (
                <Select
                  onChange={(value) => setState({ ...state, device: value })}
                  value={state.device}
                  size="large"
                  className="w-100"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children
                      ? option?.children.toLowerCase()
                      : ""
                    ).includes(input?.toLowerCase())
                  }
                >
                  <option value="">Select Meter</option>
                  {devices?.length &&
                    devices.map((item, index) => (
                      <Select.Option value={item._id} key={index}>
                        {`${item.name} ${
                          item?.customer?.name
                            ? `(${item?.customer?.customerId})`
                            : ""
                        }`}
                      </Select.Option>
                    ))}
                </Select>
              ) : (
                <input
                  className="form-select"
                  value={deviceName}
                  readOnly={true}
                  disabled
                  style={{ background: "none" }}
                />
              )}
            </div>
            <div className="col-md-3 mt-2">
              <select
                className="form-select"
                name="operator"
                value={state.operator}
                onChange={onInputChange}
              >
                <option>Real Time</option>
                <option value="max">Maximum</option>
                <option value="min">Minimum</option>
                <option value="avg">Average</option>
                <option value="sum">Sum</option>
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <DatePicker
                className="form-control"
                placeholder="Select Start Date"
                size="large"
                disabledDate={(current) => current > moment()}
                onChange={(e, date) => {
                  setState({ ...state, from: date });
                }}
              />
            </div>
            <div className="col-md-3 mt-2">
              <DatePicker
                className="form-control"
                placeholder="Select End Date"
                size="large"
                onChange={(e, date) => {
                  setState({ ...state, to: date });
                }}
                disabledDate={(current) => current > moment()}
              />
            </div>

            <div className="col-md-3 mt-2">
              <ReactSelect
                isMulti
                value={parameters}
                onChange={(value) => handleParameterChange("parameters", value)}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={`Select Meter Parameter`}
              />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button className="btn btn-success">Generate</button>
            </div>
          </div>
        </form>

        {SuccessMessage && (
          <div className="alert alert-success" role="alert">
            {SuccessMessage}
          </div>
        )}

        <div className="row" style={{ minHeight: "400px" }}>
          <div className="col-md-12">
            <div className="mt-3">
              {reportTypeGraph ? (
                <Spin spinning={isLoadingGraph}>
                  <SplineChart
                    title="Parameter Comparison"
                    data={deviceData}
                    from={graphFrom}
                    to={graphTo}
                    isLoading={isLoadingGraph}
                    interval={interval}
                    type="area"
                  />
                </Spin>
              ) : (
                <Spin spinning={isLoadingTable}>
                  <ReportTable
                    title="Parameter Comparison"
                    data={deviceTableData}
                    parameters={parameters}
                    total={total}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                    isLoading={isLoadingTable}
                    isAdminMeter={isAdminMeter}
                    showPrice={true}
                  />
                </Spin>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisReporting;
