import { Image, View, StyleSheet, Text } from "@react-pdf/renderer";
import imgSource from "./tnb-logo.png";

export const HeaderMalayPdf = ({ pageNumber, headerText }) => {
  const styles = StyleSheet.create({
    wrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    singleRowOfAdminInfo: {
      marginBottom: "1px",
      display: "flex",
      flexDirection: "row",
    },
    rightTextAdminInfo: {
      fontSize: "12px",
      width: "29%",
    },
    leftTextAdminInfo: {
      fontSize: "12px",
      width: "70%",
    },
    imageView: {
      width: "40%",
    },
    contactView: {
      width: "60%",
    },
    image: {
      width: "100px",
    },
    companyName: {
      fontSize: "14px",
      fontWeight: "heavy",
      marginBottom: "25px",
    },
    title: {
      fontSize: "14px",
      fontWeight: "black",
      marginBottom: "10px",
      marginTop: "25px",
      borderBottom: "1px solid black",
    },
    pageNumber: {
      fontSize: "8px",
      textAlign: "right",
      marginBottom: "15px",
    },
  });

  return (
    <>
      <View style={styles.wrapper}>
        <View style={styles.imageView}>
          <Image src={imgSource} style={styles.image} />
        </View>
        <View style={styles.contactView}>
          <Text style={styles.companyName}>
            Village Support Services Sdn Bhd
          </Text>
          {/* <View style={styles.singleRowOfAdminInfo}>
            <Text style={styles.rightTextAdminInfo}>Customer Care Line</Text>
            <Text style={styles.leftTextAdminInfo}>: 1800-220-2293</Text>
          </View> */}
          <View style={styles.singleRowOfAdminInfo}>
            <Text style={styles.rightTextAdminInfo}>e-mel</Text>
            <Text style={styles.leftTextAdminInfo}>: tech@reneontech.com</Text>
          </View>
        </View>
      </View>
      <Text style={styles.title}>
        {headerText || "Monthly Statistics Report"}
      </Text>
      <Text style={styles.pageNumber}>Muka Surat {pageNumber}</Text>
    </>
  );
};
