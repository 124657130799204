import { Modal, notification, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { VsssbSelect } from "../../components/Common/VsssbSelect";
import { userRole } from "../../constant";
import { transactionTypes } from "./CustomerPaymentSummary";

const ManualCustomerPayment = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [siteLocation, setSiteLocation] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [transactionType, setTransactionType] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [deductAmount, setDeductAmount] = useState(0);
  const [installmentDetails, setInstallmentDetails] = useState({});
  const userDetails = useSelector((state) => state.user.userDetails);

  const getInstallmentDetails = async () => {
    try {
      const res = await axios.get(
        `user/get-installment-details?userId=${
          selectedUser._id
        }&&transactionType=${
          transactionType !== transactionTypes.internalWiringPayment
            ? transactionTypes.depositPayment
            : transactionType
        }`
      );
      setInstallmentDetails(res?.data || {});
      const pd = res?.data?.totalPaid || 0;
      if (
        transactionType === transactionTypes.customerDepositRefund &&
        pd !== selectedUser.reloadBalance
      ) {
        setSelectedUser({
          ...selectedUser,
          reloadBalance: res?.data?.totalPaid || 0,
        });
      }
    } catch (error) {}
    setIsLoading(false);
  };
  // console.log(installmentDetails);
  useEffect(() => {
    if (
      (transactionType === transactionTypes.customerDepositRefund ||
        transactionType === transactionTypes.depositPayment ||
        transactionType === transactionTypes.internalWiringPayment) &&
      selectedUser?._id
    ) {
      getInstallmentDetails();
    }
  }, [transactionType, selectedUser]);

  const handleCustomer = (customerId) => {
    setInstallmentDetails({});
    if (customerId) {
      setSelectedUser(users.find(({ _id }) => _id === customerId));
    } else {
      setSelectedUser({});
    }
  };

  const onUpdateBalance = async () => {
    if (!selectedUser._id) return;
    setIsLoading(true);
    try {
      await axios.put(
        `user/add-balance/${selectedUser._id}`,
        {
          newBalance: Number(selectedUser.reloadBalance),
          transactionType,
          site: selectedSite,
          oneTime:
            transactionType === transactionTypes.prepaidReload ||
            transactionType === transactionTypes.depositPayment ||
            transactionType === transactionTypes.internalWiringPayment,
        },
        {
          withCredentials: true,
        }
      );
      notification.success({
        message:
          transactionType === transactionTypes.depositPayment ||
          transactionType === transactionTypes.internalWiringPayment
            ? "Customer payment status successfully updated"
            : transactionType === transactionTypes.prepaidReload
            ? "Balance has been added."
            : transactionType === transactionTypes.customerDepositRefund
            ? "Deposit amount successfully recorded as refunded for this customer"
            : "Payment successfully recorded for this customer",
        placement: "bottomLeft",
      });
      let currentBalance = 0;
      if (transactionType === "prepaidReload") {
        currentBalance =
          Number(selectedUser?.currentBalance || 0) +
          Number(selectedUser?.reloadBalance);
      }
      if (transactionType === transactionTypes.customerDepositRefund) {
        selectedUser.plan.depositType = "";
      }
      setSelectedUser({
        ...selectedUser,
        currentBalance:
          transactionType === "prepaidReload"
            ? currentBalance
            : selectedUser.currentBalance,
        reloadBalance: 0,
      });
      getUser(selectedSite, true);
      // if (
      //   (transactionType === transactionTypes.customerDepositRefund ||
      //     transactionType === transactionTypes.depositPayment ||
      //     transactionType === transactionTypes.internalWiringPayment) &&
      //   selectedUser?._id
      // ) {
      //   getInstallmentDetails();
      // }
    } catch (error) {
      notification.warning({
        message: error.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  const updateConfirm = (e) => {
    e.preventDefault();
    Modal.confirm({
      title:
        transactionType === transactionTypes.depositPayment ||
        transactionType === transactionTypes.internalWiringPayment
          ? "Do you wish to confirm this action?"
          : transactionType === transactionTypes.prepaidReload
          ? "Do you wish to confirm this manual reload action?"
          : transactionType === transactionTypes.prepaidReload
          ? ""
          : "Do you wish to confirm this manual payment action?",
      okText: "Confirm",
      onOk: onUpdateBalance,
    });
  };

  const onDeductBalance = async () => {
    if (!selectedUser._id) return;
    setIsLoading(true);
    try {
      await axios.put(
        `user/remove-balance/${selectedUser._id}`,
        {
          newBalance: Number(deductAmount),
        },
        {
          withCredentials: true,
        }
      );
      notification.success({
        message: "Balance has been deducted.",
        placement: "bottomLeft",
      });

      let currentBalance =
        Number(selectedUser?.currentBalance || 0) - Number(deductAmount);
      setSelectedUser({
        ...selectedUser,
        currentBalance,
        reloadBalance: 0,
      });
      setDeductAmount(0);
      getUser(selectedSite);
    } catch (error) {
      notification.warning({
        message: error.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  const updateConfirmDeduction = (e) => {
    e.preventDefault();
    Modal.confirm({
      title: " Do you wish to confirm this deduction action?",
      okText: "Confirm",
      onOk: onDeductBalance,
    });
  };

  const getUser = async (siteId, customerId) => {
    if (!siteId) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(`user/siteUser/${siteId}/user`, {
        withCredentials: true,
        params: { onlyActive: true },
      });
      if (response) {
        const allUsers = response?.data || [];
        setUsers(allUsers);
        let temp = allUsers.find(({ _id }) => _id === customerId);
        if (customerId && temp?._id) {
          setSelectedUser(temp);
          setTransactionType("prepaidReload");
        }
      }
    } catch (error) {
      setUsers([]);
    }
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  const handleLocationChange = (siteId, customerID) => {
    setSelectedSite(siteId);
    getUser(siteId, customerID);
    setSelectedUser({});
  };

  useEffect(() => {
    getSiteLocation();
    const siteId = searchParams.get("site");
    const customerID = searchParams.get("customer");
    if (siteId && customerID) {
      // getUser(siteId, customerID);
      handleLocationChange(siteId, customerID);
    }
  }, []);

  const hasValidTransactionType = typesOfTransaction.some(
    (item) => item === transactionType
  );
  const disableReloadAmountInput =
    (transactionType === "depositPayment" &&
      (selectedUser?.plan?.depositType === "Yes" ||
        selectedUser?.plan?.depositType === "N/A")) ||
    (transactionType === "internalWiringPayment" &&
      (selectedUser?.plan?.internalWiringType === "Yes" ||
        selectedUser?.plan?.internalWiringType === "N/A"));

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Manual Customer Payments</span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="site" className="form-label">
                    Site Locations
                  </label>
                  <select
                    name="site"
                    className="form-select"
                    onChange={(e) => handleLocationChange(e.target.value)}
                    value={selectedSite}
                  >
                    <option label="All Site Locations"></option>
                    {siteLocation?.length &&
                      siteLocation.map((item, index) => (
                        <option value={item._id} key={item._id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <VsssbSelect
                    label="Customer"
                    placeholder="Select Customer"
                    value={selectedUser?._id}
                    onChange={handleCustomer}
                    options={users}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="plan" className="form-label">
                    Subscription Plan
                  </label>
                  <input
                    type="text"
                    name="plan"
                    className="form-control"
                    placeholder="Plan name"
                    readOnly
                    value={selectedUser?.plan?.plan?.name || "N/A"}
                    disabled
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label htmlFor="user" className="form-label">
                    Select Transaction Type
                  </label>
                  <select
                    name="transactionType"
                    className="form-select"
                    value={transactionType}
                    onChange={(e) => {
                      setTransactionType(e.target.value);
                      setInstallmentDetails({});
                      setSelectedUser({
                        ...selectedUser,
                        reloadBalance: 0,
                      });
                    }}
                  >
                    <option>Select Type</option>
                    <option value="prepaidReload">Prepaid Reload</option>
                    <option value="depositPayment">Deposit Payment</option>
                    <option value="internalWiringPayment">
                      Internal Wiring Payment
                    </option>
                    {userDetails?.role === userRole.superAdmin && (
                      <option value="deduct">Deduct Customer Balance</option>
                    )}
                    <option value="lateRegistrationCompound">
                      Late Registration Compound
                    </option>
                    <option value="reconnectionFeeCharges">
                      Reconnection Fee Charges
                    </option>
                    <option value="adminFees">Admin Fees</option>
                    <option value="repairServices">Repair Services</option>
                    <option value="customerDepositRefund">
                      Customer Deposit Refund
                    </option>
                  </select>
                </div>
              </div>
              {(transactionType === transactionTypes.customerDepositRefund ||
                transactionType === transactionTypes.depositPayment ||
                transactionType === transactionTypes.internalWiringPayment) &&
                selectedUser?._id && (
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="status" className="form-label">
                        Status
                      </label>
                      <input
                        name="status"
                        type="text"
                        className="form-control"
                        readOnly
                        value={getInstallmentStatus(
                          transactionType === transactionTypes.depositPayment ||
                            transactionType ===
                              transactionTypes.customerDepositRefund
                            ? selectedUser?.plan?.depositType
                            : selectedUser?.plan?.internalWiringType
                        )}
                        disabled
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                )}
              {(transactionType === transactionTypes.customerDepositRefund ||
                transactionType === transactionTypes.depositPayment ||
                transactionType === transactionTypes.internalWiringPayment) &&
                !disableReloadAmountInput && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="totalPaid" className="form-label">
                          Total Paid (RM)
                        </label>
                        <input
                          name="totalPaid"
                          type="text"
                          className="form-control"
                          readOnly
                          value={(installmentDetails?.totalPaid || 0).toFixed(
                            2
                          )}
                          disabled
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="Amount_out" className="form-label">
                          Amount Outstanding (RM)
                        </label>
                        <input
                          name="Amount_out"
                          type="text"
                          className="form-control"
                          readOnly
                          value={(
                            installmentDetails?.remainAmount || 0
                          ).toFixed(2)}
                          disabled
                          onChange={() => {}}
                        />
                      </div>
                    </div>
                  </>
                )}
              {(transactionType === "prepaidReload" ||
                transactionType === "deduct") && (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="balance" className="form-label">
                      Current Balance (RM)
                    </label>
                    <input
                      type="number"
                      name="balance"
                      className="form-control"
                      id="balance"
                      placeholder="00.00"
                      readOnly
                      disabled
                      value={
                        selectedUser?.currentBalance
                          ? Number(selectedUser.currentBalance).toFixed(2)
                          : "N/A"
                      }
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )}
              {hasValidTransactionType && (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="amount" className="form-label">
                      {transactionType === "prepaidReload"
                        ? "Reload Amount (RM)"
                        : transactionType ===
                            transactionTypes.internalWiringPayment ||
                          transactionType === transactionTypes.depositPayment
                        ? "One-Time Payment (RM)"
                        : transactionType ===
                          transactionTypes.customerDepositRefund
                        ? "Refund Amount (RM)"
                        : "Payment Amount (RM)"}
                    </label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      id="amount"
                      placeholder={
                        disableReloadAmountInput
                          ? selectedUser?.plan?.depositType === "N/A" ||
                            selectedUser?.plan?.internalWiringType === "N/A"
                            ? `${
                                transactionType === "internalWiringPayment"
                                  ? "Internal wiring payment"
                                  : "Deposit payment"
                              } is not applicable for current customer.`
                            : `Customer is currently on an instalment plan for ${
                                transactionType === "internalWiringPayment"
                                  ? "internal wiring payment"
                                  : "deposit payment"
                              }.`
                          : transactionType === "prepaidReload"
                          ? "Enter Reload Amount"
                          : transactionType ===
                            transactionTypes.customerDepositRefund
                          ? "Enter Amount"
                          : "Enter Payment Amount"
                      }
                      required
                      disabled={
                        disableReloadAmountInput ||
                        transactionType ===
                          transactionTypes.customerDepositRefund
                      }
                      onChange={(e) =>
                        setSelectedUser({
                          ...selectedUser,
                          reloadBalance: e.target.value,
                        })
                      }
                      value={selectedUser?.reloadBalance || ""}
                    />
                  </div>
                </div>
              )}
              {transactionType === "deduct" && (
                <>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="balance" className="form-label">
                        Deduction Amount (RM)
                      </label>
                      <input
                        type="number"
                        name="balance"
                        className="form-control"
                        id="balance"
                        placeholder="00.00"
                        value={deductAmount}
                        onChange={(e) => {
                          setDeductAmount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="balance" className="form-label">
                        New Balance (RM)
                      </label>
                      <input
                        type="number"
                        name="balance"
                        className="form-control"
                        id="balance"
                        readOnly
                        disabled
                        placeholder="00.00"
                        value={(
                          Number(selectedUser.currentBalance) - deductAmount
                        ).toFixed(2)}
                        onChange={() => {}}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="float-end">
                {transactionType === "deduct" ? (
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    disabled={!selectedUser._id || !deductAmount}
                    onClick={updateConfirmDeduction}
                  >
                    Confirm
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    onClick={updateConfirm}
                    disabled={
                      !selectedUser._id ||
                      !selectedUser.reloadBalance ||
                      !hasValidTransactionType
                    }
                  >
                    {transactionType === transactionTypes.depositPayment ||
                    transactionType === transactionTypes.internalWiringPayment
                      ? "Confirm Payment"
                      : transactionType === transactionTypes.prepaidReload
                      ? "Reload"
                      : "Confirm"}
                  </button>
                )}
                <Link
                  className="btn btn-secondary"
                  to={"/customer-payment-summary"}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default ManualCustomerPayment;

const typesOfTransaction = [
  "prepaidReload",
  "depositPayment",
  "internalWiringPayment",
  "lateRegistrationCompound",
  "reconnectionFeeCharges",
  "adminFees",
  "repairServices",
  "customerDepositRefund",
];

const getInstallmentStatus = (status) => {
  if (status === "N/A") return "N/A";
  else if (status === "Yes") return "Monthly";
  else if (status === "No") return "Upfront";
  else return "Unassigned";
};

// Late Registration Compound
// Reconnection Fee Charges
// Admin Fees
// Repair Services
// Customer Deposit Refund
