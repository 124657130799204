import React from "react";
import DataTable from "react-data-table-component";
import { FiEdit, FiTrash } from "react-icons/fi";


const FormulasTable = ({ formulas, onDeleteRow, onEditRow }) => {

  const columns = [
    {
      name: "No",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "Formula Name",
      selector: (row) => row?.name,
    },
    {
      name: "Unit / Value",
      selector: (row) => row?.unit,
    },
    {
      name: "Formula",
      selector: (row) => row?.formula,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button className="btn btn-dark" onClick={() => onEditRow(row)}>
            <FiEdit />
          </button>
          <button className="btn btn-danger" onClick={() => onDeleteRow(row)}>
            <FiTrash />
          </button>
        </div>
      ),
      center: true,
      width: '120px',
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={formulas}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  );
};

export default FormulasTable;
