import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SplineChart = ({ title, data, from, to, type }) => {

    from = moment(from).startOf('day').toDate();
    to = moment(to).endOf('day').toDate()

    let series = data
    let options = {
        chart: {
            height: 350,
            type: type
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            // categories: data[index]?.date,
            min: from.getTime(),
            max: to.getTime(),
            tickAmount: 1,
            labels: {
                datetimeUTC: false,
            },
        },
        title: {
            text: title,
            align: 'center'
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        }
    }

    if (series.length <= 0) {
        return <div> No data found </div>
    }


    return (
        <div>
            {
                series.length > 0 ? <ReactApexChart options={options} series={series} type={type} height={450} /> : ''
            }
        </div>
    );
}

export default SplineChart;
