import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import UserDashboard from "./UserDashboard";
import SiteDashboard from "./SiteDashboard";
import SiteMeterDashboard from "./SiteMeterDashboard";
import { userRole } from "../../constant";

const Dashboard = ({ handleFullScreen }) => {
  const { userDetails, activeDashboard } = useSelector((state) => state.user);

  useEffect(() => {
    document.title = "IVIS – Prepaid Monitoring System (PMS)";
  }, []);

  console.log({ userDetails, activeDashboard });

  return (
    <div>
      {activeDashboard === "site_location_dashboard" &&
      userDetails?.role !== userRole.user ? (
        <SiteDashboard handleFullScreen={handleFullScreen} />
      ) : activeDashboard === "site_meter_dashboard" &&
        userDetails?.role !== userRole.user ? (
        <SiteMeterDashboard handleFullScreen={handleFullScreen} />
      ) : (
        <UserDashboard handleFullScreen={handleFullScreen} />
      )}
    </div>
  );
};

export default Dashboard;
