import { DatePicker, Input, notification, Select, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { FinancePdf } from "./pdf/FinancePdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import appConfig from "../../appConfig";
import { numberFormater } from "../../utils";

const FinanceReports = () => {
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchValue, setSearchValue] = useState({});
  const [report, setReport] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChangeSearch = (value) => {
    if (!value) {
      setSearchValue({ site: "" });
    } else {
      setSearchValue({ ...searchValue, site: value });
    }
  };
  // const getSiteLocation = async () => {
  //   try {
  //     const response = await axios.get(`/site-location`, {
  //       withCredentials: true,
  //     });
  //     if (response) {
  //       setSiteLocation(response.data);
  //     }
  //   } catch (error) {}
  // };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocation(response.data);
    } catch (error) {}
  };

  const onChangeMonth = (date, dateString) => {
    setSearchValue({ ...searchValue, cycleDate: dateString });
  };
  const onCreateReport = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get("/report/get-financial-report", {
        params: searchValue,
      });
      setReport(data);
      console.log(data);
    } catch (error) {
      notification.warning({
        message: error?.response?.data?.message || "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  console.log(searchValue);

  useEffect(() => {
    getSiteLocation();
    document.title = `${appConfig.name} - Finance Reports`;
    // eslint-disable-next-line
  }, []);

  return (
    <Spin spinning={isLoading}>
      {/* <FinancePdf report={report} /> */}

      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">Finance Reports</span>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-3">
                  <Select
                    onChange={onChangeSearch}
                    value={searchValue.site || ""}
                    placeholder="Select Location"
                    size="large"
                    className="w-100"
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children
                        ? option?.children.toLowerCase()
                        : ""
                      ).includes(input?.toLowerCase())
                    }
                  >
                    <Select.Option disabled value={""}>
                      Select Location
                    </Select.Option>
                    {siteLocation &&
                      siteLocation.length > 0 &&
                      siteLocation.map((item, index) => (
                        <Select.Option value={item._id} key={index}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="col-md-2">
                  <DatePicker
                    onChange={onChangeMonth}
                    picker="month"
                    format={"MMM, YYYY"}
                    allowClear
                    size="large"
                    disabledDate={disabledDate}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    variant="success"
                    onClick={onCreateReport}
                    disabled={!searchValue.cycleDate || !searchValue?.site}
                  >
                    Search
                  </Button>
                </div>
                <div className="col-md-6 mt-4">
                  <label className="az-content-label mb-3">
                    Customer Information
                  </label>
                  {customerInformation.map(({ label, feildName }) => (
                    <div className="d-flex justify-content-between mb-2">
                      <p style={{ fontWeight: "600", width: "50%" }}>{label}</p>
                      <p
                        className="form-control"
                        style={{
                          // fontWeight: "600",
                          width: "50%",
                          minHeight: "38px",
                          height: "auto",
                          backgroundColor: "#e9ecef",
                        }}
                      >
                        {report[feildName]}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="col-md-6 mt-4">
                  <label className="az-content-label">
                    Electricity Usage Information
                  </label>
                  {/* table */}
                  <table className="reportTable">
                    <tr>
                      <th colSpan={2}>Electricity Usage</th>
                    </tr>
                    {electricityUsage.map(({ label, feildName }) => (
                      <tr>
                        <td className="text-end" width={"50%"}>
                          {label}
                        </td>
                        <td>{numberFormater(report[feildName])}</td>
                      </tr>
                    ))}
                  </table>

                  <label className="az-content-label mt-5">
                    Payment Collection Information
                  </label>
                  <table className="reportTable border-top-0">
                    <tr>
                      <th colSpan={3}>Breakdown of Payment Collected</th>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Customer Top-Up (RM)</td>
                      <td>Cash</td>
                      <td style={{ minWidth: 100 }}>
                        {numberFormater(report.totalReload)}
                      </td>
                    </tr>
                    <tr>
                      <td>Bank Transfer</td>
                      <td style={{ minWidth: 100 }}>0.00</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Customer Deposit (RM)</td>
                      <td>Cash</td>
                      <td style={{ minWidth: 100 }}>
                        {/* {report?.depositFee} */}
                        {numberFormater(report.depositFee)}
                      </td>
                    </tr>
                    <tr>
                      <td>Bank Transfer</td>
                      <td style={{ minWidth: 100 }}>0.00</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Customer Internal Wiring Fees (RM)</td>
                      <td>Cash</td>
                      <td style={{ minWidth: 100 }}>
                        {/* {report?.internalWiringFee || "0.00"} */}
                        {numberFormater(report.internalWiringFee)}
                      </td>
                    </tr>
                    <tr>
                      <td>Bank Transfer</td>
                      <td style={{ minWidth: 100 }}>0.00</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Customer KWTBB Surcharge Fees (RM) </td>
                      <td style={{ minWidth: 100 }}>
                        {/* {report?.kwtbbFee} */}
                        {numberFormater(report.kwtbbFee)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Maintenance Fees (RM)</td>
                      <td style={{ minWidth: 100 }}>
                        {numberFormater(report.maintenanceFee)}
                      </td>
                    </tr>
                    {otherCharges.map(({ label, feildName }, idx) => (
                      <tr key={idx}>
                        <td colSpan={2}>{label}</td>
                        <td style={{ minWidth: 100 }}>
                          {idx === otherCharges.length - 1 &&
                          Number(report[feildName])
                            ? "- "
                            : ""}
                          {numberFormater(report[feildName])}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2}> Tax (0%) </td>
                      <td style={{ minWidth: 100 }}>0.00</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <b>Total Collections (RM) </b>
                      </td>
                      <td style={{ minWidth: 100 }}>
                        <b>{numberFormater(report.totalCollection)}</b>
                        {/* {report?.totalCollection} */}
                      </td>
                    </tr>
                  </table>

                  <label className="az-content-label mt-5">Comparison</label>
                  {/* <table className="reportTable border-top-0">
                    <tr>
                      <th colSpan={3}>Comparison Statistics</th>
                    </tr>
                    <tr>
                      <td>
                        <b> Period </b>
                      </td>
                      <td>
                        <b> Total kWh Used </b>
                      </td>
                      <td>
                        <b> Total Payment Collection (RM) </b>
                      </td>
                    </tr>

                    <tr>
                      <td>September 2023</td>
                      <td> 2002.88 </td>
                      <td> 1188.87 </td>
                    </tr>
                  </table> */}

                  {/* <table className="reportTable border-top-0">
                    <tr>
                      <th colSpan={2}>Financial Statistics</th>
                    </tr>
                    {financialStatistics.map(({ label, feildName }) => (
                      <tr>
                        <td width={"50%"}>{label}</td>
                        <td>{report[feildName]}</td>
                      </tr>
                    ))}
                  </table> */}
                  <table className="reportTable mt-0">
                    <tr>
                      <th colSpan={3}>Comparison Statistics</th>
                    </tr>
                    <tr>
                      <td width={"25%"} className={"fw-bold"}>
                        Period
                      </td>
                      <td width={"25%"} className={"fw-bold"}>
                        Total kWh Used
                      </td>
                      <td width={"50%"} className={"fw-bold"}>
                        Total Payment Collected (RM)
                      </td>
                    </tr>
                    {getLast6MonthDate(report.cycleDate).map((date, idx) => (
                      <tr key={idx}>
                        <td>{date}</td>
                        <td>
                          {numberFormater(
                            report?.past6MonthStatistics?.[idx]?.kWh
                          )}
                        </td>
                        <td style={{ textAlign: "end" }}>
                          {numberFormater(
                            report?.past6MonthStatistics?.[idx]
                              ?.paymentCollected
                          )}
                          {/* {
                            report?.past6MonthStatistics?.[idx]
                              ?.paymentCollected
                          } */}
                        </td>
                      </tr>
                    ))}
                  </table>
                  {/* table end */}
                  <div className="float-end mt-5">
                    <button
                      type="submit"
                      className={
                        report?.createdAt
                          ? "btn btn-success me-2"
                          : "btn btn-success me-2 disable_area"
                      }
                    >
                      <PDFDownloadLink
                        document={<FinancePdf report={report} />}
                        fileName={`${report?.siteName} - Finance Report ${report?.cycleDate}.pdf`}
                      >
                        <span style={{ color: "#fff" }}>Download PDF</span>
                      </PDFDownloadLink>
                    </button>
                    <button type="button" className="btn btn-secondary">
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default FinanceReports;

const otherCharges = [
  {
    label: "Late Registration Compound (RM)",
    feildName: "lateRegistrationCompound",
  },
  {
    label: "Reconnection Fee Charges (RM)",
    feildName: "reconnectionFeeCharges",
  },
  { label: "Admin Fees (RM)", feildName: "adminFees" },
  { label: "Repair Fees (RM)", feildName: "repairServices" },
  { label: "Deposit Refund (RM)", feildName: "customerDepositRefund" },
];

const customerInformation = [
  { label: "Report Generation Date", feildName: "createdAt" },
  { label: "Cycle Selected", feildName: "cycleDate" },
  { label: "Site Name", feildName: "siteName" },
  { label: "Admin Name", feildName: "adminNames" },
  { label: "Total Number of Customers", feildName: "totalCustomer" },
];
const electricityUsage = [
  { label: "Usage This Month (kWh)", feildName: "monthlyKWHUsed" },
  { label: "Total Usage Cost (RM)", feildName: "totalUsageCost" },
];
const financialStatistics = [
  { label: "Payment Collected (RM)", feildName: "totalPaymentCollected" },
];

export const getLast6MonthDate = (cycleDate) => {
  let today = new Date(cycleDate);
  let d;
  let month;
  let year;
  const dates = [];

  for (let i = 6; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    month = monthNames[d.getMonth()];
    year = d.getFullYear();
    if (cycleDate) dates.push(`${month} ${year}`);
    else dates.push("-");
  }
  return dates.reverse();
};
let monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const disabledDate = (current) => {
  // return false
  let date = new Date();
  const currentDate = moment(date).format("MMM, YYYY");
  const monentDate = current.format("MMM, YYYY");
  const dayOfCrntMonth = date.getDate();
  const dayFlag = dayOfCrntMonth < 7 && currentDate === monentDate;
  const dateCurrent = Number(moment(date).format("x"));
  return (current && current.valueOf() > dateCurrent) || dayFlag;
};
