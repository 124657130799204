import { notification, Spin } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const MinimumCreditThreshold = () => {
  const [amount, setAmount] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const getBalance = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/app-settings/get-minimum-e-wallet`, {
        withCredentials: true,
      });
      setAmount(res.data.minimumEwalletBalance);
    } catch (error) { }
    setLoading(false);
  };

  const handleAction = async () => {
    const minimumEwalletBalance = Number(amount || 0);
    setLoading(true);
    if (minimumEwalletBalance >= 0) {
      try {
        await axios.post(
          `/app-settings/set-minimum-e-wallet`,
          { minimumEwalletBalance },
          {
            withCredentials: true,
          }
        );
        notification.success({
          message:
            "Minimum balance for e-wallet has been updated for all customers.",
          placement: "bottomLeft",
        });
      } catch (error) {
        notification.warning({
          message: "Something went wrong.",
          placement: "bottomLeft",
        });
      }
    } else {
      notification.warning({
        message: "Invalid payment amount",
        placement: "bottomLeft",
      });
    }
    setLoading(false);
  }
  const handleConfirmation = () => {
    Swal.fire({
      title: "Are you sure?",
      text: 'This action will affect all customer accounts in the system.',
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        handleAction();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div className="col-md-8 p-0">
                  <label className="az-content-label">Manage</label>
                  <span className="d-block py-2">
                    Set Minimum Credit Threshold
                  </span>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="amount" className="pr-2 fw-bold mb-1">
                      Minimum Balance (RM):
                    </label>
                    <span className="d-block pb-2">
                      Customers with account balance reaching this amount will
                      receive a notification via email and SMS to reload their
                      balances.
                    </span>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          className="form-control"
                          min="0"
                          step="10"
                          value={amount}
                          onChange={handleAmountChange}
                          placeholder="Enter the amount"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 mt-5 float-end">
                  <button
                    className="btn btn-success me-2"
                    type="submit"
                    onClick={handleConfirmation}
                    disabled={!amount}
                  >
                    Set
                  </button>
                  <Link
                    to="/customer-payment-summary"
                    className="btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default MinimumCreditThreshold;
