import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { HomePageBarChart } from "./HomePageBarChart";
import { UserStatistics } from "./UserStatistics";
import { userRole } from "../../constant";
import Swal from "sweetalert2";
import { FullScreen } from "react-full-screen";
import { Select, DatePicker, Spin } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { VsssbSelect } from "../../components/Common/VsssbSelect";
import AreaChart from "../../components/Charts/AreaChart";

const SiteMeterDashboard = ({ handleFullScreen }) => {
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const [users, setUsers] = useState([]);
  const [siteLocation, setSiteLocation] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [params, setParams] = useState({
    site: "",
    user: "",
    device: "",
  });
  const [filter, setFilter] = useState({
    type: "month",
  });
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [chartData, setChartData] = useState([]);
  const [isDailyLoading, setDailyLoading] = useState(false);
  const [isMonthlyLoading, seMonthlytLoading] = useState(false);
  const [dailyUsage, setDailyUsage] = useState(0);
  // const [monthlyUsage, setMonthlyUsage] = useState(0);
  const [monthlyUsageCost, setMonthlyUsageCost] = useState(0);

  const setDateRange = ({ type, mObj }) => {
    if (type === "date") {
      setFrom(mObj.startOf("day").format("YYYY-MM-DD"));
      setTo(mObj.endOf("day").format("YYYY-MM-DD"));
    } else if (type === "week") {
      setFrom(mObj.startOf("week").format("YYYY-MM-DD"));
      setTo(mObj.endOf("week").format("YYYY-MM-DD"));
    } else if (type === "month") {
      setFrom(mObj.startOf("month").format("YYYY-MM-DD"));
      setTo(mObj.endOf("month").format("YYYY-MM-DD"));
    }
  };

  const getDailyChartData = async () => {
    if (!params.device) return;
    setDailyLoading(true);
    try {
      const response = await axios.get(
        `/dashboard/chart/kwh-consumption-daily`,
        {
          params: { deviceId: params.device, from, to },
          withCredentials: true,
        }
      );
      const results = response.data?.results || [];
      const results1 = [];
      results.map((item) => {
        results1.push({ ...item, y: Number(item?.y?.toFixed(2) || 0) });
      });
      setDailyUsage(response?.data?.sumRawValue || 0);
      setChartData(results1);
    } catch (error) {}
    setDailyLoading(false);
  };

  // const getAllChartData = () => {
  //   getDailyChartData();
  //   // getMonthUsage();
  // };
  useEffect(() => {
    getDailyChartData();
    const interval = setInterval(() => {
      getDailyChartData();
    }, 600000);
    return () => clearInterval(interval);
  }, [params, from, to]);

  const getUser = async (siteId, firstLoad) => {
    if (!siteId) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site: siteId, deviceType: "admin" },
      });
      if (response) {
        // console.log(response)
        setUsers(response?.data?.results || []);
        if (response?.data?.results?.length && firstLoad) {
          setParams({
            ...params,
            site: siteId,
            device: response?.data?.results?.[0]?._id,
          });
        }
      }
    } catch (error) {
      setUsers([]);
    }
  };
  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
        if (response.data?.length) {
          getUser(response.data?.[0]?._id, true);
        }
      }
    } catch (error) {}
  };
  console.log(params);
  const handleLocationChange = (siteId) => {
    setUsers([]);
    setDailyUsage(0);
    setParams({ ...params, site: siteId, device: "" });
    getUser(siteId);
  };

  const handleCustomerChange = (device) => {
    setParams({
      ...params,
      device,
    });
  };

  useEffect(() => {
    getSiteLocation();

    let mObj = dayjs();
    setDateRange({ type: "month", mObj });
    // eslint-disable-next-line
  }, [userDetails]);

  // console.log(params);
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return (
    <div>
      <div className="az-content-body">
        {userDetails?.role !== "user" ? (
          <div className="row mb-5">
            <div className="col-md-4 mt-3">
              <VsssbSelect
                label="Site Locations"
                placeholder="Select Site Locations"
                value={params.site}
                onChange={handleLocationChange}
                options={siteLocation}
              />
            </div>
            <div className="col-md-4 lg-mt-0 md-mt-0 mt-3">
              <VsssbSelect
                label="Meter"
                placeholder="Select Site Meter"
                value={params.device}
                onChange={handleCustomerChange}
                options={users}
              />
            </div>
            <div className="col-md-4 d-inline-flex justify-content-end align-items-end mt-3 md-mt-0">
              <button
                onClick={getDailyChartData}
                // to="#"
                className="btn btn-success ms-2 mb-2 mt-3"
              >
                Refresh
              </button>
            </div>
          </div>
        ) : (
          <div className="col-md-12 d-inline-flex justify-content-end align-items-end">
            <button
              onClick={getDailyChartData}
              // to="#"
              className="btn btn-success ms-2 mb-2"
            >
              Refresh
            </button>
          </div>
        )}
        <FullScreen handle={handleFullScreen}>
          <div>
            <div className="row row-sm mg-b-15 mg-sm-b-20">
              <div className="col-lg-12">
                <div className="card card-dashboard-six graphShadow">
                  <div className="d-md-flex align-items-center justify-content-between flex-row-reverse mb-4">
                    <form name="filterForm" className="d-flex mb-3 mb-md-0">
                      <Select
                        style={{ width: 110 }}
                        value={filter?.type}
                        onChange={(e) => {
                          setFilter({ ...filter, type: e });
                          setSelectedDate(null);
                          setFrom("");
                          setTo("");
                          // setDateRange({ type: e, mObj: selectedDate });
                        }}
                        size="large"
                        options={[
                          {
                            name: "Daily",
                            label: "Daily",
                            value: "date",
                          },
                          {
                            name: "Weekly",
                            label: "Weekly",
                            value: "week",
                          },
                          {
                            name: "Monthly",
                            label: "Monthly",
                            value: "month",
                          },
                        ]}
                      />
                      <DatePicker
                        style={{ width: "150px" }}
                        className="form-control text-center ms-2"
                        picker={filter?.type}
                        value={selectedDate}
                        onChange={(m) => {
                          setSelectedDate(m);
                          setDateRange({ mObj: m, type: filter?.type });
                        }}
                        disabledDate={disabledDate}
                      />
                    </form>
                    <h6 className="fw-bold text-uppercase">
                      ENERGY USAGE OVERVIEW
                    </h6>
                  </div>
                  <div className="row g-4  mb-4">
                    {/* <div className="col-xxl-2 col-lg-3 col-md-6">
                      <div className="text-uppercase az-content-label  fw-bold">
                        daily Total (kwh)
                      </div>
                      <div className="fs-2 fw-bold">
                        {Number(dailyUsage).toLocaleString("en", options)}
                      </div>
                    </div> */}
                    <div className="col-xxl-2 col-lg-3 col-md-6">
                      <div className="text-uppercase az-content-label  fw-bold">
                        total (kwh)
                      </div>
                      <div className="fs-2 fw-bold">
                        {Number(dailyUsage).toLocaleString("en", options)}
                      </div>
                    </div>
                    {/* <div className="col-xxl-4 col-lg-6 col-md-6">
                      <div className="text-uppercase az-content-label  fw-bold">
                        current bill (rm)
                      </div>
                      <div className="fs-2 fw-bold">
                        {Number(monthlyUsageCost).toLocaleString("en", options)}
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <AreaChart
                      data={chartData}
                      from={from}
                      to={to}
                      name="KWH"
                      xAxisName="Time (h)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-sm mg-b-15 mg-sm-b-20 align-items-stretch">
              <HomePageBarChart params={{ deviceId: params.device }} />
              {/* <UserStatistics
                user={params.user}
                options={options}
                role={userDetails?.role}
              /> */}
            </div>
          </div>
        </FullScreen>
      </div>
    </div>
  );
};

export default SiteMeterDashboard;

const disabledDate = (current) => {
  let date = new Date();
  const dateCurrent = Number(moment(date).format("x"));
  return current && current.valueOf() > dateCurrent;
};
