import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  getDatesInRange,
  getLast12MonthName,
  getLast7DaysName,
  last24Hour,
} from "./chartHelper.js";

const BarChart = ({
  title,
  name,
  data,
  viewType,
  dateRange,
  xAxisName,
  xTitleClassName,
  colors,
}) => {
  let isDark = false;

  const [chartInfo, setChartInfo] = useState({
    series: [
      {
        name: name,
        data: data,
      },
    ],
    options: getOptions({ title, isDark, viewType, dateRange, colors }),
  });

  useEffect(() => {
    let newOptions = getOptions({
      title,
      name,
      isDark,
      viewType,
      dateRange,
      colors,
    });
    setChartInfo({
      ...chartInfo,
      options: newOptions,
      series: [
        {
          name: name,
          data,
        },
      ],
    });
  }, [data, viewType, isDark, dateRange]);

  return (
    <div>
      <Chart {...chartInfo} type="bar" height={345} />
      <p
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          textAlign: "center",
          margin: "0",
        }}
        className={xTitleClassName}
      >
        {xAxisName}
      </p>
    </div>
  );
};

export default BarChart;

const getOptions = ({ title, name, isDark, viewType, dateRange, colors }) => {
  let categories = last24Hour;
  if (viewType === "monthly") {
    categories = getLast12MonthName();
  } else if (viewType === "weekly") {
    categories = getLast7DaysName();
  } else if (viewType === "today") {
    categories = last24Hour;
  } else if (dateRange?.length) {
    categories = getDatesInRange(
      new Date(dateRange[0].format("YYYY-MM-DD")),
      new Date(dateRange[1].format("YYYY-MM-DD"))
    );
  }
  // start.setHours(0, 0, 0, 0);
  // console.log(categories);
  return {
    chart: {
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: title,
      align: "center",
    },
    plotOptions: {
      bar: {
        distributed: colors?.length > 1,
      },
    },
    colors: colors,
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories,
      // type: "datetime",
      // min: start.getTime(),
      // max: end.getTime(),
      // tickAmount: "dataPoints",
    },
    yaxis: {
      title: {
        text: "Energy (kWh)",
        style: {
          fontSize: "14px",
          // color: "#000",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    toolbar: {
      tools: {
        download: false,
      },
    },
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 8800,
    //       borderColor: "#00E396",
    //       label: {
    //         borderColor: "#00E396",
    //         style: {
    //           color: "#fff",
    //           background: "#00E396",
    //         },
    //         text: "Y-axis annotation on 8800",
    //       },
    //     },
    //   ],
    // },
    theme: isDark
      ? {
          mode: "dark",
          palette: "palette1",
          monochrome: {
            enabled: false,
            color: "green",
            shadeTo: "light",
            shadeIntensity: 0.65,
          },
        }
      : {},
  };
};
