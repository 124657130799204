import React from "react";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";

const LayoutCounter = ({ data, mode, handlerRemove, handlerUpdate }) => {
  const { userDetails } = useSelector((state) => state.user);



  return (
    <div className="p-4">
      {userDetails.role === "superAdmin" && <div className="d-flex justify-content-end align-items-center">
        {
          mode === 'view' ? '' : (<>
            <div className="clickbtn" onClick={() => handlerUpdate(data._id)}>
              <FiEdit className="text-secondary me-1" />
            </div>
            <div className="clickbtn" onClick={() => handlerRemove(data._id)}>
              <MdDeleteForever className="fs-5 text-secondary" />
            </div>
          </>)
        }
      </div>}
      <div className="mt-5 border p-2 text-center bg-success text-white">
        <h3>Counter Rate</h3>
        <h2>500</h2>
        <strong>KW</strong>
      </div>
    </div>
  );
};

export default LayoutCounter;
