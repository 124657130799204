import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spin, Tag } from "antd";
import { useSelector } from "react-redux";
import { meterStatus } from "../../constant";

const DeviceView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const deviceId = params.meterId;
  const [isDeviceLoading, setDeviceIsLoading] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState();
  const userDetails = useSelector((state) => state.user.userDetails);

  const getDevice = async () => {
    setDeviceIsLoading(true);
    try {
      const response = await axios.get(`/device/${deviceId}`, {
        withCredentials: true,
      });
      if (response) {
        setDeviceDetails(response.data.result);
      }
    } catch (error) {}
    setDeviceIsLoading(false);
  };

  const getStatusColor = (status) => {
    if (status === meterStatus.deactivated) return "red";
    else if (status === meterStatus.offline) return "magenta";
    else if (status === meterStatus.online) return "green";
  };

  useEffect(() => {
    document.title = "IVIS PMS - Device Information";
    getDevice();
  }, [deviceId]);

  return (
    <Spin spinning={isDeviceLoading}>
      <div className="card p-3 m-3">
        <h3>Meter Information</h3>
        <div className="row mt-4">
          <div className="col-md-6">
            <h4 className="mb-4">General Description</h4>
            <div className="row mb-2">
              <div className="col-4">Meter Name</div>
              <div className="col-8">: {deviceDetails?.name}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Site Location</div>
              <div className="col-8">: {deviceDetails?.site?.name}</div>
            </div>
            {deviceDetails?.deviceType === "admin" ? (
              ""
            ) : (
              <div className="row mb-2">
                <div className="col-4">Assign Customer Name</div>
                <div className="col-8">
                  : {deviceDetails?.customer?.name || "N/A"}
                </div>
              </div>
            )}

            {deviceDetails?.deviceType === "admin" && (
              <div className="row mb-2">
                <div className="col-4">Meter API Key</div>
                <div className="col-8">: {deviceDetails?.apiKey || "--"}</div>
              </div>
            )}
            <div className="row mb-2">
              <div className="col-4">Status</div>
              <div className="col-8">
                :{" "}
                <Tag color={getStatusColor(deviceDetails?.status)}>
                  {deviceDetails?.status}
                </Tag>
              </div>
            </div>

            {userDetails?.role !== "user" &&
              deviceDetails?.deviceType !== "admin" && (
                <>
                  <div className="row mb-2">
                    <div className="col-4">TCP port</div>
                    <div className="col-8">: {deviceDetails?.tcpPort}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">Meter ID</div>
                    <div className="col-8">: {deviceDetails?.meterId}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">HEX code (On)</div>
                    <div className="col-8">: {deviceDetails?.onHexCode}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">HEX code (Off)</div>
                    <div className="col-8">: {deviceDetails?.offHexCode}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">HEX code (Check)</div>
                    <div className="col-8">: {deviceDetails?.checkHexCode}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">HEX code (kwh)</div>
                    <div className="col-8">: {deviceDetails?.kwhHexCode}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-4">KWH interval</div>
                    <div className="col-8">: {deviceDetails?.pullInterval}</div>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 d-flex justify-content-end">
            <Link
              to={`/edit-meter/${deviceDetails?._id}`}
              className="btn btn-success me-2"
            >
              Edit
            </Link>
            <button className="btn btn-secondary" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DeviceView;
