import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import moment from "moment";

export const CustomerInfo = ({ receiptId, today, ...rest }) => {
  const styles = StyleSheet.create({
    singleRowOfAdminInfo: {
      marginBottom: "1px",
      display: "flex",
      flexDirection: "row",
    },
    rightTextAdminInfo: {
      fontSize: "12px",
      width: "40%",
    },
    leftTextAdminInfo: {
      fontSize: "12px",
      width: "50%",
    },
    sectionTitle: {
      fontSize: "12px",
      marginBottom: "10px",
      fontWeight: "bold",
    },
    addressRightRow: {
      display: "flex",
      flexDirection: "row",
      width: "60%",
    },
    addressInnerLetfRow: {
      fontSize: "12px",
      width: "4px",
      marginRight: '2px'
    },
    addressInnerRightRow: {
      fontSize: "12px",
    },
  });
  const getCustomerAddress = () => {
    let { unit, address1, address2, city, locationState, postCode } =
      rest || {};
    city = null; postCode = null;
    let customerAddress = ` ${unit || "notExist"},\n  ${address1 || "notExist"},\n  ${address2 || "notExist"
      },\n ${postCode || "notExist"}, ${city ? city : (!city && !postCode) ? "notExist" : 'hasData'},\n ${locationState || "notExist"
      }, Malaysia`;
    customerAddress = customerAddress.replace(/ hasData,/gi, "").trim();
    customerAddress = customerAddress.replace(/ notExist,\n/gi, "").trim();
    return customerAddress.replace(/ notExist,/gi, "").trim();
  };
  return (
    <>
      <Text style={styles.sectionTitle}>Customer Information</Text>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Report Generation Date</Text>
        <Text style={styles.leftTextAdminInfo}>
          : {moment(today).format("HH:mm:ss DD/MM/YYYY")}
        </Text>
      </View>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Receipt ID</Text>
        <Text style={styles.leftTextAdminInfo}>: {receiptId}</Text>
      </View>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Customer ID</Text>
        <Text style={styles.leftTextAdminInfo}>
          : {rest?.customerId || "--"}
        </Text>
      </View>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Customer Name</Text>
        <Text style={styles.leftTextAdminInfo}>: {rest?.customerName || "--"}</Text>
      </View>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Site Name</Text>
        <Text style={styles.leftTextAdminInfo}>
          : {rest?.siteName || "--"}
        </Text>

      </View>
      <View style={styles.singleRowOfAdminInfo}>
        <Text style={styles.rightTextAdminInfo}>Customer Address</Text>
        {/* <Text style={styles.leftTextAdminInfo}> */}
        <View style={styles.addressRightRow}>
          <Text style={styles.addressInnerLetfRow}>:</Text>
          <Text style={styles.addressInnerRightRow}>{getCustomerAddress() || "--"}</Text>
        </View>
        {/* </Text> */}
      </View>
    </>
  );
};
