import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecipientListTable from "./RecipientListTable";
import { endpoint } from "../../api";
import { Input, Select, Spin } from "antd";

const RecipientList = () => {
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [siteLocations, setSiteLocations] = useState([]);
  const [assignedAlarm, setAssignedAlarm] = useState([]);
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationsEx, setNotificationsEx] = useState([]);
  const [assignAlerm, setAssignAlarm] = useState({});
  const [AssignAlarmEx, setAssignAlarmEx] = useState({
    isActive: true,
  });

  const onInputChangeEx = ({ name, value }) => {
    setAssignAlarmEx({ ...AssignAlarmEx, [name]: value });
  };
  const onInputChange = ({ name, value }) => {
    setAssignAlarm({ ...assignAlerm, [name]: value });
  };
  const handleUserChange = (value) => {
    let user = users.filter((item) => item._id === value);
    setAssignAlarm({
      ...assignAlerm,
      user: value,
      name: user[0]?.name,
      email: user[0]?.email,
    });
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocations(response.data);
    } catch (error) {}
  };
  const getNotifications = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: assignAlerm.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotifications(res.data?.results));
  };
  useEffect(() => {
    if (assignAlerm.site) {
      getNotifications();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site]);

  const getNotificationsEx = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: AssignAlarmEx.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotificationsEx(res.data?.results));
  };
  useEffect(() => {
    if (AssignAlarmEx.site) {
      getNotificationsEx();
    }
    // eslint-disable-next-line
  }, [AssignAlarmEx.site]);
  // console.log({ assignAlerm });
  const getUsers = async () => {
    if (!assignAlerm?.site || !assignAlerm?.role) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(
        `/user/siteUser/${assignAlerm.site}/${assignAlerm?.role}`,
        {
          withCredentials: true,
        }
      );
      if (response) {
        setUsers(response.data);
      }
    } catch (error) {
      setUsers([]);
    }
  };
  useEffect(() => {
    if (assignAlerm?.role === "external") {
    } else {
      getUsers();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site, assignAlerm?.role]);

  const getAssignedAlarm = async () => {
    setIsTableLoading(true);
    const response = await axios.get(`/notification/assign`, {
      withCredentials: true,
    });
    if (response) {
      setAssignedAlarm(response.data);
    }
    setIsTableLoading(false);
  };
  useEffect(() => {
    document.title = "IVIS PMS - Recipient List";
    getAssignedAlarm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSiteLocations(userDetails);
    // eslint-disable-next-line
  }, [userDetails]);

  const handleSubmitEx = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await axios.post(
      `/notification/assign`,
      { ...AssignAlarmEx, ...assignAlerm },
      {
        withCredentials: true,
      }
    );
    if (response) {
      setIsLoading(false);
      setSuccessMessage("Alarm Assign Successfully");
      setTimeout(() => {
        setSuccessMessage();
        window.location.reload(false);
      }, 2000);
    }
  };

  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="card p-3 mb-3">
            <div className="row">
              <div className="col-md-6">
                <h6>Notification Recipient List</h6>
                <div className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" variant="dark" />}
                </div>
                {SuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {SuccessMessage}
                  </div>
                )}
              </div>
              <div className="col-md-6 ">
                <Link
                  to="/alarm-summary"
                  className="btn btn-secondary float-end"
                >
                  Back
                </Link>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6 border-end">
                <h5 className="pb-2">Assign Existing System User Accounts</h5>
                <form onSubmit={handleSubmitEx}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="site" className="form-label">
                        Site Location
                      </label>
                      <Select
                        name="site"
                        size="large"
                        className="w-100"
                        value={assignAlerm?.site || null}
                        // className="form-select"
                        onChange={(value) =>
                          onInputChange({ name: "site", value })
                        }
                        placeholder="Select Site"
                      >
                        {siteLocations &&
                          siteLocations.length > 0 &&
                          siteLocations.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="role" className="form-label">
                        User Type
                      </label>
                      <Select
                        name="role"
                        value={assignAlerm?.role || null}
                        className="w-100"
                        size="large"
                        onChange={(value) =>
                          onInputChange({ name: "role", value })
                        }
                        placeholder="Select Role"
                      >
                        <option value="businessAdmin">Business Admin</option>
                        <option value="admin">Site Admin</option>
                        <option value="installer">Installer</option>
                        <option value="user">Customer</option>
                      </Select>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="user" className="form-label">
                        Site User
                      </label>
                      <Select
                        name="user"
                        id="user"
                        // className="form-select"
                        style={{ width: "100%" }}
                        size="large"
                        onChange={handleUserChange}
                        value={assignAlerm?.user || null}
                        placeholder="Select User"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.children
                            ? option?.children.toLowerCase()
                            : ""
                          ).includes(input?.toLowerCase())
                        }
                      >
                        {users &&
                          users.length > 0 &&
                          users.map((item, index) => (
                            <option value={item._id} key={index}>
                              {`${item.name}
                              ${
                                assignAlerm?.role !== "user"
                                  ? ""
                                  : `(${item.customerId})`
                              }`}
                            </option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="alarm" className="form-label">
                        Alarm Name
                      </label>
                      <Select
                        name="alarm"
                        value={assignAlerm.alarm || null}
                        className="w-100"
                        size="large"
                        onChange={(value) =>
                          onInputChange({ name: "alarm", value })
                        }
                        placeholder="Select Alarm"
                      >
                        {notifications &&
                          notifications.length > 0 &&
                          notifications.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-success mt-4"
                        disabled={
                          !assignAlerm?.role ||
                          !assignAlerm?.user ||
                          !assignAlerm?.alarm
                        }
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <h5 className="pb-2">
                  Assign Email Address (External Accounts)
                </h5>
                <form onSubmit={handleSubmitEx}>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="site" className="form-label">
                        Site Location
                      </label>
                      <Select
                        name="site"
                        size="large"
                        className="w-100"
                        value={AssignAlarmEx?.site || null}
                        // className="form-select"
                        onChange={(value) =>
                          onInputChangeEx({ name: "site", value })
                        }
                        placeholder="Select Site"
                      >
                        {siteLocations &&
                          siteLocations.length > 0 &&
                          siteLocations.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="name" className="form-label">
                        Full Name
                      </label>
                      {console.log(AssignAlarmEx)}
                      <Input
                        type="text"
                        name="name"
                        size="large"
                        placeholder="Enter full name"
                        value={AssignAlarmEx?.name || null}
                        onChange={(e) =>
                          onInputChangeEx({
                            name: "name",
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>

                      <Input
                        type="email"
                        name="email"
                        size="large"
                        placeholder="Enter email"
                        value={AssignAlarmEx?.email || null}
                        onChange={(e) =>
                          onInputChangeEx({
                            name: "email",
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <label htmlFor="alarm" className="form-label">
                        Alarm Name
                      </label>
                      <Select
                        name="alarm"
                        value={AssignAlarmEx.alarm || null}
                        className="w-100"
                        size="large"
                        onChange={(value) =>
                          onInputChangeEx({ name: "alarm", value })
                        }
                        placeholder="Select Alarm"
                      >
                        {notificationsEx &&
                          notificationsEx.length > 0 &&
                          notificationsEx.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <button
                        type="submit"
                        className="btn btn-success mt-4 float-end"
                        disabled={
                          !AssignAlarmEx?.site ||
                          !AssignAlarmEx?.name ||
                          !AssignAlarmEx?.email ||
                          !AssignAlarmEx?.alarm
                        }
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr />
            <Spin spinning={isTableLoading}>
              <RecipientListTable
                data={assignedAlarm}
                getAssignedAlarm={getAssignedAlarm}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientList;
