import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { endpoint } from "../../api";

const AlarmView = () => {
  const Params = useParams();
  const alarmId = Params.alarmId;
  const [alarmDetails, setAlarmDetails] = useState();
  const [isLoading, setLoading] = useState(false);

  const getNotification = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        endpoint.notification_v1 + "/" + alarmId,
        {
          withCredentials: true,
        }
      );
      if (response) {
        setAlarmDetails(response.data);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="installer-view">
      <div className="container-fluid d-block">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <h3>Alarm Profile</h3>
              <Spin spinning={isLoading}>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="row mb-2">
                      <div className="col-5">Alarm Name</div>
                      <div className="col-7">: {alarmDetails?.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Alarm Type</div>
                      <div className="col-7 text-capitalize">
                        : {alarmDetails?.type}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Site Location</div>
                      <div className="col-7">: {alarmDetails?.site?.name}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Device</div>
                      <div className="col-7">
                        : {alarmDetails?.device?.name}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Device Parameter</div>
                      <div className="col-7">
                        : {alarmDetails?.parameter?.name}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Alarm Notification Option</div>
                      <div className="col-7 text-capitalize">
                        : {alarmDetails?.option}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5 text-capitalize">
                        {alarmDetails?.option} value
                      </div>
                      <div className="col-7">
                        :
                        {alarmDetails?.option === "max"
                          ? alarmDetails?.max
                          : alarmDetails?.option === "min"
                          ? alarmDetails?.min
                          : `${alarmDetails?.min} to ${alarmDetails?.max}`}
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-5">Trigger Interval</div>
                      <div className="col-7">
                        : {alarmDetails?.interval} min
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-end">
                    <Link
                      to={`/edit-alarm/` + alarmId}
                      className="btn btn-success me-1"
                    >
                      Edit
                    </Link>
                    <Link to={`/alarm-summary`} className="btn btn-secondary">
                      Back
                    </Link>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmView;
