import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";

const AreaChart = ({ name, data, xAxisName, from, to, title = "" }) => {
  let isDark = false;
  const [chartInfo, setChartInfo] = useState({
    series: [
      {
        name: name,
        data: data,
      },
    ],
    options: getOptions({
      title,
      xAxisName,
      isDark,
      from,
      to,
    }),
  });

  useEffect(() => {
    let newOptions = getOptions({
      title,
      xAxisName,
      isDark,
      from,
      to,
    });
    setChartInfo({
      ...chartInfo,
      options: newOptions,
      series: [
        {
          name: name,
          data,
        },
      ],
    });
  }, [data, isDark, from, to]);

  return (
    <div>
      <Chart
        options={chartInfo.options}
        series={chartInfo.series}
        type="area"
        height={350}
      />
      {xAxisName && (
        <p
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textAlign: "center",
            margin: "-10px 0 0",
          }}
        >
          {xAxisName}
        </p>
      )}
    </div>
  );
};

export default AreaChart;

const getOptions = ({ title, isDark, from, to }) => {
  let start = new Date(from);
  let end = new Date(to);

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  // console.log({ start, end, from, to });
  // console.log( start, end)
  // start.setHours(0, 0, 0, 0);
  // end.setHours(23, 59, 59, 999);

  return {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: start.getTime(),
      max: end.getTime(),
      tickAmount: 1,
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      title: {
        text: "Energy (kWh)",
        style: {
          fontSize: "14px",
          // color: "#000",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    title: {
      text: title,
      align: "center",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
    theme: isDark ? { mode: "dark" } : { mode: "light" },

    stroke: { width: 2 },
  };
};
