import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Checkbox, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { meterStatus } from "../../constant";
import { apiNotification } from "../../utils";

const AddDevice = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [haveSamePortAndID, setSamePortAndID] = useState(false);
  const navigate = useNavigate();
  const [deviceData, setDeviceData] = useState({
    name: "",
    apiKey: "",
    site: "",
    customer: "",
    tcpPort: "",
    offHexCode: "",
    onHexCode: "",
    meterId: "",
    kwhHexCode: "",
    checkHexCode: "",
    pullInterval: 5,
    deviceType: "customer",
    status: meterStatus.offline,
    isDisableOfflineNotification: false,
  });
  const {
    name,
    apiKey,
    site,
    tcpPort,
    offHexCode,
    onHexCode,
    deviceType,
    kwhHexCode,
    meterId,
    checkHexCode,
    pullInterval,
    customer,
    isDisableOfflineNotification,
  } = deviceData;
  const userDetails = useSelector((state) => state.user.userDetails);
  // console.log(deviceData);
  const onInputChange = (e) => {
    if (e.target.name === "site") {
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setDeviceData({ ...deviceData, site: selectedOptions, customer: "" });
    } else if (e.target.name === "deviceType") {
      setDeviceData({
        ...deviceData,
        [e.target.name]: e.target.value,
        customer: "",
        tcpPort: "",
        meterId: "",
        apiKey: "",
        onHexCode: "",
        offHexCode: "",
        checkHexCode: "",
        kwhHexCode: "",
      });
    } else {
      setDeviceData({ ...deviceData, [e.target.name]: e.target.value });
    }
  };
  // console.log(deviceData);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!deviceData?.site) {
      apiNotification({
        message: "Please select Site.",
        warn: true,
      });
      return;
    } else if (!deviceData?.customer && deviceData?.deviceType === "customer") {
      apiNotification({
        message: "Please select a Customer.",
        warn: true,
      });
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        `/device`,
        { ...deviceData, tcpPort: tcpPort.trim() },
        {
          withCredentials: true,
        }
      );
      setTimeout(() => {
        navigate("/meters");
      }, 2000);
      apiNotification({
        message: "New device created successfully",
      });
    } catch (error) {
      apiNotification({
        message: error?.response?.data?.message || "Something went wrong.",
        warn: true,
      });
    }
    setIsLoading(false);
  };

  const getUser = async () => {
    if (!site) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(
        `user/siteUser/${site}/${deviceType === "admin" ? "admin" : "user"}`,
        {
          withCredentials: true,
          params: {
            dontHaveDevice: true,
          },
        }
      );
      if (response) {
        // console.log(response)
        setUsers(Array.isArray(response?.data) ? response?.data : []);
        // setUsers(response?.data || []);
      }
    } catch (error) {
      setUsers([]);
    }
  };

  //htmlFor site location dropdown
  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(response.data);
      }
    } catch (error) {}
  };
  const checkPortAndID = async () => {
    try {
      const response = await axios.get(`/device/check-port-and-meterId`, {
        params: { tcpPort: tcpPort.trim(), meterId },
      });
      setSamePortAndID(response?.data?.isHave);
    } catch (error) {
      setSamePortAndID(false);
    }
  };
  useEffect(() => {
    getUser();
  }, [site, deviceType]);
  useEffect(() => {
    if (tcpPort) checkPortAndID();
    else setSamePortAndID(false);
  }, [tcpPort, meterId]);
  useEffect(() => {
    document.title = "IVIS PMS - Add New Meter";
    getSiteLocations();
    // eslint-disable-next-line
  }, []);
  console.log(
    deviceType === "customer" &&
      (haveSamePortAndID ||
        !tcpPort ||
        !meterId ||
        !onHexCode ||
        !offHexCode ||
        !checkHexCode ||
        !kwhHexCode ||
        !customer)
  );
  return (
    <Spin spinning={isLoading}>
      <div className="devices">
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="card p-3 mb-3">
              <div className="row">
                <div className="col-md-6">
                  <h6 className="mb-4">Add New Meter</h6>
                </div>
              </div>
              <form onSubmit={submitHandler}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Meter Name <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={onInputChange}
                      className="form-control"
                      id="name"
                      placeholder="Enter Meter Name"
                      required
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="site" className="form-label">
                      Site Location <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      className="form-select"
                      id="site"
                      name="site"
                      value={site}
                      onChange={onInputChange}
                      aria-label="Select Site"
                    >
                      <option label="Select Site" value=""></option>
                      {siteLocations &&
                        siteLocations.length > 0 &&
                        siteLocations.map((item, index) => (
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="deviceType" className="form-label">
                      Meter Type <sup style={{ color: "red" }}>*</sup>
                    </label>
                    <select
                      className="form-select"
                      id="deviceType"
                      name="deviceType"
                      value={deviceType}
                      onChange={onInputChange}
                    >
                      <option value={"customer"}>Customer Meter</option>
                      <option value={"admin"}>Admin Meter</option>
                    </select>
                  </div>
                </div>
                {deviceType === "admin" ? (
                  ""
                ) : (
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="customer" className="form-label">
                        {deviceType === "admin" ? "Admin" : "Customer"}{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        value={customer}
                        onChange={(value) =>
                          setDeviceData({ ...deviceData, customer: value })
                        }
                        filterOption={(input, option) =>
                          (option?.children
                            ? option?.children.toLowerCase()
                            : ""
                          ).includes(input?.toLowerCase())
                        }
                        size="large"
                      >
                        <Select.Option value={""} disabled>
                          {`Select ${
                            deviceType === "admin" ? "Admin" : "Customer"
                          }`}
                        </Select.Option>
                        {users?.length &&
                          users.map(({ _id, name, customerId }) => (
                            <Select.Option value={_id}>
                              {`${name} (${customerId})`}
                              {/* {name}{" "}
                              {deviceType === "admin" ? "" : `(${customerId})`} */}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  </div>
                )}
                {deviceType !== "customer" && (
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label htmlFor="apiKey" className="form-label">
                        Meter API Key <sup style={{ color: "red" }}>*</sup>
                      </label>
                      <input
                        type="text"
                        name="apiKey"
                        value={apiKey}
                        onChange={onInputChange}
                        className="form-control"
                        id="apiKey"
                        placeholder="Enter device API key"
                        required
                      />
                      <div id="emailHelp" className="form-text">
                        API Key is mandatory to get device data from the gateway
                      </div>
                    </div>
                  </div>
                )}
                {userDetails?.role !== "user" && deviceType !== "admin" && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label">
                          TCP Port <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          type="text"
                          name="tcpPort"
                          value={tcpPort}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter TCP Port"
                          required
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginBottom: "5px" }}>
                      <div className="col-md-6">
                        <label className="form-label">
                          Meter ID <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          type="text"
                          name="meterId"
                          value={meterId}
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Enter Meter ID"
                          required
                        />
                        <div
                          id="emailHelp"
                          className="form-text"
                          style={{
                            visibility: haveSamePortAndID
                              ? "visible"
                              : "hidden",
                            color: "red",
                          }}
                        >
                          Meter ID is taken.
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label">
                          HEX Code (On) <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="onHexCode"
                          value={onHexCode}
                          onChange={onInputChange}
                        >
                          <option
                            label="Select HEX Code For On"
                            value=""
                          ></option>
                          <option value="05 00 00 FF 00">05 00 00 FF 00</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label">
                          HEX Code(Off) <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="offHexCode"
                          value={offHexCode}
                          onChange={onInputChange}
                        >
                          <option
                            label="Select HEX Code For Off"
                            value=""
                          ></option>
                          <option value="05 00 00 00 00">05 00 00 00 00</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label">
                          HEX Code(Check) <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="checkHexCode"
                          value={checkHexCode}
                          onChange={onInputChange}
                        >
                          <option
                            label="Select HEX Code For Status Check"
                            value=""
                          ></option>
                          <option value="01 00 00 00 01">01 00 00 00 01</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label className="form-label">
                          HEX Code(kwh) <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                          className="form-select"
                          name="kwhHexCode"
                          value={kwhHexCode}
                          onChange={onInputChange}
                        >
                          <option
                            label="Select HEX Code For KWH"
                            value=""
                          ></option>
                          <option value="04 01 56 00 02">04 01 56 00 02</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-md-6">
                        <div>
                          <div className="input-group">
                            <Checkbox
                              style={{ fontSize: "1rem", fontWeight: "400" }}
                              checked={isDisableOfflineNotification}
                              onChange={(e) =>
                                setDeviceData({
                                  ...deviceData,
                                  isDisableOfflineNotification:
                                    e.target.checked,
                                })
                              }
                            >
                              Disable Offline Meter Email Notifications
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex justify-content2-end align-items-center">
                          <label className="form-label">Interval <sup style={{ color: "red" }}>*</sup> :</label>
                          <input
                            type="number"
                            name="pullInterval"
                            value={pullInterval}
                            style={{ maxWidth: "100px", marginLeft: "10px" }}
                            onChange={onInputChange}
                            className="form-control"
                            placeholder="Enter TCP port"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {deviceData?.deviceType === "admin" ? (
                  <div className="row mb-5">
                    <div className="col-md-6">
                      <div>
                        <div className="input-group">
                          <Checkbox
                            style={{ fontSize: "1rem", fontWeight: "400" }}
                            s
                            checked={isDisableOfflineNotification}
                            onChange={(e) =>
                              setDeviceData({
                                ...deviceData,
                                isDisableOfflineNotification: e.target.checked,
                              })
                            }
                          >
                            Disable Offline Meter Email Notifications
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6"></div>
                  </div>
                ) : (
                  ""
                )}
                <div className="float-end">
                  <button
                    type="submit"
                    className="btn btn-success me-2"
                    disabled={
                      (deviceType === "admin" && !apiKey) ||
                      (deviceType === "customer" &&
                        (haveSamePortAndID ||
                          !tcpPort ||
                          !meterId ||
                          !onHexCode ||
                          !offHexCode ||
                          !checkHexCode ||
                          !kwhHexCode ||
                          // !customer ||
                          !pullInterval)) ||
                      !name ||
                      !site?.length
                    }
                  >
                    Create Meter
                  </button>
                  <Link to="/meters" className="btn btn-secondary">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AddDevice;

const hexCodeOptions = [
  "01 00 00 00 01",
  "05 00 00 FF 00",
  "05 00 00 00 00",
  "04 01 56 00 02",
];

// Check - 01 00 00 00 01
// On - 05 00 00 FF 00
// Off - 05 00 00 00 00
// KWH - 04 01 56 00 02
