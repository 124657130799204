import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export default function SiteLocationView() {
  const Params = useParams();
  const siteLocationId = Params.siteLocationId;
  const [siteLocationDetails, setSiteLocationDetails] = useState();
  const getSiteLocation = async () => {
    const response = await axios.get(`/site-location/` + siteLocationId, {
      withCredentials: true,
    });
    if (response) {
      setSiteLocationDetails(response.data);
    }
  };
  useEffect(() => {
    document.title = "IVIS PMS - Site Location";
    getSiteLocation();
  }, []);
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div className="col-md-8 p-0">
                <label className="az-content-label">Manage Site Location</label>
                <span className="d-block py-2">Site Location Details</span>
              </div>
              <div className="col-md-4 d-flex justify-content-end p-0"></div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Site Name</div>
                  <div className="col-8">: {siteLocationDetails?.name}</div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Location Address Line 1</div>
                  <div className="col-8">
                    : {siteLocationDetails?.buildingAddress1}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Location Address Line 2</div>
                  <div className="col-8">
                    : {siteLocationDetails?.buildingAddress2}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4"> Location Postal Code </div>
                  <div className="col-8">
                    : {siteLocationDetails?.buildingPostalCode}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4"> Location City </div>
                  <div className="col-8">
                    : {siteLocationDetails?.locationCity}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4"> Location State </div>
                  <div className="col-8">
                    : {siteLocationDetails?.locationState}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4"> Location Country </div>
                  <div className="col-8">
                    : {siteLocationDetails?.buildingCountry || "Malaysia"}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row mb-2">
                  <div className="col-4">Admin</div>
                  <div className="col-8">
                    {" "}
                    {siteLocationDetails?.admin?.map(({_id, name}) => (
                      <div key={_id}>: {name} </div>
                    ))}
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-4">Installer</div>
                  <div className="col-8">
                    <div className="d-flex">
                      :
                      <div className="ml-1">
                        {siteLocationDetails?.installer.map((item) => (
                          <div>{item?.name}</div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 d-flex justify-content-end">
                <Link
                  to={`/edit-site-location/` + siteLocationId}
                  className="btn btn-success me-1"
                >
                  Edit
                </Link>
                <Link to={`/site-locations`} className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
