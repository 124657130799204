import moment from "moment";
import React from "react";
import { numberFormater } from "../../../utils";
import { transactionTypes } from "../CustomerPaymentSummary";
import { TablePdf } from "./TablePdf";

export const TableFormatterPdf = ({ report }) => {
  // prepaid reload
  const prepaidTopRows = [
    {
      title: "Amount Reloaded into Account (RM)",
      value: numberFormater(report.reloadedBalance),
    },
    { title: "Reload Type", value: "Manual Reload (By Admin)" },
    {
      title: "Reload Date",
      value: moment(report.createdAt).format("DD/MM/YYYY"),
    },
  ];
  const prepaidBottomRows = [
    {
      title: "Total Amount Paid (RM)",
      value: numberFormater(report.reloadedBalance),
      algnRight: true,
      bold: true,
    },
    {
      title: "Previous Account Balance (RM)",
      value: numberFormater(report.currentBalance - report.reloadedBalance),
      algnRight: true,
    },
    {
      title: "New Account Balance (RM)",
      value: numberFormater(report.currentBalance),
      algnRight: true,
    },
  ];

  // one time deposite/internal fee
  const oneTimeDepositeTopRows = [
    {
      title: "Total Cost (RM)",
      value:
        report.transactionType === transactionTypes.internalWiringPayment
          ? "816.00"
          : "300.00",
    },
  ];
  const oneTimeDepositeBottomRows = [
    {
      title: "Total Amount Paid (RM)",
      value: numberFormater(report.reloadedBalance),
      algnRight: true,
      bold: true,
    },
  ];

  // making the date range for installment
  let firstDay = new Date(report.createdAt);
  let lastDay = new Date(report.createdAt);
  if (report.transactionType === transactionTypes.internalWiringPayment) {
    let [startDate, endDate] =
      report?.user?.plan?.internalInstallmentPeriod?.split("-");
    if (startDate && endDate) {
      firstDay = "7/" + startDate.trim();
      lastDay = "7/" + endDate.trim();
    }
    // const internalInstallmentCount = report.internalInstallmentCount;
    // firstDay = new Date(
    //   firstDay.getFullYear(),
    //   firstDay.getMonth() - internalInstallmentCount,
    //   1
    // );
    // lastDay = new Date(
    //   lastDay.getFullYear(),
    //   lastDay.getMonth() + (48 - internalInstallmentCount),
    //   0
    // );
  } else {
    let [startDate, endDate] =
      report?.user?.plan?.depositInstallmentPeriod?.split("-");
    if (startDate && endDate) {
      firstDay = "7/" + startDate.trim();
      lastDay = "7/" + endDate.trim();
    }
    // const depositInstallmentCount = report.depositInstallmentCount;
    // firstDay = new Date(
    //   firstDay.getFullYear(),
    //   firstDay.getMonth() - depositInstallmentCount,
    //   1
    // );
    // lastDay = new Date(
    //   lastDay.getFullYear(),
    //   lastDay.getMonth() + (12 - depositInstallmentCount),
    //   0
    // );
  }
  firstDay = moment(firstDay).format("DD/MM/YYYY");
  lastDay = moment(lastDay).format("DD/MM/YYYY");

  // one time deposite/internal fee
  const depositInstallmentTopRows = [
    {
      title: "Total Cost (RM)",
      value:
        report.transactionType === transactionTypes.internalWiringPayment
          ? "816.00"
          : "300.00",
    },
    {
      title: "Month",
      value: moment(report.createdAt).format("MMMM, YYYY"),
    },
    {
      title: `Instalment Period (${
        report.transactionType === transactionTypes.internalWiringPayment
          ? 48
          : 12
      } Months)`,
      value: report.user
        ? report.transactionType === transactionTypes.internalWiringPayment
          ? `7/${report.user?.plan?.internalInstallmentPeriod
              .split("-")?.[0]
              ?.trim()} - ${report.user?.plan?.internalInstallmentPeriod
              .split("-")?.[1]
              ?.trim()}`
          : `7/${report.user?.plan?.depositInstallmentPeriod
              .split("-")?.[0]
              ?.trim()} - ${report.user?.plan?.depositInstallmentPeriod
              .split("-")?.[1]
              ?.trim()}`
        : "N/A",
    },
    {
      title: "This Month Deduction (RM)",
      value:
        report.transactionType === transactionTypes.internalWiringPayment
          ? `17.00`
          : `25.00`,
    },
    {
      title: "Payment Schedule",
      value:
        report.transactionType === transactionTypes.internalWiringPayment
          ? `${report.internalInstallmentCount} / 48`
          : `${report.depositInstallmentCount} / 12`,
    },
    {
      title: "Total Outstanding (RM)",
      value:
        report.transactionType === transactionTypes.internalWiringPayment
          ? `${(816 - report.internalInstallmentCount * 17).toFixed(
              2
            )} / 816.00`
          : `${(300 - report.depositInstallmentCount * 25).toFixed(
              2
            )} / 300.00`,
    },
  ];
  const depositInstallmentBottomRows = [
    {
      title: "Total Amount Deducted (RM)",
      value: Number(report.reloadedBalance).toFixed(2),
      algnRight: true,
      bold: true,
    },
    {
      title: "Previous Account Balance (RM)",
      value: numberFormater(report.currentBalance + report.reloadedBalance),
      algnRight: true,
    },
    {
      title: "New Account Balance (RM)",
      value: numberFormater(report.currentBalance),
      algnRight: true,
    },
  ];

  const getTableData = () => {
    if (report.transactionType === transactionTypes.prepaidReload) {
      return {
        rows1: prepaidTopRows,
        rows2: prepaidBottomRows,
        title: "Account Prepaid Reload",
      };
    } else if (
      report.transactionType === transactionTypes.depositPayment &&
      report.oneTime
    ) {
      return {
        rows1: oneTimeDepositeTopRows,
        rows2: oneTimeDepositeBottomRows,
        title: "Other Charges : Deposit (One-Time Fee)",
      };
    } else if (
      report.transactionType === transactionTypes.internalWiringPayment &&
      report.oneTime
    ) {
      return {
        rows1: oneTimeDepositeTopRows,
        rows2: oneTimeDepositeBottomRows,
        title: "Other Charges : Internal Wiring (One-Time Fee)",
      };
    } else if (
      report.transactionType === transactionTypes.adminFees ||
      report.transactionType === transactionTypes.lateRegistrationCompound ||
      report.transactionType === transactionTypes.repairServices ||
      report.transactionType === transactionTypes.reconnectionFeeCharges
    ) {
      return {
        rows1: [
          {
            title: "Total Cost (RM)",
            value: getAmountForOtherCharges(report.transactionType),
          },
        ],
        rows2: [
          {
            title: "Total Amount Paid (RM)",
            value: numberFormater(report.reloadedBalance),
            algnRight: true,
            bold: true,
          },
        ],
        title: `Other Charges : ${getTitleForOtherCharges(
          report.transactionType
        )}`,
      };
    } else {
      return {
        rows1: depositInstallmentTopRows,
        rows2: depositInstallmentBottomRows,
        title: `Other Charges : ${
          report.transactionType === transactionTypes.internalWiringPayment
            ? "Internal Wiring"
            : "Deposit Installment"
        }`,
      };
    }
  };
  const data = getTableData();
  return (
    <>
      <TablePdf tableTitle={data.title} rows={data.rows1} />
      <TablePdf rows={data.rows2} />
    </>
  );
};

const getTitleForOtherCharges = (transactionType) => {
  if (transactionType === transactionTypes.adminFees)
    return "Admin Fee Charges";
  if (transactionType === transactionTypes.lateRegistrationCompound)
    return "Late Registration Compound Fee";
  if (transactionType === transactionTypes.reconnectionFeeCharges)
    return "Reconnection Fee Charges";
  if (transactionType === transactionTypes.repairServices)
    return "Repair Services Charges";
};
const getAmountForOtherCharges = (transactionType) => {
  if (transactionType === transactionTypes.adminFees) return "50.00";
  if (transactionType === transactionTypes.lateRegistrationCompound)
    return "100.00";
  if (transactionType === transactionTypes.reconnectionFeeCharges)
    return "200.00";
  if (transactionType === transactionTypes.repairServices) return "350.00";
};
